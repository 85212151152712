//
// Purpose: SAMB - product Row
// Author: Ronny Van Elewyck
// Version: 1.0
// Creation Date: 28.05.2020
// Modification Date: 22.10.2020
//

// installed components
import React from "react";
import { Typeahead } from "react-bootstrap-typeahead";
import "react-bootstrap-typeahead/css/Typeahead.css";
import Tooltip from "react-bootstrap/Tooltip";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";

// config files
import {
  saveClass2,
  deleteClass5,
  classErrorMessage,
  productMessage1,
  productMessage2,
  productMessage3,
} from "../../config.json";

const ProductRow = ({
  item,
  saveProductItem,
  deleteMaterialItem,
  productList,
}) => {
  //
  // format row of table
  //

  const [order, setValue1] = React.useState(item.order);
  const [quantity, setValue2] = React.useState(parseInt(item.quantity));
  const [product, setValue3] = React.useState(item.producttext);

  // validate input
  const [showError1, setShowError1] = React.useState("");
  const [isError1, setError1] = React.useState(false);
  const [showError2, setShowError2] = React.useState("");
  const [isError2, setError2] = React.useState(false);
  const [showError3, setShowError3] = React.useState("");
  const [isError3, setError3] = React.useState(false);

  React.useEffect(() => {
    if (order > 0 && order < 51) {
      setError1(false);
      setShowError1("");
    } else {
      setError1(true);
      setShowError1(productMessage1);
    }
  }, [order]);

  React.useEffect(() => {
    const regex = /^[0-9]*$/;

    const found = quantity.toString().match(regex);

    if (found === null || quantity.toString() === "") {
      setError2(true);
      setShowError2(productMessage2);
    } else {
      setError2(false);
      setShowError2("");
    }
  }, [quantity]);

  React.useEffect(() => {
    if (typeof product === "undefined") {
      setError3(true);
      setShowError3(productMessage3);
    } else {
      setError3(false);
      setShowError3("");
    }
  }, [product]);

  function renderTooltip(title) {
    return <Tooltip id="button-tooltip">{title}</Tooltip>;
  }

  return (
    <tr>
      <td>
        {" "}
        <div>
          {!isError1 && !isError2 && !isError3 && (
            <OverlayTrigger
              placement="left"
              delay={{ show: 250, hide: 400 }}
              overlay={renderTooltip("bewaar in geheugen")}
            >
              <div>
                <button
                  className={saveClass2}
                  onClick={() =>
                    saveProductItem({ ...item, order, quantity, product })
                  }
                ></button>{" "}
              </div>
            </OverlayTrigger>
          )}
          <OverlayTrigger
            placement="left"
            delay={{ show: 250, hide: 400 }}
            overlay={renderTooltip("verwijder")}
          >
            <div>
              <button
                className={deleteClass5}
                onClick={() => deleteMaterialItem({ ...item })}
              ></button>
            </div>
          </OverlayTrigger>
        </div>
      </td>
      <td>
        {isError1 && <div className={classErrorMessage}>{showError1}</div>}
        <input
          type="text"
          className="form-control"
          value={order}
          onChange={(e) => setValue1(e.currentTarget.value)}
        />
      </td>
      <td>
        {isError3 && <div className={classErrorMessage}>{showError3}</div>}
        <Typeahead
          id={"producttext"}
          labelKey={"label"}
          options={productList}
          placeholder={product}
          onChange={(selectedOptions) => setValue3(selectedOptions[0])}
          highlightOnlyResult={true}
          clearButton={true}
        />
      </td>
      <td>
        {isError2 && <div className={classErrorMessage}>{showError2}</div>}
        <input
          type="text"
          className="form-control"
          value={quantity}
          onChange={(e) => setValue2(e.currentTarget.value)}
        />
      </td>
    </tr>
  );
};

export default ProductRow;
