//
// Purpose: SAMB - convert dates functions
// Author: Ronny Van Elewyck
// Version: 1.1
// Creation Date: 02.03.2020
// Modification Date: 04.12.2020
//

export function slashdate(yyyymmdd) {
  const yyyy = yyyymmdd.substring(0, 4);
  const mm = yyyymmdd.substring(4, 6);
  const dd = yyyymmdd.substring(6, 8);

  return dd + "/" + mm + "/" + yyyy;
}

export function slashdateUTC(yyyymmdd) {
  const yyyy = yyyymmdd.substring(0, 4);
  const mm = yyyymmdd.substring(4, 6);
  const dd = yyyymmdd.substring(6, 8);

  return mm + "/" + dd + "/" + yyyy;
}

export function slashdateFromUTC(longdate) {
  const mm = longdate.getMonth() + 1; // getMonth() is zero-based
  const dd = longdate.getDate();

  return [
    (dd > 9 ? "" : "0") + dd + "/",
    (mm > 9 ? "" : "0") + mm + "/",
    longdate.getFullYear(),
  ].join("");
}

export function yyyymmdd(longdate) {
  var mm = longdate.getMonth() + 1; // getMonth() is zero-based
  var dd = longdate.getDate();

  return [
    longdate.getFullYear(),
    (mm > 9 ? "" : "0") + mm,
    (dd > 9 ? "" : "0") + dd,
  ].join("");
}

export function fromToDate(longdate) {
  let year = longdate.getUTCFullYear();
  const nextYear = year + 2;
  year = year - 1;
  const fromDate = year + "0101";
  const toDate = nextYear + "1231";

  return {
    fromDate,
    toDate,
  };
}

export function yyyymmddhhmmss(longdate) {
  let mm = longdate.getMonth() + 1; // getMonth() is zero-based
  let dd = longdate.getDate();
  let seconds = longdate.getSeconds();
  let minutes = longdate.getMinutes();
  let hour = longdate.getHours();

  return [
    longdate.getFullYear(),
    (mm > 9 ? "" : "0") + mm,
    (dd > 9 ? "" : "0") + dd,
    hour,
    minutes,
    seconds,
  ].join("");
}

// eslint-disable-next-line
export default {
  slashdate,
  slashdateUTC,
  slashdateFromUTC,
  yyyymmdd,
  fromToDate,
  yyyymmddhhmmss,
};
