//
// Purpose: SAMB - ingredient Row
// Author: Ronny Van Elewyck
// Version: 1.0
// Creation Date: 28.05.2020
// Modification Date: 16.10.2020
//

// installed components
import React from "react";
import { Typeahead } from "react-bootstrap-typeahead";
import "react-bootstrap-typeahead/css/Typeahead.css";
import Tooltip from "react-bootstrap/Tooltip";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";

// config files
import {
  saveClass2,
  deleteClass5,
  classErrorMessage,
  ingredientMessage1,
  ingredientMessage2,
  ingredientMessage3,
  ingredientMessage4,
} from "../../config.json";

const IngredientRow = ({
  item,
  saveIngredientItem,
  deleteIngredientItem,
  ingredientList,
  uomList,
}) => {
  //
  // format row of table
  //

  //console.log("ingredientRow item ", item);
  const [order, setValue1] = React.useState(item.order);
  const [quantity, setValue2] = React.useState(item.quantity);
  const [product, setValue3] = React.useState(item.producttext);
  const [uom, setValue4] = React.useState(item.uomtext);

  // validate input
  const [showError1, setShowError1] = React.useState("");
  const [isError1, setError1] = React.useState(false);
  const [showError2, setShowError2] = React.useState("");
  const [isError2, setError2] = React.useState(false);
  const [showError3, setShowError3] = React.useState("");
  const [isError3, setError3] = React.useState(false);
  const [showError4, setShowError4] = React.useState("");
  const [isError4, setError4] = React.useState(false);

  React.useEffect(() => {
    if (order > 0 && order < 51) {
      setError1(false);
      setShowError1("");
    } else {
      setError1(true);
      setShowError1(ingredientMessage1);
    }
  }, [order]);

  React.useEffect(() => {
    //const regex = /^\d+(\.\d{1,2})?$/;
    // check decimal number with comma
    const regex = /^(0|[1-9]\d*)(,\d+)?$/;
    const found = quantity.match(regex);
    if (found !== null && quantity !== "") {
      setError2(false);
      setShowError2("");
    } else {
      setError2(true);
      setShowError2(ingredientMessage2);
    }
  }, [quantity]);

  React.useEffect(() => {
    if (typeof product === "undefined") {
      setError3(true);
      setShowError3(ingredientMessage3);
    } else {
      setError3(false);
      setShowError3("");
    }
  }, [product]);

  React.useEffect(() => {
    if (typeof uom === "undefined") {
      setError4(true);
      setShowError4(ingredientMessage4);
    } else {
      setError4(false);
      setShowError4("");
    }
  }, [uom]);

  function renderTooltip(title) {
    return <Tooltip id="button-tooltip">{title}</Tooltip>;
  }

  return (
    <tr>
      <td>
        {" "}
        <div>
          {!isError1 && !isError2 && !isError3 && !isError4 && (
            <OverlayTrigger
              placement="left"
              delay={{ show: 250, hide: 400 }}
              overlay={renderTooltip("bewaar in geheugen")}
            >
              <div>
                <button
                  className={saveClass2}
                  onClick={() =>
                    saveIngredientItem({
                      ...item,
                      order,
                      quantity,
                      product,
                      uom,
                    })
                  }
                ></button>
              </div>
            </OverlayTrigger>
          )}
          <OverlayTrigger
            placement="left"
            delay={{ show: 250, hide: 400 }}
            overlay={renderTooltip("verwijder")}
          >
            <div>
              <button
                className={deleteClass5}
                onClick={() => deleteIngredientItem({ ...item })}
              ></button>
            </div>
          </OverlayTrigger>
        </div>
      </td>
      <td>
        {isError1 && <div className={classErrorMessage}>{showError1}</div>}
        <input
          type="text"
          className="form-control"
          value={order}
          onChange={(e) => setValue1(e.currentTarget.value)}
        />
      </td>
      <td>
        {isError3 && <div className={classErrorMessage}>{showError3}</div>}
        <Typeahead
          id={"producttext"}
          labelKey={"label"}
          options={ingredientList}
          placeholder={product}
          onChange={(selectedOptions) => setValue3(selectedOptions[0])}
          highlightOnlyResult={true}
          clearButton={true}
        />
      </td>
      <td>
        {isError2 && <div className={classErrorMessage}>{showError2}</div>}
        <input
          type="text"
          className="form-control"
          value={quantity}
          onChange={(e) => setValue2(e.currentTarget.value)}
        />
      </td>
      <td>
        {isError4 && <div className={classErrorMessage}>{showError4}</div>}
        <Typeahead
          id={"uomtext"}
          labelKey={"label"}
          options={uomList}
          placeholder={uom}
          onChange={(selectedOptions) => setValue4(selectedOptions[0])}
          highlightOnlyResult={true}
          clearButton={true}
        />
      </td>
    </tr>
  );
};

export default IngredientRow;
