//
// Purpose: SAMB - courses screen
// Author: Ronny Van Elewyck
// Version: 1.0
// Creation Date: 01.04.2020
// Modification Date: 04.09.2020
//

// installed components
import React, { Component } from "react";
import "react-bootstrap-typeahead/css/Typeahead.css";
import _ from "lodash";

// created components
import NavBar from "./navBar";
import CourseTable from "./common/courseTable";
import TopCourseButton from "./common/topCourseButton";
import NewCourseForm from "./common/newCourseForm";
import DeleteCourseForm from "./common/deleteCourseForm";

// utilities
import {
  slashdateUTC,
  yyyymmdd,
  slashdateFromUTC,
  fromToDate,
} from "../utils/convertDates";

// rest services
import getCourse from "../services/courseService";
import getTask from "../services/taskService";

// config files
import {
  noError,
  locale,
  errorUpdateMessage1,
  errorInsertMessage1,
  errorDeleteMessage1,
  errorDeleteMessage2,
  dateErrorMessage3,
  timeErrorMessage1,
  selectErrorMessage3,
  maxDate1,
  minDate1,
  classTitle,
  classErrorMessage,
  classTables,
  courseTitle,
  errorUserMessag1,
} from "../config.json";

class Courses extends Component {
  state = {
    fromDate: new Date(slashdateUTC(fromToDate(new Date()).fromDate)),
    fromDateError: false,
    toDate: new Date(slashdateUTC(fromToDate(new Date()).toDate)),
    toDateError: false,
    dateError: false,
    timeError: false,
    group: {
      id: 0,
      text: "",
    },
    mastercourse: {
      id: 0,
      text: "",
      type: "",
    },
    groupCourseList: [],
    allGroupCourseList: [],
    userCourseList: [],
    isEmptyGroup: true,
    isEmptyCourse: true,
    selectErrorFlag: false,
    updateErrorFlag: false,
    updateSuccessFlag: false,
    insertErrorFlag: false,
    insertSuccessFlag: false,
    deleteErrorFlag: false,
    deleteSuccessFlag: false,
    maxDate: new Date(maxDate1),
    minDate: new Date(minDate1),
    isUserLoaded: false,
    isUserInserted: false,
    isNew: false,
    updateRecord: {
      courseid: "",
      mastercourseid: 0,
      mastercoursetext: "",
      groupid: 0,
      grouptext: "",
      teacherid: 0,
      teachername: "",
      courseDate: new Date(maxDate1),
      courseTimeBlock1: "",
      courseTimeBlock2: "",
    },
    isAskDeleteSure: false,
    isDeletePossible: true,
    deleteRecord: undefined,
    courseRecord: {
      courseid: "",
      mastercourseid: "",
      mastercoursetext: "",
      groupid: "",
      grouptext: "",
      teacherid: "",
      teachername: "",
      courseDate: "",
      courseTimeBlock1: "",
      courseTimeBlock2: "",
      autoTimeBlock: "",
      title: "",
    },
    isLoaded: false,
    isUserInGroup: true,
  };

  componentDidMount = async () => {
    //
    // action after mounting render
    //

    ////console.lof("componentDidMount(): ");

    if (this.props.history.location.state !== null) {
      // prev selection
      const {
        courseRecord,
        userCourseList,
        fromDate,
        toDate,
      } = this.props.history.location.state;

      const group = {
        id: courseRecord.groupid,
        text: courseRecord.grouptext,
      };

      const mastercourse = {
        id: courseRecord.mastercourseid,
        text: courseRecord.mastercoursetext,
      };

      // set state
      this.setState({ fromDate, toDate, group, mastercourse, userCourseList });

      // refresh list
      await this.getCourses(group, mastercourse, fromDate, toDate);
    }
  };

  componentDidUpdate = async (prevProps) => {
    //
    // action after update
    //

    if (this.props.session !== prevProps.session) {
      //////console.lof("componentDidUpdate other session detected : ", prevProps);
    }
  };

  getCourses = async (group, mastercourse, fromDate, toDate) => {
    //
    // get courses from database
    //

    const { session, userList, user } = this.props;
    let groupCourseList = [];
    let allGroupCourseList = [];
    let userCourseList = [];
    let updateRecord = this.state.courseRecord;
    let isLoaded = false;
    let yyyymmdd1 = yyyymmdd(fromDate);
    let yyyymmdd2 = yyyymmdd(toDate);

    //console.lof("getCourses group: ", group);
    //console.lof("getCourses mastercourse: ", mastercourse);
    //console.lof("getCourses fromDate: ", fromDate);
    //console.lof("getCourses toDate: ", toDate);

    if (group.id !== 0 && mastercourse.id !== 0 && toDate > fromDate) {
      // even if no data found set true
      isLoaded = true;

      // prepare basic updateRecord
      updateRecord.courseid = "";
      updateRecord.mastercourseid = mastercourse.id;
      updateRecord.mastercoursetext = mastercourse.text;
      updateRecord.groupid = group.id;
      updateRecord.grouptext = group.text;
      updateRecord.teacherid = user.userid;
      updateRecord.teachername = user.firstname + " " + user.lastname;
      updateRecord.courseDate = new Date();
      updateRecord.courseTimeBlock1 =
        updateRecord.courseDate.getHours() + ":00";
      updateRecord.courseTimeBlock2 =
        updateRecord.courseDate.getHours() + 1 + ":00";

      // get students
      userCourseList = userList.filter((m) => m.groupid1 === group.id);

      // get courses for group
      const jsonList = await getCourse.groupCourse(
        session,
        mastercourse.id,
        group.id,
        yyyymmdd1,
        yyyymmdd2
      );

      if (jsonList.jsonReturn[0].errorcode === noError) {
        // sort by date
        groupCourseList = _.sortBy(jsonList.groupCourseList, function (item) {
          return item.yyyymmdd;
        });

        // save state in App.js
        this.props.onSaveGroupCourseList(groupCourseList);

        for (let index = 0; index < groupCourseList.length; index++) {
          // convert date
          const dateObject = new Date(
            slashdateUTC(groupCourseList[index].yyyymmdd)
          );
          groupCourseList[index].dateUTC = dateObject;
        }
      }
    }

    this.setState({
      updateRecord,
      userCourseList,
      groupCourseList,
      allGroupCourseList,
      isLoaded,
      isDeletePossible: true,
    });
  };

  onFromDateChange = async (fromDateIn) => {
    //
    // handle from date change
    //
    const { group, mastercourse, toDate } = this.state;
    let isLoaded = false;

    if (fromDateIn > toDate) {
      this.setState({
        dateError: true,
        fromDate: fromDateIn,
        isLoaded,
      });
    } else {
      if (mastercourse.id !== 0 && group.id !== 0) {
        // refresh table from database
        await this.getCourses(group, mastercourse, fromDateIn, toDate);
        isLoaded = true;
      }
      this.setState({
        dateError: false,
        fromDate: fromDateIn,
        isLoaded,
      });
    }
  };

  onToDateChange = async (toDateIn) => {
    //
    // handle to date change
    //
    const { group, mastercourse, fromDate } = this.state;
    let isLoaded = false;

    if (toDateIn < fromDate) {
      this.setState({
        dateError: true,
        toDate: toDateIn,
        isLoaded,
      });
    } else {
      if (mastercourse.id !== 0 && group.id !== 0) {
        // refresh table from database
        await this.getCourses(group, mastercourse, fromDate, toDateIn);
        isLoaded = true;
      }
      this.setState({
        dateError: false,
        toDate: toDateIn,
        isLoaded,
      });
    }
  };

  handleGroupChange = async (selectedOptions) => {
    //
    // handle group change
    //

    const { mastercourse, fromDate, toDate } = this.state;
    let group = {
      id: 0,
      text: "",
    };

    if (typeof selectedOptions[0] !== "undefined") {
      group.id = selectedOptions[0].id;
      group.text = selectedOptions[0].label;

      if (group.id !== 0 && mastercourse.id !== 0) {
        // refresh table from database
        await this.getCourses(group, mastercourse, fromDate, toDate);
      }
    }

    this.setState({
      group,
    });
  };

  handleCourseChange = async (selectedOptions) => {
    //
    // handle course change
    //
    const { group, fromDate, toDate } = this.state;
    const { allMasterCourseList } = this.props;
    let mastercourse = {
      id: 0,
      text: "",
      type: "",
    };

    if (typeof selectedOptions[0] !== "undefined") {
      // read type in table
      const selectedCourse = allMasterCourseList.filter(
        (m) => m.mastercourseid === selectedOptions[0].id
      );
      if (typeof selectedCourse[0] !== "undefined") {
        mastercourse.type = selectedCourse[0].type;
        mastercourse.id = selectedOptions[0].id;
        mastercourse.text = selectedOptions[0].label;
        if (group.id !== 0 && mastercourse.id !== 0) {
          // refresh table from database
          await this.getCourses(group, mastercourse, fromDate, toDate);
        }
      }
    }

    this.setState({
      mastercourse,
    });
  };

  handleNew = () => {
    //
    // new
    //

    let updateRecord = this.state.updateRecord;
    const { user, userList } = this.props;
    const { mastercourse, group } = this.state;

    // check if users are assigned to group
    const isUserInGroup = userList.filter((m) => m.groupid1 === group.id);

    if (isUserInGroup.length > 0) {
      // prepare basic updateRecord
      updateRecord.courseid = "";
      updateRecord.mastercourseid = mastercourse.id;
      updateRecord.mastercoursetext = mastercourse.text;
      updateRecord.groupid = group.id;
      updateRecord.grouptext = group.text;
      updateRecord.teacherid = user.userid;
      updateRecord.teachername = user.firstname + " " + user.lastname;
      updateRecord.courseDate = new Date();
      updateRecord.courseTimeBlock1 =
        updateRecord.courseDate.getHours() + ":00";
      updateRecord.courseTimeBlock2 =
        updateRecord.courseDate.getHours() + 1 + ":00";

      this.setState({
        updateRecord,
        isNew: true,
        isUserInGroup: true,
      });
    } else {
      this.setState({
        updateRecord,
        isNew: false,
        isUserInGroup: false,
      });
    }
  };

  handleCancelDeleteItem = () => {
    //
    // cancel delete window
    //

    this.setState({
      isAskDeleteSure: false,
      deleteRecord: undefined,
    });
  };

  handleCancelItem = () => {
    //
    // cancel new window
    //

    this.setState({ isNew: false });
  };

  handleNewItem = async (itemData) => {
    //
    // new or changed course
    //
    const { session } = this.props;
    const { group, mastercourse, toDate, fromDate } = this.state;
    // inserted course information
    let insertCourse1Record = {
      courseid: "0",
      groupid: group.id,
      mastercourseid: mastercourse.id,
      teacherid: "0",
      yyyymmdd: "",
      showdate: "",
      timeblock: "",
      autotimeblock: "",
      fromtime: "",
      totime: "",
    };

    // trainer
    if (typeof itemData.trainer.id !== "undefined") {
      insertCourse1Record.teacherid = itemData.trainer.id;
    } else {
      insertCourse1Record.teacherid = itemData.updateRecord.teacherid;
    }

    // dates
    insertCourse1Record.yyyymmdd = yyyymmdd(itemData.newdate);
    insertCourse1Record.showdate = slashdateFromUTC(itemData.newdate);

    // from time
    if (itemData.fromtime.length === 4) {
      insertCourse1Record.fromtime = "0" + itemData.fromtime;
    } else {
      insertCourse1Record.fromtime = itemData.fromtime;
    }

    // to time
    if (itemData.totime.length === 4) {
      insertCourse1Record.totime = "0" + itemData.totime;
    } else {
      insertCourse1Record.totime = itemData.totime;
    }

    // change date for free exercise
    if (mastercourse.type === "3VO") {
      insertCourse1Record.fromtime = "00:05";
      insertCourse1Record.totime = "23:55";
      insertCourse1Record.autotimeblock = "";
      insertCourse1Record.yyyymmdd = yyyymmdd(toDate);
      insertCourse1Record.showdate = slashdateFromUTC(toDate);
    }

    // timeblock
    insertCourse1Record.timeblock =
      insertCourse1Record.fromtime + " tot " + insertCourse1Record.totime;

    if (itemData.courseid === "0" || itemData.courseid === "") {
      // insert course
      const jsonReturn = await getCourse.insertCourse(
        session,
        insertCourse1Record
      );

      // retuncode
      const jsonList = jsonReturn["jsonReturn"];
      if (jsonList[0].errorcode === noError) {
        // insert all students to new course
        await this.insertCourseUsers(jsonList[0].lastid);
        this.setState({
          insertErrorFlag: false,
          isNew: false,
        });
        // refresh
        await this.getCourses(group, mastercourse, fromDate, toDate);
      } else {
        // error message
        this.setState({
          insertErrorFlag: true,
          isNew: true,
        });
      }
    } else {
      // console.log("insertCourse1Record: ", insertCourse1Record);
      insertCourse1Record.courseid = itemData.courseid;
      // update course
      const jsonReturn = await getCourse.updateCourse(
        session,
        insertCourse1Record
      );

      // retuncode
      const jsonList = jsonReturn["jsonReturn"];
      if (jsonList[0].errorcode === noError) {
        this.setState({
          updateErrorFlag: false,
          isNew: false,
        });
        // refresh
        await this.getCourses(group, mastercourse, fromDate, toDate);
      } else {
        // error message
        this.setState({
          updateErrorFlag: true,
          isNew: true,
        });
      }
    }
  };

  scrollTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  render() {
    // state and props
    const {
      groupList,
      masterCourseList,
      trainerList,
      session,
      user,
      isAdmin,
    } = this.props;

    // don't render if not
    if (typeof session.companyimageurl === "undefined") {
      ////////console.lof("render: ", new Date());
      return null;
    }

    const {
      fromDate,
      toDate,
      group,
      mastercourse,
      selectErrorFlag,
      groupCourseList,
      updateErrorFlag,
      insertErrorFlag,
      deleteErrorFlag,
      isLoaded,
      maxDate,
      minDate,
      userCourseList,
      isNew,
      updateRecord,
      dateError,
      timeError,
      isAskDeleteSure,
      isDeletePossible,
      deleteRecord,
      isUserInGroup,
    } = this.state;

    //console.lof("render: ", isLoaded, isNew);

    return (
      <React.Fragment>
        {/* error and success messages */}
        {timeError && (
          <div className={classErrorMessage}>{timeErrorMessage1}</div>
        )}
        {dateError && (
          <div className={classErrorMessage}>{dateErrorMessage3}</div>
        )}

        {selectErrorFlag && (
          <div className={classErrorMessage}>{selectErrorMessage3}</div>
        )}
        {updateErrorFlag && (
          <div className={classErrorMessage}>{errorUpdateMessage1}</div>
        )}
        {insertErrorFlag && (
          <div className={classErrorMessage}>{errorInsertMessage1}</div>
        )}
        {deleteErrorFlag && (
          <div className={classErrorMessage}>{errorDeleteMessage1}</div>
        )}
        {!isDeletePossible && (
          <div className={classErrorMessage}>{errorDeleteMessage2}</div>
        )}
        {!isUserInGroup && (
          <div className={classErrorMessage}>{errorUserMessag1}</div>
        )}
        {/* navigation bar */}
        <NavBar />

        {/* title */}
        <h4>
          <span className={classTitle}>{courseTitle}</span>
        </h4>

        {/* confirm course delete */}
        {isAskDeleteSure && (
          <div className={classTables}>
            <DeleteCourseForm
              confirmDeleteItem={this.handleConfirmDeleteItem}
              cancelItem={this.handleCancelDeleteItem}
              item={deleteRecord}
            />
          </div>
        )}

        {/* new/change course */}
        {updateRecord.mastercourseid !== 0 &&
          updateRecord.groupid !== 0 &&
          isNew &&
          isLoaded && (
            <div className={classTables}>
              <NewCourseForm
                newItem={this.handleNewItem}
                cancelItem={this.handleCancelItem}
                groupList={groupList}
                masterCourseList={masterCourseList}
                updateRecord={updateRecord}
                locale={locale}
                maxDate={maxDate}
                minDate={minDate}
                trainerList={trainerList}
                isAdmin={isAdmin}
              />
            </div>
          )}

        {/* panel with 3 rows and 1 column */}
        <TopCourseButton
          getCourses={this.getCourses}
          groupList={groupList}
          group={group}
          handleGroupChange={this.handleGroupChange}
          masterCourseList={masterCourseList}
          mastercourse={mastercourse}
          handleCourseChange={this.handleCourseChange}
          locale={locale}
          onFromDateChange={this.onFromDateChange}
          fromDate={fromDate}
          maxDate={maxDate}
          minDate={minDate}
          toDate={toDate}
          onToDateChange={this.onToDateChange}
          handleNew={this.handleNew}
          isLoaded={isLoaded}
        />

        <div className={classTables}>
          <CourseTable
            groupCourseList={groupCourseList}
            updateItem={this.updateItem}
            copyItem={this.copyItem}
            deleteItem={this.deleteItem}
            fromDate={fromDate}
            toDate={toDate}
            group={group}
            mastercourse={mastercourse}
            isLoaded={isLoaded}
            maxDate={maxDate}
            isAdmin={isAdmin}
            user={user}
            locale={locale}
            userCourseList={userCourseList}
          />
        </div>
      </React.Fragment>
    );
  }

  /*****************/
  /* row functions */
  /*****************/
  updateItem = async (newItemData) => {
    //
    // update course in database
    //

    ////console.lof("updateItem: ", newItemData);
    let updateRecord = this.state.updateRecord;
    updateRecord.courseid = newItemData._id;
    updateRecord.mastercourseid = newItemData.mastercourse.id;
    updateRecord.mastercoursetext = newItemData.mastercourse.text;
    updateRecord.groupid = newItemData.group.id;
    updateRecord.grouptext = newItemData.group.text;
    updateRecord.teacherid = newItemData.teacherid;
    updateRecord.teachername = newItemData.teachername;
    updateRecord.courseDate = newItemData.dateUTC;
    updateRecord.courseTimeBlock1 = newItemData.fromtime;
    updateRecord.courseTimeBlock2 = newItemData.totime;

    this.scrollTop();

    this.setState({
      isNew: true,
      updateRecord,
      isDeletePossible: true,
    });
  };

  copyItem = async (newItemData) => {
    //
    // copy course: insert in database
    //

    const dateUTC = new Date();
    const timeFrom = dateUTC.getHours() + ":00";
    const timeTo = dateUTC.getHours() + 1 + ":00";

    let oldRecord = [];

    // get local state
    const {
      allGroupCourseList,
      mastercourse,
      group,
      toDate,
      fromDate,
    } = this.state;

    // get props from App.js
    const { session } = this.props;

    // inserted course information
    const insertCourse1Record = {
      mastercourseid: mastercourse.id,
      teacherid: session.userid,
      groupid: group.id,
      yyyymmdd: "",
      timeblock: "",
      autotimeblock: "",
      //autotimeblock: newItemData.autotimeblock,
      fromtime: newItemData.fromtime.label,
      totime: newItemData.totime.label,
      showdate: "",
    };

    if (
      typeof newItemData.dateUTC === "undefined" ||
      newItemData.dateUTC === "" ||
      newItemData.dateUTC === null
    ) {
      insertCourse1Record.yyyymmdd = yyyymmdd(dateUTC);
      insertCourse1Record.showdate = slashdateFromUTC(dateUTC);
    } else {
      insertCourse1Record.yyyymmdd = yyyymmdd(newItemData.dateUTC);
      insertCourse1Record.showdate = slashdateFromUTC(newItemData.dateUTC);
    }

    // check undefined
    if (newItemData._id < 99999) {
      // get old data if not dummy record
      oldRecord = allGroupCourseList.filter((m) => m._id === newItemData._id);
    }
    if (typeof insertCourse1Record.fromtime === "undefined") {
      if (typeof oldRecord[0] === "undefined" || oldRecord[0].fromtime === "") {
        insertCourse1Record.fromtime = timeFrom;
      } else {
        insertCourse1Record.fromtime = oldRecord[0].fromtime;
      }
    }
    if (typeof insertCourse1Record.totime === "undefined") {
      if (typeof oldRecord[0] === "undefined" || oldRecord[0].totime === "") {
        insertCourse1Record.totime = timeTo;
      } else {
        insertCourse1Record.totime = oldRecord[0].totime;
      }
    }

    // change date for free exercise
    if (mastercourse.type === "3VO") {
      insertCourse1Record.fromtime = "17:00";
      insertCourse1Record.totime = "23:55";
      insertCourse1Record.autotimeblock = "";
      insertCourse1Record.yyyymmdd = yyyymmdd(toDate);
      insertCourse1Record.showdate = slashdateFromUTC(toDate);
    }
    ////////console.lof("mastercourse: ", mastercourse);

    // timeblock
    insertCourse1Record.timeblock =
      insertCourse1Record.fromtime + " tot " + insertCourse1Record.totime;

    ////////console.lof("insertCourse1Record: ", insertCourse1Record);

    // insert course
    const jsonReturn = await getCourse.insertCourse(
      session,
      insertCourse1Record
    );

    // retuncode
    const jsonList = jsonReturn["jsonReturn"];
    if (jsonList[0].errorcode === noError) {
      // //////console.lof("jsonList[0].lastid: ", jsonList[0].lastid);
      // insert all students to new course
      await this.insertCourseUsers(jsonList[0].lastid);

      // refresh table from database
      await this.getCourses(group, mastercourse, fromDate, toDate);

      // success message
      this.setState({
        insertSuccessFlag: true,
        updateSuccessFlag: false,
        updateErrorFlag: false,
        deleteSuccessFlag: false,
        deleteErrorFlag: false,
        selectErrorFlag: false,
        isFreeExercise: false,
        isDeletePossible: true,
      });
    } else {
      // error message
      this.setState({
        insertErrorFlag: true,
        updateSuccessFlag: false,
        updateErrorFlag: false,
        deleteSuccessFlag: false,
        deleteErrorFlag: false,
        selectErrorFlag: false,
        isFreeExercise: false,
        isDeletePossible: true,
      });
    }
  };

  deleteItem = async (itemData) => {
    //
    // delete item
    //

    // get status of tasks
    let courseTaskList = [];
    let doNotDelete = false;

    // get props
    const { session } = this.props;

    const { userCourseList } = this.state;

    ////console.lof("deleteItem: ", itemData);

    // check delete is possible

    if (userCourseList.length === 0) {
      // users linked to course ? no: delete is possible
      this.scrollTop();

      this.setState({
        isAskDeleteSure: true,
        isDeletePossible: true,
        deleteRecord: itemData,
      });
    } else {
      // get all tasks for course from db
      const jsonList = await getTask.courseTask(session, itemData._id);
      ////console.lof(jsonList);
      if (jsonList.jsonReturn[0].errorcode === noError) {
        courseTaskList = jsonList.courseTaskList;
        for (let index = 0; index < courseTaskList.length; index++) {
          ////console.lof(courseTaskList[index]);
          if (courseTaskList[index].taskstatus === "afgewerkt") {
            doNotDelete = true;
            break;
          }
        }
        ////console.lof(doNotDelete);
        if (doNotDelete) {
          this.setState({
            isAskDeleteSure: false,
            isDeletePossible: false,
            deleteRecord: undefined,
          });
        } else {
          // delete is possible
          this.scrollTop();
          this.setState({
            isAskDeleteSure: true,
            isDeletePossible: true,
            deleteRecord: itemData,
          });
        }
      } else {
        // delete is possible
        this.scrollTop();
        this.setState({
          isAskDeleteSure: true,
          isDeletePossible: true,
          deleteRecord: itemData,
        });
      }
    }
  };

  handleConfirmDeleteItem = async (newItemData) => {
    //
    // delete course from database
    //
    const { group, mastercourse, fromDate, toDate } = this.state;
    const { session } = this.props;
    const courseid = newItemData["item"]._id;

    // delete course
    const jsonReturn = await getCourse.deleteCourse(session, courseid);

    // retuncode
    const jsonList = jsonReturn["jsonReturn"];
    if (jsonList[0].errorcode === noError) {
      // delete all users and tasks linked to course
      const jsonReturn = await getCourse.deleteCourseUser(session, courseid);
      // retuncode
      const jsonList = jsonReturn["jsonReturn"];
      if (jsonList[0].errorcode === noError) {
        // refresh table
        await this.getCourses(group, mastercourse, fromDate, toDate);
        // success message
        this.setState({
          isAskDeleteSure: false,
          deleteErrorFlag: false,
        });
      } else {
        // error message
        this.setState({
          isAskDeleteSure: true,
          deleteErrorFlag: true,
        });
      }
    } else {
      // error message
      this.setState({
        deleteErrorFlag: true,
        isAskDeleteSure: true,
      });
    }
  };

  insertCourseUsers = async (newcourseid) => {
    //
    // insert all users in course
    //

    let isUserInserted = false;

    const { userCourseList } = this.state;
    const { session } = this.props;

    ////////console.lof("newcourseid: ",newcourseid);
    //console.lof("userCourseList: ", userCourseList);

    if (userCourseList.length > 0) {
      // insert users in database

      for (let index = 0; index < userCourseList.length; index++) {
        const jsonReturn = await getCourse.insertCourseUser(
          session,
          newcourseid,
          userCourseList[index].userid,
          "open",
          "actief"
        );
        const jsonList = jsonReturn["jsonReturn"];
        if (jsonList[0].errorcode !== noError) {
          isUserInserted = true;
        }
      }
    }

    this.setState({ isUserInserted });
  };

  // end of class
}

export default Courses;
