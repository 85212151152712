//
// Purpose: SAMB - goal Row
// Author: Ronny Van Elewyck
// Version: 1.0
// Creation Date: 04.06.2020
// Modification Date: 21.09.2020
//

// installed components
import React from "react";
import { Typeahead } from "react-bootstrap-typeahead";
import "react-bootstrap-typeahead/css/Typeahead.css";
import Tooltip from "react-bootstrap/Tooltip";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";

// config files
import { saveClass5, deleteClass5 } from "../../config.json";

const GoalRow = ({
  item,
  changeItem,
  deleteItem,
  groupList,
  masterCourseList,
}) => {
  //
  // format row of table
  //

  const [label, setValue1] = React.useState(item.label);
  const [grouptext, setValue2] = React.useState(item.grouptext);
  const [mastercoursetext, setValue3] = React.useState(item.mastercoursetext);

  const sizeLine = 500;

  const [isChecked, setChecked] = React.useState(false);

  function renderTooltip(title) {
    return <Tooltip id="button-tooltip">{title}</Tooltip>;
  }

  return (
    <tr>
      <td>
        <div>
          <OverlayTrigger
            placement="left"
            delay={{ show: 250, hide: 400 }}
            overlay={renderTooltip("detail open/dicht")}
          >
            <div>
              <input
                type="checkbox"
                checked={isChecked}
                onChange={() => setChecked(!isChecked)}
              />
            </div>
          </OverlayTrigger>
        </div>
      </td>
      <td>
        {isChecked && (
          <div>
            <OverlayTrigger
              placement="left"
              delay={{ show: 250, hide: 400 }}
              overlay={renderTooltip("bewaar")}
            >
              <div>
                <button
                  className={saveClass5}
                  onClick={() =>
                    changeItem({ ...item, label, grouptext, mastercoursetext })
                  }
                ></button>{" "}
              </div>
            </OverlayTrigger>
          </div>
        )}
        {!isChecked && <div> {grouptext}</div>}
      </td>
      <td>
        {isChecked && (
          <div>
            <OverlayTrigger
              placement="left"
              delay={{ show: 250, hide: 400 }}
              overlay={renderTooltip("verwijder")}
            >
              <div>
                <button
                  className={deleteClass5}
                  onClick={() => deleteItem({ ...item })}
                ></button>{" "}
              </div>
            </OverlayTrigger>
          </div>
        )}
        {!isChecked && <div> {mastercoursetext}</div>}
      </td>
      <td>
        {isChecked && (
          <div>
            <b>nummer: </b>
            {item.id}
          </div>
        )}
        {isChecked && (
          <div>
            <b>klas</b>
            <Typeahead
              id={"grouptext"}
              labelKey={"label"}
              options={groupList}
              placeholder={grouptext}
              onChange={(selectedOptions) => setValue2(selectedOptions[0])}
              highlightOnlyResult={true}
              clearButton={true}
            />
          </div>
        )}
        {isChecked && (
          <div>
            <b>vak</b>
            <Typeahead
              id={"mastercoursetext"}
              labelKey={"label"}
              options={masterCourseList}
              placeholder={mastercoursetext}
              onChange={(selectedOptions) => setValue3(selectedOptions[0])}
              highlightOnlyResult={true}
              clearButton={true}
            />
          </div>
        )}
        {isChecked && (
          <div>
            <b>omschrijving</b>
            <input
              type="text"
              className="form-control"
              value={label}
              onChange={(e) => setValue1(e.currentTarget.value)}
              maxLength={sizeLine}
            />
          </div>
        )}
        {!isChecked && <div>{label}</div>}
      </td>
    </tr>
  );
};

export default GoalRow;
