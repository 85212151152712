//
// Purpose: SAMB - company table component
// Author: Ronny Van Elewyck
// Version: 1.1
// Creation Date: 04.06.2020
// Modification Date: 04.03.2021
//

// installed components
import React from "react";
import { isMobile } from "react-device-detect";

// created components
import CompanyRow from "./companyRow";

const CompanyTable = ({ companyList }) => {
  return (
    <div className="table-responsive">
      {!isMobile && (
        <table className="table table-bordered">
          <tbody>
            {companyList.map((item) => (
              <CompanyRow key={item.compid} item={item} />
            ))}
          </tbody>
        </table>
      )}
      {isMobile && (
        <table className="table table-borderless">
          <tbody>
            {companyList.map((item) => (
              <CompanyRow key={item.compid} item={item} />
            ))}
          </tbody>
        </table>
      )}
    </div>
  );
};

export default CompanyTable;
