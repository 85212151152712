//
// Purpose: SAMB - new Group
// Author: Ronny Van Elewyck
// Version: 1.0
// Creation Date: 02.09.2020
// Modification Date: 15.10.2020
//

// installed components
import React from "react";
import Tooltip from "react-bootstrap/Tooltip";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";

// created components

// config files
import {
  saveClass5,
  cancelClass5,
  groupMessage2,
  classErrorMessage,
} from "../../config.json";

const NewGroupForm = ({ newItem, cancelItem }) => {
  //
  // format row of table
  //

  let title = "Nieuwe klas aanmaken";

  const [label, setValue1] = React.useState("");

  const sizeLine3 = 20;

  const [showError1, setShowError1] = React.useState("");
  const [isError1, setError1] = React.useState(false);

  React.useEffect(() => {
    if (label === "" || label.length < 3 || label.length > 20) {
      setError1(true);
      setShowError1(groupMessage2);
    } else {
      setError1(false);
      setShowError1("");
    }
  }, [label]);

  const addLineBreaks = (string) =>
    string.split("\n").map((text, index) => (
      <React.Fragment key={`${text}-${index}`}>
        {text}
        <br />
      </React.Fragment>
    ));

  function renderTooltip(title) {
    return <Tooltip id="button-tooltip">{title}</Tooltip>;
  }

  return (
    <div>
      <div>
        <OverlayTrigger
          placement="left"
          delay={{ show: 250, hide: 400 }}
          overlay={renderTooltip("terug")}
        >
          <button
            className={cancelClass5}
            onClick={() => cancelItem()}
          ></button>
        </OverlayTrigger>
        {!isError1 && (
          <OverlayTrigger
            placement="left"
            delay={{ show: 250, hide: 400 }}
            overlay={renderTooltip("bewaar")}
          >
            <button
              className={saveClass5}
              onClick={() =>
                newItem({
                  label,
                })
              }
            ></button>
          </OverlayTrigger>
        )}
      </div>

      <div className="table-responsive">
        <table className="table table-bordered">
          <tbody>
            <tr>
              <td>
                <h4>
                  <span className="badge badge-info d-flex justify-content-center">
                    {title}
                  </span>
                </h4>
              </td>
            </tr>
            <tr>
              <td>
                {isError1 && (
                  <div className={classErrorMessage}>{showError1}</div>
                )}
                <input
                  type="text"
                  className="form-control"
                  value={label}
                  onChange={(e) => setValue1(e.currentTarget.value)}
                  maxLength={sizeLine3}
                />
                <h1>{addLineBreaks("")}</h1>
                <h1>{addLineBreaks("")}</h1>
                <h1>{addLineBreaks("")}</h1>
                <h1>{addLineBreaks("")}</h1>
                <h1>{addLineBreaks("")}</h1>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default NewGroupForm;
