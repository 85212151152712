//
// Purpose: SAMB - node table component
// Author: Ronny Van Elewyck
// Version: 1.0
// Creation Date: 10.09.2020
// Modification Date: 10.09.2020
//

// installed components
import React from "react";

// created components
import NodeRow from "./nodeRow";

const NodeTable = ({ nodeList }) => {
  return (
    <div className="table-responsive">
      <table className="table table-bordered">
        <tbody>
          {nodeList.map((item) => (
            <NodeRow key={item.key} item={item} />
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default NodeTable;
