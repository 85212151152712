//
// Purpose: SAMB - routing and global arrays
// Author: Ronny Van Elewyck
// Version: 1.0
// Creation Date: 09.03.2020
// Modification Date: 16.10.2020
//

// installed components
import React, { Component } from "react";
import {
  Route,
  Redirect,
  Switch,
  BrowserRouter as Router,
} from "react-router-dom";
import _ from "lodash";
import ClearCache from "react-clear-cache";

// created components
import LoginForm from "./components/loginForm";
import Overview from "./components/overview";
import Courses from "./components/courses";
import CourseTasks from "./components/courseTasks";
import Group from "./components/group";
import MasterCourse from "./components/masterCourse";
import Steps from "./components/steps";
import ChangeSteps from "./components/changeSteps";
import Materials from "./components/materials";
import Goals from "./components/goals";
import Users from "./components/users";
import PrintWF from "./components/printWF";

// css
import "./App.css";

class App extends Component {
  //
  // start class - routing and global arrays
  //
  constructor() {
    super();

    // local storage
    this.state = {
      isAuth: false,
      isAdmin: false,
      isSupport: false,
      isOverviewLoaded: false,
      session: {},
      user: {},
      userList: {},
      groupList: {},
      masterCourseList: {},
      masterTaskList: {},
      groupCourseList: {},
      userCourseList: {},
      goalsList: {},
      allMasterCourseList: {},
      companyList: {},
      allergenList: {},
      materialList: {},
      uomList: {},
      uomList2: {},
      trainerList: {},
    };
  }

  onHandleLogin = (roleid, logon) => {
    //
    // set logged in parameter and admin authorization
    //
    this.setState({ isAuth: true });
    if (roleid === "1") {
      this.setState({ isAdmin: true });
    }
    if (logon.slice(0, 7) === "support") {
      //console.log("App.js - logon.slice(0, 7): ", logon.slice(0, 7));
      this.setState({ isSupport: true });
    }
  };

  onHandleLogout = () => {
    //
    // set all login parameters on false
    //
    this.setState({
      isAuth: false,
      isAdmin: false,
      isSupport: false,
      isOverviewLoaded: false,
    });
  };

  onOverviewLoaded = (loaded) => {
    //
    // set overview loaded
    //
    this.setState({ isOverviewLoaded: loaded });
  };

  onSaveSession = (mySession) => {
    //
    // save session data (like node, ...) in global array
    //
    let session = { ...this.state.session };
    session = mySession;
    this.setState({ session });
  };

  onSaveUser = (myUser) => {
    //
    // save logged in user information in global array
    //
    let user = { ...this.state.user };
    user = myUser;
    this.setState({ user });
  };

  onSaveUserList = (myUserList) => {
    //
    // save all users of company in global array
    //
    let userList = { ...this.state.userList };
    userList = [...myUserList];
    this.setState({ userList });

    //console.log("userList : ",userList );
  };

  onSaveAllergenList = (myAllergenList) => {
    //
    // save allergen in global array
    //
    let allergenList = { ...this.state.allergenList };
    allergenList = [...myAllergenList];
    this.setState({ allergenList });

    //console.log("allergenList : ", allergenList);
  };

  onSaveMaterialList = (myMaterialList) => {
    //
    // save material in global array
    //
    let materialList = { ...this.state.materialList };
    materialList = [...myMaterialList];
    this.setState({ materialList });

    //console.log("materialList : ", materialList);
  };

  onSaveUOMList = (myUOMList) => {
    //
    // save uom in global array
    //
    let uomList = { ...this.state.uomList };
    uomList = [...myUOMList];
    this.setState({ uomList });

    //console.log("uomList : ", uomList);
  };

  onSaveUOMList2 = (myUOMList2) => {
    //
    // save uom in global array
    //
    let uomList2 = { ...this.state.uomList2 };
    uomList2 = [...myUOMList2];
    this.setState({ uomList2 });

    //console.log("uomList2 : ", uomList2);
  };

  onSaveGroupList = (myGroupList) => {
    //
    // save all groups of company in global array
    //
    let groupList = { ...this.state.groupList };
    groupList = [...myGroupList];
    const sortedList = _.sortBy(groupList, function (item) {
      return item.label;
    });

    this.setState({ groupList: sortedList });
  };

  onSaveGoalsList = (myGoalsList) => {
    //
    // save all goals of courses in global array
    let goalsList = { ...this.state.goalsList };
    goalsList = [...myGoalsList];
    this.setState({ goalsList });
  };

  onSaveCompanyList = (myCompanyList) => {
    //
    // save all company details in global array
    let companyList = { ...this.state.companyList };
    companyList = [...myCompanyList];
    this.setState({ companyList });
  };

  onSaveMasterCourseList = (myMasterCourseList) => {
    //
    // save courses in global array
    //
    let masterCourseList = { ...this.state.masterCourseList };
    masterCourseList = [...myMasterCourseList];
    this.setState({ masterCourseList });
  };

  onSaveAllMasterCourseList = (myMasterCourseList) => {
    //
    // save all course information in global array
    //
    let allMasterCourseList = { ...this.state.allMasterCourseList };
    allMasterCourseList = [...myMasterCourseList];

    //console.log(allMasterCourseList);
    this.setState({ allMasterCourseList });
  };

  onSaveMasterTaskList = (myMasterTaskList) => {
    //
    // save tasks in global array
    //
    let masterTaskList = { ...this.state.masterTaskList };
    masterTaskList = [...myMasterTaskList];

    const sortedList = _.sortBy(masterTaskList, function (item) {
      return item.mastertasktext;
    });

    this.setState({ masterTaskList: sortedList });

    //console.log("masterTaskList: ",masterTaskList);
  };

  onSaveGroupCourseList = (myGroupCourseList) => {
    //
    // save all courses of group in global array
    //
    let groupCourseList = { ...this.state.groupCourseList };
    groupCourseList = [...myGroupCourseList];
    this.setState({ groupCourseList });
  };

  onSaveUserCourseList = (myUserCourseList) => {
    //
    // save all courses from group in global array
    //
    let userCourseList = { ...this.state.userCourseList };
    userCourseList = [...myUserCourseList];
    this.setState({ userCourseList });
  };

  onSaveTrainerList = (myTrainerList) => {
    //
    // save all trainers in global array
    //
    let trainerList = { ...this.state.trainerList };
    trainerList = [...myTrainerList];
    this.setState({ trainerList });
  };

  render() {
    const {
      isAdmin,
      isSupport,
      session,
      user,
      isOverviewLoaded,
      isAuth,
      userList,
      groupList,
      masterCourseList,
      allMasterCourseList,
      masterTaskList,
      goalsList,
      allergenList,
      materialList,
      uomList,
      uomList2,
      companyList,
      trainerList,
    } = this.state;

    return (
      <React.Fragment>
        <ClearCache>
          {/* force new version */}
          {({ isLatestVersion, emptyCacheStorage }) => (
            <div>
              {!isLatestVersion && (
                <p>
                  <a
                    href="https://www.qognimacloud.be"
                    onClick={(e) => {
                      e.preventDefault();
                      emptyCacheStorage();
                    }}
                  >
                    nieuwe versie
                  </a>
                </p>
              )}
            </div>
          )}
        </ClearCache>
        <Router>
          <main className="container">
            <Switch>
              {/* if authorized show overview else nothing */}
              {isAuth ? (
                <Route
                  path="/overview"
                  render={(props) => (
                    <Overview
                      isAdmin={isAdmin}
                      isSupport={isSupport}
                      session={session}
                      user={user}
                      onSaveUserList={this.onSaveUserList}
                      onSaveGroupList={this.onSaveGroupList}
                      onSaveGoalsList={this.onSaveGoalsList}
                      onSaveCompanyList={this.onSaveCompanyList}
                      onSaveMasterCourseList={this.onSaveMasterCourseList}
                      onSaveAllMasterCourseList={this.onSaveAllMasterCourseList}
                      onSaveMasterTaskList={this.onSaveMasterTaskList}
                      onSaveAllergenList={this.onSaveAllergenList}
                      onSaveMaterialList={this.onSaveMaterialList}
                      onSaveUOMList={this.onSaveUOMList}
                      onSaveUOMList2={this.onSaveUOMList2}
                      onOverviewLoaded={this.onOverviewLoaded}
                      onHandleLogout={this.onHandleLogout}
                      isOverviewLoaded={isOverviewLoaded}
                      onSaveTrainerList={this.onSaveTrainerList}
                      {...props}
                    />
                  )}
                />
              ) : null}
              {/* if not authorized show login if authorized show overview */}
              {!isAuth ? (
                <Route
                  exact
                  path="/login"
                  render={(props) => (
                    <LoginForm
                      onHandleLogin={this.onHandleLogin}
                      isAuth={isAuth}
                      onSaveSession={this.onSaveSession}
                      onSaveUser={this.onSaveUser}
                      {...props}
                    />
                  )}
                />
              ) : (
                <Redirect from="/login" exact to="/overview" />
              )}
              {/* only allow courses if overview is loaded */}
              {this.state.isOverviewLoaded ? (
                <Route
                  path="/courses"
                  render={(props) => (
                    <Courses
                      isAdmin={isAdmin}
                      session={session}
                      user={user}
                      userList={userList}
                      groupList={groupList}
                      masterCourseList={masterCourseList}
                      allMasterCourseList={allMasterCourseList}
                      masterTaskList={masterTaskList}
                      onSaveGroupCourseList={this.onSaveGroupCourseList}
                      trainerList={trainerList}
                      {...props}
                    />
                  )}
                />
              ) : null}

              <Route
                path="/coursetasks"
                render={(props) => (
                  <CourseTasks
                    isAdmin={isAdmin}
                    session={session}
                    user={user}
                    goalsList={goalsList}
                    masterTaskList={masterTaskList}
                    {...props}
                  />
                )}
              />
              <Route
                path="/groups"
                render={(props) => (
                  <Group
                    isAdmin={isAdmin}
                    session={session}
                    user={user}
                    onSaveGroupList={this.onSaveGroupList}
                    {...props}
                  />
                )}
              />
              <Route
                path="/mastercourse"
                render={(props) => (
                  <MasterCourse
                    isAdmin={isAdmin}
                    session={session}
                    user={user}
                    masterCourseList={masterCourseList}
                    allMasterCourseList={allMasterCourseList}
                    onSaveAllMasterCourseList={this.onSaveAllMasterCourseList}
                    onSaveMasterCourseList={this.onSaveMasterCourseList}
                    {...props}
                  />
                )}
              />
              <Route
                path="/steps"
                render={(props) => (
                  <Steps
                    isAdmin={isAdmin}
                    session={session}
                    user={user}
                    masterTaskList={masterTaskList}
                    onSaveMasterTaskList={this.onSaveMasterTaskList}
                    {...props}
                  />
                )}
              />
              <Route
                path="/printwf"
                render={(props) => <PrintWF session={session} {...props} />}
              />
              <Route
                path="/changesteps"
                render={(props) => (
                  <ChangeSteps
                    isAdmin={isAdmin}
                    session={session}
                    user={user}
                    masterTaskList={masterTaskList}
                    goalsList={goalsList}
                    uomList={uomList}
                    {...props}
                  />
                )}
              />
              <Route
                path="/materials"
                render={(props) => (
                  <Materials
                    isAdmin={isAdmin}
                    session={session}
                    user={user}
                    allergenList={allergenList}
                    materialList={materialList}
                    uomList={uomList}
                    uomList2={uomList2}
                    onSaveMaterialList={this.onSaveMaterialList}
                    onSaveUOMList={this.onSaveUOMList}
                    onSaveUOMList2={this.onSaveUOMList2}
                    {...props}
                  />
                )}
              />
              <Route
                path="/goals"
                render={(props) => (
                  <Goals
                    isAdmin={isAdmin}
                    session={session}
                    user={user}
                    goalsList={goalsList}
                    groupList={groupList}
                    masterCourseList={masterCourseList}
                    onSaveGoalsList={this.onSaveGoalsList}
                    {...props}
                  />
                )}
              />
              <Route
                path="/users"
                render={(props) => (
                  <Users
                    isAdmin={isAdmin}
                    session={session}
                    user={user}
                    companyList={companyList}
                    groupList={groupList}
                    onSaveUserList={this.onSaveUserList}
                    onSaveTrainerList={this.onSaveTrainerList}
                    {...props}
                  />
                )}
              />
              <Redirect from="/" exact to="/login" />
              <Redirect from="*" exact to="/login" />
            </Switch>
          </main>
        </Router>
      </React.Fragment>
    );
  }
}

export default App;
