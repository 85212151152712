//
// Purpose: SAMB - second screen after login - buffer data
// Author: Ronny Van Elewyck
// Version: 1.3
// Creation Date: 27.02.2020
// Modification Date: 04.03.2021
//

// installed components
import React, { Component } from "react";
import _ from "lodash";
import Tooltip from "react-bootstrap/Tooltip";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import { isMobile } from "react-device-detect";

// created components
import NavBar from "./navBar";
import SearchBox from "./common/searchBox";
import OverviewTaskTable from "./common/overviewTaskTable";
import NodeTable from "./common/nodeTable";
import CompanyTable from "./common/companyTable";
import ChangePasswordForm from "./common/changePasswordForm";
import TopWaitingMessage from "./common/topWaitingMessage";
import ExportCSV from "./common/exportCSV";
import CopyWF from "./common/copyWF";

// rest services
import getUser from "../services/userService";
import getGroup from "../services/groupService";
import getGoal from "../services/goalService";
import getCourse from "../services/courseService";
import getTask from "../services/taskService";
import auth from "../services/authService";
import getProduct from "../services/productService";
import getNode from "../services/nodeService";

// config file
import {
  noError,
  titleAdmin,
  titleTeacher,
  globalrefreshOK,
  classTopButtons,
  classTitle,
  classPasswordTitle,
  classTables,
  passwordClass5,
  refreshClass5,
  logoffClass5,
  taskClass5,
  downloadClass5,
  supportClass5,
  copyWFMessage1,
} from "../config.json";

class Overview extends Component {
  state = {
    searchQuery: "",
    isError: false,
    isLoaded: false,
    allCourseTaskList: [],
    taskList: [],
    downloadTaskList: [],
    isAllLoaded: false,
    fileName: "SAMBDOWNLOAD",
    companyList: [],
    isChangePassword: false,
    isNodeLoaded: false,
    nodeReturn1: [],
    nodeReturn2: [],
    companyList11: [],
    companyList12: [],
    companyList21: [],
    companyList22: [],
    companyList1: [],
    companyList2: [],
    userList11: [],
    userList12: [],
    userList21: [],
    userList22: [],
    userList1: [],
    userList2: [],
    loggingList: [],
    sessionList: [],
    whereUsedProductList: [],
    isCopyWFError: false,
  };

  componentDidMount = async () => {
    // set local state
    if (this.props.isOverviewLoaded) {
      this.setState({ isLoaded: true });
      await this.getData(this.props.session);
    }
  };

  componentDidUpdate = async (prevProps) => {
    //
    // action after update
    //

    if (this.props.session !== prevProps.session) {
      // refresh data from db when session is different
      await this.getData(this.props.session);
    }
  };

  handleLogOut = () => {
    //
    // logout
    //
    this.props.onHandleLogout();
  };

  getData = async (session) => {
    //
    // get global used data in app
    //

    let userList = [];
    let groupList = [];
    let masterCourseList = [];
    let masterTaskList = [];
    let goalsList = [];
    let allMasterCourseList = [];
    let allergenList = [];
    let materialList = [];
    let uomList = [];
    let uomList2 = [];
    let trainerList = [];
    let trainerList1 = [];
    let trainerList2 = [];

    this.setState({
      isAllLoaded: false,
      allCourseTaskList: [],
      taskList: [],
      isCompanyLoaded: false,
      companyList: [],
      isLoaded: false,
      isError: false,
      isChangePassword: false,
    });
    // the data will be stored in the state off App.js and is passed to other components using routing
    // get company data
    const company = await auth.companyDetails(session);
    if (company[0].errorcode === noError) {
      this.props.onSaveCompanyList(company);
      // set overview loaded
      this.props.onOverviewLoaded(true);
      // set load status
      this.setState({
        companyList: company,
        isLoaded: true,
        isError: false,
      });
    } else {
      this.setState({
        companyList: [],
        isLoaded: false,
        isError: true,
      });
    }

    // users get
    const result1 = await getUser.userDetails(session, 2);
    if (result1.jsonReturn[0].errorcode === noError) {
      userList = result1.userList;
    }
    this.props.onSaveUserList(userList);


    // groups get
    const result2 = await getGroup.groupDetails(session);
    if (result2.jsonReturn[0].errorcode === noError) {
      groupList = result2.groupList;
    }
    this.props.onSaveGroupList(groupList);

    // master courses get
    const result3 = await getCourse.masterCourse(session);
    if (result3.jsonReturn[0].errorcode === noError) {
      //masterCourseList = result3.masterCourseList;
      masterCourseList = _.sortBy(result3.masterCourseList, function (item) {
        return item.label;
      });
    }
    this.props.onSaveMasterCourseList(masterCourseList);

    // mastertasks get
    const result4 = await getTask.masterTask(session);
    if (result4.jsonReturn[0].errorcode === noError) {
      masterTaskList = result4.masterTaskList;
    }
    this.props.onSaveMasterTaskList(masterTaskList);

    // goals get
    const result5 = await getGoal.goalDetails(session);
    if (result5.jsonReturn[0].errorcode === noError) {
      goalsList = result5.goalsList;
    }
    this.props.onSaveGoalsList(goalsList);

    // mastercourse get all info
    const result6 = await getCourse.allMasterCourses(session);
    if (result6.jsonReturn[0].errorcode === noError) {
      allMasterCourseList = _.sortBy(result6.masterCourseList, function (item) {
        return item.mastertasktext;
      });
    }
    this.props.onSaveAllMasterCourseList(allMasterCourseList);

    // allergen get
    const result7 = await getProduct.allAllergen(session);
    if (result7.jsonReturn[0].errorcode === noError) {
      allergenList = result7.allergenList;
    }
    this.props.onSaveAllergenList(allergenList);

    // material get
    const result8 = await getProduct.productDetails(session);
    if (result8.jsonReturn[0].errorcode === noError) {
      materialList = _.sortBy(result8.productList, function (item) {
        return item.searchfield;
      });
    }
    this.props.onSaveMaterialList(materialList);

    // uom get dropdown
    const result9 = await getProduct.allUom(session);
    if (result9.jsonReturn[0].errorcode === noError) {
      uomList = result9.uomList;
    }
    this.props.onSaveUOMList(uomList);

    // uom get details
    const result10 = await getProduct.uomDetails(session);
    if (result10.jsonReturn[0].errorcode === noError) {
      uomList2 = result10.uomList;
    }
    this.props.onSaveUOMList2(uomList2);

    // trainer list
    const result11 = await getUser.userDetails(session, 3);
    if (result11.jsonReturn[0].errorcode === noError) {
      trainerList1 = result11.userList;
    }
    const result12 = await getUser.userDetails(session, 1);
    if (result12.jsonReturn[0].errorcode === noError) {
      trainerList2 = result12.userList;
    }
    for (let index = 0; index < trainerList1.length; index++) {
      trainerList.push({
        id: trainerList1[index].userid,
        label: trainerList1[index].name,
      });
    }
    for (let index = 0; index < trainerList2.length; index++) {
      trainerList.push({
        id: trainerList2[index].userid,
        label: trainerList2[index].name,
      });
    }
    this.props.onSaveTrainerList(trainerList);
  };

  makeTitle() {
    //
    // create title
    //
    const { isAdmin } = this.props;

    let title = "";
    if (isAdmin) {
      title = " (" + titleAdmin + ")";
    } else {
      title = " (" + titleTeacher + ")";
    }
    return title;
  }

  makeSubTitle(user, session) {
    //
    // create subtitle
    //

    var subtitle;
    if (isMobile) {
      subtitle = user.firstname;
    } else {
      subtitle =
        user.firstname + " " + user.lastname + " - " + session.company_text1;
    }

    return subtitle;
  }

  handleGetAllTasks = async () => {
    //
    // get all tasks
    //

    let downloadTaskList = [];

    this.setState({
      isAllLoaded: false,
      allCourseTaskList: [],
      taskList: [],
      downloadTaskList: [],
      isChangePassword: false,
    });

    const { session } = this.props;
    const jsonList = await getTask.allCourseTask(session);
    const dbreturnList = jsonList.jsonReturn;
    const allCourseTaskList = jsonList.allCourseTaskList;
    const taskList = allCourseTaskList;
    if (dbreturnList[0].errorcode === noError) {
      // create download tasklist
      downloadTaskList = this.createDownloadTaskList(allCourseTaskList);
      // save local state
      this.setState({
        isAllLoaded: true,
        allCourseTaskList,
        taskList,
        downloadTaskList,
        isCompanyLoaded: false,
      });
    } else {
      this.setState({
        isAllLoaded: false,
        allCourseTaskList: [],
        taskList: [],
        downloadTaskList: [],
      });
    }
  };

  createDownloadTaskList = (allCourseTaskList) => {
    //
    // create download tasklist
    //

    let downloadTaskList = [];

    for (let index = 0; index < allCourseTaskList.length; index++) {
      //console.log(allCourseTaskList[index]);
      downloadTaskList.push({
        datum: allCourseTaskList[index].coursedate,
        vantot: allCourseTaskList[index].coursetime,
        klas: allCourseTaskList[index].grouptext,
        begeleider: allCourseTaskList[index].teachername,
        leerling: allCourseTaskList[index].studentname,
        les: allCourseTaskList[index].mastercoursetext,
        taak: allCourseTaskList[index].mastertasktext,
        status: allCourseTaskList[index].taskstate,
      });
    }

    return downloadTaskList;
  };

  handlePassword = () => {
    //
    // show change pasword
    //

    this.setState({
      isChangePassword: true,
      isCompanyLoaded: false,
      isAllLoaded: false,
    });
  };

  handleShowNodes = async () => {
    //
    // compare nodes
    //

    let nodeReturn2 = [];
    let companyList12 = [];
    let companyList22 = [];
    let companyList2 = [];
    let userList12 = [];
    let userList22 = [];
    let userList2 = [];
    let loggingList = [];
    let sessionList = [];

    let randomKeyAddition = (Math.random() * 101 * Date.now()) | 0;

    const result = await getNode.getData("X");

    const nodeReturn1 = result.jsonReturn;
    for (let index = 0; index < result.jsonReturn.length; index++) {
      randomKeyAddition = (Math.random() * (index + 101) * Date.now()) | 0;
      nodeReturn2.push({
        key: randomKeyAddition + index + result.jsonReturn[index].errorcode,
        text:
          result.jsonReturn[index].errorcode +
          " - " +
          result.jsonReturn[index].message,
      });
    }

    const companyList11 = result.companyList1;
    for (let index = 0; index < result.companyList1.length; index++) {
      randomKeyAddition = (Math.random() * (index + 101) * Date.now()) | 0;
      companyList12.push({
        key: randomKeyAddition + result.companyList1[index].compid,
        text:
          result.companyList1[index].compid +
          " - " +
          result.companyList1[index].text1 +
          " - " +
          result.companyList1[index].logontxt,
      });
    }

    const companyList21 = result.companyList2;
    for (let index = 0; index < result.companyList2.length; index++) {
      randomKeyAddition = (Math.random() * (index + 101) * Date.now()) | 0;
      companyList22.push({
        key: randomKeyAddition + result.companyList2[index].compid,
        text:
          result.companyList2[index].compid +
          " - " +
          result.companyList2[index].text1 +
          " - " +
          result.companyList2[index].logontxt,
      });
    }

    const companyList1 = result.companyList;
    for (let index = 0; index < result.companyList.length; index++) {
      randomKeyAddition = (Math.random() * (index + 101) * Date.now()) | 0;
      companyList2.push({
        key: randomKeyAddition + result.companyList[index].compid,
        text:
          result.companyList[index].compid +
          " - " +
          result.companyList[index].text1 +
          " - " +
          result.companyList[index].logontxt,
      });
    }

    const userList11 = result.userList1;
    for (let index = 0; index < result.userList1.length; index++) {
      randomKeyAddition = (Math.random() * (index + 101) * Date.now()) | 0;
      userList12.push({
        key: randomKeyAddition + result.userList1[index].userid,
        text:
          result.userList1[index].userid +
          " - " +
          result.userList1[index].compid +
          " - " +
          result.userList1[index].logon,
      });
    }

    const userList21 = result.userList2;
    for (let index = 0; index < result.userList2.length; index++) {
      randomKeyAddition = (Math.random() * (index + 101) * Date.now()) | 0;
      userList22.push({
        key: randomKeyAddition + result.userList2[index].userid,
        text:
          result.userList2[index].userid +
          " - " +
          result.userList2[index].compid +
          " - " +
          result.userList2[index].logon,
      });
    }

    const userList1 = result.userList;
    for (let index = 0; index < result.userList.length; index++) {
      randomKeyAddition = (Math.random() * (index + 101) * Date.now()) | 0;
      userList2.push({
        key: randomKeyAddition + result.userList[index].userid,
        text:
          result.userList[index].userid +
          " - " +
          result.userList[index].compid +
          " - " +
          result.userList[index].logon,
      });
    }

    const result2 = await getNode.sessionDetails();
    for (let index = 0; index < result2.length; index++) {
      if (result2[index].errorcode === noError) {
        randomKeyAddition = (Math.random() * (index + 101) * Date.now()) | 0;
        sessionList.push({
          key:
          randomKeyAddition + result2[index]._id + result2[index].sessid + result2[index].compid,
          text:
            result2[index].node +
            " - " +
            result2[index].sessid +
            " - " +
            result2[index].userid +
            " - " +
            result2[index].logon +
            " - " +
            result2[index].actdb +
            " - " +
            result2[index].compid +
            " - " +
            result2[index].text1 +
            " - " +
            result2[index].prd +
            " - " +
            result2[index].timestamp +
            " - " +
            result2[index].ipaddress +
            " - " +
            result2[index].os +
            " - " +
            result2[index].version +
            " - " +
            result2[index].app,
        });
      }
    }

    const result3 = await getNode.loggingDetails();
    for (let index = 0; index < result3.length; index++) {
      if (result3[index].errorcode === noError) {
        randomKeyAddition = (Math.random() * (index + 101) * Date.now()) | 0;
        loggingList.push({
          key:
          randomKeyAddition + result3[index].chts + result3[index].compid + result3[index].userid,
          text:
            result3[index].chts +
            " - " +
            result3[index].compid +
            " - " +
            result3[index].companytext +
            " - " +
            result3[index].userid +
            " - " +
            result3[index].logon +
            " - " +
            result3[index].actdb +
            " - " +
            result3[index].os +
            " - " +
            result3[index].version +
            " - " +
            result3[index].app +
            " - " +
            result3[index].text,
        });
      }
    }

    this.setState({
      isNodeLoaded: true,
      isCopyWFError: false,
      nodeReturn1,
      nodeReturn2,
      companyList11,
      companyList12,
      companyList21,
      companyList22,
      companyList1,
      companyList2,
      userList11,
      userList12,
      userList21,
      userList22,
      userList1,
      userList2,
      sessionList,
      loggingList,
    });
  };

  handleSearch = (query) => {
    //
    // execute search filter
    //
    let taskList = [];
    let downloadTaskList = [];
    const { allCourseTaskList } = this.state;

    ////console.log(allCourseTaskList);

    if (query !== "") {
      taskList = allCourseTaskList.filter((m) =>
        m.searchfield.toLowerCase().includes(query.toLowerCase())
      );
    } else {
      taskList = allCourseTaskList;
    }

    downloadTaskList = this.createDownloadTaskList(taskList);

    this.setState({
      downloadTaskList,
      taskList,
      searchQuery: query,
    });
  };

  handleCancelCopyWF = () => {
    //
    // cancel copy WF
    //

    this.setState({ isCopyWFError: false, isNodeLoaded: false });
  };

  handleCopyWF = async (itemData) => {
    //
    // copy wf from other environment
    //
    this.setState({ isCopyWFError: false, isNodeLoaded: false });

    const jsonReturn = await getTask.copyCompleteWF(
      itemData.node1,
      itemData.node2,
      itemData.mastertaskid,
      itemData.force
    );

    // retuncode
    const jsonList = jsonReturn["jsonReturn"];
    if (jsonList[0].errorcode === noError) {
    } else {
      this.setState({ isCopyWFError: true });
    }
  };

  renderTooltip(title) {
    return <Tooltip id="button-tooltip">{title}</Tooltip>;
  }

  render() {
    ////console.log(window.innerHeight);
    //console.log("isMobile: ",isMobile);

    const { session, user, isSupport } = this.props;

    // don't render if not ready
    if (typeof session.companyimageurl === "undefined") {
      ////console.log("render: ", new Date(), session.companyimageurl);
      return null;
    }

    const {
      isLoaded,
      taskList,
      downloadTaskList,
      isAllLoaded,
      searchQuery,
      companyList,
      fileName,
      isChangePassword,
      isNodeLoaded,
      nodeReturn1,
      nodeReturn2,
      companyList11,
      companyList12,
      companyList21,
      companyList22,
      companyList1,
      companyList2,
      userList11,
      userList12,
      userList21,
      userList22,
      userList1,
      userList2,
      loggingList,
      sessionList,
      isCopyWFError,
    } = this.state;

    // titles
    const title = this.makeTitle();
    const subtitle = this.makeSubTitle(user, session);

    return (
      <React.Fragment>
        {/* messages */}
        {!isLoaded && <TopWaitingMessage messageToShow={globalrefreshOK} />}
        {isCopyWFError && (
          <div className="alert alert-danger">{copyWFMessage1}</div>
        )}
        {/* navigation bar */}
        <NavBar />

        {/* title */}
        {!isMobile && (
          <h4>
            <span className={classTitle}>
              {subtitle}
              {title}
            </span>
          </h4>
        )}
        {isMobile && <span className={classTitle}>{subtitle}</span>}

        {/* button row for global actions */}
        <div className={classTopButtons}>
          <form className="form-inline">
            <OverlayTrigger
              placement="left"
              delay={{ show: 250, hide: 400 }}
              overlay={this.renderTooltip("ververs globale gegevens")}
            >
              <button
                type="button"
                className={refreshClass5}
                onClick={async () => this.getData(this.props.session)}
              ></button>
            </OverlayTrigger>
            <OverlayTrigger
              placement="left"
              delay={{ show: 250, hide: 400 }}
              overlay={this.renderTooltip("toon alle taken")}
            >
              <button
                type="button"
                className={taskClass5}
                onClick={() => this.handleGetAllTasks()}
              ></button>
            </OverlayTrigger>
            <OverlayTrigger
              placement="left"
              delay={{ show: 250, hide: 400 }}
              overlay={this.renderTooltip("wijzig paswoord")}
            >
              <button
                type="button"
                className={passwordClass5}
                onClick={() => this.handlePassword()}
              ></button>
            </OverlayTrigger>
            <OverlayTrigger
              placement="left"
              delay={{ show: 250, hide: 400 }}
              overlay={this.renderTooltip("uitloggen")}
            >
              <button
                type="button"
                className={logoffClass5}
                onClick={() => this.handleLogOut()}
              ></button>
            </OverlayTrigger>

            {isSupport && (
              <button
                type="button"
                className={supportClass5}
                onClick={() => this.handleShowNodes()}
              ></button>
            )}
          </form>
        </div>

        {isLoaded && (
          <div className={classTables}>
            <CompanyTable companyList={companyList}/>
          </div>
        )}

        {isChangePassword && (
          <div className={classPasswordTitle}>
            <ChangePasswordForm
              session={session}
              logon={session.logon}
              userid={user.userid}
              name={user.firstname + " " + user.lastname}
            />
          </div>
        )}

        {isAllLoaded && (
          <div>
            <div className={classTables}>
              <ExportCSV
                csvData={downloadTaskList}
                fileName={fileName}
                className={downloadClass5}
              />
              <SearchBox value={searchQuery} onChange={this.handleSearch} />
              <OverviewTaskTable taskList={taskList} />
            </div>
          </div>
        )}
        {isSupport && isNodeLoaded && (
          <div>
            <h3>copy workflow from between sam databases</h3>
            <div>
              <CopyWF
                newItem={this.handleCopyWF}
                cancelItem={this.handleCancelCopyWF}
              />
            </div>
            <h3>Sessions</h3>
            <div className={classTables}>
              <ExportCSV
                csvData={sessionList}
                fileName={fileName}
                className={downloadClass5}
              />
              <NodeTable nodeList={sessionList} />
            </div>
            <h3>Logging</h3>
            <div className={classTables}>
              <ExportCSV
                csvData={loggingList}
                fileName={fileName}
                className={downloadClass5}
              />
              <NodeTable nodeList={loggingList} />
            </div>
            <h3>Node Compare Results</h3>
            <div className={classTables}>
              <ExportCSV
                csvData={nodeReturn1}
                fileName={fileName}
                className={downloadClass5}
              />
              <NodeTable nodeList={nodeReturn2} />
            </div>
            <h3>User Compare</h3>
            <div className={classTables}>
              <ExportCSV
                csvData={userList1}
                fileName={fileName}
                className={downloadClass5}
              />
              <NodeTable nodeList={userList2} />
            </div>
            <h3>Company Compare</h3>
            <div className={classTables}>
              <ExportCSV
                csvData={companyList1}
                fileName={fileName}
                className={downloadClass5}
              />
              <NodeTable nodeList={companyList2} />
            </div>
            <h3>Company Node1</h3>
            <div className={classTables}>
              <ExportCSV
                csvData={companyList11}
                fileName={fileName}
                className={downloadClass5}
              />
              <NodeTable nodeList={companyList12} />
            </div>
            <h3>Company Node2</h3>
            <div className={classTables}>
              <ExportCSV
                csvData={companyList21}
                fileName={fileName}
                className={downloadClass5}
              />
              <NodeTable nodeList={companyList22} />
            </div>

            <h3>User Node1</h3>
            <div className={classTables}>
              <ExportCSV
                csvData={userList11}
                fileName={fileName}
                className={downloadClass5}
              />
              <NodeTable nodeList={userList12} />
            </div>
            <h3>User Node2</h3>
            <div className={classTables}>
              <ExportCSV
                csvData={userList21}
                fileName={fileName}
                className={downloadClass5}
              />
              <NodeTable nodeList={userList22} />
            </div>
          </div>
        )}
      </React.Fragment>
    );
  }
}

export default Overview;
