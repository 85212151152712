//
// Purpose: SAMB - group screen (class in case of education usage)
// Author: Ronny Van Elewyck
// Version: 1.0
// Creation Date: 06.06.2020
// Modification Date: 02.09.2020
//

// installed components
import React, { Component } from "react";
import _ from "lodash";

// created components
import NavBar from "./navBar";

import SearchBox from "./common/searchBox";
import GroupTable from "./common/groupTable";
import TopButton from "./common/topButton";
import NewGroupForm from "./common/newGroupForm";

// rest services
import getGroup from "../services/groupService";

// utilities
import { checkCharacters } from "../utils/specialCharacters";

// config files
import {
  noError,
  classTables,
  classTitle,
  groupTitle,
  groupMessage1,
} from "../config.json";

class Group extends Component {
  //
  // groupscreen
  //

  // local data
  state = {
    listArray: [],
    allListArray: [],
    downloadListArray: [],
    searchQuery: "",
    isLoaded: false,
    fileName: "SAMDOWNLOADKLASSEN",
    isUpdateError: false,
    isNew: false,
  };

  async componentDidMount() {
    //
    // mount after render
    //

    //console.log("componentDidMount(): ");

    await this.refreshGroups();
  }

  refreshGroups = async () => {
    //
    // refresh group data
    //

    this.setState({ listArray: [], allListArray: [] });

    let listArray = [];
    let downloadListArray = [];
    let groupList = [];

    const { session } = this.props;

    // groups get
    const jsonList = await getGroup.allGroup(session);

    if (jsonList.jsonReturn[0].errorcode === noError) {
      listArray = _.sortBy(jsonList.groupList, function (item) {
        return item.grouptext;
      });
      // download version for list
      downloadListArray = this.createDownloadList(listArray);

      this.setState({
        downloadListArray,
        listArray,
        allListArray: listArray,
        isLoaded: true,
      });
      // groups get
      const result2 = await getGroup.groupDetails(session);
      if (result2.jsonReturn[0].errorcode === noError) {
        groupList = result2.groupList;
      }
      this.props.onSaveGroupList(groupList);
    } else {
      this.setState({
        downloadListArray: [],
        listArray: [],
        allListArray: [],
        isLoaded: false,
      });
    }
  };

  createDownloadList = (listArray) => {
    //
    // create download list
    //

    let downloadList = [];

    for (let index = 0; index < listArray.length; index++) {
      downloadList.push({
        nummer: listArray[index].groupid1,
        klas: listArray[index].grouptext,
        actief: listArray[index].active,
      });
    }

    return downloadList;
  };

  handleSearch = (query) => {
    //
    // execute search filter
    //
    let listArray = [];
    let downloadListArray = [];

    const { allListArray } = this.state;

    if (query !== "") {
      listArray = allListArray.filter((m) =>
        m.searchfield.toLowerCase().includes(query.toLowerCase())
      );
    } else {
      listArray = allListArray;
    }

    listArray = _.sortBy(listArray, function (item) {
      return item.grouptext;
    });

    // download version for list
    downloadListArray = this.createDownloadList(listArray);

    this.setState({
      downloadListArray,
      listArray,
      searchQuery: query,
    });
  };

  handleRefresh = async () => {
    //
    // Refresh
    //

    await this.refreshGroups();
  };

  scrollTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  render() {
    //
    // render task screen
    //

    //console.log("render(): ");

    // get local state variables
    const {
      listArray,
      downloadListArray,
      isLoaded,
      fileName,
      searchQuery,
      isUpdateError,
      isNew,
    } = this.state;
    const { session, isAdmin } = this.props;

    // don't render if not ready
    if (typeof session.companyimageurl === "undefined") {
      return null;
    }

    // don't render if not ready
    if (typeof listArray === "undefined") {
      return null;
    }

    return (
      <React.Fragment>
        {/* error messages */}
        {isUpdateError && (
          <div className="alert alert-danger">{groupMessage1}</div>
        )}

        {/* navigation bar */}
        <NavBar />

        {/* header with school & teacher */}

        {/* title */}
        <h4>
          <span className={classTitle}>{groupTitle}</span>
        </h4>

        {/* button row for global actions */}

        <TopButton
          handleRefresh={this.handleRefresh}
          listArray={downloadListArray}
          fileName={fileName}
          handleNew={this.handleNew}
        />

        {/* new */}
        {isNew && (
          <div className={classTables}>
            <NewGroupForm
              newItem={this.handleNewItem}
              cancelItem={this.handleCancelItem}
            />
          </div>
        )}

        {/* table */}
        {isLoaded && (
          <div className={classTables}>
            <SearchBox value={searchQuery} onChange={this.handleSearch} />
            <GroupTable
              listArray={listArray}
              changeItem={this.changeItem}
              copyItem={this.copyItem}
              deleteItem={this.deleteItem}
              isAdmin={isAdmin}
            />
          </div>
        )}
      </React.Fragment>
    );
  }

  /*****************/
  /* row functions */
  /*****************/
  handleNew = () => {
    //
    // new
    //

    this.scrollTop();

    this.setState({
      isNew: true,
    });
  };

  handleCancelItem = () => {
    //
    // cancel
    //

    this.setState({ isNew: false });
  };

  handleNewItem = async (itemData) => {
    //
    // insert group
    //

    const { session } = this.props;

    this.setState({
      listArray: [],
      allListArray: [],
      isUpdateError: false,
    });

    // insertgroups
    const jsonList = await getGroup.insertGroup(session, itemData.label);
    const dbreturnList = jsonList.jsonReturn;

    if (dbreturnList[0].errorcode === noError) {
      // refresh data
      await this.refreshGroups();
      this.setState({
        isNew: false,
      });
    } else {
      // error
      this.setState({
        isUpdateError: true,
      });
    }
  };

  changeItem = async (itemData) => {
    //
    // change item
    //
    this.setState({ listArray: [], allListArray: [], isUpdateError: false });

    const { session } = this.props;

    //console.log("changeItem: ", itemData);

    // convert special characters
    const convertedGroupText = checkCharacters(itemData.grouptext);
    // update groups
    const jsonList = await getGroup.updateGroup(
      session,
      itemData.groupid1,
      itemData.active,
      convertedGroupText
    );
    const dbreturnList = jsonList.jsonReturn;

    if (dbreturnList[0].errorcode === noError) {
      // refresh data
      await this.refreshGroups();
    } else {
      // error
      this.setState({
        isUpdateError: true,
      });
    }
  };
  copyItem = async (itemData) => {
    //
    // copy item
    //

    const { session } = this.props;

    this.setState({
      listArray: [],
      allListArray: [],
      isUpdateError: false,
    });

    //console.log("copyItem: ", itemData);

    itemData.grouptext = "1k - " + itemData.grouptext;
    // insertgroups
    const jsonList = await getGroup.insertGroup(session, itemData.grouptext);
    const dbreturnList = jsonList.jsonReturn;

    if (dbreturnList[0].errorcode === noError) {
      // refresh data
      await this.refreshGroups();
    } else {
      // error
      this.setState({
        isUpdateError: true,
      });
    }
  };
  deleteItem = async (itemData) => {
    //
    // inactivate item
    //
    this.setState({
      listArray: [],
      allListArray: [],
      isUpdateError: false,
    });

    let checkError = "E99";

    const { session } = this.props;

    //console.log("deleteItem: ", itemData);

    // try to delete group
    const jsonList1 = await getGroup.deleteGroup(session, itemData.groupid1);
    //console.log("deleteItem jsonList1 : ", jsonList1);
    if (jsonList1.jsonReturn[0].errorcode === noError) {
      checkError = noError;
    } else {
      // if delete is not possible put inactive
      if (itemData.active === "X") {
        itemData.active = "";
      } else {
        itemData.active = "X";
      }

      // update groups
      const jsonList2 = await getGroup.updateGroup(
        session,
        itemData.groupid1,
        itemData.active,
        itemData.grouptext
      );

      if (jsonList2.jsonReturn[0].errorcode === noError) {
        checkError = noError;
      }
    }

    if (checkError === noError) {
      // refresh data
      await this.refreshGroups();
    } else {
      // error
      this.setState({
        isUpdateError: true,
      });
    }
  };
}

export default Group;
