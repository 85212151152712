//
// Purpose: SAMB - goal table component
// Author: Ronny Van Elewyck
// Version: 1.0
// Creation Date: 04.06.2020
// Modification Date: 21.09.2020
//

// installed components
import React from "react";

// created components
import GoalRow from "./goalRow";

const GoalTable = ({
  listArray,
  changeItem,
  deleteItem,
  groupList,
  masterCourseList,
}) => {
  return (
    <div className="table-responsive">
      <table className="table table-bordered">
        <thead className="thead-light">
          <tr>
            <th></th>
            <th></th>
            <th></th>
            <th>Doelstellingen</th>
          </tr>
        </thead>
        <tbody>
          {listArray.map((item) => (
            <GoalRow
              key={item.id}
              item={item}
              changeItem={changeItem}
              deleteItem={deleteItem}
              groupList={groupList}
              masterCourseList={masterCourseList}
            />
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default GoalTable;
