//
// Purpose: SAMB - image service
// Author: Ronny Van Elewyck
// Version: 1.2
// Creation Date: 25.02.2020
// Modification Date: 31.01.2021
//

import axios from "axios";
import { basisUrlSam, prd, p1, p2, p3, p4, p5, p6 } from "../config.json";

export async function uploadImage(imagePHP, uploadedImage) {
  //
  // upload image
  //

  const data = new FormData();
  data.append("file", uploadedImage);

  const postPHP = basisUrlSam + imagePHP;

  ////console.log(postPHP);

  const { data: response } = await axios.post(postPHP, data, {});
  const jsonReturn = response.DBRETURN;
  ////console.log(response.DBRETURN);

  return {
    jsonReturn,
  };
}

export async function updateUserImage(
  session,
  userid,
  imageurl,
  image,
  deleteFilePath
) {
  //
  // update user image and delete old
  //

  // variables
  let param1 = "";
  const getPHP = "updateUserImage.php";

  // sam instance
  if (session.actdb === prd) {
    param1 = session.prd;
  } else {
    param1 = session.dev;
  }
  // url
  const urlPut =
    basisUrlSam +
    getPHP +
    p1 +
    param1 +
    p2 +
    userid +
    p3 +
    imageurl +
    p4 +
    image +
    p5 +
    deleteFilePath;

  ////console.log(urlPut);

  // async call
  const { data: response } = await axios.get(urlPut);
  const jsonReturn = response.DBRETURN;

  return {
    jsonReturn,
  };
}

export async function updateProductImage(
  session,
  productid1,
  imageurl,
  image,
  deleteFilePath
) {
  //
  // update product image and delete old
  //

  // variables
  let param1 = "";
  const getPHP = "updateProductImage.php";

  // sam instance
  if (session.actdb === prd) {
    param1 = session.prd;
  } else {
    param1 = session.dev;
  }
  // url
  const urlPut =
    basisUrlSam +
    getPHP +
    p1 +
    param1 +
    p2 +
    productid1 +
    p3 +
    imageurl +
    p4 +
    image +
    p5 +
    deleteFilePath;

  ////console.log(urlPut);

  // async call
  const { data: response } = await axios.get(urlPut);
  const jsonReturn = response.DBRETURN;

  return {
    jsonReturn,
  };
}

export async function updateTaskImage(
  session,
  mastertaskid1,
  imageurl,
  image,
  deleteFilePath
) {
  //
  // update task image and delete old
  //

  // variables
  let param1 = "";
  const getPHP = "updateTaskImage.php";

  // sam instance
  if (session.actdb === prd) {
    param1 = session.prd;
  } else {
    param1 = session.dev;
  }
  // url
  const urlPut =
    basisUrlSam +
    getPHP +
    p1 +
    param1 +
    p2 +
    mastertaskid1 +
    p3 +
    imageurl +
    p4 +
    image +
    p5 +
    deleteFilePath;

  //console.log("updateTaskImage.php: ", urlPut);

  // async call
  const { data: response } = await axios.get(urlPut);
  const jsonReturn = response.DBRETURN;

  return {
    jsonReturn,
  };
}

export async function updateStepImage(
  session,
  subtask3,
  imageurl,
  image,
  deleteFilePath,
  subid
) {
  //
  // update step image(s) and delete old
  //

  // variables
  let param1 = "";
  const getPHP = "updateStepImage.php";

  // sam instance
  if (session.actdb === prd) {
    param1 = session.prd;
  } else {
    param1 = session.dev;
  }

  // url
  const urlPut =
    basisUrlSam +
    getPHP +
    p1 +
    param1 +
    p2 +
    subtask3 +
    p3 +
    imageurl +
    p4 +
    image +
    p5 +
    deleteFilePath +
    p6 +
    subid;

  //console.log("updateStepImage.php: ", urlPut);

  // async call
  const { data: response } = await axios.get(urlPut);
  const jsonReturn = response.DBRETURN;

  return {
    jsonReturn,
  };
}

export async function deleteImage(session, image) {
  //
  // delete image
  //

  // variables
  let param1 = "";
  const delPHP = "deleteImage.php";

  // sam instance
  if (session.actdb === prd) {
    param1 = session.prd;
  } else {
    param1 = session.dev;
  }

  // url
  const urlPut = basisUrlSam + delPHP + p1 + param1 + p2 + image;

  //console.log("updateStepImage.php: ", urlPut);

  // async call
  const { data: response } = await axios.get(urlPut);
  const jsonReturn = response.DBRETURN;

  return {
    jsonReturn,
  };
}

// eslint-disable-next-line
export default {
  deleteImage,
  uploadImage,
  updateUserImage,
  updateProductImage,
  updateTaskImage,
  updateStepImage,
};
