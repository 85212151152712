//
// Purpose: SAMB - crypt before sending over network
// Author: Ronny Van Elewyck
// Version: 1.0
// Creation Date: 25.02.2020
// Modification Date: 25.02.2020
//

import sha256 from "crypto-js/sha256";

export function encrypt(i1) {
  let v1 = sha256(i1);
  return v1.toString();
}
