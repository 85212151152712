//
// Purpose: SAMB - course service
// Author: Ronny Van Elewyck
// Version: 1.1
// Creation Date: 27.02.2020
// Modification Date: 04.12.2020
//

import axios from "axios";
import {
  basisUrlSam,
  prd,
  p1,
  p2,
  p3,
  p4,
  p5,
  p6,
  p7,
  p8,
  p9,
  p10,
  p11,
} from "../config.json";

export async function masterCourse(session) {
  //
  // get all sam master courses for dropdown
  //

  // variables
  let param1 = "";
  const getPHP = "getAllMasterCourses.php";

  // sam instance
  if (session.actdb === prd) {
    param1 = session.prd;
  } else {
    param1 = session.dev;
  }
  // url
  const urlGet = basisUrlSam + getPHP + p1 + param1;

  // async call
  const { data: response } = await axios.get(urlGet);
  const jsonReturn = response.DBRETURN;
  const masterCourseList = response.ALLCOURSE;

  return {
    jsonReturn,
    masterCourseList,
  };
}

export async function allMasterCourses(session) {
  //
  // get all sam master courses for maintenance
  //

  // variables
  let param1 = "";
  const getPHP = "getMasterCourse1.php";

  // sam instance
  if (session.actdb === prd) {
    param1 = session.prd;
  } else {
    param1 = session.dev;
  }
  // url
  const urlGet = basisUrlSam + getPHP + p1 + param1;

  //console.log("urlGet: ",urlGet);

  // async call
  const { data: response } = await axios.get(urlGet);
  const jsonReturn = response.DBRETURN;
  const masterCourseList = response.ALLMASTERCOURSE;

  return {
    jsonReturn,
    masterCourseList,
  };
}

export async function insertMasterCourse(session, text, type) {
  //
  // insert master course
  //

  // variables
  let param1 = "";
  const getPHP = "insertMasterCourse.php";

  // sam instance
  if (session.actdb === prd) {
    param1 = session.prd;
  } else {
    param1 = session.dev;
  }
  // url
  const urlPut = basisUrlSam + getPHP + p1 + param1 + p2 + text + p3 + type;

  //console.log(urlPut);

  // async call
  const { data: response } = await axios.get(urlPut);
  const jsonReturn = response.DBRETURN;

  return {
    jsonReturn,
  };
}

export async function updateMasterCourse(
  session,
  mastercourseid,
  active,
  text,
  type
) {
  //
  // update user courses
  //

  // variables
  let param1 = "";
  const getPHP = "updateMasterCourse.php";

  // sam instance
  if (session.actdb === prd) {
    param1 = session.prd;
  } else {
    param1 = session.dev;
  }
  // url
  const urlPut =
    basisUrlSam +
    getPHP +
    p1 +
    param1 +
    p2 +
    mastercourseid +
    p3 +
    active +
    p4 +
    text +
    p5 +
    type;

  //console.log(urlPut);

  // async call
  const { data: response } = await axios.get(urlPut);
  const jsonReturn = response.DBRETURN;

  return {
    jsonReturn,
  };
}

export async function groupCourse(
  session,
  mastercourseid,
  groupid,
  fromdate,
  todate
) {
  //
  // get all group courses
  //

  // variables
  let param1 = "";
  const getPHP = "getGroupCourses.php";

  // sam instance
  if (session.actdb === prd) {
    param1 = session.prd;
  } else {
    param1 = session.dev;
  }
  // url
  const urlGet =
    basisUrlSam +
    getPHP +
    p1 +
    param1 +
    p2 +
    mastercourseid +
    p3 +
    groupid +
    p4 +
    fromdate +
    p5 +
    todate;

  //console.log(urlGet);

  // async call
  const { data: response } = await axios.get(urlGet);
  const jsonReturn = response.DBRETURN;
  const groupCourseList = response.ALLGROUPCOURSES;

  return {
    jsonReturn,
    groupCourseList,
  };
}

export async function updateCourse(session, courseRecord) {
  //
  // get update course
  //

  // variables
  let param1 = "";
  const getPHP = "updateCourse1.php";

  // sam instance
  if (session.actdb === prd) {
    param1 = session.prd;
  } else {
    param1 = session.dev;
  }
  // url
  const urlPut =
    basisUrlSam +
    getPHP +
    p1 +
    param1 +
    p2 +
    courseRecord.courseid +
    p3 +
    courseRecord.mastercourseid +
    p4 +
    courseRecord.teacherid +
    p5 +
    courseRecord.groupid +
    p6 +
    courseRecord.yyyymmdd +
    p7 +
    courseRecord.timeblock +
    p8 +
    courseRecord.autotimeblock +
    p9 +
    courseRecord.fromtime +
    p10 +
    courseRecord.totime +
    p11 +
    courseRecord.showdate;

  //console.log(urlPut);

  // async call
  const { data: response } = await axios.get(urlPut);
  const jsonReturn = response.DBRETURN;

  return {
    jsonReturn,
  };
}

export async function insertCourse(session, courseRecord) {
  //
  // get insert course
  //

  // variables
  let param1 = "";
  const getPHP = "insertCourse1.php";

  // sam instance
  if (session.actdb === prd) {
    param1 = session.prd;
  } else {
    param1 = session.dev;
  }
  // url
  const urlPut =
    basisUrlSam +
    getPHP +
    p1 +
    param1 +
    p2 +
    courseRecord.mastercourseid +
    p3 +
    courseRecord.teacherid +
    p4 +
    courseRecord.groupid +
    p5 +
    courseRecord.yyyymmdd +
    p6 +
    courseRecord.timeblock +
    p7 +
    courseRecord.autotimeblock +
    p8 +
    courseRecord.fromtime +
    p9 +
    courseRecord.totime +
    p10 +
    courseRecord.showdate;

  //console.log(urlPut);

  // async call
  const { data: response } = await axios.get(urlPut);
  const jsonReturn = response.DBRETURN;

  return {
    jsonReturn,
  };
}

export async function deleteCourse(session, courseid) {
  //
  // get delete course
  //

  // variables
  let param1 = "";
  const getPHP = "deleteCourse1.php";

  // sam instance
  if (session.actdb === prd) {
    param1 = session.prd;
  } else {
    param1 = session.dev;
  }
  // url
  const urlPut = basisUrlSam + getPHP + p1 + param1 + p2 + courseid;

  //console.log(urlPut);

  // async call
  const { data: response } = await axios.get(urlPut);
  const jsonReturn = response.DBRETURN;

  return {
    jsonReturn,
  };
}

export async function userCourse(session, groupid, courseid) {
  //
  // get all group user courses
  //

  // variables
  let param1 = "";
  const getPHP = "getCourseUsers.php";

  // sam instance
  if (session.actdb === prd) {
    param1 = session.prd;
  } else {
    param1 = session.dev;
  }
  // url
  const urlGet =
    basisUrlSam + getPHP + p1 + param1 + p2 + groupid + p3 + courseid;
  //console.log(urlGet);

  // async call
  const { data: response } = await axios.get(urlGet);
  const jsonReturn = response.DBRETURN;
  const userCourseList = response.COURSEUSERS;

  return {
    jsonReturn,
    userCourseList,
  };
}

export async function updateCourseUser(
  session,
  courseid,
  studentid,
  status,
  activated
) {
  //
  // update user courses
  //

  // variables
  let param1 = "";
  const getPHP = "updateCourseUser.php";

  // sam instance
  if (session.actdb === prd) {
    param1 = session.prd;
  } else {
    param1 = session.dev;
  }
  // url
  const urlPut =
    basisUrlSam +
    getPHP +
    p1 +
    param1 +
    p2 +
    courseid +
    p3 +
    studentid +
    p4 +
    status +
    p5 +
    activated;

  //console.log(urlPut);

  // async call
  const { data: response } = await axios.get(urlPut);
  const jsonReturn = response.DBRETURN;

  return {
    jsonReturn,
  };
}

export async function insertCourseUser(
  session,
  courseid,
  studentid,
  status,
  activated
) {
  //
  // insert user course
  //

  // variables
  let param1 = "";
  const getPHP = "insertCourseUser.php";

  // sam instance
  if (session.actdb === prd) {
    param1 = session.prd;
  } else {
    param1 = session.dev;
  }
  // url
  const urlPut =
    basisUrlSam +
    getPHP +
    p1 +
    param1 +
    p2 +
    courseid +
    p3 +
    studentid +
    p4 +
    status +
    p5 +
    activated;

  //console.log(urlPut);

  // async call
  const { data: response } = await axios.get(urlPut);
  const jsonReturn = response.DBRETURN;

  return {
    jsonReturn,
  };
}

export async function deleteCourseUser(session, courseid) {
  //
  // delete user course and all linked tasks
  //

  // variables
  let param1 = "";
  const getPHP = "deleteCourseUser.php";

  // sam instance
  if (session.actdb === prd) {
    param1 = session.prd;
  } else {
    param1 = session.dev;
  }
  // url
  const urlPut = basisUrlSam + getPHP + p1 + param1 + p2 + courseid;
  //+
  //p3 +
  //studentid
  //console.log(urlPut);

  // async call
  const { data: response } = await axios.get(urlPut);
  const jsonReturn = response.DBRETURN;

  return {
    jsonReturn,
  };
}

export async function deleteMasterCourse(session, mastercourseid) {
  //
  // delete mastercourse if not used
  //

  // variables
  let param1 = "";
  const getPHP = "deleteMasterCourse.php";

  // sam instance
  if (session.actdb === prd) {
    param1 = session.prd;
  } else {
    param1 = session.dev;
  }
  // url
  const urlPut = basisUrlSam + getPHP + p1 + param1 + p2 + mastercourseid;

  //console.log(urlPut);

  // async call
  const { data: response } = await axios.get(urlPut);
  const jsonReturn = response.DBRETURN;

  return {
    jsonReturn,
  };
}

// eslint-disable-next-line
export default {
  masterCourse,
  groupCourse,
  updateCourse,
  insertCourse,
  deleteCourse,
  userCourse,
  updateCourseUser,
  insertCourseUser,
  deleteCourseUser,
  allMasterCourses,
  insertMasterCourse,
  updateMasterCourse,
  deleteMasterCourse,
};
