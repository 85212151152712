//
// Purpose: SAMB - copy WF from other environments
// Author: Ronny Van Elewyck
// Version: 1.0
// Creation Date: 29.01.2021
// Modification Date: 29.01.2021
//

// installed components
import React from "react";
import Tooltip from "react-bootstrap/Tooltip";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";

// created components

// config files
import { saveClass5, cancelClass5 } from "../../config.json";

const CopyWF = ({ newItem, cancelItem }) => {
  //
  // format row of table
  //

  let title = "copy workflow from other database";

  const [node1, setValue1] = React.useState("");
  const [node2, setValue2] = React.useState("");
  const [mastertaskid, setValue3] = React.useState("");
  const [force, setValue4] = React.useState("");

  const sizeLine1 = 10;
  const sizeLine2 = 1;

  function renderTooltip(title) {
    return <Tooltip id="button-tooltip">{title}</Tooltip>;
  }

  return (
    <div>
      <div>
        <OverlayTrigger
          placement="left"
          delay={{ show: 250, hide: 400 }}
          overlay={renderTooltip("terug")}
        >
          <button
            className={cancelClass5}
            onClick={() => cancelItem()}
          ></button>
        </OverlayTrigger>

        <OverlayTrigger
          placement="left"
          delay={{ show: 250, hide: 400 }}
          overlay={renderTooltip("kopie")}
        >
          <button
            className={saveClass5}
            onClick={() =>
              newItem({
                node1,
                node2,
                mastertaskid,
                force,
              })
            }
          ></button>
        </OverlayTrigger>
      </div>

      <div className="table-responsive">
        <table className="table table-bordered">
          <tbody>
            <tr>
              <td>
                <h4>
                  <span className="badge badge-info d-flex justify-content-center">
                    {title}
                  </span>
                </h4>
              </td>
            </tr>
            <tr>
              <td>
                <h6>from sam database</h6>
                <input
                  type="text"
                  className="form-control"
                  value={node1}
                  onChange={(e) => setValue1(e.currentTarget.value)}
                  maxLength={sizeLine1}
                />
                <h6>to sam database</h6>
                <input
                  type="text"
                  className="form-control"
                  value={node2}
                  onChange={(e) => setValue2(e.currentTarget.value)}
                  maxLength={sizeLine1}
                />
                <h6>mastertaskid</h6>
                <input
                  type="text"
                  className="form-control"
                  value={mastertaskid}
                  onChange={(e) => setValue3(e.currentTarget.value)}
                  maxLength={sizeLine1}
                />
                <h6>copy with force option?</h6>
                <input
                  type="text"
                  className="form-control"
                  value={force}
                  onChange={(e) => setValue4(e.currentTarget.value)}
                  maxLength={sizeLine2}
                />
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default CopyWF;
