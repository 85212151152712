//
// Purpose: SAMB - courseTasks screen
// Author: Ronny Van Elewyck
// Version: 1.1
// Creation Date: 19.03.2020
// Modification Date: 15.04.2021
//

// installed
import React, { Component } from "react";
import _ from "lodash";
import Tooltip from "react-bootstrap/Tooltip";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";

// created components
import SearchBox from "./common/searchBox";
import ExportCSV from "./common/exportCSV";
import TaskTable from "./common/taskTable";
import DeleteTaskForm from "./common/deleteTaskForm";
import NewTaskForm from "./common/newTaskForm";
import QrcodeForm from "./common/qrcodeForm";

// utilities
import { checkCharacters } from "../utils/specialCharacters";

// rest services
import getTask from "../services/taskService";
import getCourse from "../services/courseService";

// config
import {
  noError,
  classTopButtons,
  classErrorMessage,
  classSuccessMessage,
  classTables,
  backClass5,
  newClass5,
  downloadClass5,
  classTopMenu2,
  errorUpdateMessage1,
  errorDeleteMessage1,
  selectErrorMessage2,
  noDataMessage1,
  fatalDBError1,
  errorInsertMessage1,
  taskMessage1,
} from "../config.json";

class CourseTasks extends Component {
  state = {
    goalsList: [],
    userCourseList: [],
    courseTaskList: [],
    allCourseTaskList: [],
    downloadCourseTaskList: [],
    masterTaskDropdown: [],
    fileName: "SAMLESTAKEN",
    searchQuery: "",
    isReadOnly: false,
    isLoaded: false,
    isNothingToDo: false,
    isInsertedError: false,
    isUpdateError: false,
    isNewTask: false,
    isAskDeleteSure: false,
    deleteRecord: undefined,
    isDBInconsistent: false,
    isError: false,
    isDeleteError: false,
    isCreateQRCODE: false,
    qrcodeRecord : {
      logon: "",
      pw: "",
      taskid: "",
      studentid: "",
      mastertaskid: "",
      tasktext: "",
    },
  };

  componentDidMount = async () => {
    //
    // mount after render
    //

    const { courseRecord } = this.props.location.state;
    const { masterTaskList } = this.props;

    let goalsList = [];
    let masterTaskDropdown = [];
    let isReadOnly = this.props.location.state.isReadOnly;
    let userCourseList = this.props.location.state.userCourseList;

    if (this.props.goalsList.length > 0) {
      // filter goals
      goalsList = this.props.goalsList.filter(
        (m) =>
          m.mastercourseid === courseRecord.mastercourseid &&
          m.groupid === courseRecord.groupid
      );
      if (goalsList.length > 0) {
        // sort
        goalsList = _.sortBy(goalsList, function (item) {
          return item.goaltext;
        });
        // put blanco line as first
        goalsList.unshift(goalsList.pop());
      } else {
        goalsList.push({
          id: 1,
          label: "",
          mastercourseid: courseRecord.mastercourseid,
        });
      }
    } else {
      goalsList.push({
        id: 1,
        label: "",
        mastercourseid: courseRecord.mastercourseid,
      });
    }

    // dropdownlist for mastertasks
    for (let index = 0; index < masterTaskList.length; index++) {
      masterTaskDropdown.push({
        id: masterTaskList[index].mastertaskid1,
        label:
          masterTaskList[index].mastertasktext +
          " - " +
          masterTaskList[index].mastertasktext2,
        typeid1: masterTaskList[index].typeid1,
      });
    }

    // get course tasks
    await this.getCourseTasks(courseRecord.courseid);

    // check new users in group not yet in userCourse
    await this.checkCourseUser(courseRecord.groupid, courseRecord.courseid);

    this.setState({
      isReadOnly,
      goalsList,
      userCourseList,
      masterTaskDropdown,
    });
  };

  checkCourseUser = async (groupid, courseid) => {
    //
    // check users not in userCourse
    //

    let isDBInconsistent = false;

    const { userCourseList } = this.props.location.state;
    const { session } = this.props;
    const jsonList1 = await getCourse.userCourse(session, groupid, courseid);

    if (jsonList1.jsonReturn[0].errorcode === noError) {
      for (let index = 0; index < userCourseList.length; index++) {
        const isUserInDB = jsonList1.userCourseList.filter(
          (m) => m._id === userCourseList[index].userid
        );

        if (isUserInDB.length === 0) {
          //console.log("not in db:", userCourseList[index].userid);

          // create user in db
          const jsonReturn = await getCourse.insertCourseUser(
            session,
            courseid,
            userCourseList[index].userid,
            "open",
            "actief"
          );
          const jsonList = jsonReturn["jsonReturn"];
          
          if (jsonList[0].errorcode !== noError) {
            // error
            isDBInconsistent = true;
          }
        }
      }
    } else {
      // error
      isDBInconsistent = true;
    }

    this.setState({ isDBInconsistent });
  };

  getCourseTasks = async (courseid) => {
    //
    // get user tasks
    //

    let isError = false;
    let isLoaded = false;
    this.setState({
      isLoaded,
      isError,
      allCourseTaskList: [],
      courseTaskList: [],
    });

    //console.log("getCourseTasks", new Date());
    // state objects

    let courseTaskList = [];
    let allCourseTaskList = [];
    let downloadCourseTaskList = [];

    // get props
    const { session } = this.props;

    // get all tasks from db
    const jsonList = await getTask.courseTask(session, courseid);
    //const dbreturnList = jsonList.jsonReturn;

    if (jsonList.jsonReturn[0].errorcode === noError) {
      allCourseTaskList = jsonList.courseTaskList;
      //console.log("getCourseTasks(courseid): ", courseTaskList);
      isError = false;

      // replace . with , for portion
      for (let index = 0; index < allCourseTaskList.length; index++) {
        if (allCourseTaskList[index].hasOwnProperty("portion")) {
          allCourseTaskList[index].portion = allCourseTaskList[index].portion
            .toString()
            .replace(/[.]+/, ",");
        }
      }

      // apply filter
      if (this.state.searchQuery !== "") {
        courseTaskList = allCourseTaskList.filter((m) =>
          m.searchfield
            .toLowerCase()
            .includes(this.state.searchQuery.toLowerCase())
        );
        //console.log(this.state.searchQuery);
      } else {
        courseTaskList = allCourseTaskList;
      }

      // sort by student first and then by order
      courseTaskList = _.sortBy(courseTaskList, function (item) {
        return parseInt(item.taskorder);
      });
      courseTaskList = _.sortBy(courseTaskList, function (item) {
        return item.name;
      });
      allCourseTaskList = _.sortBy(allCourseTaskList, function (item) {
        return parseInt(item.taskorder);
      });
      allCourseTaskList = _.sortBy(allCourseTaskList, function (item) {
        return item.name;
      });

      // download version for list
      downloadCourseTaskList = this.createDownloadList(courseTaskList);
    } else {
      isError = true;
    }

    isLoaded = true;

    //console.log(courseTaskList);
    this.setState({
      isLoaded,
      courseTaskList,
      allCourseTaskList,
      downloadCourseTaskList,
      isError,
    });
  };

  createDownloadList = (listArray) => {
    //
    // create download list
    //

    const { courseRecord } = this.props.location.state;

    //console.log(courseRecord);
    let downloadList = [];

    for (let index = 0; index < listArray.length; index++) {
      //console.log(listArray[index].tasktext);
      downloadList.push({
        les: courseRecord.title,
        leerling: listArray[index].name,
        taak: listArray[index].tasktext,
        status: listArray[index].taskstatus,
        volgorde: listArray[index].taskorder,
        zichtbaar: listArray[index].visible,
        aantal: listArray[index].portion,
        doel1: listArray[index].goal1,
        doel2: listArray[index].goal2,
        doel3: listArray[index].goal3,
        doel4: listArray[index].goal4,
        doel5: listArray[index].goal5,
      });
    }

    return downloadList;
  };

  handleSort = (sortColumn) => {
    //
    // sort clicked
    //
    this.setState({ sortColumn });
  };

  handleSearch = (query) => {
    //
    // execute search filter
    //

    let courseTaskList = [];
    let downloadCourseTaskList = [];

    const { allCourseTaskList } = this.state;

    if (query !== "") {
      courseTaskList = allCourseTaskList.filter((m) =>
        m.searchfield.toLowerCase().includes(query.toLowerCase())
      );
    } else {
      courseTaskList = allCourseTaskList;
    }

    // sort
    courseTaskList = _.sortBy(courseTaskList, function (item) {
      return item.name;
    });

    // download version for list
    downloadCourseTaskList = this.createDownloadList(courseTaskList);

    this.setState({
      downloadCourseTaskList,
      courseTaskList,
      searchQuery: query,
    });
  };

  handleBack = () => {
    //
    // set history to go back
    //

    const {
      courseRecord,
      userCourseList,
      isReadOnly,
      fromDate,
      toDate,
    } = this.props.history.location.state;

    // back to parent
    this.props.history.push({
      pathname: "/courses",
      state: { userCourseList, courseRecord, isReadOnly, fromDate, toDate },
    });
  };

  handleNew = () => {
    //
    // new task clicked
    //

    //console.log("handleNew: ");
    this.setState({
      isNewTask: true,
    });
  };

  handleCancelNewTask = () => {
    //
    // cancel new task window
    //

    //console.log("handleCancelNewTask: ");

    this.setState({
      isNewTask: false,
    });
  };

  handleCancelQRCODE = () => {
    //
    // cancel qrcode creation window
    //

    const { qrcodeRecord } = this.state;
  
    qrcodeRecord.logon = "";
    qrcodeRecord.pw = "";
    qrcodeRecord.taskid = "";
    qrcodeRecord.studentid = "";
    qrcodeRecord.mastertaskid = "";
    qrcodeRecord.tasktext = "";

    this.setState({
      isCreateQRCODE: false,
      qrcodeRecord,
    });
  };

  
  handleNewTask = async (newItemData) => {
    //
    // save new task
    //

    let isNothingToDo = false;
    let taskRecord = {
      courseid: 0,
      studentid: 0,
      mastertaskid1: 0,
      status: "open",
      portion: 0.0,
      goal1: "",
      goal2: "",
      goal3: "",
      goal4: "",
      goal5: "",
      priority: 1,
      visible: "",
    };

    const { userCourseList } = this.state;
    const { session } = this.props;
    const { courseRecord } = this.props.location.state;

    //console.log("handleNewTask: ", newItemData, courseRecord);

    // courseid
    taskRecord.courseid = courseRecord.courseid;

    // mastertaskid
    taskRecord.mastertaskid1 = newItemData.masterCourse.id;

    // portion
    if (newItemData.masterCourse.typeid1 === "1") {
      // replace , by .
      // convert to decimal
      const portionDec = parseFloat(newItemData.portion.replace(/[,]+/, "."));
      taskRecord.portion = portionDec;
    } else {
      taskRecord.portion = 1;
    }

    // priority (order)
    taskRecord.priority = newItemData.order;

    // visible (show task)
    taskRecord.visible = newItemData.showTask;

    // goals
    // standard goals
    if (typeof newItemData.goal1 !== "undefined") {
      taskRecord.goal1 = newItemData.goal1.label;
    } else {
      taskRecord.goal1 = "";
    }
    if (typeof newItemData.goal2 !== "undefined") {
      taskRecord.goal2 = newItemData.goal2.label;
    } else {
      taskRecord.goal2 = "";
    }
    if (typeof newItemData.goal3 !== "undefined") {
      taskRecord.goal3 = newItemData.goal3.label;
    } else {
      taskRecord.goal3 = "";
    }
    if (typeof newItemData.goal4 !== "undefined") {
      taskRecord.goal4 = newItemData.goal4.label;
    } else {
      taskRecord.goal4 = "";
    }
    // free goal
    taskRecord.goal5 = newItemData.goal5;

    // loop over all active users and insert new task
    const selectedUsers = userCourseList.filter(
      (m) => m.activated === "actief"
    );
    if (selectedUsers.length > 0) {
      for (let index = 0; index < selectedUsers.length; index++) {
        // studentid
        taskRecord.studentid = selectedUsers[index].userid;
        // insert in db
        await this.insertTaskinDB(session, taskRecord);
        // refresh
        await this.getCourseTasks(courseRecord.courseid);
      }
    } else {
      isNothingToDo = true;
    }

    this.setState({
      isNothingToDo,
      isNewTask: false,
    });
  };

  handleStudentActivate = () => {
    //
    // activate all students
    //
    const { userCourseList } = this.state;

    for (let index = 0; index < userCourseList.length; index++) {
      userCourseList[index].activated = "actief";
    }

    this.setState({
      userCourseList,
    });
  };

  handleStudentDeActivate = () => {
    //
    // deactivate all students
    //
    const { userCourseList } = this.state;

    for (let index = 0; index < userCourseList.length; index++) {
      userCourseList[index].activated = "niet actief";
    }

    this.setState({
      userCourseList,
    });
  };

  updateStudentItem = (item) => {
    //
    // update individual row
    //

    const { userCourseList } = this.state;

    //console.log("updateStudentItem: ", item, userCourseList);
    const index = userCourseList.findIndex((r) => r.userid === item.userid);
    let updatedRows = userCourseList;
    if (item.activated === "actief") {
      item.activated = "niet actief";
    } else {
      item.activated = "actief";
    }
    updatedRows.splice(index, 1, item);

    //console.log("updatedRows: ", updatedRows);

    this.setState({
      userCourseList: updatedRows,
    });
  };

  renderTooltip(title) {
    return <Tooltip id="button-tooltip">{title}</Tooltip>;
  }

  scrollTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  render() {
    const { session } = this.props;
    // don't render if not ready
    if (typeof session.companyimageurl === "undefined") {
      //console.log("render: ", new Date());
      return null;
    }

    const {
      courseTaskList,
      downloadCourseTaskList,
      masterTaskDropdown,
      userCourseList,
      goalsList,
      searchQuery,
      fileName,
      deleteRecord,
      isReadOnly,
      isLoaded,
      isDBInconsistent,
      isNothingToDo,
      isInsertedError,
      isUpdateError,
      isDeleteError,
      isNewTask,
      isError,
      isAskDeleteSure,
      isCreateQRCODE,
      qrcodeRecord,
    } = this.state;

    //console.log("render", isError, isLoaded);

    const { courseRecord } = this.props.location.state;

    // console.log("goalsList in render: ", goalsList);

    return (
      <React.Fragment>
        {/* screen messages */}
        {!isLoaded && (
          <div className="d-flex justify-content-center">
            <div className="spinner-grow text-warning" role="status"></div>
            <div className="alert alert-warning ml-2">
              {selectErrorMessage2}
            </div>
            <div className="spinner-grow text-warning ml-2" role="status"></div>
          </div>
        )}
        {isUpdateError && (
          <div className={classErrorMessage}>{errorUpdateMessage1}</div>
        )}
        {isInsertedError && (
          <div className={classErrorMessage}>{errorInsertMessage1}</div>
        )}
        {isNothingToDo && (
          <div className={classErrorMessage}>{taskMessage1}</div>
        )}
        {isError && <div className={classSuccessMessage}>{noDataMessage1}</div>}
        {isDeleteError && (
          <div className={classErrorMessage}>{errorDeleteMessage1}</div>
        )}
        {isDBInconsistent && (
          <div className={classErrorMessage}>{fatalDBError1}</div>
        )}

        {/* header */}
        <div className={classTopMenu2}>
          <h4>{courseRecord.title}</h4>
        </div>

        {/* button row for global actions */}
        <div className={classTopButtons}>
          <form className="form-inline">
            <div>
              <OverlayTrigger
                placement="left"
                delay={{ show: 250, hide: 400 }}
                overlay={this.renderTooltip("terug")}
              >
                <div>
                  <button
                    type="button"
                    className={backClass5}
                    onClick={() => this.handleBack()}
                  ></button>
                </div>
              </OverlayTrigger>
            </div>

            {!isReadOnly && (
              <OverlayTrigger
                placement="left"
                delay={{ show: 250, hide: 400 }}
                overlay={this.renderTooltip("nieuw")}
              >
                <div>
                  <button
                    type="button"
                    className={newClass5}
                    onClick={() => this.handleNew()}
                  ></button>
                </div>
              </OverlayTrigger>
            )}

            <ExportCSV
              csvData={downloadCourseTaskList}
              fileName={fileName}
              className={downloadClass5}
            />
          </form>
        </div>

        {/* new task */}
        {!isReadOnly && isNewTask && (
          <div className={classTables}>
            <NewTaskForm
              newItem={this.handleNewTask}
              cancelItem={this.handleCancelNewTask}
              handleStudentActivate={this.handleStudentActivate}
              handleStudentDeActivate={this.handleStudentDeActivate}
              updateStudentItem={this.updateStudentItem}
              userCourseList={userCourseList}
              masterCourseList={masterTaskDropdown}
              goalsList={goalsList}
            />
          </div>
        )}

        {/* create qrcode */}
        {!isReadOnly && isCreateQRCODE && (
          <div className={classTables}>
            <QrcodeForm
            cancelItem={this.handleCancelQRCODE}
            qrcodeRecord={qrcodeRecord}
            session={session}
            />
          </div>
        )}

        {/* confirm task delete */}
        {isAskDeleteSure && (
          <div className={classTables}>
            <DeleteTaskForm
              confirmDeleteItem={this.handleConfirmDeleteItem}
              cancelItem={this.handleCancelDeleteItem}
              item={deleteRecord}
            />
          </div>
        )}

        {!isNewTask && !isError && (
          <div className={classTables}>
            <SearchBox value={searchQuery} onChange={this.handleSearch} />
            <TaskTable
              courseTaskList={courseTaskList}
              goalsList={goalsList}
              updateItem={this.updateItem}
              deleteItem={this.deleteItem}
              isReadOnly={isReadOnly}
              createQRCODE={this.createQRCODE}
            />
          </div>
        )}
      </React.Fragment>
    );
  }

  /*****************/
  /* row functions */
  /*****************/
  insertTaskinDB = async (session, taskRecord) => {
    //
    // insert db
    //

    //console.log("taskRecord: ", taskRecord);
    let isInsertedError = false;

    // call rest service
    const jsonReturn = await getTask.insertTask(session, taskRecord);
    // retuncode
    const jsonList = jsonReturn["jsonReturn"];
    if (jsonList[0].errorcode !== noError) {
      // refresh screen fields
      isInsertedError = true;
    }

    // update local state
    this.setState({
      isInsertedError,
    });
  };

  deleteItem = (item) => {
    //
    // confirm delete individual row
    //

    this.scrollTop();

    this.setState({
      isAskDeleteSure: true,
      deleteRecord: item,
    });
  };

  handleConfirmDeleteItem = async () => {
    //
    // delete individual row
    //
    const { session } = this.props;
    const { deleteRecord } = this.state;

    await this.deleteDB(session, deleteRecord._id, deleteRecord.courseid);

    this.setState({
      isAskDeleteSure: false,
      deleteErrorFlag: false,
    });
  };

  createQRCODE = (item) => {
    //
    // create qrcode
    //

    const { qrcodeRecord } = this.state;

    
    this.scrollTop();

    qrcodeRecord.logon = "";
    qrcodeRecord.pw = "";
    qrcodeRecord.taskid = item._id;
    qrcodeRecord.studentid = item.studentid;
    qrcodeRecord.mastertaskid = item.mastertaskid;
    qrcodeRecord.tasktext = item.tasktext;
    

    this.setState({
      isCreateQRCODE: true,
      qrcodeRecord,
    });
  };



  handleCancelDeleteItem = () => {
    //
    // cancel delete window
    //

    this.setState({
      isAskDeleteSure: false,
      deleteRecord: undefined,
    });
  };

  deleteDB = async (session, taskid1, courseid) => {
    //
    // delete record in database
    //

    let isDeleteError = false;

    const jsonReturn = await getTask.deleteTask(session, taskid1);

    if (jsonReturn["jsonReturn"][0].errorcode === noError) {
      // refresh user courses
      await this.getCourseTasks(courseid);
    }

    this.setState({
      isDeleteError,
    });
  };

  updateDB = async (session, taskRecord) => {
    //
    // update db
    //

    let isUpdateError = false;

    //console.log("updateDB taskRecord: ", taskRecord);

    // special characters
    const convertedGoalText = checkCharacters(taskRecord.goal5);
    taskRecord.goal5 = convertedGoalText;

    // update db
    const jsonReturn = await getTask.updateTask(session, taskRecord);
    // retuncode
    const jsonList = jsonReturn["jsonReturn"];
    if (jsonList[0].errorcode === noError) {
      // get course tasks
      await this.getCourseTasks(taskRecord.courseid);
    } else {
      isUpdateError = true;
    }

    this.setState({
      isUpdateError,
    });
  };

  updateItem = async (newItemData) => {
    //
    // update individual row
    //

    const { session } = this.props;

    let taskRecord = {
      taskid1: 0,
      courseid: 0,
      studentid: 0,
      mastertaskid1: 0,
      status: "open",
      portion: 0.0,
      goal1: "",
      goal2: "",
      goal3: "",
      goal4: "",
      goal5: "",
      priority: 1,
      visible: "",
    };

    // taskid
    taskRecord.taskid1 = newItemData._id;

    // courseid
    taskRecord.courseid = newItemData.courseid;

    // studentid
    taskRecord.studentid = newItemData.studentid;

    // task id
    taskRecord.mastertaskid1 = newItemData.mastertaskid;

    // status
    taskRecord.status = newItemData.taskstatus;

    // portion
    if (newItemData.typeid1 === "1") {
      // replace , by .
      // convert to decimal
      const portionDec = parseFloat(newItemData.portion.replace(/[,]+/, "."));
      taskRecord.portion = portionDec;
    } else {
      taskRecord.portion = 1;
    }

    // priority (order)
    taskRecord.priority = newItemData.taskorder;

    // visible (show task)
    taskRecord.visible = newItemData.visible;

    // goals
    // standard goals
    if (typeof newItemData.goal1 !== "undefined") {
      if (typeof newItemData.goal1.label !== "undefined") {
        taskRecord.goal1 = newItemData.goal1.label;
      } else {
        taskRecord.goal1 = newItemData.goal1;
      }
    } else {
      taskRecord.goal1 = "";
    }
    if (typeof newItemData.goal2 !== "undefined") {
      if (typeof newItemData.goal2.label !== "undefined") {
        taskRecord.goal2 = newItemData.goal2.label;
      } else {
        taskRecord.goal2 = newItemData.goal2;
      }
    } else {
      taskRecord.goal2 = "";
    }
    if (typeof newItemData.goal3 !== "undefined") {
      if (typeof newItemData.goal3.label !== "undefined") {
        taskRecord.goal3 = newItemData.goal3.label;
      } else {
        taskRecord.goal3 = newItemData.goal3;
      }
    } else {
      taskRecord.goal3 = "";
    }
    if (typeof newItemData.goal4 !== "undefined") {
      if (typeof newItemData.goal4.label !== "undefined") {
        taskRecord.goal4 = newItemData.goal4.label;
      } else {
        taskRecord.goal4 = newItemData.goal4;
      }
    } else {
      taskRecord.goal4 = "";
    }
    // free goal
    taskRecord.goal5 = newItemData.goal5;

    //let allCourseTaskList = this.state.allCourseTaskList;

    //console.log("before taskRecord: ", taskRecord);

    // update local state
    /* const index = allCourseTaskList.findIndex((r) => r._id === newItemData._id);
    let updatedRows = allCourseTaskList;
    updatedRows.splice(index, 1, newItemData);

    allCourseTaskList = updatedRows;*/

    // updateDB
    await this.updateDB(session, taskRecord);
  };
  // end of class
}

export default CourseTasks;
