//
// Purpose: SAMB - workflow Row
// Author: Ronny Van Elewyck
// Version: 1.1
// Creation Date: 04.06.2020
// Modification Date: 30.01.2021
//

// installed components
import React from "react";
import { Link } from "react-router-dom";
import Tooltip from "react-bootstrap/Tooltip";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";

// config files
import {
  changeClass5,
  copyClass5,
  deleteClass5,
  printClass5,
} from "../../config.json";

const WFRow = ({ item, copyItem, deleteItem, isAdmin }) => {
  //
  // format row of table
  //

  let isTemplateWF = false;

  // prevent deleting standard WF
  if (
    item.mastertaskid1 === "1" ||
    item.mastertaskid1 === "2" ||
    item.mastertaskid1 === "3"
  ) {
    isTemplateWF = true;
  }

  function renderTooltip(title) {
    return <Tooltip id="button-tooltip">{title}</Tooltip>;
  }

  return (
    <tr>
      {isAdmin && (
        <td>
          {!isTemplateWF && (
            <Link
              to={{
                pathname: "/changesteps",
                state: { isChange: true, item },
              }}
            >
              <div>
                <OverlayTrigger
                  placement="left"
                  delay={{ show: 250, hide: 400 }}
                  overlay={renderTooltip("ga naar detail")}
                >
                  <div>
                    <button className={changeClass5}></button>
                  </div>
                </OverlayTrigger>
              </div>
            </Link>
          )}
        </td>
      )}
      {isAdmin && (
        <td>
          <div>
            <OverlayTrigger
              placement="left"
              delay={{ show: 250, hide: 400 }}
              overlay={renderTooltip("kopieer")}
            >
              <div>
                <button
                  className={copyClass5}
                  onClick={() => copyItem({ ...item })}
                ></button>
              </div>
            </OverlayTrigger>
          </div>
        </td>
      )}
      <td>
        <Link
          to={{
            pathname: "/printwf",
            state: { item },
          }}
        >
          <div>
            <OverlayTrigger
              placement="left"
              delay={{ show: 250, hide: 400 }}
              overlay={renderTooltip("print/pdf stappenplan")}
            >
              <div>
                <button className={printClass5}></button>
              </div>
            </OverlayTrigger>
          </div>
        </Link>
      </td>
      {isAdmin && (
        <td>
          {!isTemplateWF && (
            <div>
              <OverlayTrigger
                placement="left"
                delay={{ show: 250, hide: 400 }}
                overlay={renderTooltip("verwijder stappenplan")}
              >
                <div>
                  <button
                    className={deleteClass5}
                    onClick={() => deleteItem({ ...item })}
                  ></button>
                </div>
              </OverlayTrigger>
            </div>
          )}
        </td>
      )}
      <td>{item.typetext}</td>
      <td>{item.mastertasktext}</td>
      <td>{item.mastertasktext2}</td>
      <td>
        <img src={item.imageurl} alt="geen afbeelding" height="50" width="50" />
      </td>
      <td>{item.mastertaskid1}</td>
    </tr>
  );
};

export default WFRow;
