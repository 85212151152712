//
// Purpose: SAMB - new WF
// Author: Ronny Van Elewyck
// Version: 1.0
// Creation Date: 15.07.2020
// Modification Date: 14.10.2020
//

// installed components
import React from "react";
import { Typeahead } from "react-bootstrap-typeahead";
import "react-bootstrap-typeahead/css/Typeahead.css";
import Tooltip from "react-bootstrap/Tooltip";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";

// created components

// config files
import {
  saveClass5,
  cancelClass5,
  classErrorMessage,
  headerMessage1,
  headerMessage2,
  errorMessageTypeWF,
} from "../../config.json";

const NewWFForm = ({ newItem, cancelItem }) => {
  //
  // format row of table
  //

  const typeList = [];

  typeList.push({
    id: 1,
    label: "Recept",
  });
  typeList.push({
    id: 2,
    label: "Standaard1",
  });
  typeList.push({
    id: 3,
    label: "Standaard2",
  });

  let title = "Nieuw stappenplan aanmaken";

  const [mastertasktext, setValue1] = React.useState("");
  const [mastertasktext2, setValue2] = React.useState("");
  const [typeid1, setValue3] = React.useState(0);

  const [showError1, setShowError1] = React.useState("");
  const [isError1, setError1] = React.useState(false);
  const [showError2, setShowError2] = React.useState("");
  const [isError2, setError2] = React.useState(false);
  const [showError3, setShowError3] = React.useState("");
  const [isError3, setError3] = React.useState(false);

  React.useEffect(() => {
    if (typeof typeid1 !== "undefined" && typeof typeid1.id !== "undefined") {
      setError1(false);
      setShowError1("");
    } else {
      setError1(true);
      setShowError1(errorMessageTypeWF);
    }
  }, [typeid1]);

  React.useEffect(() => {
    // check uppercase first letter
    const regex = /^[A-Z]/;
    const found = mastertasktext.match(regex);

    if (
      found === null ||
      mastertasktext === "" ||
      mastertasktext.length < 5 ||
      mastertasktext.length > 40
    ) {
      setError2(true);
      setShowError2(headerMessage1);
    } else {
      setError2(false);
      setShowError2("");
    }
  }, [mastertasktext]);

  React.useEffect(() => {
    if (mastertasktext2.length > 40) {
      setError3(true);
      setShowError3(headerMessage2);
    } else {
      setError3(false);
      setShowError3("");
    }
  }, [mastertasktext2]);

  const sizeLine = 40;

  const addLineBreaks = (string) =>
    string.split("\n").map((text, index) => (
      <React.Fragment key={`${text}-${index}`}>
        {text}
        <br />
      </React.Fragment>
    ));

  function renderTooltip(title) {
    return <Tooltip id="button-tooltip">{title}</Tooltip>;
  }

  return (
    <div>
      <div>
        <OverlayTrigger
          placement="left"
          delay={{ show: 250, hide: 400 }}
          overlay={renderTooltip("terug")}
        >
          <button
            className={cancelClass5}
            onClick={() => cancelItem()}
          ></button>
        </OverlayTrigger>

        {!isError1 && !isError2 && !isError3 && (
          <OverlayTrigger
            placement="left"
            delay={{ show: 250, hide: 400 }}
            overlay={renderTooltip("bewaar")}
          >
            <button
              className={saveClass5}
              onClick={() =>
                newItem({
                  typeid1,
                  mastertasktext,
                  mastertasktext2,
                })
              }
            ></button>
          </OverlayTrigger>
        )}
      </div>
      <div className="table-responsive">
        <table className="table table-bordered">
          <tbody>
            <tr>
              <td>
                <h4>
                  <span className="badge badge-info d-flex justify-content-center">
                    {title}
                  </span>
                </h4>
              </td>
            </tr>

            <tr>
              <td>
                <div>
                  <b className="mt-1 mb-1">Type</b>
                  {isError1 && (
                    <div className={classErrorMessage}>{showError1}</div>
                  )}
                  <Typeahead
                    id={"type"}
                    labelKey={"label"}
                    options={typeList}
                    onChange={(selectedOptions) =>
                      setValue3(selectedOptions[0])
                    }
                    highlightOnlyResult={true}
                    clearButton={true}
                  />
                </div>
                <br></br>
                <div>
                  <b className="mt-1 mb-1">Titel</b>
                  {isError2 && (
                    <div className={classErrorMessage}>{showError2}</div>
                  )}
                  <input
                    type="text"
                    className="form-control"
                    value={mastertasktext}
                    onChange={(e) => setValue1(e.currentTarget.value)}
                    maxLength={sizeLine}
                  />
                </div>
                <br></br>
                <div>
                  {" "}
                  <b className="mt-1 mb-1">Ondertitel</b>
                  {isError3 && (
                    <div className={classErrorMessage}>{showError3}</div>
                  )}
                  <input
                    type="text"
                    className="form-control"
                    value={mastertasktext2}
                    onChange={(e) => setValue2(e.currentTarget.value)}
                    maxLength={sizeLine}
                  />
                </div>

                <h1>{addLineBreaks("")}</h1>
                <h1>{addLineBreaks("")}</h1>
                <h1>{addLineBreaks("")}</h1>
                <h1>{addLineBreaks("")}</h1>
                <h1>{addLineBreaks("")}</h1>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default NewWFForm;
