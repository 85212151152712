//
// Purpose: SAMB - print service
// Author: Ronny Van Elewyck
// Version: 1.2
// Creation Date: 15.07.2020
// Modification Date: 07.12.2020
//

import axios from "axios";
import { basisUrlSam, prd, p1, p2 } from "../config.json";

export async function getData(session, mastertaskid) {
  //
  // get all data for one workflow step
  //

  // variables
  let param1 = "";
  const getPHP = "getPrintData.php";

  // sam instance
  if (session.actdb === prd) {
    param1 = session.prd;
  } else {
    param1 = session.dev;
  }
  // url
  const urlGet = basisUrlSam + getPHP + p1 + param1 + p2 + mastertaskid;

  //console.log("getPrintData.php: ", urlGet);

  // async call
  const { data: response } = await axios.get(urlGet);
  const jsonReturn = response.DBRETURN;
  const headerList = response.HEADER;
  const stepList = response.STEPS;
  const materialList = response.MATERIAL;
  const ingredientList = response.INGREDIENT;
  const allergenList = response.ALLERGEN;

  return {
    jsonReturn,
    headerList,
    stepList,
    materialList,
    ingredientList,
    allergenList,
  };
}

// eslint-disable-next-line
export default {
  getData,
};
