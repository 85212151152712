//
// Purpose: SAMB - step Row
// Author: Ronny Van Elewyck
// Version: 1.1
// Creation Date: 27.05.2020
// Modification Date: 12.04.2021
//

// installed components
import React from "react";
import { Typeahead } from "react-bootstrap-typeahead";
import "react-bootstrap-typeahead/css/Typeahead.css";
import Tooltip from "react-bootstrap/Tooltip";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";

// config files
import {
  saveClass2,
  imageClass1,
  imageClass2,
  deleteClass5,
  movieClass1,
} from "../../config.json";


const StepRow = ({
  item,
  saveStepItem,
  deleteStepItem,
  screenList,
  stepErrorMessage,
  classErrorMessage,
  changeImage1Step,
  changeImage2Step,
  changeMovieStep,
  refreshStepItem,
  isRefresh,
}) => {
  //
  // format row of table
  //

  let isText = false;
  let isImage = false;
  let isUrl1 = false;
  let isUrl2 = false;
  let isMovie = false;
  let isPDF = false;
  let isURL = false;

  let sizeLine = 200;



  const [steptitle, setValue1] = React.useState(item.steptitle);
  const [screenformat, setValue2] = React.useState(item.screenformatlabel);
  const [order, setValue3] = React.useState(item.order);
  const [text1, setValue4] = React.useState(item.text1);
  const [text2, setValue5] = React.useState(item.text2);
  const [text3, setValue6] = React.useState(item.text3);
  const [text4, setValue7] = React.useState(item.text4);
  const [text5, setValue8] = React.useState(item.text5);
  const [text6, setValue9] = React.useState(item.text6);
  const [text7, setValue10] = React.useState(item.text7);
  const [text8, setValue11] = React.useState(item.text8);
  const [text9, setValue12] = React.useState(item.text9);
  const [text10, setValue13] = React.useState(item.text10);

  const [showError1, setShowError1] = React.useState("");
  const [isError1, setError1] = React.useState(false);

  React.useEffect(() => {
    if (order > 0 && order < 51) {
      setError1(false);
      setShowError1("");
    } else {
      setError1(true);
      setShowError1(
        "enkel de nummers 1 tot en met 50 zijn geldig voor volgorde"
      );
    }
  }, [order]);



  const adaptScreen = () => {
  // show fields depending on screenformat
  // text
  if (item.screenformatid === "C0") {
    sizeLine = 512;
  }
  if (
    item.screenformatid === "C0" ||
    item.screenformatid === "C1" ||
    item.screenformatid === "C2" ||
    item.screenformatid === "C3" ||
    item.screenformatid === "C4" ||
    item.screenformatid === "C5" ||
    item.screenformatid === "C14" 
  ) {
    isText = true;
  } else {
    isText = false;
  }
  // image1
  if (
    item.screenformatid === "C0" ||
    item.screenformatid === "C3" ||
    item.screenformatid === "C6" ||
    item.screenformatid === "C9" ||
    item.screenformatid === "C14" 
  ) {
    isUrl1 = false;
  } else {
    isUrl1 = true;
  }
  // image2
  if (
    item.screenformatid === "C2" ||
    item.screenformatid === "C5" ||
    item.screenformatid === "C8" ||
    item.screenformatid === "C12" ||
    item.screenformatid === "C11"
  ) {
    isUrl2 = true;
  } else {
    isUrl2 = false;
  }
  if (isUrl1 || isUrl2) {
    isImage = true;
  } else {
    isImage = false;
  }
  // movie
  if (
    item.screenformatid === "C3" ||
    item.screenformatid === "C4" ||
    item.screenformatid === "C5" ||
    item.screenformatid === "C9" ||
    item.screenformatid === "C10" ||
    item.screenformatid === "C11"
  ) {
    isMovie = true;
  } else {
    isMovie = false;
  }

  // PDF
  if (item.screenformatid === "C12") {
    isPDF = true;
    isImage = false;
  } else {
    isPDF = false;
  }

  // URL
  if (item.screenformatid === "C13") {
    isURL = true;
    isImage = false;
  } else {
    isURL = false;
  }
  }

  const changeScreenFormat = (selectedOptions) => {
  //
  // change screen when changing format
  //
    if (selectedOptions !== undefined) {
      if (selectedOptions[0] !== undefined) {
        setValue2(selectedOptions[0]);
        item.screenformat = selectedOptions[0].label;
        item.screenformatid = selectedOptions[0].id;
        item.screenformatlabel = selectedOptions[0].label;
        saveStepItem({
          ...item
        })
      }
    }
  }



  function renderTooltip(title) {
    return <Tooltip id="button-tooltip">{title}</Tooltip>;
  }


  adaptScreen();

  // render row
  return (
    <tr>
      <td>
        {" "}
        <div>
          {!isError1 && (
            <OverlayTrigger
              placement="left"
              delay={{ show: 250, hide: 400 }}
              overlay={renderTooltip("bewaar in geheugen")}
            >
              <div>
                <button
                  className={saveClass2}
                  onClick={() =>
                    saveStepItem({
                      ...item,
                      steptitle,
                      screenformat,
                      order,
                      text1,
                      text2,
                      text3,
                      text4,
                      text5,
                      text6,
                      text7,
                      text8,
                      text9,
                      text10,
                    })
                  }
                ></button>{" "}
              </div>
            </OverlayTrigger>
          )}
          <OverlayTrigger
            placement="left"
            delay={{ show: 250, hide: 400 }}
            overlay={renderTooltip("verwijder")}
          >
            <div>
              <button
                className={deleteClass5}
                onClick={() => deleteStepItem({ ...item })}
              ></button>
            </div>
          </OverlayTrigger>
        </div>
      </td>
      <td>
        {typeof stepErrorMessage !== "undefined" && (
          <div className={classErrorMessage}>{stepErrorMessage}</div>
        )}
        <b>scherm formaat</b>
        <Typeahead
          id={"screenformat"}
          labelKey={"label"}
          options={screenList}
          placeholder={screenformat}
         // onChange={(selectedOptions) => setValue2(selectedOptions[0])}
          onChange={(selectedOptions) => changeScreenFormat(selectedOptions)}
          highlightOnlyResult={true}
          //clearButton={true}
        />

        <div>
          {isError1 && <div className={classErrorMessage}>{showError1}</div>}
          <b>stap nummer</b>
          <input
            type="text"
            className="form-control"
            value={order}
            onChange={(e) => setValue3(e.currentTarget.value)}
          />
        </div>

        <div>
          <b>titel</b>
          <input
            type="text"
            className="form-control"
            value={steptitle}
            onChange={(e) => setValue1(e.currentTarget.value)}
            maxLength="40"
          />
        </div>
        {isText && (
          <div>
            <b>tekst</b>
            <div>
              <input
                type="text"
                className="form-control"
                value={text1}
                onChange={(e) => setValue4(e.currentTarget.value)}
                maxLength={sizeLine}
              />
            </div>
            <div>
              <input
                type="text"
                className="form-control"
                value={text2}
                onChange={(e) => setValue5(e.currentTarget.value)}
                maxLength={sizeLine}
              />
            </div>
            <div>
              <input
                type="text"
                className="form-control"
                value={text3}
                onChange={(e) => setValue6(e.currentTarget.value)}
                maxLength={sizeLine}
              />
            </div>
            <div>
              <input
                type="text"
                className="form-control"
                value={text4}
                onChange={(e) => setValue7(e.currentTarget.value)}
                maxLength={sizeLine}
              />
            </div>
            <div>
              <input
                type="text"
                className="form-control"
                value={text5}
                onChange={(e) => setValue8(e.currentTarget.value)}
                maxLength={sizeLine}
              />
            </div>
            <div>
              <input
                type="text"
                className="form-control"
                value={text6}
                onChange={(e) => setValue9(e.currentTarget.value)}
                maxLength={sizeLine}
              />
            </div>
            <div>
              <input
                type="text"
                className="form-control"
                value={text7}
                onChange={(e) => setValue10(e.currentTarget.value)}
                maxLength={sizeLine}
              />
            </div>
            <div>
              <input
                type="text"
                className="form-control"
                value={text8}
                onChange={(e) => setValue11(e.currentTarget.value)}
                maxLength={sizeLine}
              />
            </div>
            <div>
              <input
                type="text"
                className="form-control"
                value={text9}
                onChange={(e) => setValue12(e.currentTarget.value)}
                maxLength={sizeLine}
              />
            </div>
            <div>
              <input
                type="text"
                className="form-control"
                value={text10}
                onChange={(e) => setValue13(e.currentTarget.value)}
                maxLength={sizeLine}
              />
            </div>
          </div>
        )}

        {isImage && (
          <div>
            {isUrl1 && (
              <div>
                <div>
                  <OverlayTrigger
                    placement="left"
                    delay={{ show: 250, hide: 400 }}
                    overlay={renderTooltip("foto1 opladen")}
                  >
                    <div>
                      <button
                        className={imageClass1}
                        onClick={() => changeImage1Step({ ...item })}
                      ></button>{" "}
                    </div>
                  </OverlayTrigger>
                </div>
                <img
                  src={item.imageurl1}
                  alt="geen afbeelding"
                  width="50"
                  height="50"
                />
              </div>
            )}
            {isUrl2 && (
              <div>
                <div>
                  <OverlayTrigger
                    placement="left"
                    delay={{ show: 250, hide: 400 }}
                    overlay={renderTooltip("foto2 opladen")}
                  >
                    <div>
                      <button
                        className={imageClass2}
                        onClick={() => changeImage2Step({ ...item })}
                      ></button>{" "}
                    </div>
                  </OverlayTrigger>
                </div>
                <img
                  src={item.imageurl2}
                  alt="geen afbeelding"
                  width="50"
                  height="50"
                />
              </div>
            )}
          </div>
        )}

        {isMovie && (
          <div>
            <div>
              <OverlayTrigger
                placement="left"
                delay={{ show: 250, hide: 400 }}
                overlay={renderTooltip("film url aanpassen")}
              >
                <div>
                  <button
                    className={movieClass1}
                    onClick={() => changeMovieStep({ ...item })}
                  ></button>{" "}
                </div>
              </OverlayTrigger>
            </div>
          </div>
        )}

        {isPDF && (
          <div>   
            <b>PDF url</b>
            <div>
              <input
                type="text"
                className="form-control"
                value={text1}
                onChange={(e) => setValue4(e.currentTarget.value)}
                maxLength={sizeLine}
              />
            </div>
          </div>
        )}

        {isURL && (
          <div>
            <b>Website url</b>
            <div>
              <input
                type="text"
                className="form-control"
                value={text1}
                onChange={(e) => setValue4(e.currentTarget.value)}
                maxLength={sizeLine}
              />
            </div>
          </div>
        )}

      </td>
    </tr>
  );
};

export default StepRow;
