//
// Purpose: SAMB - new User
// Author: Ronny Van Elewyck
// Version: 1.0
// Creation Date: 11.06.2020
// Modification Date: 14.10.2020
//

// installed components
import React from "react";
import { Typeahead } from "react-bootstrap-typeahead";
import "react-bootstrap-typeahead/css/Typeahead.css";
import Tooltip from "react-bootstrap/Tooltip";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";

// created components

// config files
import {
  saveClass5,
  userTitleNew1,
  userTitleNew2,
  cancelClass5,
  classErrorMessage,
  userMessage1,
  userMessage2,
} from "../../config.json";

const NewUserForm = ({ newItem, cancelItem, groupList, isUser }) => {
  //
  // format row of table
  //

  let title = "";

  const [firstname, setValue1] = React.useState("");
  const [lastname, setValue2] = React.useState("");
  const [grouptext1, setValue3] = React.useState("");

  const [showError1, setShowError1] = React.useState("");
  const [isError1, setError1] = React.useState(false);
  const [showError2, setShowError2] = React.useState("");
  const [isError2, setError2] = React.useState(false);
  const [showError3, setShowError3] = React.useState("");
  const [isError3, setError3] = React.useState(false);

  const sizeLine3 = 25;

  React.useEffect(() => {
    if (
      typeof grouptext1 !== "undefined" &&
      typeof grouptext1.id !== "undefined" &&
      typeof grouptext1.label !== "undefined"
    ) {
      setError1(false);
      setShowError1("");
    } else {
      if (isUser) {
        setError1(true);
        setShowError1("klas is een verplicht veld");
      } else {
        setError1(false);
        setShowError1("");
      }
    }
  }, [grouptext1, isUser]);

  React.useEffect(() => {
    // check uppercase first letter
    const regex = /^[A-Z]/;
    const found = firstname.match(regex);

    if (
      found === null ||
      firstname === "" ||
      firstname.length > 25 ||
      firstname.length < 2
    ) {
      setError2(true);
      setShowError2(userMessage1);
    } else {
      setError2(false);
      setShowError2("");
    }
  }, [firstname]);

  React.useEffect(() => {
    // check uppercase first letter
    const regex = /^[A-Z]/;
    const found = lastname.match(regex);
    if (
      found === null ||
      lastname === "" ||
      lastname.length > 25 ||
      lastname.length < 2
    ) {
      setError3(true);
      setShowError3(userMessage2);
    } else {
      setError3(false);
      setShowError3("");
    }
  }, [lastname]);

  if (isUser) {
    title = userTitleNew1;
  } else {
    title = userTitleNew2;
  }

  const addLineBreaks = (string) =>
    string.split("\n").map((text, index) => (
      <React.Fragment key={`${text}-${index}`}>
        {text}
        <br />
      </React.Fragment>
    ));

  function renderTooltip(title) {
    return <Tooltip id="button-tooltip">{title}</Tooltip>;
  }

  return (
    <div>
      <div>
        <OverlayTrigger
          placement="left"
          delay={{ show: 250, hide: 400 }}
          overlay={renderTooltip("terug")}
        >
          <button
            className={cancelClass5}
            onClick={() => cancelItem()}
          ></button>
        </OverlayTrigger>
        {!isError1 && !isError2 && !isError3 && (
          <OverlayTrigger
            placement="left"
            delay={{ show: 250, hide: 400 }}
            overlay={renderTooltip("bewaar")}
          >
            <button
              className={saveClass5}
              onClick={() =>
                newItem({
                  firstname,
                  lastname,
                  grouptext1,
                })
              }
            ></button>
          </OverlayTrigger>
        )}
      </div>

      <div className="table-responsive">
        <table className="table table-bordered">
          <tbody>
            <tr>
              <td>
                <h4>
                  <span className="badge badge-info d-flex justify-content-center">
                    {title}
                  </span>
                </h4>
              </td>
            </tr>
            <tr>
              <td>
                {" "}
                <div>
                  <b>voornaam </b>
                  {isError2 && (
                    <div className={classErrorMessage}>{showError2}</div>
                  )}
                  <input
                    type="text"
                    className="form-control"
                    value={firstname}
                    placeholder="voornaam"
                    onChange={(e) => setValue1(e.currentTarget.value)}
                    maxLength={sizeLine3}
                  />
                  <b>achternaam </b>
                  {isError3 && (
                    <div className={classErrorMessage}>{showError3}</div>
                  )}
                  <input
                    type="text"
                    className="form-control"
                    value={lastname}
                    placeholder="achternaam"
                    onChange={(e) => setValue2(e.currentTarget.value)}
                    maxLength={sizeLine3}
                  />
                </div>
                {isUser && (
                  <div>
                    <div>
                      <b>klas</b>
                      {isError1 && (
                        <div className={classErrorMessage}>{showError1}</div>
                      )}
                      <Typeahead
                        id={"grouptext1"}
                        labelKey={"label"}
                        options={groupList}
                        placeholder={grouptext1}
                        onChange={(selectedOptions) =>
                          setValue3(selectedOptions[0])
                        }
                        highlightOnlyResult={true}
                        clearButton={true}
                      />
                    </div>
                    <h1>{addLineBreaks("")}</h1>
                    <h1>{addLineBreaks("")}</h1>
                    <h1>{addLineBreaks("")}</h1>
                    <h1>{addLineBreaks("")}</h1>
                    <h1>{addLineBreaks("")}</h1>
                  </div>
                )}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default NewUserForm;
