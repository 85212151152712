//
// Purpose: SAMB - task service
// Author: Ronny Van Elewyck
// Version: 1.2
// Creation Date: 02.03.2020
// Modification Date: 29.01.2021
//

import axios from "axios";
import {
  basisUrlSam,
  //  basisUrlSam2,
  prd,
  p1,
  p2,
  p3,
  p4,
  p5,
  p6,
  p7,
  p8,
  p9,
  p10,
  p11,
  p12,
  p13,
  p14,
  p15,
  p16,
  p17,
  p18,
  p19,
  p20,
  p21,
  p22,
  p23,
  //  pdf,
} from "../config.json";

export async function masterTask(session) {
  //
  // get all sam master tasks
  //

  // variables
  let param1 = "";
  const getPHP = "getAllMasterTasks.php";

  // sam instance
  if (session.actdb === prd) {
    param1 = session.prd;
  } else {
    param1 = session.dev;
  }
  // url
  const urlGet = basisUrlSam + getPHP + p1 + param1;

  // async call
  const { data: response } = await axios.get(urlGet);
  const jsonReturn = response.DBRETURN;
  const masterTaskList = response.ALLMTASKS;
  return {
    jsonReturn,
    masterTaskList,
  };
}

/*export async function copyMastertask(
  session,
  frommastertaskid,
  mastertasktext
) {
  //
  // copy mastertask
  //

  // variables
  let param1 = "";
  const putPHP = "copyMastertask1.php";

  // sam instance
  if (session.actdb === prd) {
    param1 = session.prd;
  } else {
    param1 = session.dev;
  }
  // url
  const urlPut =
    basisUrlSam +
    putPHP +
    p1 +
    param1 +
    p2 +
    frommastertaskid +
    p3 +
    mastertasktext;

  ////console.log(urlPut);

  // async call
  const { data: response } = await axios.get(urlPut);
  const jsonReturn = response.DBRETURN;

  return {
    jsonReturn,
  };
}*/

export async function insertMastertask(
  session,
  typeid1,
  mastertasktext,
  mastertasktext2
) {
  //
  // insert mastertask
  //

  // variables
  let param1 = "";
  let numberofsteps = 0;
  let step1active = "";
  let step2active = "";
  let step3active = "";
  const step4active = "";

  const putPHP = "insertMastertask1.php";

  // sam instance
  if (session.actdb === prd) {
    param1 = session.prd;
  } else {
    param1 = session.dev;
  }

  // number of steps and stepactive

  if (typeid1 === 1) {
    numberofsteps = 3;
    step1active = "X";
    step2active = "X";
    step3active = "X";
  } else if (typeid1 === 2) {
    numberofsteps = 0;
  } else {
    numberofsteps = 2;
    step1active = "X";
    step3active = "X";
  }
  // url
  const urlPut =
    basisUrlSam +
    putPHP +
    p1 +
    param1 +
    p2 +
    typeid1 +
    p3 +
    "X" +
    p4 +
    mastertasktext +
    p5 +
    mastertasktext2 +
    p6 +
    "" +
    p7 +
    numberofsteps +
    p8 +
    step1active +
    p9 +
    step2active +
    p10 +
    step3active +
    p11 +
    step4active +
    p12 +
    "";
  // //console.log(urlPut);

  // async call
  const { data: response } = await axios.get(urlPut);
  const jsonReturn = response.DBRETURN;

  return {
    jsonReturn,
  };
}

/*export async function copySubTask1(session, frommastertaskid, tomastertaskid) {
  //
  // copy subtask1
  //

  // variables
  let param1 = "";
  const putPHP = "copySubTask1.php";

  // sam instance
  if (session.actdb === prd) {
    param1 = session.prd;
  } else {
    param1 = session.dev;
  }
  // url
  const urlPut =
    basisUrlSam +
    putPHP +
    p1 +
    param1 +
    p2 +
    frommastertaskid +
    p3 +
    tomastertaskid;

  ////console.log(urlPut);

  // async call
  const { data: response } = await axios.get(urlPut);
  const jsonReturn = response.DBRETURN;

  return {
    jsonReturn,
  };
}*/

/*export async function copySubTask2(session, frommastertaskid, tomastertaskid) {
  //
  // copy subtask2
  //

  // variables
  let param1 = "";
  const putPHP = "copySubTask2.php";

  // sam instance
  if (session.actdb === prd) {
    param1 = session.prd;
  } else {
    param1 = session.dev;
  }
  // url
  const urlPut =
    basisUrlSam +
    putPHP +
    p1 +
    param1 +
    p2 +
    frommastertaskid +
    p3 +
    tomastertaskid;

  ////console.log(urlPut);

  // async call
  const { data: response } = await axios.get(urlPut);
  const jsonReturn = response.DBRETURN;

  return {
    jsonReturn,
  };
}*/

/*export async function copyWFSteps(session, frommastertaskid, tomastertaskid) {
  //
  // copy WF steps
  //

  // variables
  let param1 = "";
  const putPHP = "copyWFSteps.php";

  // sam instance
  if (session.actdb === prd) {
    param1 = session.prd;
  } else {
    param1 = session.dev;
  }
  // url
  const urlPut =
    basisUrlSam +
    putPHP +
    p1 +
    param1 +
    p2 +
    frommastertaskid +
    p3 +
    tomastertaskid;

  ////console.log(urlPut);

  // async call
  const { data: response } = await axios.get(urlPut);
  const jsonReturn = response.DBRETURN;

  return {
    jsonReturn,
  };
}*/

export async function deleteWFSteps(session, mastertaskid) {
  //
  // delete WF steps
  //

  // variables
  let param1 = "";
  const putPHP = "deleteWFSteps.php";

  // sam instance
  if (session.actdb === prd) {
    param1 = session.prd;
  } else {
    param1 = session.dev;
  }
  // url
  const urlPut = basisUrlSam + putPHP + p1 + param1 + p2 + mastertaskid;

  ////console.log(urlPut);

  // async call
  const { data: response } = await axios.get(urlPut);
  const jsonReturn = response.DBRETURN;

  return {
    jsonReturn,
  };
}

export async function deleteSubtask1(session, mastertaskid) {
  //
  // delete subtask1
  //

  // variables
  let param1 = "";
  const putPHP = "deleteSubtask1.php";

  // sam instance
  if (session.actdb === prd) {
    param1 = session.prd;
  } else {
    param1 = session.dev;
  }
  // url
  const urlPut = basisUrlSam + putPHP + p1 + param1 + p2 + mastertaskid;

  ////console.log(urlPut);

  // async call
  const { data: response } = await axios.get(urlPut);
  const jsonReturn = response.DBRETURN;

  return {
    jsonReturn,
  };
}

export async function deleteSubtask2(session, mastertaskid) {
  //
  // delete subtask1
  //

  // variables
  let param1 = "";
  const putPHP = "deleteSubtask2.php";

  // sam instance
  if (session.actdb === prd) {
    param1 = session.prd;
  } else {
    param1 = session.dev;
  }
  // url
  const urlPut = basisUrlSam + putPHP + p1 + param1 + p2 + mastertaskid;

  ////console.log(urlPut);

  // async call
  const { data: response } = await axios.get(urlPut);
  const jsonReturn = response.DBRETURN;

  return {
    jsonReturn,
  };
}

export async function updateWFSteps(row) {
  //
  // update WFSTeps
  //

  // variables
  const putPHP = "updateWFSteps.php";

  // url
  const urlPut =
    basisUrlSam +
    putPHP +
    p1 +
    row.p1 +
    p2 +
    row.p2 +
    p3 +
    row.p3 +
    p4 +
    row.p4 +
    p5 +
    row.p5 +
    p6 +
    row.p6 +
    p7 +
    row.p7 +
    p8 +
    row.p8 +
    p9 +
    row.p9 +
    p10 +
    row.p10 +
    p11 +
    row.p11 +
    p12 +
    row.p12 +
    p13 +
    row.p13 +
    p14 +
    row.p14 +
    p15 +
    row.p15 +
    p16 +
    row.p16 +
    p17 +
    row.p17;
  ////console.log(urlPut);

  // async call
  const { data: response } = await axios.get(urlPut);
  const jsonReturn = response.DBRETURN;

  return {
    jsonReturn,
  };
}

export async function insertWFSteps(row) {
  //
  // insert WFSTeps
  //

  // variables
  const putPHP = "insertWFSteps.php";

  // url
  const urlPut =
    basisUrlSam +
    putPHP +
    p1 +
    row.p1 +
    p2 +
    row.p2 +
    p3 +
    row.p3 +
    p4 +
    row.p4 +
    p5 +
    row.p5 +
    p6 +
    row.p6 +
    p7 +
    row.p7 +
    p8 +
    row.p8 +
    p9 +
    row.p9 +
    p10 +
    row.p10 +
    p11 +
    row.p11 +
    p12 +
    row.p12 +
    p13 +
    row.p13 +
    p14 +
    row.p14 +
    p15 +
    row.p15 +
    p16 +
    row.p16 +
    p17 +
    row.p17 +
    p18 +
    row.p18 +
    p19 +
    row.p19 +
    p20 +
    row.p20 +
    p21 +
    row.p21 +
    p22 +
    row.p22 +
    p23 +
    row.p23;
  //console.log(urlPut);

  // async call
  const { data: response } = await axios.get(urlPut);
  const jsonReturn = response.DBRETURN;

  return {
    jsonReturn,
  };
}

export async function updateSubtask1(row) {
  //
  // update Subtask1
  //

  // variables
  const putPHP = "updateSubTask1.php";

  // url
  const urlPut =
    basisUrlSam +
    putPHP +
    p1 +
    row.p1 +
    p2 +
    row.p2 +
    p3 +
    row.p3 +
    p4 +
    row.p4 +
    p5 +
    row.p5 +
    p6 +
    row.p6 +
    p7 +
    row.p7;
  ////console.log(urlPut);

  // async call
  const { data: response } = await axios.get(urlPut);
  const jsonReturn = response.DBRETURN;

  return {
    jsonReturn,
  };
}

export async function insertSubtask1(row) {
  //
  // insert Subtask1
  //

  // variables
  const putPHP = "insertSubTask1.php";

  // url
  const urlPut =
    basisUrlSam +
    putPHP +
    p1 +
    row.p1 +
    p2 +
    row.p2 +
    p3 +
    row.p3 +
    p4 +
    row.p4 +
    p5 +
    row.p5 +
    p6 +
    row.p6;

  ////console.log(urlPut);

  // async call
  const { data: response } = await axios.get(urlPut);
  const jsonReturn = response.DBRETURN;

  return {
    jsonReturn,
  };
}

export async function updateSubtask2(row) {
  //
  // update Subtask2
  //

  // variables
  const putPHP = "updateSubTask2.php";

  // url
  const urlPut =
    basisUrlSam +
    putPHP +
    p1 +
    row.p1 +
    p2 +
    row.p2 +
    p3 +
    row.p3 +
    p4 +
    row.p4 +
    p5 +
    row.p5 +
    p6 +
    row.p6 +
    p7 +
    row.p7 +
    p8 +
    row.p8 +
    p9 +
    row.p9;
  ////console.log(urlPut);

  // async call
  const { data: response } = await axios.get(urlPut);
  const jsonReturn = response.DBRETURN;

  return {
    jsonReturn,
  };
}

export async function insertSubtask2(row) {
  //
  // insert Subtask2
  //

  // variables
  const putPHP = "insertSubTask2.php";

  // url
  const urlPut =
    basisUrlSam +
    putPHP +
    p1 +
    row.p1 +
    p2 +
    row.p2 +
    p3 +
    row.p3 +
    p4 +
    row.p4 +
    p5 +
    row.p5 +
    p6 +
    row.p6 +
    p7 +
    row.p7 +
    p8 +
    row.p8;

  ////console.log(urlPut);

  // async call
  const { data: response } = await axios.get(urlPut);
  const jsonReturn = response.DBRETURN;

  return {
    jsonReturn,
  };
}

export async function deleteMastertask1(session, mastertaskid) {
  //
  // delete mastertask for recipes: type1
  //

  // variables
  let param1 = "";
  const putPHP = "deleteMastertask1.php";

  // sam instance
  if (session.actdb === prd) {
    param1 = session.prd;
  } else {
    param1 = session.dev;
  }
  // url
  const urlPut =
    basisUrlSam +
    putPHP +
    p1 +
    param1 +
    p2 +
    mastertaskid +
    p3 +
    session.imagedir;

  ////console.log(urlPut);

  // async call
  const { data: response } = await axios.get(urlPut);
  const jsonReturn = response.DBRETURN;

  return {
    jsonReturn,
  };
}

export async function deleteMastertask2(session, mastertaskid) {
  //
  // delete mastertask for standard workflow: type2
  //

  // variables
  let param1 = "";
  const putPHP = "deleteMastertask2.php";

  // sam instance
  if (session.actdb === prd) {
    param1 = session.prd;
  } else {
    param1 = session.dev;
  }
  // url
  const urlPut =
    basisUrlSam +
    putPHP +
    p1 +
    param1 +
    p2 +
    mastertaskid +
    p3 +
    session.imagedir;

  ////console.log(urlPut);

  // async call
  const { data: response } = await axios.get(urlPut);
  const jsonReturn = response.DBRETURN;

  return {
    jsonReturn,
  };
}

export async function deleteMastertask3(session, mastertaskid) {
  //
  // delete mastertask for maintenance workflows: type3
  //

  // variables
  let param1 = "";
  const putPHP = "deleteMastertask3.php";

  // sam instance
  if (session.actdb === prd) {
    param1 = session.prd;
  } else {
    param1 = session.dev;
  }
  // url
  const urlPut =
    basisUrlSam +
    putPHP +
    p1 +
    param1 +
    p2 +
    mastertaskid +
    p3 +
    session.imagedir;

  ////console.log(urlPut);

  // async call
  const { data: response } = await axios.get(urlPut);
  const jsonReturn = response.DBRETURN;

  return {
    jsonReturn,
  };
}

export async function updateMastertask(uploadList) {
  //
  // update mastertask header
  //

  // variables
  const putPHP = "updateMastertask1.php";

  // url
  const urlPut =
    basisUrlSam +
    putPHP +
    p1 +
    uploadList[0].p1 +
    p2 +
    uploadList[0].p2 +
    p3 +
    uploadList[0].p3 +
    p4 +
    uploadList[0].p4 +
    p5 +
    uploadList[0].p5 +
    p6 +
    uploadList[0].p6 +
    p7 +
    uploadList[0].p7 +
    p8 +
    uploadList[0].p8 +
    p9 +
    uploadList[0].p9 +
    p10 +
    uploadList[0].p10 +
    p11 +
    uploadList[0].p11 +
    p12 +
    uploadList[0].p12;
  ////console.log(urlPut);

  // async call
  const { data: response } = await axios.get(urlPut);
  const jsonReturn = response.DBRETURN;

  return {
    jsonReturn,
  };
}

export async function courseTask(session, courseid) {
  //
  // get all sam master tasks
  //

  // variables
  let param1 = "";
  const getPHP = "getCourseTasks.php";

  // sam instance
  if (session.actdb === prd) {
    param1 = session.prd;
  } else {
    param1 = session.dev;
  }
  // url
  const urlGet = basisUrlSam + getPHP + p1 + param1 + p2 + courseid;

  // ////console.log(urlGet);

  // async call
  const { data: response } = await axios.get(urlGet);
  const jsonReturn = response.DBRETURN;
  const courseTaskList = response.COURSETASKS;

  // ////console.log("service: ", courseTaskList, jsonReturn);
  return {
    jsonReturn,
    courseTaskList,
  };
}

export async function updateTask(session, taskRecord) {
  //
  // get update course
  //

  // variables
  let param1 = "";
  const getPHP = "updateTask1.php";

  // sam instance
  if (session.actdb === prd) {
    param1 = session.prd;
  } else {
    param1 = session.dev;
  }
  // url
  const urlPut =
    basisUrlSam +
    getPHP +
    p1 +
    param1 +
    p2 +
    taskRecord.taskid1 +
    p3 +
    taskRecord.courseid +
    p4 +
    taskRecord.studentid +
    p5 +
    taskRecord.mastertaskid1 +
    p6 +
    taskRecord.status +
    p7 +
    taskRecord.portion +
    p8 +
    taskRecord.goal1 +
    p9 +
    taskRecord.goal2 +
    p10 +
    taskRecord.goal3 +
    p11 +
    taskRecord.goal4 +
    p12 +
    taskRecord.goal5 +
    p13 +
    taskRecord.priority +
    p14 +
    taskRecord.visible;

  //console.log(urlPut);

  // async call
  const { data: response } = await axios.get(urlPut);
  const jsonReturn = response.DBRETURN;

  return {
    jsonReturn,
  };
}

export async function deleteTask(session, taskid1) {
  //
  // get delete course
  //

  // variables
  let param1 = "";
  const getPHP = "deleteTask1.php";

  // sam instance
  if (session.actdb === prd) {
    param1 = session.prd;
  } else {
    param1 = session.dev;
  }
  // url
  const urlPut = basisUrlSam + getPHP + p1 + param1 + p2 + taskid1;

  //////console.log(urlPut);

  // async call
  const { data: response } = await axios.get(urlPut);
  const jsonReturn = response.DBRETURN;

  return {
    jsonReturn,
  };
}

export async function insertTask(session, taskRecord) {
  //
  // insert user task
  //

  // variables
  let param1 = "";
  const getPHP = "insertTask1.php";

  // sam instance
  if (session.actdb === prd) {
    param1 = session.prd;
  } else {
    param1 = session.dev;
  }
  // url
  const urlPut =
    basisUrlSam +
    getPHP +
    p1 +
    param1 +
    p2 +
    taskRecord.visible +
    p3 +
    taskRecord.courseid +
    p4 +
    taskRecord.studentid +
    p5 +
    taskRecord.mastertaskid1 +
    p6 +
    taskRecord.status +
    p7 +
    taskRecord.portion +
    p8 +
    taskRecord.goal1 +
    p9 +
    taskRecord.goal2 +
    p10 +
    taskRecord.goal3 +
    p11 +
    taskRecord.goal4 +
    p12 +
    taskRecord.goal5 +
    p13 +
    taskRecord.priority;

  //////console.log(urlPut);

  // async call
  const { data: response } = await axios.get(urlPut);
  const jsonReturn = response.DBRETURN;

  return {
    jsonReturn,
  };
}

export async function allCourseTask(session) {
  //
  // get all sam tasks
  //

  // variables
  let param1 = "";
  const getPHP = "getAllCourseTasks.php";

  // sam instance
  if (session.actdb === prd) {
    param1 = session.prd;
  } else {
    param1 = session.dev;
  }
  // url
  const urlGet = basisUrlSam + getPHP + p1 + param1;

  //////console.log(urlGet);

  // async call
  const { data: response } = await axios.get(urlGet);
  const jsonReturn = response.DBRETURN;
  const allCourseTaskList = response.ALLCOURSETASKS;

  // ////console.log("service: ", courseTaskList, jsonReturn);
  return {
    jsonReturn,
    allCourseTaskList,
  };
}

export async function allSteps(session, mastertaskid) {
  //
  // get all sam tasks
  //

  // variables
  let param1 = "";
  const getPHP = "getWFSteps.php";

  // sam instance
  if (session.actdb === prd) {
    param1 = session.prd;
  } else {
    param1 = session.dev;
  }
  // url
  const urlGet = basisUrlSam + getPHP + p1 + param1 + p2 + mastertaskid;

  ////console.log(urlGet);

  // async call
  const { data: response } = await axios.get(urlGet);
  const jsonReturn = response.DBRETURN;
  const stepList = response.ALLSTEPS;

  return {
    jsonReturn,
    stepList,
  };
}

export async function allProducts(session, mastertaskid) {
  //
  // get all sam tasks
  //

  // variables
  let param1 = "";
  const getPHP = "getSubtask1.php";

  // sam instance
  if (session.actdb === prd) {
    param1 = session.prd;
  } else {
    param1 = session.dev;
  }
  // url
  const urlGet = basisUrlSam + getPHP + p1 + param1 + p2 + mastertaskid;

  ////console.log(urlGet);

  // async call
  const { data: response } = await axios.get(urlGet);
  const jsonReturn = response.DBRETURN;
  const productList = response.ALLPRODUCTS;

  return {
    jsonReturn,
    productList,
  };
}

export async function allIngredients(session, mastertaskid) {
  //
  // get all sam tasks
  //

  // variables
  let param1 = "";
  const getPHP = "getSubtask2.php";

  // sam instance
  if (session.actdb === prd) {
    param1 = session.prd;
  } else {
    param1 = session.dev;
  }
  // url
  const urlGet = basisUrlSam + getPHP + p1 + param1 + p2 + mastertaskid;

  ////console.log(urlGet);

  // async call
  const { data: response } = await axios.get(urlGet);
  const jsonReturn = response.DBRETURN;
  const ingredientList = response.ALLINGREDIENTS;

  return {
    jsonReturn,
    ingredientList,
  };
}

export async function allScreens(session) {
  //
  // get all screen formats
  //

  // variables
  let param1 = "";
  const getPHP = "getScreenFormat.php";

  // sam instance
  if (session.actdb === prd) {
    param1 = session.prd;
  } else {
    param1 = session.dev;
  }
  // url
  const urlGet = basisUrlSam + getPHP + p1 + param1;

  ////console.log(urlGet);

  // async call
  const { data: response } = await axios.get(urlGet);
  const jsonReturn = response.DBRETURN;
  const screenList = response.ALLSCREENFORMATS;

  return {
    jsonReturn,
    screenList,
  };
}

/*export async function makeHTML(samNode, mastertaskid, samDir, compid) {
  //
  // create html file
  //

  // variables
  const putPHP = "makeHTML.php";

  // url
  const urlPut =
    basisUrlSam2 +
    pdf +
    putPHP +
    p1 +
    samNode +
    p2 +
    mastertaskid +
    p3 +
    samDir +
    p4 +
    compid;

  ////console.log(urlPut);

  // async call
  const { data: response } = await axios.get(urlPut);
  const jsonReturn = response.DBRETURN;

  return {
    jsonReturn,
  };
}*/

export async function copyCompleteWF(node1, node2, mastertaskid1, force) {
  //
  // copy complete workflow
  //

  // variables
  const putPHP = "copyBetweenDB.php";

  // url
  const urlPut =
    basisUrlSam +
    putPHP +
    p1 +
    node1 +
    p2 +
    node2 +
    p3 +
    mastertaskid1 +
    p4 +
    force;

  //console.log(urlPut);

  // async call
  const { data: response } = await axios.get(urlPut);
  const jsonReturn = response.DBRETURN;

  return {
    jsonReturn,
  };
}

// eslint-disable-next-line
export default {
  masterTask,
  //copyMastertask,
  //copySubTask1,
  //copySubTask2,
  //copyWFSteps,
  updateWFSteps,
  insertWFSteps,
  deleteWFSteps,
  updateSubtask1,
  insertSubtask1,
  deleteSubtask1,
  updateSubtask2,
  insertSubtask2,
  deleteSubtask2,
  deleteMastertask1,
  deleteMastertask2,
  deleteMastertask3,
  updateMastertask,
  insertMastertask,
  allSteps,
  allProducts,
  allIngredients,
  courseTask,
  updateTask,
  deleteTask,
  insertTask,
  allCourseTask,
  allScreens,
  //makeHTML,
  copyCompleteWF,
};
