//
// Purpose: SAMB - header table component
// Author: Ronny Van Elewyck
// Version: 1.0
// Creation Date: 28.05.2020
// Modification Date: 17.06.2020
//

// installed components
import React from "react";

// created components
import HeaderRow from "./headerRow";

const HeaderTable = ({ headerList2, saveHeaderItem, changeImageHeader }) => {
  return (
    <div className="table-responsive">
      <table className="table table-bordered">
        <thead className="thead-light">
          <tr>
            <th></th>
            <th>type</th>
            <th>titel</th>
            <th>ondertitel</th>
            <th>foto</th>
          </tr>
        </thead>
        <tbody>
          {headerList2.map((item) => (
            <HeaderRow
              key={item.mastertaskid1}
              item={item}
              saveHeaderItem={saveHeaderItem}
              changeImageHeader={changeImageHeader}
            />
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default HeaderTable;
