//
// Purpose: SAMB - course table component
// Author: Ronny Van Elewyck
// Version: 1.0
// Creation Date: 04.06.2020
// Modification Date: 04.06.2020
//

// installed components
import React from "react";

// created components
import CourseRow from "./courseRow";

const CourseTable = ({
  groupCourseList,
  locale,
  updateItem,
  copyItem,
  deleteItem,
  group,
  mastercourse,
  isLoaded,
  maxDate,
  isAdmin,
  user,
  userCourseList,
  fromDate,
  toDate,
}) => {
  return (
    <div className="table-responsive">
      <table className="table table-bordered">
        {isLoaded && (
          <thead className="thead-light">
            <tr>
              <th></th>
              <th></th>
              <th></th>
              <th></th>
              <th>datum</th>
              <th>tijdstip</th>
              <th>begeleider</th>
            </tr>
          </thead>
        )}
        <tbody>
          {groupCourseList.map((item) => (
            <CourseRow
              key={item._id}
              item={item}
              updateItem={updateItem}
              copyItem={copyItem}
              deleteItem={deleteItem}
              fromDate={fromDate}
              toDate={toDate}
              group={group}
              mastercourse={mastercourse}
              isLoaded={isLoaded}
              maxDate={maxDate}
              isAdmin={isAdmin}
              user={user}
              locale={locale}
              userCourseList={userCourseList}
            />
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default CourseTable;
