//
// Purpose: SAMB - node service
// Author: Ronny Van Elewyck
// Version: 1.1
// Creation Date: 10.09.2020
// Modification Date: 04.12.2020
//

import axios from "axios";
import { basisUrlNode, p1 } from "../config.json";

export async function sessionDetails() {
  //
  // get session details
  //

  // variables
  const getPHP = "getSessions.php";

  // url
  const urlGet = basisUrlNode + getPHP;

  //console.log("urlGet: ", urlGet);

  // async call
  const { data: response } = await axios.get(urlGet);
  const sessionList = response.SESSION;
  return sessionList;
}

export async function loggingDetails() {
  //
  // get logging details
  //

  // variables
  const getPHP = "getLogging.php";

  // url
  const urlGet = basisUrlNode + getPHP;

  //console.log("urlGet: ", urlGet);

  // async call
  const { data: response } = await axios.get(urlGet);
  const loggingList = response.LOGGING;
  return loggingList;
}

export async function getData(detail) {
  //
  // compare nodes
  //

  // variables
  const getPHP = "compareNodes.php";

  // url
  const urlGet = basisUrlNode + getPHP + p1 + detail;

  //console.log("compareNodes.php: ", urlGet);

  // async call
  const { data: response } = await axios.get(urlGet);
  const jsonReturn = response.DBRETURN;
  const companyList1 = response.COMPANYNODE1;
  const companyList2 = response.COMPANYNODE2;
  const companyList = response.COMPANYCOMPARE;
  const userList1 = response.USERNODE1;
  const userList2 = response.USERNODE2;
  const userList = response.USERCOMPARE;

  //console.log("companyList1: ", companyList1);
  return {
    jsonReturn,
    companyList1,
    companyList2,
    companyList,
    userList1,
    userList2,
    userList,
  };
}

// eslint-disable-next-line
export default {
  getData,
  sessionDetails,
  loggingDetails,
};
