//
// Purpose: SAMB - Task Row
// Author: Ronny Van Elewyck
// Version: 1.1
// Creation Date: 04.06.2020
// Modification Date: 15.04.2021
//

// installed components
import React from "react";
import { Typeahead } from "react-bootstrap-typeahead";
import "react-bootstrap-typeahead/css/Typeahead.css";
import Tooltip from "react-bootstrap/Tooltip";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";

// config files
import {
  saveClass5,
  deleteClass5,
  mastercourseMessage4,
  classErrorMessage,
  taskMessage2,
  mastercourseMessage6,
  mastercourseMessage5,
  goalMessage4,
  qrcodeClass5,
  nameQRCODEUser,
} from "../../config.json";

const TaskRow = ({ item, updateItem, deleteItem, goalsList, readonly, createQRCODE }) => {
  //
  // format row of table
  //
  let isRecipe = false;
  let isQrcodeEnabled = false;

  const options3 = [];
  const options4 = [];

  for (let index = 1; index < 51; index++) {
    const textValue = index.toString();
    options3.push(textValue);
  }

  for (let index = 1; index < 1001; index++) {
    const textValue = index.toString();
    options4.push(textValue);
  }

  if (item.typeid1 === "1") {
    isRecipe = true;
  } else {
    isRecipe = false;
  }

  // statefull value and function to update it

  const [goal1, setValue1] = React.useState(item.goal1);
  const [goal2, setValue2] = React.useState(item.goal2);
  const [goal3, setValue3] = React.useState(item.goal3);
  const [goal4, setValue4] = React.useState(item.goal4);
  const [goal5, setValue5] = React.useState(item.goal5);
  const [taskstatus, setValue6] = React.useState(item.taskstatus);
  const [visible, setValue7] = React.useState(item.visible);
  const [taskorder, setValue8] = React.useState(item.taskorder);
  const [portion, setValue9] = React.useState(item.portion);

  const [isChecked, setChecked] = React.useState(false);

  const [showError1, setShowError1] = React.useState("");
  const [isError1, setError1] = React.useState(false);
  const [showError2, setShowError2] = React.useState("");
  const [isError2, setError2] = React.useState(false);
  const [showError3, setShowError3] = React.useState("");
  const [isError3, setError3] = React.useState(false);
  const [showError4, setShowError4] = React.useState("");
  const [isError4, setError4] = React.useState(false);
  const [showError9, setShowError9] = React.useState("");
  const [isError9, setError9] = React.useState(false);

  React.useEffect(() => {
    if (taskstatus === "open" || taskstatus === "afgewerkt") {
      setError1(false);
      setShowError1("");
    } else {
      setError1(true);
      setShowError1(taskMessage2);
    }
  }, [taskstatus]);

  const lineSizeStatus = 9;

  React.useEffect(() => {
    const regex = /^[0-9]*$/;
    const found = taskorder.match(regex);

    if (found !== null && parseInt(taskorder) > 0 && parseInt(taskorder) < 51) {
      setError2(false);
      setShowError2("");
    } else {
      setError2(true);
      setShowError2(mastercourseMessage4);
    }
  }, [taskorder]);

  const lineSizeOrder = 2;

  React.useEffect(() => {
    const regex = /^(0|[1-9]\d*)(,\d+)?$/;
    const found = portion.match(regex);

    if (found !== null) {
      setError3(false);
      setShowError3("");
    } else {
      setError3(true);
      setShowError3(mastercourseMessage5);
    }
  }, [portion]);

  const lineSizePortion = 13;

  React.useEffect(() => {
    if (visible === "ja" || visible === "nee") {
      setError4(false);
      setShowError4("");
    } else {
      setError4(true);
      setShowError4(mastercourseMessage6);
    }
  }, [visible]);

  const lineSizeShow = 3;

  React.useEffect(() => {
    // check uppercase first letter
    const regex = /^[A-Z]/;
    const found = goal5.match(regex);

    if (goal5 === "") {
      setError9(false);
      setShowError9("");
    } else {
      if (found === null || goal5.lenght < 5 || goal5.lenght > 500) {
        setError9(true);
        setShowError9(goalMessage4);
      } else {
        setError9(false);
        setShowError9("");
      }
    }
  }, [goal5]);

  const lineSizeGoal = 500;

  if (item.name === nameQRCODEUser){
    isQrcodeEnabled = true;
  } 

  function renderTooltip(title) {
    return <Tooltip id="button-tooltip">{title}</Tooltip>;
  }

  return (
    <tr>
      <td>
        <div>
          <OverlayTrigger
            placement="left"
            delay={{ show: 250, hide: 400 }}
            overlay={renderTooltip("detail open/dicht")}
          >
            <div>
              <input
                type="checkbox"
                checked={isChecked}
                onChange={() => setChecked(!isChecked)}
              />
            </div>
          </OverlayTrigger>
        </div>
      </td>
      {isChecked &&
        !readonly &&
        !isError1 &&
        !isError2 &&
        !isError3 &&
        !isError4 && (
          <td>
            <div>
              <OverlayTrigger
                placement="left"
                delay={{ show: 250, hide: 400 }}
                overlay={renderTooltip("bewaar")}
              >
                <div>
                  <button
                    className={saveClass5}
                    onClick={() =>
                      updateItem({
                        ...item,
                        goal1,
                        goal2,
                        goal3,
                        goal4,
                        goal5,
                        taskstatus,
                        visible,
                        taskorder,
                        portion,
                      })
                    }
                  ></button>{" "}
                </div>
              </OverlayTrigger>
            </div>
            {isQrcodeEnabled && (<div>
            <OverlayTrigger
              placement="left"
              delay={{ show: 250, hide: 400 }}
              overlay={renderTooltip("qrcode maken")}
            >
              <div>
                <button
                  className={qrcodeClass5}
                  onClick={() => createQRCODE({ ...item })}
                ></button>
              </div>
            </OverlayTrigger>
          </div>)}
          </td>
        )}
      {isChecked && !readonly && (
        <td>
          <div>
            <OverlayTrigger
              placement="left"
              delay={{ show: 250, hide: 400 }}
              overlay={renderTooltip("verwijder")}
            >
              <div>
                <button
                  className={deleteClass5}
                  onClick={() => deleteItem({ ...item })}
                ></button>
              </div>
            </OverlayTrigger>
          </div>
        </td>
      )}
      {!isChecked && <td>{item.taskorder}</td>}
      {!isChecked && <td>{item.visible}</td>}
      <td>{item.name}</td>
      {isChecked && <td>{item.tasktext}</td>}
      {!isChecked && <td>{item.taskstatus}</td>}
      {!isChecked && <td>{item.tasktext}</td>}
      {isChecked && (
        <td>
          <b className="mt-1 mb-1">status (open/afgewerkt): </b>{" "}
          {!readonly && (
            <div>
              {" "}
              {isError1 && (
                <div className={classErrorMessage}>{showError1}</div>
              )}
              <input
                type="text"
                className="form-control"
                value={taskstatus}
                onChange={(e) => setValue6(e.currentTarget.value)}
                maxLength={lineSizeStatus}
                size={lineSizeStatus}
              />
            </div>
          )}
          {readonly && <div>{taskstatus}</div>}
          <b className="mt-1 mb-1">volgorde (1-50): </b>{" "}
          {!readonly && (
            <div>
              {" "}
              {isError2 && (
                <div className={classErrorMessage}>{showError2}</div>
              )}
              <input
                type="text"
                className="form-control"
                value={taskorder}
                onChange={(e) => setValue8(e.currentTarget.value)}
                maxLength={lineSizeOrder}
                size={lineSizeOrder}
              />
            </div>
          )}
          {readonly && <div>{taskorder}</div>}
          {isRecipe && (
            <div>
              {" "}
              <b className="mt-1 mb-1">portie: </b>{" "}
              {!readonly && (
                <div>
                  {isError3 && (
                    <div className={classErrorMessage}>{showError3}</div>
                  )}
                  <input
                    type="text"
                    className="form-control"
                    value={portion}
                    onChange={(e) => setValue9(e.currentTarget.value)}
                    maxLength={lineSizePortion}
                    size={lineSizePortion}
                  />
                </div>
              )}
              {readonly && <div>{portion}</div>}
            </div>
          )}
          {!readonly && (
            <div>
              <b className="mt-1 mb-1">toon taak (ja/nee): </b>{" "}
              <div>
                {isError4 && (
                  <div className={classErrorMessage}>{showError4}</div>
                )}
                <input
                  type="text"
                  className="form-control"
                  value={visible}
                  onChange={(e) => setValue7(e.currentTarget.value)}
                  maxLength={lineSizeShow}
                  size={lineSizeShow}
                />
              </div>
              <div>
                {" "}
                <div>
                  <b>doelstellingen (standaard): </b>
                  <Typeahead
                    id={"goal1"}
                    labelKey={"label"}
                    options={goalsList}
                    placeholder={goal1}
                    onChange={(selectedOptions) =>
                      setValue1(selectedOptions[0])
                    }
                    highlightOnlyResult={true}
                    clearButton={true}
                  />
                  <Typeahead
                    id={"goal2"}
                    labelKey={"label"}
                    options={goalsList}
                    placeholder={goal2}
                    onChange={(selectedOptions) =>
                      setValue2(selectedOptions[0])
                    }
                    highlightOnlyResult={true}
                    clearButton={true}
                  />
                  <Typeahead
                    id={"goal3"}
                    labelKey={"label"}
                    options={goalsList}
                    placeholder={goal3}
                    onChange={(selectedOptions) =>
                      setValue3(selectedOptions[0])
                    }
                    highlightOnlyResult={true}
                    clearButton={true}
                  />
                  <Typeahead
                    id={"goal4"}
                    labelKey={"label"}
                    options={goalsList}
                    placeholder={goal4}
                    onChange={(selectedOptions) =>
                      setValue4(selectedOptions[0])
                    }
                    highlightOnlyResult={true}
                    clearButton={true}
                  />
                </div>
                <div>
                  <b>vrije doelstelling: </b>
                  {isError9 && (
                    <div className={classErrorMessage}>{showError9}</div>
                  )}
                  <input
                    type="text"
                    className="form-control"
                    value={goal5}
                    onChange={(e) => setValue5(e.currentTarget.value)}
                    maxLength={lineSizeGoal}
                    size={lineSizeGoal}
                  />
                </div>
              </div>
            </div>
          )}
          {readonly && (
            <div>
              <b>doelstellingen</b>
              <p>{goal1}</p>
              <p>{goal2}</p>
              <p>{goal3}</p>
              <p>{goal4}</p>
              <p>{goal5}</p>
            </div>
          )}
        </td>
      )}
    </tr>
  );
};

export default TaskRow;
