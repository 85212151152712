//
// Purpose: SAMB - change password form
// Author: Ronny Van Elewyck
// Version: 1.1
// Creation Date: 06.06.2020
// Modification Date: 04.03.2021
//

// installed components
import React from "react";
import Joi from "joi-browser";
import { isMobile } from "react-device-detect";

// created components
import Form from "./form";

// utilities
import { encrypt } from "../../utils/encrypt";

// rest service
import auth from "../../services/authService";

// config files
import {
  noError,
  changePWError1,
  changePWError2,
  changePWError3,
  changePWError4,
  changePWError5,
} from "../../config.json";

class ChangePasswordForm extends Form {
  //
  // login form
  //

  // local data storage
  state = {
    data: { newpassword1: "", newpassword2: "" },
    errors: {},
    errorFlag: 0,
  };

  // will not change so no need to be part of the state
  // schema used to check validity
  schema = {
    newpassword1: Joi.string()
      .required()
      .min(5)
      .error(() => {
        return {
          message: changePWError2,
        };
      }),
    newpassword2: Joi.string()
      .required()
      .min(5)
      .error(() => {
        return {
          message: changePWError3,
        };
      }),
  };

  doSubmit = async () => {
    //
    // executed after submitting changed passwords
    //

    let useridToChange = "";

    const { newpassword1, newpassword2 } = this.state.data;
    const { session, userid } = this.props;

    const newEncryptedPassWord1 = encrypt(newpassword1);
    const newEncryptedPassWord2 = encrypt(newpassword2);

    //console.log("new1: ", newpassword1, newEncryptedPassWord1);
    //console.log("new2: ", newpassword2, newEncryptedPassWord2);

    if (userid !== "") {
      useridToChange = userid;
    } else {
      useridToChange = session.userid;
    }

    if (newEncryptedPassWord1 === newEncryptedPassWord2) {
      const result = await auth.changePassword(
        useridToChange,
        session.compid,
        newEncryptedPassWord1
      );
      const dbreturnList = result.jsonReturn;

      if (dbreturnList[0].errorcode === noError) {
        this.setState({ errorFlag: 3 });
      } else {
        this.setState({ errorFlag: 2 });
      }
    } else {
      this.setState({ errorFlag: 1 });
    }
  };

  render() {
    ////console.log("session: ", this.props.session);

    var titleScreen;
    const { errorFlag } = this.state;
    const { logon, name } = this.props;

    if (isMobile){
      titleScreen = "Wijzig Paswoord";
    } else {
      titleScreen = "Wijzig Paswoord " + logon + "(" + name + ")";
    }
    

    return (
      <div className="table-responsive">
        <table className="table table-bordered">
          <tbody>
            <tr>
              <td>
              {!isMobile && (
                <h4>
                  <span className="badge badge-info d-flex justify-content-center">
                    {titleScreen}
                  </span>
                </h4>)}
                {isMobile && (
                <h4>
                  <span className="badge badge-info d-flex justify-content-center">
                    {titleScreen}
                  </span>
                </h4>)}
                {/* error message */}
                {errorFlag === 1 && (
                  <div className="alert alert-danger">{changePWError4}</div>
                )}
                {errorFlag === 2 && (
                  <div className="alert alert-danger">{changePWError1}</div>
                )}
                {errorFlag === 3 && (
                  <div className="alert alert-success">{changePWError5}</div>
                )}
              </td>
            </tr>
            {/* logon input and button */}
            <tr>
              <td>
                {" "}
                <form onSubmit={this.handleSubmit}>
                  {this.renderInput(
                    "newpassword1",
                    "nieuw paswoord",
                    "password"
                  )}
                  {this.renderInput(
                    "newpassword2",
                    "herhaal nieuw paswoord",
                    "password"
                  )}
                  {this.renderButton("aanpassen")}
                </form>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    );
  }
}

export default ChangePasswordForm;
