//
// Purpose: SAMB - top user buttons
// Author: Ronny Van Elewyck
// Version: 1.0
// Creation Date: 11.06.2020
// Modification Date: 11.06.2020
//

// installed components
import React from "react";
import Tooltip from "react-bootstrap/Tooltip";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";

// created components
import ExportCSV from "./exportCSV";

// config files
import {
  refreshClass5,
  classTopButtons,
  newClass5,
  downloadClass5,
} from "../../config.json";

const TopUserButton = ({ listArray, handleRefresh, handleNew, fileName }) => {
  function renderTooltip(title) {
    return <Tooltip id="button-tooltip">{title}</Tooltip>;
  }

  return (
    <div className={classTopButtons}>
      <form className="form-inline">
        <div>
          <OverlayTrigger
            placement="left"
            delay={{ show: 250, hide: 400 }}
            overlay={renderTooltip("ververs")}
          >
            <div>
              <button
                type="button"
                className={refreshClass5}
                onClick={() => handleRefresh()}
              ></button>{" "}
            </div>
          </OverlayTrigger>
        </div>
        <div>
          <OverlayTrigger
            placement="left"
            delay={{ show: 250, hide: 400 }}
            overlay={renderTooltip("nieuw")}
          >
            <div>
              <button
                type="button"
                className={newClass5}
                onClick={() => handleNew()}
              ></button>{" "}
            </div>
          </OverlayTrigger>
        </div>
        <ExportCSV
          csvData={listArray}
          fileName={fileName}
          className={downloadClass5}
        />
      </form>
    </div>
  );
};

export default TopUserButton;
