//
// Purpose: SAMB - navigation bar
// Author: Ronny Van Elewyck
// Version: 1.0
// Creation Date: 16.03.2020
// Modification Date: 21.07.2020
//

// installed components
import React, { Component } from "react";
import { NavLink } from "react-router-dom";
import { Beforeunload } from "react-beforeunload";

// config files
import {
  sam1Image,
  sam1Text,
  sam1Height,
  sam1Width,
  sam2020Url,
  overzichtMenu,
  lessenMenu,
  klassenMenu,
  vakkenMenu,
  doelMenu,
  stapMenu,
  materiaalMenu,
  gebruikersMenu,
} from "../config.json";

class NavBar extends Component {
  constructor(props) {
    super(props);
    this.toggleNavbar = this.toggleNavbar.bind(this);
    this.state = {
      collapsed: true,
    };
  }
  toggleNavbar() {
    this.setState({
      collapsed: !this.state.collapsed,
    });
  }

  render() {
    const collapsed = this.state.collapsed;
    const classOne = collapsed
      ? "navbar-nav collapse navbar-collapse"
      : "navbar-nav collapse navbar-collapse show";
    const classTwo = collapsed
      ? "navbar-nav navbar-toggler navbar-toggler-right collapsed"
      : "navbar-nav navbar-toggler navbar-toggler-right";
    return (
      <nav className="navbar navbar-expand-lg navbar-dark bg-primary mb-1 ml-1 mr-1">
        <Beforeunload onBeforeunload={(event) => event.preventDefault()} />
        <div className="container">
          <a className="navbar-brand" href={sam2020Url}>
            <img
              src={sam1Image}
              alt={sam1Text}
              height={sam1Height}
              width={sam1Width}
            />
          </a>

          <button
            onClick={this.toggleNavbar}
            className={`${classTwo}`}
            type="button"
            data-toggle="collapse"
            data-target="#navbarResponsive"
            aria-controls="navbarResponsive"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon" />
          </button>
          <div className={`${classOne}`} id="navbarResponsive">
            <NavLink className="nav-item nav-link ml" to="/overview">
              {overzichtMenu}
            </NavLink>
            <NavLink className="nav-item nav-link ml" to="/courses">
              {lessenMenu}
            </NavLink>
            <NavLink className="nav-item nav-link ml" to="/steps">
              {stapMenu}
            </NavLink>
            <NavLink className="nav-item nav-link ml" to="/groups">
              {klassenMenu}
            </NavLink>
            <NavLink className="nav-item nav-link ml" to="/mastercourse">
              {vakkenMenu}
            </NavLink>
            <NavLink className="nav-item nav-link ml" to="/goals">
              {doelMenu}
            </NavLink>
            <NavLink className="nav-item nav-link ml" to="/materials">
              {materiaalMenu}
            </NavLink>
            <NavLink className="nav-item nav-link ml" to="/users">
              {gebruikersMenu}
            </NavLink>
          </div>
        </div>
      </nav>
    );
  }
}

export default NavBar;
