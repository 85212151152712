//
// Purpose: SAMB - course Row
// Author: Ronny Van Elewyck
// Version: 1.0
// Creation Date: 17.07.2020
// Modification Date: 17.07.2020
//

// installed components
import React from "react";
import { Link } from "react-router-dom";
import Tooltip from "react-bootstrap/Tooltip";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";

// utilities

// config files
import {
  copyClass5,
  taskClass5,
  deleteClass5,
  changeClass5,
} from "../../config.json";

const CourseRow = ({
  item,
  updateItem,
  copyItem,
  deleteItem,
  group,
  mastercourse,
  isLoaded,
  user,
  userCourseList,
  fromDate,
  toDate,
  isAdmin,
}) => {
  //
  // format row of table
  //

  let readonly = false;
  let showButton1 = true;

  if (item.teacherid !== user.userid && !isAdmin) {
    // don't show buttons
    showButton1 = false;
    readonly = true;
  }

  // courseRecord
  const courseRecord = {
    courseid: item._id,
    mastercourseid: mastercourse.id,
    mastercoursetext: mastercourse.text,
    groupid: group.id,
    grouptext: group.text,
    teacherid: item.teacherid,
    teachername: item.teachername,
    courseDate: item.showdate,
    courseTimeBlock1: item.fromtime,
    courseTimeBlock2: item.totime,
    autoTimeBlock: "",
    title: "",
  };

  courseRecord.title =
    courseRecord.grouptext +
    " - " +
    courseRecord.mastercoursetext +
    " - " +
    courseRecord.courseDate +
    " van " +
    courseRecord.courseTimeBlock1 +
    " tot " +
    courseRecord.courseTimeBlock2;

  function renderTooltip(title) {
    return <Tooltip id="button-tooltip">{title}</Tooltip>;
  }

  return (
    <tr>
      <td>
        {isLoaded && userCourseList.length > 0 && (
          <Link
            to={{
              pathname: "/courseTasks",
              state: {
                userCourseList,
                courseRecord,
                readonly,
                fromDate,
                toDate,
              },
            }}
          >
            <div>
              <OverlayTrigger
                placement="left"
                delay={{ show: 250, hide: 400 }}
                overlay={renderTooltip("ga naar taken")}
              >
                <div>
                  <button className={taskClass5}></button>
                </div>
              </OverlayTrigger>
            </div>
          </Link>
        )}
      </td>
      <td>
        {showButton1 && isLoaded && (
          <div>
            <OverlayTrigger
              placement="left"
              delay={{ show: 250, hide: 400 }}
              overlay={renderTooltip("wijzig")}
            >
              <button
                className={changeClass5}
                onClick={() => updateItem({ ...item, mastercourse, group })}
              ></button>
            </OverlayTrigger>
          </div>
        )}
      </td>
      <td>
        {isLoaded && (
          <div>
            <OverlayTrigger
              placement="left"
              delay={{ show: 250, hide: 400 }}
              overlay={renderTooltip("kopieer")}
            >
              <div>
                <button
                  className={copyClass5}
                  onClick={() => copyItem({ ...item })}
                ></button>
              </div>
            </OverlayTrigger>
          </div>
        )}
      </td>
      <td>
        {showButton1 && isLoaded && (
          <div>
            <OverlayTrigger
              placement="left"
              delay={{ show: 250, hide: 400 }}
              overlay={renderTooltip("verwijder")}
            >
              <div>
                <button
                  className={deleteClass5}
                  onClick={() => deleteItem({ ...item, mastercourse, group })}
                ></button>
              </div>
            </OverlayTrigger>
          </div>
        )}
      </td>
      <td>{isLoaded && <div>{courseRecord.courseDate}</div>}</td>
      <td>
        {isLoaded && (
          <div>
            {courseRecord.courseTimeBlock1} {" - "}{" "}
            {courseRecord.courseTimeBlock2}
          </div>
        )}
      </td>
      <td>{isLoaded && <div>{courseRecord.teachername}</div>}</td>
    </tr>
  );
};

export default CourseRow;
