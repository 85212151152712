//
// Purpose: SAMB - new mp4 url
// Author: Ronny Van Elewyck
// Version: 1.0
// Creation Date: 08.07.2020
// Modification Date: 19.08.2020
//

// installed components
import React from "react";
//import "react-bootstrap-typeahead/css/Typeahead.css";

// created components

// config files
import { saveClass5, cancelClass5 } from "../../config.json";

const NewMP4Form = ({ newItem, cancelItem, subtaskid3, movieUrl }) => {
  //
  // format row of table
  //

  let title = "URL voor film bewaren in databank";

  const [url, setValue1] = React.useState(movieUrl);

  const sizeLine3 = 1024;

  const addLineBreaks = (string) =>
    string.split("\n").map((text, index) => (
      <React.Fragment key={`${text}-${index}`}>
        {text}
        <br />
      </React.Fragment>
    ));

  return (
    <div className="table-responsive">
      <table className="table table-bordered">
        <tbody>
          <tr>
            <td>
              <h4>
                <span className="badge badge-info d-flex justify-content-center">
                  {title}
                </span>
              </h4>

              <button
                className={cancelClass5}
                onClick={() => cancelItem()}
              ></button>
              <button
                className={saveClass5}
                onClick={() =>
                  newItem({
                    url,
                    subtaskid3,
                  })
                }
              ></button>
            </td>
          </tr>
          <tr>
            <td>
              <input
                type="text"
                className="form-control"
                value={url}
                onChange={(e) => setValue1(e.currentTarget.value)}
                maxLength={sizeLine3}
              />
              <h1>{addLineBreaks("")}</h1>
              <h1>{addLineBreaks("")}</h1>
              <h1>{addLineBreaks("")}</h1>
              <h1>{addLineBreaks("")}</h1>
              <h1>{addLineBreaks("")}</h1>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};

export default NewMP4Form;
