//
// Purpose: SAMB - change steps
// Author: Ronny Van Elewyck
// Version: 1.1
// Creation Date: 13.05.2020
// Modification Date: 04.12.2020
//

// installed components
import React, { Component } from "react";
import _ from "lodash";
import Papa from "papaparse";
import Tooltip from "react-bootstrap/Tooltip";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";

// created components
import ExportCSV from "./common/exportCSV";
import ErrorTable from "./common/errorTable";
import HeaderTable from "./common/headerTable";
import StepTable from "./common/stepTable";
import ProductTable from "./common/productTable";
import IngredientTable from "./common/ingredientTable";
import TopWaitingMessage from "./common/topWaitingMessage";
import UploadCSV from "./common/uploadCSV";
import UploadImageForm from "./common/uploadImageForm";
//mport UploadMP4Form from "./common/uploadMP4Form";
import NewMP4Form from "./common/newMP4Form";

// utilities
import {
  checkHeaderInput,
  checkStepInput,
  validateStepTitle,
  validateStepTextLine,
  validateProduct,
  checkProductInput,
  validateIngredient,
  checkIngredientInput,
} from "../utils/checkTextInput";
import {
  checkHeaderList,
  checkStepList,
  checkProductList,
  checkIngredientList,
} from "../utils/checkWFBeforeSave";
import { createImageRecord } from "../utils/imageHelpers";

// rest services
import getTask from "../services/taskService";
import getProduct from "../services/productService";
import getMovie from "../services/movieService";
import putImage from "../services/imageService";

// config files
import {
  noError,
  classTopButtons,
  classTitle,
  classErrorMessage,
  classWarningMessage,
  uploadClass5,
  backClass5,
  saveClass5,
  headerClass,
  workflowClass,
  productClass,
  ingredientClass,
  stepMessage21,
  stepMessage22,
  stepMessage28,
  stepMessage30,
  saveDBError2,
  changeStepsTitle,
  errorTableTitle1,
  classImageTitle,
  downloadClass5,
  classTopMenu,
  classTables,
  newClass5,
  stepMessage31,
} from "../config.json";
import {
  prefixImageTask,
  minWidthImageTask,
  maxWidthImageTask,
  minHeightImageTask,
  maxHeightImageTask,
  prefixImageStep,
  minWidthImageStep1,
  minHeightImageStep1,
  maxWidthImageStep1,
  maxHeightImageStep1,
  minWidthImageStep2,
  minHeightImageStep2,
  maxWidthImageStep2,
  maxHeightImageStep2,
  minWidthImageStep3,
  minHeightImageStep3,
  maxWidthImageStep3,
  maxHeightImageStep3,
} from "../imageConfig.json";

class ChangeSteps extends Component {
  //
  // change steps screen
  //

  // local data
  state = {
    csvfile1: undefined,
    xlsxfile1: "SAMBdownload",
    isChange: false,
    item: {},
    isCSVLoaded: false,
    isHeader: false,
    isWorkflow: false,
    isProduct: false,
    isIngredient: false,
    isNew: false,
    headerList1: [],
    headerList2: [],
    headerList3: [],
    isFileCSV: false,
    headerErrorMessage: undefined,
    isStepLoaded: false,
    stepList1: [],
    stepList2: [],
    stepList3: [],
    inputTextError: undefined,
    stepErrorMessage: undefined,
    isProductLoaded: false,
    productList1: [],
    productList2: [],
    productList3: [],
    productErrorMessage: undefined,
    isIngredientLoaded: false,
    ingredientList1: [],
    ingredientList2: [],
    ingredientList3: [],
    ingredientErrorMessage: undefined,
    screenList: [],
    productList: [],
    ingredientList: [],
    productIdCheck: [],
    beforeSaveError: undefined,
    saveDBBusy: false,
    imageRecord: undefined,
    isUploadImage: false,
    isUploadMP4: false,
    isImageSaved: true,
    subtaskid3: 0,
    isShowSave: false,
    movieUrl: "https://*.mp4",
    isNewStep: false,
    mastertaskid1: 0,
    typeid1: 0,
    uomList: [],
    uomIdCheck: [],
    isRefresh: false,
  };

  async componentDidMount() {
    //
    // mount after
    //

    let mastertaskid1 = 0;
    let typeid1 = 0;
    let uomList = [];
    let uomIdCheck = [];

    const imageRecord = createImageRecord();
    this.setState({
      imageRecord,
    });

    if (typeof this.props.uomList !== "undefined") {
      uomList = _.sortBy(this.props.uomList, function (item) {
        return item.label;
      });
      for (let index = 0; index < uomList.length; index++) {
        // create uom validation table
        uomIdCheck.push(uomList[index].id);
      }
    }

    if (typeof this.props.location !== "undefined") {
      const result = this.mountSaveHeader();
      mastertaskid1 = result.mastertaskid1;
     
      typeid1 = result.typeid1;
      // get dropdown data
      await this.getScreenList();
      await this.getProductList();

      // get steps
      await this.getSteps(mastertaskid1);
      if (typeid1 === "1" || typeid1 === "3") {
        // get materials (recept) / products (maintenance)
        await this.getProducts(mastertaskid1);
        if (typeid1 === "1") {
          // get ingredients
          await this.getIngredients(mastertaskid1);
        }
      }
    }


    this.setState({
      uomIdCheck,
      uomList,
      mastertaskid1,
      typeid1,
    });
  }

  refreshWF = async () => {
    //
    // refresh WF
    //

    const { mastertaskid1, typeid1 } = this.state;

    // get steps
    await this.getSteps(mastertaskid1);
    if (typeid1 === "1" || typeid1 === "3") {
      // get materials (recept) / products (maintenance)
      await this.getProducts(mastertaskid1);
      if (typeid1 === "1") {
        // get ingredients
        await this.getIngredients(mastertaskid1);
      }
    }
  };

  getScreenList = async () => {
    //
    // get corresponding screen formats from database
    //
    const { session } = this.props;

    const jsonList = await getTask.allScreens(session);
    const dbreturnList = jsonList.jsonReturn;

    if (dbreturnList[0].errorcode === noError) {
      const screenList = jsonList.screenList;
      this.setState({
        screenList,
      });
    } else {
      // error dropdown
    }
  };

  getProductList = async () => {
    //
    // get products and ingredients from database for dropdown
    //
    const { session } = this.props;
    let productList = [];
    let ingredientList = [];
    let productIdCheck = [];

    const jsonList = await getProduct.productDetails(session);
    const dbreturnList = jsonList.jsonReturn;

    if (dbreturnList[0].errorcode === noError) {
      const allProducts = jsonList.productList;
      for (let index = 0; index < allProducts.length; index++) {
        // create product validation table
        productIdCheck.push(allProducts[index].productid1);

        // create dropdown ingredients and product
        if (allProducts[index].producttype === "ingredienten") {
          ingredientList.push({
            id: allProducts[index].productid1,
            label:
              allProducts[index].producttext + " " + allProducts[index].uomlong,
            uomid: allProducts[index].uomid,
            uomtext: allProducts[index].uomlong,
          });
          // sort
          ingredientList = _.sortBy(ingredientList, function (item) {
            return item.label;
          });
        } else {
          productList.push({
            id: allProducts[index].productid1,
            label: allProducts[index].producttext,
          });
          // sort
          productList = _.sortBy(productList, function (item) {
            return item.label;
          });
        }
      }

      this.setState({ productList, ingredientList, productIdCheck });
    } else {
      // error dropdown
    }
  };

  mountSaveHeader = () => {
    //
    // save header information in state
    //

    // headerList1 : original header in case of change
    // headerList2 : changed header to show on screen
    // headerList3 : changed header to up-down load

    let headerList1 = [];
    let headerList2 = [];
    let headerList3 = [];
    let mastertaskid1 = 0;
    let typeid1 = 0;

    // get header info from previous screen
    const recordHeader = this.props.location.state.item;

    // if it's a change
    if (this.props.location.state.isChange) {
      headerList1.push({
        active: recordHeader.active,
        imageurl: recordHeader.imageurl,
        image: recordHeader.image,
        mastertaskid1: recordHeader.mastertaskid1,
        mastertasktext: recordHeader.mastertasktext,
        mastertasktext2: recordHeader.mastertasktext2,
        numberofsteps: recordHeader.numberofsteps,
        searchfield: recordHeader.searchfield,
        step1active: recordHeader.step1active,
        step2active: recordHeader.step2active,
        step3active: recordHeader.step3active,
        step4active: recordHeader.step4active,
        typeid1: recordHeader.typeid1,
        typetext: recordHeader.typetext,
      });
      typeid1 = recordHeader.typeid1;
      headerList2.push({
        mastertaskid1: headerList1[0].mastertaskid1,
        mastertasktext: headerList1[0].mastertasktext,
        mastertasktext2: headerList1[0].mastertasktext2,
        typeid1: headerList1[0].typeid1,
        typetext: headerList1[0].typetext,
        imageurl: headerList1[0].imageurl,
        image: headerList1[0].image,
      });
      //////////console.log("headerList1[0]: ", headerList1[0]);
      //////////console.log("headerList2: ", headerList2);
      /* headerList3.push({
        mastertasktext: recordHeader.mastertasktext,
        mastertasktext2: recordHeader.mastertasktext2,
        typetext: recordHeader.typetext,
        mastertaskid1: recordHeader.mastertaskid1,
        imageurl: recordHeader.imageurl,
      });*/
      headerList3 = this.refreshHeaderList3(headerList2);
      //////////console.log("headerList3: ", headerList3);
      mastertaskid1 = recordHeader.mastertaskid1;

      this.setState({
        headerList1,
        headerList2,
        headerList3,
        item: this.props.location.state.item,
        isChange: this.props.location.state.isChange,
      });
    }
    return { mastertaskid1, typeid1 };
  };

  refreshHeaderList3 = (headerList2) => {
    //
    // refresh header list 3 after update steplist2
    //

    let headerList3 = [];

    for (let index = 0; index < headerList2.length; index++) {
      headerList3.push({
        typeid1: headerList2[index].typeid1,
        mastertasktext: headerList2[index].mastertasktext,
        mastertasktext2: headerList2[index].mastertasktext2,
      });
    }

    return headerList3;
  };

  getSteps = async (mastertaskid1) => {
    //
    // get corresponding steps from database
    //

    // stepList1 : original steps in case of change
    // stepList2 : changed steps to show on screen
    // stepList3 : changed steps to up-down load

    let stepList2 = [];
    let stepList3 = [];

    const { session } = this.props;

    const jsonList = await getTask.allSteps(session, mastertaskid1);
    const dbreturnList = jsonList.jsonReturn;

    if (dbreturnList[0].errorcode === noError) {
      const stepList1 = jsonList.stepList;
      // create stepList2

      for (let index = 0; index < stepList1.length; index++) {
        stepList2.push({
          subtaskid3: stepList1[index].subtaskid3,
          order: stepList1[index].order,
          screenformatid: stepList1[index].screenformatid,
          screenformatlabel: stepList1[index].screenformatlabel,
          steptitle: stepList1[index].steptitle,
          text1: stepList1[index].text1,
          text2: stepList1[index].text2,
          text3: stepList1[index].text3,
          text4: stepList1[index].text4,
          text5: stepList1[index].text5,
          text6: stepList1[index].text6,
          text7: stepList1[index].text7,
          text8: stepList1[index].text8,
          text9: stepList1[index].text9,
          text10: stepList1[index].text10,
          imageurl1: stepList1[index].imageurl1,
          image1: stepList1[index].image1,
          imageurl2: stepList1[index].imageurl2,
          image2: stepList1[index].image2,
        });
      }

      // sort
      stepList2 = _.sortBy(stepList2, function (item) {
        return parseInt(item.order);
      });
      // refresh stepList3
      stepList3 = this.refreshStepList3(stepList2);

      this.setState({
        stepList3,
        stepList1,
        stepList2,
        isStepLoaded: true,
      });
    } else {
      // error
      this.setState({
        stepList3: [],
        stepList1: [],
        stepList2: [],
        isStepLoaded: false,
      });
    }
  };

  refreshStepList3 = (stepList2) => {
    //
    // refresh step list 3 after update steplist2
    //

    let stepList3 = [];

    for (let index = 0; index < stepList2.length; index++) {
      stepList3.push({
        order: stepList2[index].order,
        screenformatid: stepList2[index].screenformatid,
        screenformatlabel: stepList2[index].screenformatlabel,
        steptitle: stepList2[index].steptitle,
        text1: stepList2[index].text1,
        text2: stepList2[index].text2,
        text3: stepList2[index].text3,
        text4: stepList2[index].text4,
        text5: stepList2[index].text5,
        text6: stepList2[index].text6,
        text7: stepList2[index].text7,
        text8: stepList2[index].text8,
        text9: stepList2[index].text9,
        text10: stepList2[index].text10,
      });
    }

    return stepList3;
  };

  getProducts = async (mastertaskid1) => {
    //
    // get corresponding products from database
    //

    // productList1 : original products in case of change
    // productList2 : changed products to show on screen
    // productList3 : changed products to up-down load

    let productList2 = [];
    let productList3 = [];

    const { session } = this.props;

    const jsonList = await getTask.allProducts(session, mastertaskid1);
    const dbreturnList = jsonList.jsonReturn;

    if (dbreturnList[0].errorcode === noError) {
      //const productList1 = jsonList.productList;
      const productList1 = _.sortBy(jsonList.productList, function (item) {
        return parseInt(item.order);
      });

      // create productList2

      for (let index = 0; index < productList1.length; index++) {
        productList2.push({
          subtaskid1: productList1[index].subtaskid1,
          order: productList1[index].order,
          productid1: productList1[index].productid1,
          producttext: productList1[index].producttext,
          quantity: productList1[index].quantity,
        });
      }

      // refresh productList3
      productList3 = this.refreshProductList3(productList2);

      this.setState({
        productList3,
        productList1,
        productList2,
        isProductLoaded: true,
      });
    } else {
      // error
      this.setState({
        productList3: [],
        productList1: [],
        productList2: [],
        isProductLoaded: false,
      });
    }
  };

  refreshProductList3 = (productList2) => {
    //
    // refresh product list 3 after update productlist2
    //

    let productList3 = [];

    for (let index = 0; index < productList2.length; index++) {
      productList3.push({
        order: productList2[index].order,
        productid1: productList2[index].productid1,
        quantity: productList2[index].quantity,
      });
    }

    return productList3;
  };

  getIngredients = async (mastertaskid1) => {
    //
    // get corresponding ingredients from database
    //

    // ingredientList1 : original ingredients in case of change
    // ingredientList2 : changed ingredients to show on screen
    // ingredientList3 : changed ingredients to up-down load

    let ingredientList2 = [];
    let ingredientList3 = [];

    const { session } = this.props;

    const jsonList = await getTask.allIngredients(session, mastertaskid1);
    const dbreturnList = jsonList.jsonReturn;

    if (dbreturnList[0].errorcode === noError) {
      //const ingredientList1 = jsonList.ingredientList;
      const ingredientList1 = _.sortBy(
        jsonList.ingredientList,
        function (item) {
          return parseInt(item.order);
        }
      );
      // create ingredientList2

      for (let index = 0; index < ingredientList1.length; index++) {
        ingredientList2.push({
          subtaskid2: ingredientList1[index].subtaskid2,
          order: ingredientList1[index].order,
          productid1: ingredientList1[index].productid1,
          producttext: ingredientList1[index].producttext,
          quantity: ingredientList1[index].quantity
            .toString()
            .replace(/[.]+/, ","),
          uomid: ingredientList1[index].uomid,
          uomtext: ingredientList1[index].uomlong,
        });

        // refresh ingredientList3
        ingredientList3 = this.refreshIngredientList3(ingredientList2);
      }

      this.setState({
        ingredientList3,
        ingredientList1,
        ingredientList2,
        isIngredientLoaded: true,
      });
    } else {
      // error
      this.setState({
        ingredientList3: [],
        ingredientList1: [],
        ingredientList2: [],
        isIngredientLoaded: false,
      });
    }
  };

  refreshIngredientList3 = (ingredientList2) => {
    //
    // refresh ingredient list 3 after update ingredientlist2
    //

    let ingredientList3 = [];

    for (let index = 0; index < ingredientList2.length; index++) {
      ingredientList3.push({
        order: ingredientList2[index].order,
        productid1: ingredientList2[index].productid1,
        quantity: ingredientList2[index].quantity,
        uomid: ingredientList2[index].uomid,
      });
    }

    return ingredientList3;
  };

  componentDidUpdate = (prevProps) => {
    //
    // action after update
    //

    if (this.props.session !== prevProps.session) {
      //////////console.log("componentDidUpdate different session");
    }
  };

  handleBack = () => {
    //
    // set history to go back
    //

    // back to parent
    this.props.history.push({
      pathname: "/steps",
      state: {},
    });
  };

  handleSave = async () => {
    //
    // save all array changes in de database
    //

    const {
      headerList1,
      stepList2,
      productList2,
      ingredientList2,
    } = this.state;

    //////console.log(headerList1, stepList2, productList2, ingredientList2);
    // validate arrays
    if (headerList1.length === 1) {
      if (stepList2.length > 0) {
        switch (headerList1[0].typeid1) {
          case "1": //recipe
            if (productList2.length > 0) {
              if (ingredientList2.length > 0) {
                await this.handleType1Save();
              } else {
                // error ingredient - save steps, producrs and header
                await this.handleType1Save();
              }
            } else {
              // error product - save steps and header
              await this.handleType1Save();
            }
            break;
          case "2": //standard
            await this.handleType2Save();
            break;
          case "3": //maintenance
            if (productList2.length > 0) {
              await this.handleType3Save();
            } else {
              // error product - save steps and header
              await this.handleType1Save();
            }
            break;
          default:
            break;
        }
      } else {
        // only header change
        switch (headerList1[0].typeid1) {
          case "1": //recipe
            await this.handleType1Save();
          break;
            case "2": //standard1
          await this.handleType2Save();
            break;
          case "3": //standard2
            await this.handleType3Save();
          break;
          default:
          break;
        }
        
      }
    } else {
      // new scenario
    }
  };

  modifyDatabase = async (
    isChanged,
    isDeleted,
    header,
    stepUpload,
    stepInsert,
    productUpload,
    productInsert,
    ingredientUpload,
    ingredientInsert,
    mastertaskid
  ) => {
    //
    // save changes in database
    //

    const { session } = this.props;

    this.setState({
      saveDBBusy: true,
    });

    let errorList = [];

    ////console.log("modifyDatabase: ", isChanged, isDeleted);

    ////console.log("header: ", header);
    ////console.log("stepUpload: ", stepUpload);
    ////console.log("stepInsert: ", stepInsert);
    ////console.log("productUpload: ", productUpload);
    ////console.log("productInsert: ", productInsert);
    ////console.log("ingredientUpload: ", ingredientUpload);
    ////console.log("ingredientInsert: ", ingredientInsert);

    // save header changes
    if (isChanged.header) {
      // modify header in db
      //////console.log("modifyDatabase: ", header);
      const jsonList = await getTask.updateMastertask(header);
      const dbreturnList = jsonList.jsonReturn;

      if (dbreturnList[0].errorcode !== noError) {
        errorList.push({
          id:
            "algemeen db fout: " +
            header.mastertaskid1 +
            " " +
            header.mastertasktext,
          error: dbreturnList[0].errorcode,
        });
      }
    }

    // save step changes
    if (isChanged.step) {
      // modify steps in db

      for (let index = 0; index < stepUpload.length; index++) {
        const row = stepUpload[index];
        ////console.log("modifyDatabase: ", row);
        const jsonList = await getTask.updateWFSteps(row);
        const dbreturnList = jsonList.jsonReturn;
        if (dbreturnList[0].errorcode !== noError) {
          errorList.push({
            id:
              "stap db update fout: " +
              stepUpload[index].subtaskid3 +
              " " +
              stepUpload[index].order +
              " " +
              stepUpload[index].steptitle,
            error: dbreturnList[0].errorcode,
          });
        }
      }
    }

    // delete all steps
    if (isDeleted.step) {
      //////console.log("modifyDatabase delete all steps: ", mastertaskid);
      const jsonList = await getTask.deleteWFSteps(session, mastertaskid);
      const dbreturnList = jsonList.jsonReturn;
      if (dbreturnList[0].errorcode !== noError) {
        errorList.push({
          id: "stap db delete fout mastertaskid: " + mastertaskid,
          error: dbreturnList[0].errorcode,
        });
      }
    }

    // insert steps
    if (typeof stepInsert[0] !== "undefined") {
      //////console.log("modifyDatabase stepInsert ", stepInsert);
      for (let index = 0; index < stepInsert.length; index++) {
        const row = stepInsert[index];
        const jsonList = await getTask.insertWFSteps(row);
        const dbreturnList = jsonList.jsonReturn;
        if (dbreturnList[0].errorcode !== noError) {
          errorList.push({
            id:
              "stap db insert fout: " +
              stepInsert[index].subtaskid3 +
              " " +
              stepInsert[index].order +
              " " +
              stepInsert[index].steptitle,
            error: dbreturnList[0].errorcode,
          });
        }
      }
    }

    // save product changes
    if (isChanged.product) {
      //////console.log("modifyDatabase: ", productUpload);
      for (let index = 0; index < productUpload.length; index++) {
        const row = productUpload[index];
        const jsonList = await getTask.updateSubtask1(row);
        const dbreturnList = jsonList.jsonReturn;
        if (dbreturnList[0].errorcode !== noError) {
          errorList.push({
            id:
              "materialen db update fout: " +
              productUpload[index].subtaskid1 +
              " " +
              productUpload[index].order +
              " " +
              productUpload[index].productid1,
            error: dbreturnList[0].errorcode,
          });
        }
      }
    }

    // delete all products
    if (isDeleted.product) {
      //////console.log("modifyDatabase delete product: ", mastertaskid);
      const jsonList = await getTask.deleteSubtask1(session, mastertaskid);
      const dbreturnList = jsonList.jsonReturn;
      if (dbreturnList[0].errorcode !== noError) {
        errorList.push({
          id: "materialen db delete fout mastertaskid: " + mastertaskid,
          error: dbreturnList[0].errorcode,
        });
      }
    }

    // insert products
    if (
      typeof productInsert !== "undefined" &&
      typeof productInsert[0] !== "undefined"
    ) {
      //////console.log("modifyDatabase insert product: ", productInsert);
      for (let index = 0; index < productInsert.length; index++) {
        const row = productInsert[index];
        const jsonList = await getTask.insertSubtask1(row);
        const dbreturnList = jsonList.jsonReturn;
        if (dbreturnList[0].errorcode !== noError) {
          errorList.push({
            id:
              "materialen db insert fout: " +
              productInsert[index].subtaskid1 +
              " " +
              productInsert[index].order +
              " " +
              productInsert[index].productid1,
            error: dbreturnList[0].errorcode,
          });
        }
      }
    }

    // save ingredient changes
    if (isChanged.ingredient) {
      //console.log("modifyDatabase ingredient: ", ingredientUpload);
      for (let index = 0; index < ingredientUpload.length; index++) {
        const row = ingredientUpload[index];
        const jsonList = await getTask.updateSubtask2(row);
        const dbreturnList = jsonList.jsonReturn;
        if (dbreturnList[0].errorcode !== noError) {
          errorList.push({
            id:
              "ingrediënten db update fout: " +
              ingredientUpload[index].subtaskid2 +
              " " +
              ingredientUpload[index].order +
              " " +
              ingredientUpload[index].productid1 +
              " " +
              ingredientUpload[index].uomid,
            error: dbreturnList[0].errorcode,
          });
        }
      }
    }

    // delete all ingredients
    if (isDeleted.ingredient) {
      //console.log("modifyDatabase delete ingredient: ", mastertaskid);
      const jsonList = await getTask.deleteSubtask2(session, mastertaskid);
      const dbreturnList = jsonList.jsonReturn;
      if (dbreturnList[0].errorcode !== noError) {
        errorList.push({
          id: "ingrediënten db delete fout mastertaskid: " + mastertaskid,
          error: dbreturnList[0].errorcode,
        });
      }
    }

    // insert ingredients
    if (
      typeof ingredientInsert !== "undefined" &&
      typeof ingredientInsert[0] !== "undefined"
    ) {
      //console.log("modifyDatabase insert ingredient: ", ingredientInsert);
      for (let index = 0; index < ingredientInsert.length; index++) {
        const row = ingredientInsert[index];
        const jsonList = await getTask.insertSubtask2(row);
        const dbreturnList = jsonList.jsonReturn;
        if (dbreturnList[0].errorcode !== noError) {
          errorList.push({
            id:
              "materialen db insert fout: " +
              ingredientInsert[index].subtaskid2 +
              " " +
              ingredientInsert[index].order +
              " " +
              ingredientInsert[index].productid1 +
              " " +
              ingredientUpload[index].uomid,
            error: dbreturnList[0].errorcode,
          });
        }
      }
    }

    if (typeof errorList[0] === "undefined") {
      // refresh screen
      this.refreshWF();

      this.setState({
        saveDBBusy: false,
        beforeSaveError: undefined,
        isShowSave: false,
      });
    } else {
      //////console.log("modifyDatabase - errorList: ", errorList);
      this.setState({
        beforeSaveError: errorList,
        saveDBBusy: false,
        isShowSave: false,
      });
    }
  };

  handleType1Save = async () => {
    //
    // save recipe
    //

    const { session } = this.props;

    const {
      headerList1,
      headerList2,
      stepList1,
      stepList2,
      productList1,
      productList2,
      ingredientList1,
      ingredientList2,
      productIdCheck,
      uomIdCheck,
    } = this.state;

    let beforeSaveError = [];
    let headerUpload = [];
    let stepUpload = [];
    let stepInsert = [];
    let productUpload = [];
    let productInsert = [];
    let ingredientUpload = [];
    let ingredientInsert = [];
    let isChanged = {
      header: false,
      step: false,
      product: false,
      ingredient: false,
    };
    let isDeleted = {
      step: false,
      product: false,
      ingredient: false,
    };
    let result = undefined;

    // check consistency header
    result = checkHeaderList(headerList1, headerList2, 1, session);
    beforeSaveError = result.errorList;
    //////////console.log("header beforeSaveError: ", beforeSaveError);
    if (beforeSaveError.length === 0) {
      // header ok
      headerUpload = result.uploadList;
      isChanged.header = result.isChanged;
      //////////console.log("headerUpload: ", headerUpload);
      //////////console.log("isChanged: ", isChanged);
      // check consistency steps
      result = checkStepList(
        stepList1,
        stepList2,
        headerList1[0].mastertaskid1,
        session
      );
      beforeSaveError = result.errorList;
      //////////console.log("step beforeSaveError: ", beforeSaveError);
      if (beforeSaveError.length === 0) {
        // steps ok
        stepUpload = result.uploadList;
        stepInsert = result.insertList;
        isChanged.step = result.isChanged;
        isDeleted.step = result.isDeleted;
        /*////////console.log("stepUpload: ", stepUpload);
        ////////console.log("stepInsert: ", stepInsert);*/
        // check consistency products
        result = checkProductList(
          productList1,
          productList2,
          headerList1[0].mastertaskid1,
          session,
          productIdCheck
        );
        beforeSaveError = result.errorList;
        //////////console.log("product beforeSaveError: ", beforeSaveError);
        if (beforeSaveError.length === 0) {
          // products ok
          productUpload = result.uploadList;
          productInsert = result.insertList;
          isChanged.product = result.isChanged;
          isDeleted.product = result.isDeleted;

          // check consistency ingredients
          result = checkIngredientList(
            ingredientList1,
            ingredientList2,
            headerList1[0].mastertaskid1,
            session,
            productIdCheck,
            uomIdCheck
          );
          beforeSaveError = result.errorList;
          //////////console.log("ingredient beforeSaveError: ", beforeSaveError);
          if (beforeSaveError.length === 0) {
            // ingredients ok
            ingredientUpload = result.uploadList;
            isChanged.ingredient = result.isChanged;
            ingredientInsert = result.insertList;
            isDeleted.ingredient = result.isDeleted;
            ////////console.log("ingredientUpload: ", ingredientUpload);
            ////////console.log("ingredientInsert: ", ingredientInsert);
            ////////console.log("isChanged: ", isChanged);
            ////////console.log("isDeleted: ", isDeleted);
            // modify db
            await this.modifyDatabase(
              isChanged,
              isDeleted,
              headerUpload,
              stepUpload,
              stepInsert,
              productUpload,
              productInsert,
              ingredientUpload,
              ingredientInsert,
              headerList1[0].mastertaskid1
            );
            // all ok - clean error
            this.setState({
              beforeSaveError: undefined,
            });
          } else {
            // show error
            this.setState({
              beforeSaveError,
            });
          }
        } else {
          // show error
          this.setState({
            beforeSaveError,
          });
        }
      } else {
        // show error
        this.setState({
          beforeSaveError,
        });
      }
    } else {
      // show error
      this.setState({
        beforeSaveError,
      });
    }
  };

  handleType2Save = async () => {
    //
    // save standard
    //

    const { session } = this.props;
    const { headerList1, headerList2, stepList1, stepList2 } = this.state;

    let beforeSaveError = [];
    let headerUpload = [];
    let stepUpload = [];
    let stepInsert = [];
    let productUpload = [];
    let ingredientUpload = [];
    let productInsert = [];
    let ingredientInsert = [];

    let isChanged = {
      header: false,
      step: false,
      product: false,
      ingredient: false,
    };
    let isDeleted = {
      step: false,
      product: false,
      ingredient: false,
    };
    let result = undefined;

    // check consistency header
    result = checkHeaderList(headerList1, headerList2, 2, session);
    beforeSaveError = result.errorList;
    if (beforeSaveError.length === 0) {
      // header ok
      headerUpload = result.uploadList;
      isChanged.header = result.isChanged;
      // check consistency steps
      result = checkStepList(
        stepList1,
        stepList2,
        headerList1[0].mastertaskid1,
        session
      );
      beforeSaveError = result.errorList;
      if (beforeSaveError.length === 0) {
        // steps ok
        stepUpload = result.uploadList;
        stepInsert = result.insertList;
        isChanged.step = result.isChanged;
        isDeleted.step = result.isDeleted;
        // update db
        //////console.log("handleType2Save: ", headerList1[0].mastertaskid1);
        await this.modifyDatabase(
          isChanged,
          isDeleted,
          headerUpload,
          stepUpload,
          stepInsert,
          productUpload,
          productInsert,
          ingredientUpload,
          ingredientInsert,
          headerList1[0].mastertaskid1
        );
      } else {
        // show error
        this.setState({
          beforeSaveError,
        });
      }
    } else {
      // show error
      this.setState({
        beforeSaveError,
      });
    }
  };

  handleType3Save = async () => {
    //
    // save maintenance
    //

    const { session } = this.props;

    const {
      headerList1,
      headerList2,
      stepList1,
      stepList2,
      productList1,
      productList2,
      productIdCheck,
    } = this.state;

    let beforeSaveError = [];
    let headerUpload = [];
    let stepUpload = [];
    let stepInsert = [];
    let productUpload = [];
    let productInsert = [];
    let ingredientUpload = [];
    let ingredientInsert = [];
    let isChanged = {
      header: false,
      step: false,
      product: false,
      ingredient: false,
    };
    let isDeleted = {
      step: false,
      product: false,
      ingredient: false,
    };
    let result = undefined;

    ////console.log("save maintenance: ", stepList1, stepList2);

    // check consistency header
    result = checkHeaderList(headerList1, headerList2, 3, session);
    beforeSaveError = result.errorList;

    if (beforeSaveError.length === 0) {
      // header ok
      headerUpload = result.uploadList;
      isChanged.header = result.isChanged;

      // check consistency steps
      result = checkStepList(
        stepList1,
        stepList2,
        headerList1[0].mastertaskid1,
        session
      );
      beforeSaveError = result.errorList;
      ////////console.log("step beforeSaveError: ", beforeSaveError);
      if (beforeSaveError.length === 0) {
        // steps ok
        stepUpload = result.uploadList;
        stepInsert = result.insertList;
        isChanged.step = result.isChanged;
        isDeleted.step = result.isDeleted;

        // check consistency products
        result = checkProductList(
          productList1,
          productList2,
          headerList1[0].mastertaskid1,
          session,
          productIdCheck
        );
        beforeSaveError = result.errorList;
        //////////console.log("product beforeSaveError: ", beforeSaveError);
        if (beforeSaveError.length === 0) {
          // products ok
          productUpload = result.uploadList;
          productInsert = result.insertList;
          isChanged.product = result.isChanged;
          isDeleted.product = result.isDeleted;
          ////////console.log("productUpload: ", productUpload);
          ////////console.log("productInsert: ", productInsert);
          ////////console.log("isChanged: ", isChanged);
          ////////console.log("isDeleted: ", isDeleted);
          // modify db
          await this.modifyDatabase(
            isChanged,
            isDeleted,
            headerUpload,
            stepUpload,
            stepInsert,
            productUpload,
            productInsert,
            ingredientUpload,
            ingredientInsert,
            headerList1[0].mastertaskid1
          );
        } else {
          // show error
          this.setState({
            beforeSaveError,
          });
        }
      } else {
        // show error
        this.setState({
          beforeSaveError,
        });
      }
    } else {
      // show error
      this.setState({
        beforeSaveError,
      });
    }
  };

  handleFileInputChange = (e, fileKind) => {
    //
    // save file path for later upload
    //

    this.setState({
      csvfile1: undefined,
      isFileCSV: false,
    });

    ////////console.log("handleFileInputChange: ", e.target.files[0], fileKind);

    if (e.target.files[0].type === "text/csv") {
      this.setState({
        csvfile1: e.target.files[0],
        isFileCSV: false,
      });
      switch (fileKind) {
        case "importCSV1":
          this.importCSV1(e.target.files[0]);
          break;
        case "importCSV2":
          this.importCSV2(e.target.files[0]);
          break;
        case "importCSV3":
          this.importCSV3(e.target.files[0]);
          break;
        case "importCSV4":
          this.importCSV4(e.target.files[0]);
          break;

        default:
          break;
      }
    } else {
      this.setState({
        csvfile1: "undefined",
        isFileCSV: true,
      });
    }
  };

  importCSV1 = (csvfile1) => {
    this.setState({
      headerList2: [],
      isCSVLoaded: false,
      isFileMissing: false,
    });

    //const { csvfile1 } = this.state;
    if (typeof csvfile1 !== "undefined") {
      Papa.parse(csvfile1, {
        complete: this.saveCSVData1,
        header: true,
      });
    } else {
      this.setState({
        isFileMissing: true,
      });
    }
  };

  saveCSVData1 = (results) => {
    //
    // upload header data from file to screen with validation
    //

    const { headerList1 } = this.state;
    let inputTextError = [];

    let headerList2 = this.state.headerList2;
    let headerList3 = this.state.headerList3;

    if (typeof results.error === "undefined" && results.data.length === 1) {
      // validate input file
      const result = checkHeaderInput(results.data, headerList1);
      inputTextError = result.errorList;
      headerList2 = result.tableList;
      headerList3 = result.uploadList;
      /*////////console.log("result: ", result);
      ////////console.log("headerList2: ", headerList2);
      ////////console.log("headerList3: ", headerList3);
      ////////console.log("inputTextError: ", inputTextError);*/
      if (inputTextError.length === 0) {
        this.setState({
          inputTextError: undefined,
          headerList2,
          headerList3,
          isCSVLoaded: true,
          isShowSave: true,
        });
      } else {
        this.setState({
          inputTextError,
          headerList2: [],
          headerList3: [],
          isCSVLoaded: false,
        });
      }
    } else {
      this.setState({
        headerList2,
        headerList3,
        isCSVLoaded: false,
      });
    }
  };

  importCSV2 = (csvfile1) => {
    //
    // import step file
    //

    //const { csvfile1 } = this.state;
    ////////console.log("importCSV2: ", csvfile1);

    this.setState({
      inputTextError: undefined,
      stepList2: [],
      stepList3: [],
      isStepLoaded: true,
      isCSVLoaded: false,
      isFileMissing: false,
    });

    if (typeof csvfile1 !== "undefined") {
      Papa.parse(csvfile1, {
        complete: this.saveCSVData2,
        header: true,
        error: this.errorCSVData2,
      });
    } else {
      this.setState({
        isFileMissing: true,
      });
    }
  };

  errorCSVData2 = (error) => {
    this.setState({
      csvfile1: undefined,
      isFileMissing: true,
    });
    ////////console.log("Parsing error:", error);
  };

  saveCSVData2 = (results) => {
    //
    // check and save text file steps in array
    //

    const { screenList } = this.state;

    let inputTextError = undefined;

    let stepList2 = this.state.stepList2;
    const countlist2Before = stepList2.length;
    let stepList3 = this.state.stepList3;
    const countlist3Before = stepList3.length;

    /*////////console.log("saveCSVData2");
    ////////console.log("results.data: ", results.data);
    ////////console.log("results.meta): ", results.meta);
    ////////console.log("results.error: ", results.error);
    ////////console.log("stepList2: ", stepList2, countlist2Before);
    ////////console.log("stepList3: ", stepList3, countlist3Before);*/

    if (
      typeof results.error === "undefined" &&
      results.data.length > 0 &&
      countlist2Before === countlist3Before
    ) {
      // validate input file
      const result = checkStepInput(results.data, screenList);
      inputTextError = result.errorList;
      stepList2 = result.tableList;

      // sort
      stepList2 = _.sortBy(stepList2, function (item) {
        return parseInt(item.order);
      });

      stepList3 = result.uploadList;

      // sort
      stepList3 = _.sortBy(stepList3, function (item) {
        return parseInt(item.order);
      });
      /*////////console.log("result: ", result);
      ////////console.log("stepList2: ", stepList2);
      ////////console.log("stepList3: ", stepList3);
      ////////console.log("inputTextError: ", inputTextError);*/
      if (inputTextError.length === 0) {
        this.setState({
          inputTextError: undefined,
          stepList2,
          stepList3,
          isCSVLoaded: true,
          isShowSave: true,
        });
      } else {
        this.setState({
          inputTextError,
          stepList2: [],
          stepList3: [],
          isCSVLoaded: false,
        });
      }
    } else {
      this.setState({
        inputTextError: undefined,
        stepList2: [],
        stepList3: [],
        isCSVLoaded: false,
      });
    }
  };

  importCSV3 = (csvfile1) => {
    this.setState({
      productList2: [],
      isCSVLoaded: false,
      isFileMissing: false,
    });

    //const { csvfile1 } = this.state;
    if (typeof csvfile1 !== "undefined") {
      Papa.parse(csvfile1, {
        complete: this.saveCSVData3,
        header: true,
      });
    } else {
      this.setState({
        isFileMissing: true,
      });
    }
  };

  saveCSVData3 = (results) => {
    //
    // check and save text file products in array
    //

    const { productIdCheck, productList } = this.state;

    let inputTextError = undefined;

    let productList2 = [];
    let productList3 = [];

    ////////console.log("saveCSVData3");

    if (typeof results.error === "undefined" && results.data.length > 0) {
      // validate input file
      const result = checkProductInput(
        results.data,
        productIdCheck,
        productList
      );
      inputTextError = result.errorList;
      productList2 = result.tableList;
      productList3 = result.uploadList;
      ////////console.log("result: ", result);
      ////////console.log("productList2: ", productList2);
      ////////console.log("productList3: ", productList3);
      ////////console.log("inputTextError: ", inputTextError);
      if (inputTextError.length === 0) {
        // sort
        productList2 = _.sortBy(productList2, function (item) {
          return parseInt(item.order);
        });

        productList3 = _.sortBy(productList3, function (item) {
          return parseInt(item.order);
        });

        this.setState({
          inputTextError: undefined,
          productList2,
          productList3,
          isCSVLoaded: true,
          isShowSave: true,
        });
      } else {
        this.setState({
          inputTextError,
          productList2: [],
          productList3: [],
          isCSVLoaded: false,
        });
      }
    } else {
      this.setState({
        inputTextError: undefined,
        productList2: [],
        productList3: [],
        isCSVLoaded: false,
      });
    }
  };

  importCSV4 = (csvfile1) => {
    this.setState({
      ingredientList2: [],
      isCSVLoaded: false,
      isFileMissing: false,
    });

    //const { csvfile1 } = this.state;
    if (typeof csvfile1 !== "undefined") {
      Papa.parse(csvfile1, {
        complete: this.saveCSVData4,
        header: true,
      });
    } else {
      this.setState({
        isFileMissing: true,
      });
    }
  };

  saveCSVData4 = (results) => {
    //
    // check and save text file ingredients in array
    //

    const { productIdCheck, ingredientList, uomIdCheck, uomList } = this.state;

    let inputTextError = undefined;

    let ingredientList2 = [];
    let ingredientList3 = [];

    ////////console.log("saveCSVData4");

    if (typeof results.error === "undefined" && results.data.length > 0) {
      // validate input file
      const result = checkIngredientInput(
        results.data,
        productIdCheck,
        ingredientList,
        uomIdCheck,
        uomList
      );
      inputTextError = result.errorList;
      ingredientList2 = result.tableList;
      ingredientList3 = result.uploadList;
      ////////console.log("result: ", result);
      //console.log("saveCSVData4 ingredientList2: ", ingredientList2);
      //console.log("saveCSVData4 ingredientList3: ", ingredientList3);
      ////////console.log("inputTextError: ", inputTextError);
      if (inputTextError.length === 0) {
        this.setState({
          inputTextError: undefined,
          ingredientList2,
          ingredientList3,
          isCSVLoaded: true,
          isShowSave: true,
        });
      } else {
        this.setState({
          inputTextError,
          ingredientList2: [],
          ingredientList3: [],
          isCSVLoaded: false,
        });
      }
    } else {
      this.setState({
        inputTextError: undefined,
        ingredientList2: [],
        ingredientList3: [],
        isCSVLoaded: false,
      });
    }
  };

  handleHeader = () => {
    //
    // show header input screen
    //
    if (!this.state.isShowSave) {
      if (this.state.isHeader) {
        this.setState({
          isHeader: false,
          isWorkflow: false,
          isProduct: false,
          isIngredient: false,
        });
      } else {
        this.setState({
          isHeader: true,
          isWorkflow: false,
          isProduct: false,
          isIngredient: false,
        });
      }
    }
  };

  handleWorkflow = () => {
    //
    // show workflow input screen
    //
    if (!this.state.isShowSave) {
      if (this.state.isWorkflow) {
        this.setState({
          isHeader: false,
          isWorkflow: false,
          isProduct: false,
          isIngredient: false,
        });
      } else {
        this.setState({
          isHeader: false,
          isWorkflow: true,
          isProduct: false,
          isIngredient: false,
        });
      }
    }
  };

  handleProduct = () => {
    //
    // show product input screen
    //
    if (!this.state.isShowSave) {
      if (this.state.isProduct) {
        this.setState({
          isHeader: false,
          isWorkflow: false,
          isProduct: false,
          isIngredient: false,
        });
      } else {
        this.setState({
          isHeader: false,
          isWorkflow: false,
          isProduct: true,
          isIngredient: false,
        });
      }
    }
  };

  handleIngredient = () => {
    //
    // show ingredient input screen
    //

    if (this.state.isIngredient) {
      this.setState({
        isHeader: false,
        isWorkflow: false,
        isProduct: false,
        isIngredient: false,
      });
    } else {
      this.setState({
        isHeader: false,
        isWorkflow: false,
        isProduct: false,
        isIngredient: true,
      });
    }
  };

  scrollTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  renderTooltip(title) {
    return <Tooltip id="button-tooltip">{title}</Tooltip>;
  }

  render() {
    //
    // render task screen
    //

    const { session, isAdmin } = this.props;

    const {
      isCSVLoaded,
      isFileMissing,
      isHeader,
      headerList2,
      isWorkflow,
      isStepLoaded,
      stepList2,
      stepList3,
      isProduct,
      productList2,
      productList3,
      isIngredient,
      ingredientList2,
      ingredientList3,
      xlsxfile1,
      isFileCSV,
      headerErrorMessage,
      stepErrorMessage,
      screenList,
      productList,
      ingredientList,
      inputTextError,
      beforeSaveError,
      saveDBBusy,
      imageRecord,
      isUploadImage,
      isUploadMP4,
      subtaskid3,
      isShowSave,
      movieUrl,
      isImageSaved,
      uomList,
      isRefresh,
    } = this.state;

    // don't render if not ready
    if (typeof session.companyimageurl === "undefined") {
      return null;
    }
    // don't render if not ready
    if (typeof headerList2[0] === "undefined") {
      return null;
    }


    const typeid = headerList2[0].typeid1.toString();
    let csvfileReplace = "";


    return (
      <React.Fragment>
        {/* header with workflow name */}
        <div className={classTopMenu}>
          <img
            src={headerList2[0].imageurl}
            alt={headerList2[0].mastertasktext}
            height={50}
            width={50}
          />
          <h6>{headerList2[0].mastertasktext}</h6>
        </div>

        {/* title */}
        <h4>
          <span className={classTitle}>{changeStepsTitle}</span>
        </h4>

        {/* error messages */}
        {isAdmin && typeof beforeSaveError !== "undefined" && (
          <ErrorTable
            inputTextError={beforeSaveError}
            title={errorTableTitle1}
          />
        )}
        {isShowSave && (
          <div className={classWarningMessage}>{stepMessage30}</div>
        )}
        {!isImageSaved && (
          <div className={classWarningMessage}>{stepMessage31}</div>
        )}
        {!isStepLoaded && (
          <div className={classWarningMessage}>{stepMessage28}</div>
        )}

        {saveDBBusy && <TopWaitingMessage messageToShow={saveDBError2} />}
        {isFileMissing && (
          <div className={classErrorMessage}>{stepMessage21}</div>
        )}
        {isFileCSV && (
          <div className={classWarningMessage}>{stepMessage22}</div>
        )}
        {typeof headerErrorMessage !== "undefined" && (
          <div className={classErrorMessage}>{headerErrorMessage}</div>
        )}

        {/* button row for global actions */}
        {isAdmin && (
          <div className={classTopButtons}>
            {!saveDBBusy && (
              <form className="form-inline">
                <div>
                  <OverlayTrigger
                    placement="left"
                    delay={{ show: 250, hide: 400 }}
                    overlay={this.renderTooltip("terug")}
                  >
                    <div>
                      <button
                        type="button"
                        className={backClass5}
                        onClick={this.handleBack}
                      ></button>
                    </div>
                  </OverlayTrigger>
                </div>
                {isShowSave && (
                  <div>
                    <OverlayTrigger
                      placement="left"
                      delay={{ show: 250, hide: 400 }}
                      overlay={this.renderTooltip("bewaar in databank")}
                    >
                      <div>
                        <button
                          type="button"
                          className={saveClass5}
                          onClick={this.handleSave}
                        ></button>
                      </div>
                    </OverlayTrigger>
                  </div>
                )}
              </form>
            )}
          </div>
        )}

        {/* upload image */}
        {!isShowSave && isUploadImage && (
          <div className={classImageTitle}>
            <UploadImageForm
              session={session}
              imageRecord={imageRecord}
              handleImageSave={this.handleImageSave}
              cancelImageUpload={this.cancelImageUpload}
            />
          </div>
        )}

        {/* upload mp4 */}
        {!isShowSave && isUploadMP4 && (
          <div className={classTables}>
            <NewMP4Form
              newItem={this.handleNewItem}
              cancelItem={this.handleCancelItem}
              subtaskid3={subtaskid3}
              movieUrl={movieUrl}
            />
          </div>
        )}

        {/* header data  */}
        <div>
          <button
            type="button"
            className={headerClass}
            onClick={this.handleHeader}
          >
            Algemene gegevens
          </button>
        </div>
        {isAdmin &&
          isHeader &&
          !isCSVLoaded &&
          typeof inputTextError !== "undefined" && (
            <ErrorTable
              inputTextError={inputTextError}
              title={errorTableTitle1}
            />
          )}
        {isAdmin && isHeader && (
          <div>
            <div className="row ml-2 mr-2">
              <HeaderTable
                headerList2={headerList2}
                saveHeaderItem={this.saveHeaderItem}
                changeImageHeader={this.changeImageHeader}
              />
            </div>
          </div>
        )}
        {/* workflow data  */}
        <div>
          <button
            type="button"
            className={workflowClass}
            onClick={this.handleWorkflow}
          >
            Stappen
          </button>
        </div>
        {isAdmin &&
          isWorkflow &&
          !isCSVLoaded &&
          typeof inputTextError !== "undefined" && (
            <ErrorTable
              inputTextError={inputTextError}
              title={errorTableTitle1}
            />
          )}
        {isAdmin && isWorkflow && (
          <div>
            <div className="mb-2">
              <UploadCSV
                csvfileReplace={csvfileReplace}
                className={uploadClass5}
                onChange={(e) => this.handleFileInputChange(e, "importCSV2")}
              />
              <ExportCSV
                csvData={stepList3}
                fileName={xlsxfile1}
                className={downloadClass5}
              />

              <OverlayTrigger
                placement="left"
                delay={{ show: 250, hide: 400 }}
                overlay={this.renderTooltip("nieuw")}
              >
                <button
                  type="button"
                  className={newClass5}
                  onClick={this.handleNewStep}
                ></button>
              </OverlayTrigger>
            </div>
            <div className="row ml-2 mr-2">
              <StepTable
                stepList2={stepList2}
                saveStepItem={this.saveStepItem}
                deleteStepItem={this.deleteStepItem}
                screenList={screenList}
                stepErrorMessage={stepErrorMessage}
                classErrorMessage={classErrorMessage}
                changeImage1Step={this.changeImage1Step}
                changeImage2Step={this.changeImage2Step}
                changeMovieStep={this.changeMovieStep}
                refreshStepItem={this.refreshStepItem}
                isRefresh={isRefresh}
              />
            </div>
          </div>
        )}
        {/* material/product data  */}

        {typeid === "1" && (
          <div>
            <button
              type="button"
              className={productClass}
              onClick={this.handleProduct}
            >
              Keukenmaterialen
            </button>
          </div>
        )}

        {typeid === "3" && (
          <div>
            <button
              type="button"
              className={productClass}
              onClick={this.handleProduct}
            >
              Materialen
            </button>
          </div>
        )}

        {isAdmin &&
          isProduct &&
          !isCSVLoaded &&
          typeof inputTextError !== "undefined" && (
            <ErrorTable
              inputTextError={inputTextError}
              title={errorTableTitle1}
            />
          )}
        {isAdmin && isProduct && (
          <div>
            <div className="mb-2">
              <UploadCSV
                csvfileReplace={csvfileReplace}
                className={uploadClass5}
                onChange={(e) => this.handleFileInputChange(e, "importCSV3")}
              />
              <ExportCSV
                csvData={productList3}
                fileName={xlsxfile1}
                className={downloadClass5}
              />
              <OverlayTrigger
                placement="left"
                delay={{ show: 250, hide: 400 }}
                overlay={this.renderTooltip("nieuw")}
              >
                <button
                  type="button"
                  className={newClass5}
                  onClick={this.handleNewMaterial}
                ></button>
              </OverlayTrigger>
            </div>
            <div className="row ml-2 mr-2">
              <ProductTable
                productList2={productList2}
                saveProductItem={this.saveProductItem}
                deleteMaterialItem={this.deleteMaterialItem}
                productList={productList}
              />
            </div>
          </div>
        )}
        {/* ingredient data  */}

        {typeid === "1" && (
          <div>
            <button
              type="button"
              className={ingredientClass}
              onClick={this.handleIngredient}
            >
              Ingrediënten
            </button>
          </div>
        )}

        {isAdmin &&
          isIngredient &&
          !isCSVLoaded &&
          typeof inputTextError !== "undefined" && (
            <ErrorTable
              inputTextError={inputTextError}
              title={errorTableTitle1}
            />
          )}
        {isAdmin && isIngredient && (
          <div>
            <div className="mb-2">
              <UploadCSV
                csvfileReplace={csvfileReplace}
                className={uploadClass5}
                onChange={(e) => this.handleFileInputChange(e, "importCSV4")}
              />
              <ExportCSV
                csvData={ingredientList3}
                fileName={xlsxfile1}
                className={downloadClass5}
              />
              <OverlayTrigger
                placement="left"
                delay={{ show: 250, hide: 400 }}
                overlay={this.renderTooltip("nieuw")}
              >
                <button
                  type="button"
                  className={newClass5}
                  onClick={this.handleNewIngredient}
                ></button>
              </OverlayTrigger>
            </div>
            <div className="row ml-2 mr-2">
              <IngredientTable
                ingredientList2={ingredientList2}
                saveIngredientItem={this.saveIngredientItem}
                deleteIngredientItem={this.deleteIngredientItem}
                ingredientList={ingredientList}
                uomList={uomList}
              />
            </div>
          </div>
        )}
      </React.Fragment>
    );
  }

  /*****************/
  /* row functions */
  /*****************/

  handleNewStep = () => {
    //
    // add new single step
    //

    //////console.log("handleNewStep");

    let stepList2 = [];
    let stepList3 = [];

    stepList2 = this.state.stepList2;

    this.setState({
      stepList2: [],
    });

    const random_integer = (Math.random() * 101 * Date.now()) | 0;

    stepList2.unshift({
      subtaskid3: random_integer,
      order: 1,
      screenformatid: "C0",
      screenformatlabel: "enkel tekst",
      steptitle: "Nieuwe stap titel",
      text1: "",
      text2: "",
      text3: "",
      text4: "",
      text5: "",
      text6: "",
      text7: "",
      text8: "",
      text9: "",
      text10: "",
      imageurl1: "",
      image1: "",
      imageurl2: "",
      image2: "",
    });

    stepList3 = this.refreshStepList3(stepList2);
    this.setState({
      stepList2,
      stepList3,
    });
  };

  deleteStepItem = (itemData) => {
    //
    // delete single step
    //

    let stepList2 = [];
    let stepList3 = [];

    stepList2 = this.state.stepList2;

    this.setState({
      stepList2: [],
    });

    // delete record from stepList2
    const deleteIndex = stepList2
      .map(function (element) {
        return element.subtaskid3;
      })
      .indexOf(itemData.subtaskid3);

    stepList2.splice(deleteIndex, 1);

    stepList3 = this.refreshStepList3(stepList2);
    this.setState({
      stepList2,
      stepList3,
      isShowSave: true,
    });
  };

  handleNewMaterial = () => {
    //
    // add new single material
    //

    //////console.log("handleNewmaterial");

    let productList2 = [];
    let productList3 = [];

    productList2 = this.state.productList2;

    this.setState({
      productList2: [],
    });

    const random_integer = (Math.random() * 101 * Date.now()) | 0;

    productList2.unshift({
      subtaskid1: random_integer,
      order: "",
      productid1: "",
      producttext: "",
      quantity: 1,
    });

    productList3 = this.refreshProductList3(productList2);

    this.setState({
      productList2,
      productList3,
    });
  };

  deleteMaterialItem = (itemData) => {
    //
    // delete single material
    //

    //////console.log("deleteMaterialItem");

    let productList2 = [];
    let productList3 = [];

    productList2 = this.state.productList2;

    this.setState({
      productList2: [],
    });

    // delete record from productList2
    const deleteIndex = productList2
      .map(function (element) {
        return element.subtaskid1;
      })
      .indexOf(itemData.subtaskid1);

    productList2.splice(deleteIndex, 1);

    productList3 = this.refreshProductList3(productList2);
    this.setState({
      productList2,
      productList3,
      isShowSave: true,
    });
  };

  handleNewIngredient = () => {
    //
    // add new single ingredient
    //

    //console.log("handleNewIngredient");

    let ingredientList2 = [];
    let ingredientList3 = [];

    ingredientList2 = this.state.ingredientList2;

    this.setState({
      ingredientList2: [],
    });

    const random_integer = (Math.random() * 101 * Date.now()) | 0;

    ingredientList2.unshift({
      subtaskid2: random_integer,
      order: "",
      productid1: "",
      producttext: "",
      quantity: "",
      uomid: "",
      uomtext: "",
    });

    ingredientList3 = this.refreshIngredientList3(ingredientList2);

    this.setState({
      ingredientList2,
      ingredientList3,
    });
  };

  deleteIngredientItem = (itemData) => {
    //
    // delete single ingredient
    //

    let ingredientList2 = [];
    let ingredientList3 = [];

    ingredientList2 = this.state.ingredientList2;

    this.setState({
      ingredientList2: [],
    });

    // delete record from ingredientList2
    const deleteIndex = ingredientList2
      .map(function (element) {
        return element.subtaskid2;
      })
      .indexOf(itemData.subtaskid2);

    //console.log("deleteIngredientItem deleteIndex: ", deleteIndex);
    ingredientList2.splice(deleteIndex, 1);

    ingredientList3 = this.refreshIngredientList3(ingredientList2);
    this.setState({
      ingredientList2,
      ingredientList3,
      isShowSave: true,
    });
  };

  saveHeaderItem = (itemData) => {
    //
    // save header changes in array
    //
    ////////console.log("saveHeaderItem itemData: ", itemData);

    const { headerList1 } = this.state;
    let headerList2 = [];
    let headerList3 = [];
    let inputTextError = [];
    let uploadList = [];

    console.log(" saveHeaderItem itemData.typeid1: ",itemData.typeid1);
    uploadList.push({
      mastertasktext: itemData.mastertasktext,
      mastertasktext2: itemData.mastertasktext2,
      typeid1: itemData.typeid1,
    });

    // validate input file
    const result = checkHeaderInput(uploadList, headerList1);
    inputTextError = result.errorList;
    headerList2 = result.tableList;
    headerList3 = result.uploadList;
    ////////console.log("result: ", result);
    ////////console.log("headerList2: ", headerList2);
    ////////console.log("headerList3: ", headerList3);
    ////////console.log("inputTextError: ", inputTextError);
    if (inputTextError.length === 0) {
      this.setState({
        inputTextError: undefined,
        headerList2,
        headerList3,
        isCSVLoaded: true,
        isShowSave: true,
      });
    } else {
      this.setState({
        inputTextError,
        isCSVLoaded: false,
      });
    }
  };

  changeImageHeader = async (itemData) => {
    //
    // change image
    //
    //////console.log("changeImageHeader ", itemData);

    const imageRecord = this.state.imageRecord;
    imageRecord.type = prefixImageTask;
    imageRecord.id = itemData.mastertaskid1;

    if (
      itemData.image === "" ||
      typeof itemData.image === "undefined" ||
      itemData.image === null
    ) {
      imageRecord.new = true;
      imageRecord.oldname = undefined;
    } else {
      imageRecord.new = false;
      imageRecord.oldname = itemData.image;
      imageRecord.oldurl = itemData.imageurl;
    }

    imageRecord.description = itemData.mastertasktext;

    imageRecord.minWidth = minWidthImageTask;
    imageRecord.maxWidth = maxWidthImageTask;
    imageRecord.minHeight = minHeightImageTask;
    imageRecord.maxHeight = maxHeightImageTask;

    //////console.log("changeImage ", imageRecord);

    this.scrollTop();

    this.setState({
      isUploadImage: true,
      isUploadMP4: false,
      imageRecord,
    });
  };

  handleImageSave = async (uploadedImage) => {
    //
    // save to server
    //

    const { session } = this.props;
    const { imageRecord } = this.state;
    let headerList1 = [...this.state.headerList1];
    let headerList2 = [...this.state.headerList2];
    let stepList1 = [...this.state.stepList1];
    let stepList2 = [...this.state.stepList2];

    let deleteFilePath = "";
    let errorReturn = "E00";

    // save new
    const result = await putImage.uploadImage(session.imagephp, uploadedImage);

    if (result.jsonReturn[0].errorcode === noError) {
      // update url in db and delete old
      const newImageUrl = session.imageurl2 + result.jsonReturn[0].file;
      if (typeof imageRecord.oldname !== "undefined") {
        deleteFilePath = session.imagedir + imageRecord.oldname;
      } else {
        deleteFilePath = "";
      }
      //////console.log("imageRecord: ", imageRecord);
      //////console.log("newImageUrl: ", newImageUrl);
      //////console.log("deleteFilePath: ", deleteFilePath);

      switch (imageRecord.type) {
        /*  case prefixImageUser:
          const result1 = await putImage.updateUserImage(
            session,
            imageRecord.id,
            newImageUrl,
            result.jsonReturn[0].file,
            deleteFilePath
          );
          errorReturn = result1.jsonReturn[0].errorcode;
          break;
        case prefixImageProduct:
          const result2 = await putImage.updateProductImage(
            session,
            imageRecord.id,
            newImageUrl,
            result.jsonReturn[0].file,
            deleteFilePath
          );
          errorReturn = result2.jsonReturn[0].errorcode;
          break;*/
        case prefixImageTask:
          const result3 = await putImage.updateTaskImage(
            session,
            imageRecord.id,
            newImageUrl,
            result.jsonReturn[0].file,
            deleteFilePath
          );
          errorReturn = result3.jsonReturn[0].errorcode;
          if (errorReturn === noError) {
            // update headerList arrays
            const imageFromUrl = newImageUrl.split("/");
            headerList1[0].imageurl = newImageUrl;
            headerList1[0].image = imageFromUrl[5];
            headerList2[0].imageurl = newImageUrl;
            headerList2[0].image = imageFromUrl[5];
          }
          break;
        case prefixImageStep:
          const result4 = await putImage.updateStepImage(
            session,
            imageRecord.id,
            newImageUrl,
            result.jsonReturn[0].file,
            deleteFilePath,
            imageRecord.subid
          );
          errorReturn = result4.jsonReturn[0].errorcode;
          if (errorReturn === noError) {
            // update stepList arrays
            const imageFromUrl = newImageUrl.split("/");
            let index = stepList1.findIndex(
              (el) => el.subtaskid3 === imageRecord.id
            );
            let index2 = stepList2.findIndex(
              (el) => el.subtaskid3 === imageRecord.id
            );

            if (imageRecord.subid === "1") {
              stepList1[index] = {
                ...stepList1[index],
                imageurl1: newImageUrl,
              };
              stepList1[index] = {
                ...stepList1[index],
                image1: imageFromUrl[5],
              };
              stepList2[index2] = {
                ...stepList2[index2],
                imageurl1: newImageUrl,
              };
              stepList2[index2] = {
                ...stepList2[index2],
                image1: imageFromUrl[5],
              };
            } else {
              stepList1[index] = {
                ...stepList1[index],
                imageurl2: newImageUrl,
              };
              stepList1[index] = {
                ...stepList1[index],
                image2: imageFromUrl[5],
              };
              stepList2[index2] = {
                ...stepList2[index2],
                imageurl2: newImageUrl,
              };
              stepList2[index2] = {
                ...stepList2[index2],
                image2: imageFromUrl[5],
              };
            }
            ////console.log(stepList1[index], stepList2[index2]);
          }
          break;
        default:
          break;
      }

      //////console.log("errorRetrun: ", errorReturn);
      if (errorReturn === noError) {
        // update image record
        imageRecord.oldname = result.jsonReturn[0].file;

        // success message
        this.setState({
          headerList1,
          headerList2,
          stepList1,
          stepList2,
          isUploadImage: false,
          isImageSaved: true,
          imageRecord,
        });
      } else {
        // error message
        this.setState({
          isImageSaved: false,
        });
      }
    } else {
      // error
      this.setState({
        isImageSaved: false,
      });
    }
  };

  refreshStepItem = (itemData) => {
    //
    // refresh screen on change of format
    //




    this.setState({
      isRefresh: true,
    });


  }

  saveStepItem = (itemData) => {
    //
    // save step changes in array
    //


    let inputTextError = [];

    // screenformat dropdown
    if (typeof itemData.screenformat.id !== "undefined") {
      itemData.screenformatid = itemData.screenformat.id;
      itemData.screenformatlabel = itemData.screenformat.label;
    }

    // remove spaces from order
    const intOrder = parseInt(itemData.order);
    itemData.order = intOrder.toString();

    // validate title, order and screen format
    inputTextError = validateStepTitle(
      itemData.steptitle,
      itemData.order,
      itemData.screenformatid,
      inputTextError
    );
    /*  USING EVAL IS NOT SECURE for (let index = 1; index < 11; index++) {
      const textName = "tekst" + index;
      const itemDataText = "itemData.text" + index;

      inputTextError = validateStepTextLine(
        eval(itemDataText),
        inputTextError,
        textName,
        itemData.screenformatid,
        itemData.order
      );
    }*/

    if (itemData.screenformatid === 'C0'
      || itemData.screenformatid === 'C1'
      || itemData.screenformatid === 'C2'
      || itemData.screenformatid === 'C3'
      || itemData.screenformatid === 'C4'
      || itemData.screenformatid === 'C5'
    ) {
      inputTextError = validateStepTextLine(
        itemData.text1,
        inputTextError,
        "tekst1",
        itemData.screenformatid,
        itemData.order
      );
      inputTextError = validateStepTextLine(
        itemData.text2,
        inputTextError,
        "tekst2",
        itemData.screenformatid,
        itemData.order
      );
      inputTextError = validateStepTextLine(
        itemData.text3,
        inputTextError,
        "tekst3",
        itemData.screenformatid,
        itemData.order
      );
      inputTextError = validateStepTextLine(
        itemData.text4,
        inputTextError,
        "tekst4",
        itemData.screenformatid,
        itemData.order
      );
      inputTextError = validateStepTextLine(
        itemData.text5,
        inputTextError,
        "tekst5",
        itemData.screenformatid,
        itemData.order
      );
      inputTextError = validateStepTextLine(
        itemData.text6,
        inputTextError,
        "tekst6",
        itemData.screenformatid,
        itemData.order
      );
      inputTextError = validateStepTextLine(
        itemData.text7,
        inputTextError,
        "tekst7",
        itemData.screenformatid,
        itemData.order
      );
      inputTextError = validateStepTextLine(
        itemData.text8,
        inputTextError,
        "tekst8",
        itemData.screenformatid,
        itemData.order
      );
      inputTextError = validateStepTextLine(
        itemData.text9,
        inputTextError,
        "tekst9",
        itemData.screenformatid,
        itemData.order
      );
      inputTextError = validateStepTextLine(
        itemData.text10,
        inputTextError,
        "tekst10",
        itemData.screenformatid,
        itemData.order
      );

      if (inputTextError.length === 0) {
        // save changes
        // update stepList2
        let stepList2 = this.state.stepList2;
        const stepList2Record = this.state.stepList2.filter(
          (m) => m.subtaskid3 === itemData.subtaskid3
        );
        const index = stepList2.indexOf(stepList2Record[0]);

        ////////console.log("stepList2[index]: ", stepList2[index]);

        stepList2[index].order = itemData.order;
        stepList2[index].screenformatid = itemData.screenformatid;
        stepList2[index].screenformatlabel = itemData.screenformatlabel;
        stepList2[index].steptitle = itemData.steptitle;
        stepList2[index].text1 = itemData.text1;
        stepList2[index].text2 = itemData.text2;
        stepList2[index].text3 = itemData.text3;
        stepList2[index].text4 = itemData.text4;
        stepList2[index].text5 = itemData.text5;
        stepList2[index].text6 = itemData.text6;
        stepList2[index].text7 = itemData.text7;
        stepList2[index].text8 = itemData.text8;
        stepList2[index].text9 = itemData.text9;
        stepList2[index].text10 = itemData.text10;

        // refresh stepList3
        const stepList3 = this.refreshStepList3(stepList2);

        this.setState({
          inputTextError: undefined,
          stepList2,
          stepList3,
          isCSVLoaded: true,
          isShowSave: true,
          isUploadImage: false,
        });
      } else {
        //show error list
        let errorListTemp = inputTextError;
        inputTextError = [];
        for (let index = 0; index < errorListTemp.length; index++) {
          inputTextError.push({
            key: index,
            errorText: errorListTemp[index].errorText,
          });
        }
        errorListTemp = [];

        this.setState({
          inputTextError,
          isCSVLoaded: false,
          isUploadImage: false,
        });
      }

    } else if ((itemData.screenformatid === 'C12') || (itemData.screenformatid === 'C13')) {
        // pdf or web url
        // save changes
        // update stepList2
        let stepList2 = this.state.stepList2;
        const stepList2Record = this.state.stepList2.filter(
          (m) => m.subtaskid3 === itemData.subtaskid3
        );
        const index = stepList2.indexOf(stepList2Record[0]);

        stepList2[index].order = itemData.order;
        stepList2[index].screenformatid = itemData.screenformatid;
        stepList2[index].screenformatlabel = itemData.screenformatlabel;
        stepList2[index].steptitle = itemData.steptitle;
        stepList2[index].text1 = itemData.text1;

        // refresh stepList3
        const stepList3 = this.refreshStepList3(stepList2);

        this.setState({
          inputTextError: undefined,
          stepList2,
          stepList3,
          isCSVLoaded: true,
          isShowSave: true,
          isUploadImage: false,
        });
      
    } else if (itemData.screenformatid === 'C14') {
      // free text - no format checking
 
        // save changes
        // update stepList2
        let stepList2 = this.state.stepList2;
        const stepList2Record = this.state.stepList2.filter(
          (m) => m.subtaskid3 === itemData.subtaskid3
        );
        const index = stepList2.indexOf(stepList2Record[0]);

        ////////console.log("stepList2[index]: ", stepList2[index]);

        stepList2[index].order = itemData.order;
        stepList2[index].screenformatid = itemData.screenformatid;
        stepList2[index].screenformatlabel = itemData.screenformatlabel;
        stepList2[index].steptitle = itemData.steptitle;
        stepList2[index].text1 = itemData.text1;
        stepList2[index].text2 = itemData.text2;
        stepList2[index].text3 = itemData.text3;
        stepList2[index].text4 = itemData.text4;
        stepList2[index].text5 = itemData.text5;
        stepList2[index].text6 = itemData.text6;
        stepList2[index].text7 = itemData.text7;
        stepList2[index].text8 = itemData.text8;
        stepList2[index].text9 = itemData.text9;
        stepList2[index].text10 = itemData.text10;

        // refresh stepList3
        const stepList3 = this.refreshStepList3(stepList2);

        this.setState({
          inputTextError: undefined,
          stepList2,
          stepList3,
          isCSVLoaded: true,
          isShowSave: true,
          isUploadImage: false,
        });
    }



  };

  changeImage1Step = async (itemData) => {
    //
    // change image
    //
    //////console.log("changeImage1Step", itemData);

    const imageRecord = this.state.imageRecord;
    imageRecord.type = prefixImageStep;
    imageRecord.id = itemData.subtaskid3;
    imageRecord.subid = "1";

    if (
      itemData.image1 === "" ||
      typeof itemData.image1 === "undefined" ||
      itemData.image1 === null
    ) {
      imageRecord.new = true;
      imageRecord.oldname = undefined;
    } else {
      imageRecord.new = false;
      imageRecord.oldname = itemData.image1;
      imageRecord.oldurl = itemData.imageurl1;
    }
    imageRecord.screenformat = itemData.screenformatid;
    imageRecord.description =
      " - STAP" + itemData.order + " " + itemData.steptitle;

    if (
      imageRecord.screenformat === "C1" ||
      imageRecord.screenformat === "C4" ||
      imageRecord.screenformat === "C10" ||
      imageRecord.screenformat === "C8" ||
      imageRecord.screenformat === "C11"
    ) {
      // type1
      imageRecord.minWidth = minWidthImageStep1;
      imageRecord.maxWidth = maxWidthImageStep1;
      imageRecord.minHeight = minHeightImageStep1;
      imageRecord.maxHeight = maxHeightImageStep1;
    } else if (
      imageRecord.screenformat === "C2" ||
      imageRecord.screenformat === "C5"
    ) {
      // type2
      imageRecord.minWidth = minWidthImageStep2;
      imageRecord.maxWidth = maxWidthImageStep2;
      imageRecord.minHeight = minHeightImageStep2;
      imageRecord.maxHeight = maxHeightImageStep2;
    } else {
      // type 3
      imageRecord.minWidth = minWidthImageStep3;
      imageRecord.maxWidth = maxWidthImageStep3;
      imageRecord.minHeight = minHeightImageStep3;
      imageRecord.maxHeight = maxHeightImageStep3;
    }

    //////console.log("changeImage ", imageRecord);

    this.scrollTop();

    this.setState({
      isUploadImage: true,
      isUploadMP4: false,
      imageRecord,
    });
  };

  changeImage2Step = async (itemData) => {
    //
    // change image
    //
    //////console.log("changeImage2Step", itemData);

    const imageRecord = this.state.imageRecord;
    imageRecord.type = prefixImageStep;
    imageRecord.id = itemData.subtaskid3;
    imageRecord.subid = "2";

    if (
      itemData.image2 === "" ||
      typeof itemData.image2 === "undefined" ||
      itemData.image2 === null
    ) {
      imageRecord.new = true;
      imageRecord.oldname = undefined;
    } else {
      imageRecord.new = false;
      imageRecord.oldname = itemData.image2;
      imageRecord.oldurl = itemData.imageurl2;
    }
    imageRecord.screenformat = itemData.screenformatid;
    imageRecord.description =
      itemData.order + "-" + itemData.screenformatid + "-" + itemData.steptitle;

    if (
      imageRecord.screenformat === "C1" ||
      imageRecord.screenformat === "C4" ||
      imageRecord.screenformat === "C10" ||
      imageRecord.screenformat === "C8" ||
      imageRecord.screenformat === "C11"
    ) {
      // type1
      imageRecord.minWidth = minWidthImageStep1;
      imageRecord.maxWidth = maxWidthImageStep1;
      imageRecord.minHeight = minHeightImageStep1;
      imageRecord.maxHeight = maxHeightImageStep1;
    } else if (
      imageRecord.screenformat === "C2" ||
      imageRecord.screenformat === "C5"
    ) {
      // type2
      imageRecord.minWidth = minWidthImageStep2;
      imageRecord.maxWidth = maxWidthImageStep2;
      imageRecord.minHeight = minHeightImageStep2;
      imageRecord.maxHeight = maxHeightImageStep2;
    } else {
      // type 3
      imageRecord.minWidth = minWidthImageStep3;
      imageRecord.maxWidth = maxWidthImageStep3;
      imageRecord.minHeight = minHeightImageStep3;
      imageRecord.maxHeight = maxHeightImageStep3;
    }

    //////console.log("changeImage ", imageRecord);

    this.scrollTop();

    this.setState({
      isUploadImage: true,
      isUploadMP4: false,
      imageRecord,
    });
  };

  cancelImageUpload = () => {
    //
    // Cancel imagescreen
    //

    this.setState({
      isUploadImage: false,
      isUploadMP4: false,
    });
  };

  changeMovieStep = async (itemData) => {
    //
    // change image
    //
    //////console.log("changeMovieStep", itemData);

    let movieUrl = "";

    this.scrollTop();

    const movieIndex = this.state.stepList1
      .map(function (element) {
        return element.subtaskid3;
      })
      .indexOf(itemData.subtaskid3);

    if (typeof this.state.stepList1[movieIndex] !== "undefined") {
      movieUrl = this.state.stepList1[movieIndex].movieurl;
    } else {
      movieUrl = "https://*.mp4";
    }
    this.setState({
      isUploadMP4: true,
      isUploadImage: false,
      subtaskid3: itemData.subtaskid3,
      movieUrl,
    });
  };


  saveProductItem = (itemData) => {
    //
    // save product changes in array
    //
    ////////console.log("saveProductItem itemData: ", itemData);

    const { productIdCheck } = this.state;
    let inputTextError = [];

    // product dropdown
    if (
      typeof itemData.product.id !== "undefined" &&
      typeof itemData.product.label !== "undefined"
    ) {
      itemData.productid1 = itemData.product.id;
      itemData.producttext = itemData.product.label;
    }

    // remove leading zero's
    itemData.quantity = (itemData.quantity * 1).toString();

    inputTextError = validateProduct(
      itemData.order,
      itemData.productid1,
      itemData.quantity,
      productIdCheck,
      inputTextError
    );

    ////////console.log("inputTextError: ", inputTextError);

    if (inputTextError.length === 0) {
      // save changes
      // update productList2
      const productList2 = [...this.state.productList2];
      /* const productList2Record = this.state.productList2.filter(
        (m) => m.subtaskid1 === itemData.subtaskid1
      );
      const index = productList2.indexOf(productList2Record[0]);*/

      const index = productList2
        .map(function (element) {
          return element.subtaskid1;
        })
        .indexOf(itemData.subtaskid1);

      ////////console.log("productList2[index]: ", productList2[index]);

      productList2[index].order = itemData.order;
      productList2[index].productid1 = itemData.productid1;
      productList2[index].producttext = itemData.producttext;
      productList2[index].quantity = itemData.quantity;

      // refresh productList3
      const productList3 = this.refreshProductList3(productList2);

      this.setState({
        inputTextError: undefined,
        productList2,
        productList3,
        isCSVLoaded: true,
        isShowSave: true,
      });
    } else {
      //show error list
      let errorListTemp = inputTextError;
      inputTextError = [];
      for (let index = 0; index < errorListTemp.length; index++) {
        inputTextError.push({
          key: index,
          errorText: errorListTemp[index].errorText,
        });
      }
      errorListTemp = [];

      this.setState({
        inputTextError,
        isCSVLoaded: false,
      });
    }
  };

  saveIngredientItem = (itemData) => {
    //
    // save ingredient changes in array
    //
    //console.log("saveIngredientItem itemData: ", itemData);

    const { productIdCheck, uomIdCheck } = this.state;

    let inputTextError = [];

    // product dropdown
    if (typeof itemData.product !== "undefined") {
      if (typeof itemData.product.id !== "undefined") {
        itemData.productid1 = itemData.product.id;
        itemData.producttext = itemData.product.label;
      } else {
      }
    }

    // uom dropdown
    if (typeof itemData.uom !== "undefined") {
      if (typeof itemData.uom.id !== "undefined") {
        itemData.uomid = itemData.uom.id;
        itemData.uomtext = itemData.uom.label;
      } else {
      }
    }

    // remove leading zero's and replace . by ,
    //itemData.quantity = (itemData.quantity * 1).toString().replace(/[.]+/, ",");

    inputTextError = validateIngredient(
      itemData.order,
      itemData.productid1,
      itemData.quantity,
      productIdCheck,
      inputTextError,
      itemData.uomid,
      uomIdCheck
    );

    ////////console.log("inputTextError: ", inputTextError);

    if (inputTextError.length === 0) {
      // save changes
      // update ingredientList2
      const ingredientList2 = [...this.state.ingredientList2];

      // get index
      const index = ingredientList2
        .map(function (element) {
          return element.subtaskid2;
        })
        .indexOf(itemData.subtaskid2);

      //console.log("saveIngredientItem - index - ingredientList2: ", index);

      ingredientList2[index] = { ...ingredientList2[index] };
      ingredientList2[index].order = itemData.order;
      ingredientList2[index].productid1 = itemData.productid1;
      ingredientList2[index].producttext = itemData.producttext;
      ingredientList2[index].quantity = itemData.quantity;
      ingredientList2[index].uomid = itemData.uomid;
      ingredientList2[index].uomtext = itemData.uom;

      // refresh ingredientList3
      const ingredientList3 = this.refreshIngredientList3(ingredientList2);

      this.setState({
        inputTextError: undefined,
        ingredientList2,
        ingredientList3,
        isCSVLoaded: true,
        isShowSave: true,
      });
    } else {
      //show error list
      let errorListTemp = inputTextError;
      inputTextError = [];
      for (let index = 0; index < errorListTemp.length; index++) {
        inputTextError.push({
          key: index,
          errorText: errorListTemp[index].errorText,
        });
      }
      errorListTemp = [];

      this.setState({
        inputTextError,
        isCSVLoaded: false,
      });
    }
  };

  handleCancelItem = () => {
    //
    // Refresh
    //

    //console.log("handleCancelItem: ", this.state.isUploadMP4);
    this.setState({ isUploadMP4: false });
  };
  handleNewItem = async (item) => {
    //
    // insert movie url
    //

    const { session } = this.props;

    let inputTextError = [];
    this.setState({ isUploadMP4: false });

    //////console.log("handleNewItem: ", item);

    const result = await getMovie.updateStepMP4(
      session,
      item.subtaskid3,
      item.url,
      "extern",
      ""
    );

    if (result.jsonReturn[0].errorcode === noError) {
      this.setState({ inputTextError: undefined });
    } else {
      // error
      inputTextError.push({
        key: 1,
        errorText:
          result.jsonReturn[0].errorcode + result.jsonReturn[0].errormessage,
      });

      this.setState({
        inputTextError,
      });
    }
  };
}

export default ChangeSteps;
