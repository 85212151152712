//
// Purpose: SAMB - material table component
// Author: Ronny Van Elewyck
// Version: 1.1
// Creation Date: 04.06.2020
// Modification Date: 12.02.2021
//

// installed components
import React from "react";

// created components
import MaterialRow from "./materialRow";

const MaterialTable = ({
  materialList,
  changeItem,
  copyItem,
  deleteItem,
  allergenList,
  uomList,
  changeImage,
  showWhereUsed,
  isAdmin,
}) => {
  return (
    <div className="table-responsive">
      <table className="table table-bordered">
        <thead className="thead-light">
          <tr>
            <th></th>
            {isAdmin && <th></th>}
            {isAdmin && <th></th>}
            {isAdmin && <th></th>}
            <th></th>
          </tr>
        </thead>
        <tbody>
          {materialList.map((item) => (
            <MaterialRow
              key={item.productid1}
              item={item}
              changeItem={changeItem}
              copyItem={copyItem}
              deleteItem={deleteItem}
              allergenList={allergenList}
              uomList={uomList}
              changeImage={changeImage}
              showWhereUsed={showWhereUsed}
              isAdmin={isAdmin}
            />
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default MaterialTable;
