//
// Purpose: SAMB - new material
// Author: Ronny Van Elewyck
// Version: 1.0
// Creation Date: 15.07.2020
// Modification Date: 14.10.2020
//

// installed components
import React from "react";
import { Typeahead } from "react-bootstrap-typeahead";
import "react-bootstrap-typeahead/css/Typeahead.css";
import Tooltip from "react-bootstrap/Tooltip";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";

// created components

// config files
import {
  saveClass5,
  cancelClass5,
  productMessage6,
  productMessage7,
  classErrorMessage,
} from "../../config.json";

const NewMaterialForm = ({ newItem, cancelItem, uomList, allergenList }) => {
  //
  // format row of table
  //

  const typeList = [];

  typeList.push({
    id: 1,
    label: "algemeen",
  });
  typeList.push({
    id: 2,
    label: "keukenmateriaal",
  });
  typeList.push({
    id: 3,
    label: "ingredienten",
  });
  typeList.push({
    id: 4,
    label: "materiaal",
  });

  let title = "Nieuw materiaal aanmaken";

  const [producttext, setValue1] = React.useState("");
  const [uomid, setValue2] = React.useState(0);
  const [producttype, setValue3] = React.useState(0);

  const [allergen1, setValue10] = React.useState("");
  const [allergen2, setValue11] = React.useState("");
  const [allergen3, setValue12] = React.useState("");
  const [allergen4, setValue13] = React.useState("");
  const [allergen5, setValue14] = React.useState("");
  const [allergen6, setValue15] = React.useState("");
  const [allergen7, setValue16] = React.useState("");
  const [allergen8, setValue17] = React.useState("");
  const [allergen9, setValue18] = React.useState("");
  const [allergen10, setValue19] = React.useState("");
  const [allergen11, setValue20] = React.useState("");
  const [allergen12, setValue21] = React.useState("");
  const [allergen13, setValue22] = React.useState("");
  const [allergen14, setValue23] = React.useState("");
  const [allergen15, setValue24] = React.useState("");

  const sizeLine = 40;

  const [showError1, setShowError1] = React.useState("");
  const [isError1, setError1] = React.useState(false);
  const [showError2, setShowError2] = React.useState("");
  const [isError2, setError2] = React.useState(false);

  React.useEffect(() => {
    // check uppercase first letter
    const regex = /^[A-Z]/;
    const found = producttext.match(regex);

    if (found === null || producttext.length < 2 || producttext.length > 40) {
      setError1(true);
      setShowError1(productMessage6);
    } else {
      setError1(false);
      setShowError1("");
    }
  }, [producttext]);

  React.useEffect(() => {
    if (
      typeof producttype !== "undefined" &&
      typeof producttype.id !== "undefined"
    ) {
      setError2(false);
      setShowError2("");
    } else {
      setError2(true);
      setShowError2(productMessage7);
    }
  }, [producttype]);

  const addLineBreaks = (string) =>
    string.split("\n").map((text, index) => (
      <React.Fragment key={`${text}-${index}`}>
        {text}
        <br />
      </React.Fragment>
    ));

  function renderTooltip(title) {
    return <Tooltip id="button-tooltip">{title}</Tooltip>;
  }

  return (
    <div>
      <div>
        <OverlayTrigger
          placement="left"
          delay={{ show: 250, hide: 400 }}
          overlay={renderTooltip("terug")}
        >
          <button
            className={cancelClass5}
            onClick={() => cancelItem()}
          ></button>
        </OverlayTrigger>
        {!isError1 && !isError2 && (
          <OverlayTrigger
            placement="left"
            delay={{ show: 250, hide: 400 }}
            overlay={renderTooltip("bewaar")}
          >
            <button
              className={saveClass5}
              onClick={() =>
                newItem({
                  producttext,
                  uomid,
                  producttype,
                  allergen1,
                  allergen2,
                  allergen3,
                  allergen4,
                  allergen5,
                  allergen6,
                  allergen7,
                  allergen8,
                  allergen9,
                  allergen10,
                  allergen11,
                  allergen12,
                  allergen13,
                  allergen14,
                  allergen15,
                })
              }
            ></button>
          </OverlayTrigger>
        )}
      </div>

      <div className="table-responsive">
        <table className="table table-bordered">
          <tbody>
            <tr>
              <td>
                <h4>
                  <span className="badge badge-info d-flex justify-content-center">
                    {title}
                  </span>
                </h4>
              </td>
            </tr>
            <tr>
              <td>
                <div>
                  <b className="mt-1 mb-1">type: </b>
                  {isError2 && (
                    <div className={classErrorMessage}>{showError2}</div>
                  )}
                  <Typeahead
                    id={"type"}
                    labelKey={"label"}
                    options={typeList}
                    onChange={(selectedOptions) =>
                      setValue3(
                        typeof selectedOptions[0] === "undefined"
                          ? 0
                          : selectedOptions[0]
                      )
                    }
                    highlightOnlyResult={true}
                    clearButton={true}
                  />
                </div>
                <br></br>
                <b className="mt-1 mb-1">omschrijving: </b>
                {isError1 && (
                  <div className={classErrorMessage}>{showError1}</div>
                )}
                <input
                  type="text"
                  className="form-control"
                  value={producttext}
                  onChange={(e) => setValue1(e.currentTarget.value)}
                  maxLength={sizeLine}
                />
                <br></br>
                <b className="mt-1 mb-1">eenheid: </b>
                <Typeahead
                  id={"uom"}
                  labelKey={"label"}
                  options={uomList}
                  onChange={(selectedOptions) =>
                    setValue2(
                      typeof selectedOptions[0] === "undefined"
                        ? 0
                        : selectedOptions[0]
                    )
                  }
                  highlightOnlyResult={true}
                  clearButton={true}
                />
                <br></br>
                {producttype.id === 3 && (
                  <div>
                    <b>allergenen: </b>
                    <Typeahead
                      id={"allergen"}
                      labelKey={"label"}
                      options={allergenList}
                      placeholder={allergen1}
                      onChange={(selectedOptions) =>
                        setValue10(selectedOptions[0])
                      }
                      highlightOnlyResult={true}
                      clearButton={true}
                    />
                    <Typeahead
                      id={"allergen"}
                      labelKey={"label"}
                      options={allergenList}
                      placeholder={allergen2}
                      onChange={(selectedOptions) =>
                        setValue11(selectedOptions[0])
                      }
                      highlightOnlyResult={true}
                      clearButton={true}
                    />
                    <Typeahead
                      id={"allergen"}
                      labelKey={"label"}
                      options={allergenList}
                      placeholder={allergen3}
                      onChange={(selectedOptions) =>
                        setValue12(selectedOptions[0])
                      }
                      highlightOnlyResult={true}
                      clearButton={true}
                    />
                    <Typeahead
                      id={"allergen"}
                      labelKey={"label"}
                      options={allergenList}
                      placeholder={allergen4}
                      onChange={(selectedOptions) =>
                        setValue13(selectedOptions[0])
                      }
                      highlightOnlyResult={true}
                      clearButton={true}
                    />
                    <Typeahead
                      id={"allergen"}
                      labelKey={"label"}
                      options={allergenList}
                      placeholder={allergen5}
                      onChange={(selectedOptions) =>
                        setValue14(selectedOptions[0])
                      }
                      highlightOnlyResult={true}
                      clearButton={true}
                    />
                    <Typeahead
                      id={"allergen"}
                      labelKey={"label"}
                      options={allergenList}
                      placeholder={allergen6}
                      onChange={(selectedOptions) =>
                        setValue15(selectedOptions[0])
                      }
                      highlightOnlyResult={true}
                      clearButton={true}
                    />
                    <Typeahead
                      id={"allergen"}
                      labelKey={"label"}
                      options={allergenList}
                      placeholder={allergen7}
                      onChange={(selectedOptions) =>
                        setValue16(selectedOptions[0])
                      }
                      highlightOnlyResult={true}
                      clearButton={true}
                    />
                    <Typeahead
                      id={"allergen"}
                      labelKey={"label"}
                      options={allergenList}
                      placeholder={allergen8}
                      onChange={(selectedOptions) =>
                        setValue17(selectedOptions[0])
                      }
                      highlightOnlyResult={true}
                      clearButton={true}
                    />
                    <Typeahead
                      id={"allergen"}
                      labelKey={"label"}
                      options={allergenList}
                      placeholder={allergen9}
                      onChange={(selectedOptions) =>
                        setValue18(selectedOptions[0])
                      }
                      highlightOnlyResult={true}
                      clearButton={true}
                    />
                    <Typeahead
                      id={"allergen"}
                      labelKey={"label"}
                      options={allergenList}
                      placeholder={allergen10}
                      onChange={(selectedOptions) =>
                        setValue19(selectedOptions[0])
                      }
                      highlightOnlyResult={true}
                      clearButton={true}
                    />
                    <Typeahead
                      id={"allergen"}
                      labelKey={"label"}
                      options={allergenList}
                      placeholder={allergen11}
                      onChange={(selectedOptions) =>
                        setValue20(selectedOptions[0])
                      }
                      highlightOnlyResult={true}
                      clearButton={true}
                    />
                    <Typeahead
                      id={"allergen"}
                      labelKey={"label"}
                      options={allergenList}
                      placeholder={allergen12}
                      onChange={(selectedOptions) =>
                        setValue21(selectedOptions[0])
                      }
                      highlightOnlyResult={true}
                      clearButton={true}
                    />
                    <Typeahead
                      id={"allergen"}
                      labelKey={"label"}
                      options={allergenList}
                      placeholder={allergen13}
                      onChange={(selectedOptions) =>
                        setValue22(selectedOptions[0])
                      }
                      highlightOnlyResult={true}
                      clearButton={true}
                    />
                    <Typeahead
                      id={"allergen"}
                      labelKey={"label"}
                      options={allergenList}
                      placeholder={allergen14}
                      onChange={(selectedOptions) =>
                        setValue23(selectedOptions[0])
                      }
                      highlightOnlyResult={true}
                      clearButton={true}
                    />
                    <Typeahead
                      id={"allergen"}
                      labelKey={"label"}
                      options={allergenList}
                      placeholder={allergen15}
                      onChange={(selectedOptions) =>
                        setValue24(selectedOptions[0])
                      }
                      highlightOnlyResult={true}
                      clearButton={true}
                    />
                  </div>
                )}
                <h1>{addLineBreaks("")}</h1>
                <h1>{addLineBreaks("")}</h1>
                <h1>{addLineBreaks("")}</h1>
                <h1>{addLineBreaks("")}</h1>
                <h1>{addLineBreaks("")}</h1>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default NewMaterialForm;
