//
// Purpose: SAMB - overview task table component
// Author: Ronny Van Elewyck
// Version: 1.0
// Creation Date: 04.06.2020
// Modification Date: 22.10.2020
//

// installed components
import React from "react";

// created components
import OverviewTaskRow from "./overviewTaskRow";

const OverviewTaskTable = ({ taskList }) => {
  return (
    <div className="table-responsive">
      <table className="table table-bordered">
        <tbody>
          {taskList.map((item) => (
            <OverviewTaskRow key={item._id} item={item} />
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default OverviewTaskTable;
