//
// Purpose: SAMB - new uom
// Author: Ronny Van Elewyck
// Version: 1.0
// Creation Date: 17.07.2020
// Modification Date: 14.10.2020
//

// installed components
import React from "react";
import Tooltip from "react-bootstrap/Tooltip";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";

// created components

// config files
import { saveClass5, cancelClass5 } from "../../config.json";

const NewUOMForm = ({ newItem, cancelItem }) => {
  //
  // format row of table
  //

  let title = "Nieuwe eenheid aanmaken";

  const [uom, setValue1] = React.useState("");
  const [uomlong, setValue2] = React.useState("");

  let sizeLine1 = 16;
  let sizeLine2 = 32;

  const addLineBreaks = (string) =>
    string.split("\n").map((text, index) => (
      <React.Fragment key={`${text}-${index}`}>
        {text}
        <br />
      </React.Fragment>
    ));

  function renderTooltip(title) {
    return <Tooltip id="button-tooltip">{title}</Tooltip>;
  }

  return (
    <div>
      <div>
        <OverlayTrigger
          placement="left"
          delay={{ show: 250, hide: 400 }}
          overlay={renderTooltip("terug")}
        >
          <button
            className={cancelClass5}
            onClick={() => cancelItem()}
          ></button>
        </OverlayTrigger>
        <OverlayTrigger
          placement="left"
          delay={{ show: 250, hide: 400 }}
          overlay={renderTooltip("bewaar")}
        >
          <button
            className={saveClass5}
            onClick={() =>
              newItem({
                uom,
                uomlong,
              })
            }
          ></button>
        </OverlayTrigger>
      </div>

      <div className="table-responsive">
        <table className="table table-bordered">
          <tbody>
            <tr>
              <td>
                <h4>
                  <span className="badge badge-info d-flex justify-content-center">
                    {title}
                  </span>
                </h4>
              </td>
            </tr>
            <tr>
              <td>
                <div>
                  <b>omschrijving: </b>
                  <input
                    type="text"
                    className="form-control"
                    value={uom}
                    onChange={(e) => setValue1(e.currentTarget.value)}
                    maxLength={sizeLine1}
                  />
                </div>
                <div>
                  <b>lange omschrijving: </b>
                  <input
                    type="text"
                    className="form-control"
                    value={uomlong}
                    onChange={(e) => setValue2(e.currentTarget.value)}
                    maxLength={sizeLine2}
                  />
                </div>
                <h1>{addLineBreaks("")}</h1>
                <h1>{addLineBreaks("")}</h1>
                <h1>{addLineBreaks("")}</h1>
                <h1>{addLineBreaks("")}</h1>
                <h1>{addLineBreaks("")}</h1>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default NewUOMForm;
