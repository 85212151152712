//
// Purpose: SAMB - node compare row
// Author: Ronny Van Elewyck
// Version: 1.0
// Creation Date: 10.09.2020
// Modification Date: 10.09.2020
//

// installed components
import React from "react";

const NodeRow = ({ item }) => {
  //
  // format row of table
  //

  return (
    <tr>
      <td>{item.text}</td>
    </tr>
  );
};

export default NodeRow;
