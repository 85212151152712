//
// Purpose: SAMB - change special characters
// Author: Ronny Van Elewyck
// Version: 1.0
// Creation Date: 15.05.2020
// Modification Date: 19.08.2020
//

export function checkCharacters(inputString) {
  //
  // replace special characters
  //
  let outputString = "";

  if (typeof inputString !== "undefined" && inputString !== "") {
    outputString = inputString.replace(/%/g, "ppppp1");
    outputString = outputString.replace(/\r/g, "%0D");
    outputString = outputString.replace(/\n/g, "%0A");
    outputString = outputString.replace(/ç/g, "ccccc1");
    outputString = outputString.replace(/é/g, "eeeee1");
    outputString = outputString.replace(/è/g, "eeeee2");
    outputString = outputString.replace(/ë/g, "eeeee3");
    outputString = outputString.replace(/°/g, "ggggg1");
    outputString = outputString.replace(/-/g, "sssss1");
    outputString = outputString.replace(/&/g, "aaaaa1");
    outputString = outputString.replace(/\+/g, "lllll1");
    outputString = outputString.replace(/#/g, "mmmmm1");
  }

  return outputString;
}
