//
// Purpose: SAMB - upload csv
// Author: Ronny Van Elewyck
// Version: 1.0
// Creation Date: 28.05.2020
// Modification Date: 21.07.2020
//

// installed components
import React from "react";
import Tooltip from "react-bootstrap/Tooltip";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";

const UploadCSV = ({ csvfileReplace, className, onChange }) => {
  function renderTooltip(title) {
    return <Tooltip id="button-tooltip">{title}</Tooltip>;
  }

  return (
    <React.Fragment>
      <input
        className="csv-file"
        //type="hidden file"
        type="file"
        style={{ display: "none" }}
        name="file"
        placeholder={null}
        value={csvfileReplace}
        onChange={onChange}
        id="linkToButton"
      />

      <OverlayTrigger
        placement="left"
        delay={{ show: 250, hide: 400 }}
        overlay={renderTooltip("opladen")}
      >
        <label htmlFor="linkToButton">
          <h6>
            <span className={className}></span>
          </h6>
        </label>
      </OverlayTrigger>
    </React.Fragment>
  );
};

export default UploadCSV;
