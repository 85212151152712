//
// Purpose: SAMB - upload image
// Author: Ronny Van Elewyck
// Version: 1.0
// Creation Date: 12.06.2020
// Modification Date: 12.06.2020
//

// installed components
import React from "react";

const UploadImage = ({ file, className, onChange }) => {
  return (
    <React.Fragment>
      <input
        //className="csv-file"
        //type="hidden file"
        accept="image/*"
        type="file"
        style={{ display: "none" }}
        name="file"
        placeholder={null}
        value={file}
        onChange={onChange}
        id="linkToButton"
      />
      <label htmlFor="linkToButton">
        <h6>
          <span className={className}></span>
        </h6>
      </label>
    </React.Fragment>
  );
};

export default UploadImage;
