//
// Purpose: SAMB - new Course
// Author: Ronny Van Elewyck
// Version: 1.0
// Creation Date: 17.07.2020
// Modification Date: 15.10.2020
//

// installed components
import React from "react";
import DatePicker from "react-date-picker";
import { Typeahead } from "react-bootstrap-typeahead";
import Tooltip from "react-bootstrap/Tooltip";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";

// created components

// config files
import {
  saveClass5,
  cancelClass5,
  classErrorMessage,
  courseMessage2,
  courseMessage3,
  courseMessage4,
  courseMessage5,
  courseMessage6,
} from "../../config.json";

const NewCourseForm = ({
  newItem,
  cancelItem,
  updateRecord,
  locale,
  maxDate,
  minDate,
  isAdmin,
  trainerList,
}) => {
  //
  // format row of table
  //

  const lineSize1 = 5;

  let title = "";

  const courseid = updateRecord.courseid;
  if (courseid === "0" || courseid === "") {
    title =
      "Nieuwe les " +
      updateRecord.mastercoursetext +
      " voor " +
      updateRecord.grouptext;
  } else {
    title =
      "Aanpassen les " +
      updateRecord.mastercoursetext +
      " voor " +
      updateRecord.grouptext;
  }

  const grouptext = updateRecord.grouptext;
  const mastercoursetext = updateRecord.mastercoursetext;

  const [newdate, setValue3] = React.useState(updateRecord.courseDate);
  const [fromtime, setValue4] = React.useState(updateRecord.courseTimeBlock1);
  const [totime, setValue5] = React.useState(updateRecord.courseTimeBlock2);
  const [showError1, setShowError1] = React.useState("");
  const [isError1, setError1] = React.useState(false);
  const [showError2, setShowError2] = React.useState("");
  const [isError2, setError2] = React.useState(false);
  const [showError3, setShowError3] = React.useState("");
  const [isError3, setError3] = React.useState(false);
  const [showError4, setShowError4] = React.useState("");
  const [isError4, setError4] = React.useState(false);
  const [showError5, setShowError5] = React.useState("");
  const [isError5, setError5] = React.useState(false);
  const [trainer, setValue10] = React.useState(updateRecord.teachername);

  React.useEffect(() => {
    const regex = /^([0-9]|0[0-9]|1[0-9]|2[0-3]):[0-5][0-9]$/;
    const found = fromtime.match(regex);
    if (found === null) {
      setError1(true);
      setShowError1(courseMessage2);
    } else {
      setError1(false);
      setShowError1("");
    }
    const fromTimeInt = fromtime.split(":");
    const fromInt = parseInt(fromTimeInt[0]) * 60 + parseInt(fromTimeInt[1]);
    const toTimeInt = totime.split(":");
    const toInt = parseInt(toTimeInt[0]) * 60 + parseInt(toTimeInt[1]);

    if (fromInt > toInt) {
      setError4(true);
      setShowError4(courseMessage3);
      setError5(false);
      setShowError5("");
    } else {
      setError4(false);
      setShowError4("");
    }
  }, [fromtime, totime]);

  React.useEffect(() => {
    const regex = /^([0-9]|0[0-9]|1[0-9]|2[0-3]):[0-5][0-9]$/;
    const found = totime.match(regex);
    if (found === null) {
      setError2(true);
      setShowError2(courseMessage4);
    } else {
      setError2(false);
      setShowError2("");
    }

    const fromTimeInt = fromtime.split(":");
    const fromInt = parseInt(fromTimeInt[0]) * 60 + parseInt(fromTimeInt[1]);
    const toTimeInt = totime.split(":");
    const toInt = parseInt(toTimeInt[0]) * 60 + parseInt(toTimeInt[1]);

    if (toInt < fromInt) {
      setError5(true);
      setShowError5(courseMessage5);
      setError4(false);
      setShowError4("");
    } else {
      setError5(false);
      setShowError5("");
    }
  }, [totime, fromtime]);

  React.useEffect(() => {
    const today = new Date();
    today.setDate(today.getDate() - 1);

    if (newdate < today.setDate(today.getDate() - 1)) {
      setError3(true);
      setShowError3(courseMessage6);
    } else {
      setError3(false);
      setShowError3("");
    }
  }, [newdate]);

  const addLineBreaks = (string) =>
    string.split("\n").map((text, index) => (
      <React.Fragment key={`${text}-${index}`}>
        {text}
        <br />
      </React.Fragment>
    ));

  function renderTooltip(title) {
    return <Tooltip id="button-tooltip">{title}</Tooltip>;
  }

  return (
    <div>
      <div>
        <OverlayTrigger
          placement="left"
          delay={{ show: 250, hide: 400 }}
          overlay={renderTooltip("terug")}
        >
          <button
            className={cancelClass5}
            onClick={() => cancelItem()}
          ></button>
        </OverlayTrigger>
        {!isError1 && !isError2 && !isError3 && !isError4 && !isError5 && (
          <OverlayTrigger
            placement="left"
            delay={{ show: 250, hide: 400 }}
            overlay={renderTooltip("bewaar")}
          >
            <button
              className={saveClass5}
              onClick={() =>
                newItem({
                  updateRecord,
                  grouptext,
                  mastercoursetext,
                  newdate,
                  fromtime,
                  totime,
                  courseid,
                  trainer,
                })
              }
            ></button>
          </OverlayTrigger>
        )}
      </div>

      <div className="table-responsive">
        <table className="table table-bordered">
          <tbody>
            <tr>
              <td>
                <h4>
                  <span className="badge badge-info d-flex justify-content-center">
                    {title}
                  </span>
                </h4>
              </td>
            </tr>
            <tr>
              <td>
                <b>klas: </b>
                <h6>{updateRecord.grouptext}</h6>
                <b>vak: </b>
                <h6>{updateRecord.mastercoursetext}</h6>
                <b>datum: </b>
                {isError3 && (
                  <div className={classErrorMessage}>{showError3}</div>
                )}
                <div>
                  <DatePicker
                    className="mt-2 ml-2 mb-2 mr-2"
                    locale={locale}
                    onChange={(newdate) => setValue3(newdate)}
                    value={newdate}
                    maxDate={maxDate}
                    minDate={minDate}
                    clearIcon={null}
                  />
                </div>
                <b>tijdstip: </b>
                {isError1 && (
                  <div className={classErrorMessage}>{showError1}</div>
                )}
                {isError2 && (
                  <div className={classErrorMessage}>{showError2}</div>
                )}

                {isError4 && (
                  <div className={classErrorMessage}>{showError4}</div>
                )}
                {isError5 && (
                  <div className={classErrorMessage}>{showError5}</div>
                )}
                <div>
                  {" "}
                  <input
                    type="text"
                    className="mt-2 ml-2 mb-2 mr-2"
                    value={fromtime}
                    onChange={(e) => setValue4(e.currentTarget.value)}
                    maxLength={lineSize1}
                    size={lineSize1}
                  />
                  <b> tot </b>
                  <input
                    type="text"
                    className="mt-2 ml-2 mb-2 mr-2"
                    value={totime}
                    onChange={(e) => setValue5(e.currentTarget.value)}
                    maxLength={lineSize1}
                    size={lineSize1}
                  />
                </div>
                <b>begeleider: </b>
                <div>
                  {isAdmin && (
                    <Typeahead
                      id={"userid"}
                      labelKey={"label"}
                      options={trainerList}
                      placeholder={trainer}
                      onChange={(selectedOptions) =>
                        setValue10(selectedOptions[0])
                      }
                      highlightOnlyResult={true}
                      clearButton={true}
                    />
                  )}
                  {!isAdmin && <h6>{updateRecord.teachername}</h6>}
                </div>
                <h1>{addLineBreaks("")}</h1>
                <h1>{addLineBreaks("")}</h1>
                <h1>{addLineBreaks("")}</h1>
                <h1>{addLineBreaks("")}</h1>
                <h1>{addLineBreaks("")}</h1>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default NewCourseForm;
