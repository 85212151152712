//
// Purpose: SAMB - user screen
// Author: Ronny Van Elewyck
// Version: 1.0
// Creation Date: 06.04.2020
// Modification Date: 23.10.2020
//

// installed components
import React, { Component } from "react";
import _ from "lodash";

// created components
import NavBar from "./navBar";
import SearchBox from "./common/searchBox";
import UserTable from "./common/userTable";
import TopUserButton from "./common/topUserButton";
import ChangePasswordForm from "./common/changePasswordForm";
import UploadImageForm from "./common/uploadImageForm";
import NewUserForm from "./common/newUserForm";
import DeleteUserForm from "./common/deleteUserForm";
import SameUserForm from "./common/sameUserForm";
import ErrorTable from "./common/errorTable";

// utilities
import { checkUserCreate, validateUserCreate } from "../utils/checkTextInput";
import { createImageRecord } from "../utils/imageHelpers";
import { checkCharacters } from "../utils/specialCharacters";

// rest services
import getUser from "../services/userService";
import putImage from "../services/imageService";

// config files
import {
  noError,
  errorTableTitle1,
  userTitle,
  classImageTitle,
  classTables,
  classTitle,
  userTitle2,
  userTitle3,
  selectionClass,
  classPasswordTitle,
  stepMessage31,
  classWarningMessage,
} from "../config.json";
import {
  prefixImageUser,
  minWidthImageUser,
  maxWidthImageUser,
  minHeightImageUser,
  maxHeightImageUser,
} from "../imageConfig.json";

class Users extends Component {
  //
  // user screen
  //

  // local data
  state = {
    listArray13: [],
    listArray: [],
    allListArray: [],
    downloadListArray: [],
    downloadListArray13: [],
    searchQuery: "",
    isLoaded: false,
    isAdminUser: false,
    isUser: false,
    isChangePassword: false,
    isUploadImage: false,
    isImageSaved: true,
    isNew: false,
    logonToChange: "",
    useridToChange: "",
    userNameToChange: "",
    fileName1: "SAMDOWNLOADBEHEER",
    fileName2: "SAMDOWNLOADLEERLING",
    inputTextError: undefined,
    isCheckOK: true,
    isSame: false,
    deleteRecord: undefined,
    imageRecord: {},
    isAvailable: false,
    isOverrideAvailable: false,
    userRecord: undefined,
  };

  async componentDidMount() {
    //
    // mount after render
    //

    ////////console.log("componentDidMount(): ");

    const imageRecord = createImageRecord();
    this.setState({
      imageRecord,
    });

    await this.refreshUsers();
  }

  handleSearch = (query) => {
    //
    // execute search filter
    //
    let listArray = [];
    const { allListArray } = this.state;

    if (query !== "") {
      listArray = allListArray.filter((m) =>
        m.searchfield.toLowerCase().includes(query.toLowerCase())
      );
    } else {
      listArray = allListArray;
    }

    listArray = _.sortBy(listArray, function (item) {
      return parseInt(item._id);
    });

    this.setState({
      listArray,
      searchQuery: query,
      isUploadImage: false,
    });
  };

  refreshUsers = async () => {
    //
    // Refresh users
    //

    this.setState({
      listArray13: [],
      listArray: [],
      allListArray: [],
      isLoaded: false,
      inputTextError: undefined,
      isUploadImage: false,
    });

    let listArray = [];
    let listArray13 = [];
    let userList = [];
    let downloadListArray13 = [];
    let downloadListArray = [];
    let trainerList = [];
    let trainerList1 = [];
    let trainerList2 = [];

    const { session } = this.props;

    // sam users get

    const jsonList = await getUser.userDetails(session, "");
    const dbreturnList = jsonList.jsonReturn;

    if (dbreturnList[0].errorcode === noError) {
      listArray = _.sortBy(jsonList.userList, function (item) {
        return parseInt(item._id);
      });

      // node users get
      const nodeUsersList = await getUser.userNodeDetails(session);
      const nodeUsers = nodeUsersList["userNodeList"];

      // check return
      if (nodeUsers[0].errorcode === noError) {
        // create combined user array
        const tempArray = listArray;
        listArray = [];

        for (let index = 0; index < tempArray.length; index++) {
          const findMatch = nodeUsers.filter(
            (m) => m.userid === tempArray[index].userid
          );

          ////////console.log("tempArray[index]: ", tempArray[index]);
          ////////console.log("findMatch[0]: ", findMatch[0]);

          if (findMatch.length === 1) {
            if (findMatch[0].roleid === "2") {
              // students
              listArray.push({
                _id: tempArray[index].userid,
                logon: findMatch[0].logon,
                groupid1: tempArray[index].groupid1,
                grouptext1: tempArray[index].grouptext1,
                firstname: tempArray[index].firstname,
                lastname: tempArray[index].lastname,
                roleid: findMatch[0].roleid,
                roletext: findMatch[0].roletext,
                compid: findMatch[0].compid,
                password: findMatch[0].password,
                active: findMatch[0].active,
                logactive: findMatch[0].logactive,
                actdb: findMatch[0].actdb,
                imageurl: tempArray[index].imageurl,
                image: tempArray[index].image,
                attribute1: tempArray[index].attribute1,
                attribute2: tempArray[index].attribute2,
                attribute3: tempArray[index].attribute3,
                attribute4: tempArray[index].attribute4,
                attribute5: tempArray[index].attribute5,
                attribute6: tempArray[index].attribute6,
                attribute7: tempArray[index].attribute7,
                attribute8: tempArray[index].attribute8,
                attribute9: tempArray[index].attribute9,
                attribute10: tempArray[index].attribute10,
                pincode: tempArray[index].pincode,
                searchfield:
                  tempArray[index].grouptext1 +
                  " " +
                  tempArray[index].name +
                  " " +
                  findMatch[0].roletext,
              });
            } else {
              // admin or teachers
              listArray13.push({
                _id: tempArray[index].userid,
                logon: findMatch[0].logon,
                groupid1: tempArray[index].groupid1,
                grouptext1: tempArray[index].grouptext1,
                firstname: tempArray[index].firstname,
                lastname: tempArray[index].lastname,
                roleid: findMatch[0].roleid,
                roletext: findMatch[0].roletext,
                compid: findMatch[0].compid,
                password: findMatch[0].password,
                active: findMatch[0].active,
                logactive: findMatch[0].logactive,
                actdb: findMatch[0].actdb,
                imageurl: tempArray[index].imageurl,
                image: tempArray[index].image,
                attribute1: tempArray[index].attribute1,
                attribute2: tempArray[index].attribute2,
                attribute3: tempArray[index].attribute3,
                attribute4: tempArray[index].attribute4,
                attribute5: tempArray[index].attribute5,
                attribute6: tempArray[index].attribute6,
                attribute7: tempArray[index].attribute7,
                attribute8: tempArray[index].attribute8,
                attribute9: tempArray[index].attribute9,
                attribute10: tempArray[index].attribute10,
                pincode: tempArray[index].pincode,
                searchfield:
                  tempArray[index].grouptext1 +
                  " " +
                  tempArray[index].name +
                  " " +
                  findMatch[0].roletext,
              });
            }
          }
        }
        listArray = _.sortBy(listArray, function (item) {
          return parseInt(item._id);
        });
        listArray13 = _.sortBy(listArray13, function (item) {
          return parseInt(item._id);
        });

        // create download arrays
        for (let index = 0; index < listArray13.length; index++) {
          downloadListArray13.push({
            id: listArray13[index]._id,
            login: listArray13[index].logon,
            voornaam: listArray13[index].firstname,
            achternaam: listArray13[index].lastname,
            rol: listArray13[index].roletext,
            databank: listArray13[index].actdb,
            pincode: listArray13[index].pincode,
          });
        }
        for (let index = 0; index < listArray.length; index++) {
          downloadListArray.push({
            id: listArray[index]._id,
            login: listArray[index].logon,
            klas: listArray[index].grouptext1,
            voornaam: listArray[index].firstname,
            achternaam: listArray[index].lastname,
            rol: listArray[index].roletext,
            logging_aktief: listArray[index].logactive,
            databank: listArray[index].actdb,
            pincode_controle: listArray[index].attribute1,
            fotohulp: listArray[index].attribute2,
            regelvan3: listArray[index].attribute3,
            rekenmachine: listArray[index].attribute4,
            tussenniveau: listArray[index].attribute5,
          });
        }
        this.setState({
          downloadListArray13,
          downloadListArray,
          listArray13,
          listArray,
          allListArray: listArray,
          isLoaded: true,
          inputTextError: undefined,
        });

        // users get
        const result1 = await getUser.userDetails(session, 2);
        if (result1.jsonReturn[0].errorcode === noError) {
          userList = result1.userList;
        }
        this.props.onSaveUserList(userList);

        // get trainers
        // trainer list
        const result11 = await getUser.userDetails(session, 3);
        if (result11.jsonReturn[0].errorcode === noError) {
          trainerList1 = result11.userList;
        }
        const result12 = await getUser.userDetails(session, 1);
        if (result12.jsonReturn[0].errorcode === noError) {
          trainerList2 = result12.userList;
        }
        for (let index = 0; index < trainerList1.length; index++) {
          trainerList.push({
            id: trainerList1[index].userid,
            label: trainerList1[index].name,
          });
        }
        for (let index = 0; index < trainerList2.length; index++) {
          trainerList.push({
            id: trainerList2[index].userid,
            label: trainerList2[index].name,
          });
        }
        this.props.onSaveTrainerList(trainerList);
      } else {
        this.setState({
          listArray13: [],
          listArray: [],
          allListArray: [],
          isLoaded: false,
          inputTextError: undefined,
        });
      }
    } else {
      this.setState({
        listArray13: [],
        listArray: [],
        allListArray: [],
        isLoaded: false,
        inputTextError: undefined,
      });
    }
  };

  handleRefresh = async () => {
    //
    // Refresh
    //
    //////console.log("handleRefresh");

    await this.refreshUsers();
  };

  handleNew = () => {
    //
    // new
    //
    ////console.log("handleNew");

    this.scrollTop();

    this.setState({
      isChangePassword: false,
      isUploadImage: false,
      logonToChange: "",
      useridToChange: "",
      userNameToChange: "",
      isNew: true,
      isDelete: false,
    });
  };

  handleNewItem = async (itemData, isOverrideAvailable) => {
    //
    // Refresh
    //
    ////console.log("handleNewItem", itemData);

    let groupid1 = "0";
    let roleid = "2";
    let inputTextError = [];
    let uploadList = [];
    let errorInsert = false;
    let isAvailable = false;

    const { session } = this.props;
    const { isUser } = this.state;

    // validate input
    if (isUser) {
      roleid = 2;
      // group dropdown
      if (
        typeof itemData.grouptext1 !== "undefined" &&
        typeof itemData.grouptext1.id !== "undefined" &&
        typeof itemData.grouptext1.label !== "undefined"
      ) {
        groupid1 = itemData.grouptext1.id;
      } else {
        groupid1 = "0";
      }
    } else {
      // groupid1 = 2
      groupid1 = "1";
      roleid = "3";
    }

    // automatic uppercase of first character
    itemData.firstname =
      itemData.firstname[0].toUpperCase() + itemData.firstname.slice(1);
    itemData.lastname =
      itemData.lastname[0].toUpperCase() + itemData.lastname.slice(1);

    if (!isOverrideAvailable) {
      // check user is already created
      isAvailable = this.checkUserExist(
        itemData.firstname,
        itemData.lastname,
        itemData._id
      );
    }

    // console.log("isAvailable: ", isAvailable);
    if (isAvailable && !isOverrideAvailable) {
      this.scrollTop();
      this.setState({
        isAvailable,
        userRecord: itemData,
      });
    } else {
      uploadList.push({
        firstname: itemData.firstname,
        lastname: itemData.lastname,
        groupid1: groupid1,
      });
      const result = checkUserCreate(uploadList);
      inputTextError = result.errorList;

      if (inputTextError.length === 0) {
        const jsonList = await getUser.createUser(
          session,
          roleid,
          groupid1,
          itemData.firstname,
          itemData.lastname,
          "X",
          "prd"
        );
        const dbreturnList = jsonList.jsonReturn;
        ////console.log(dbreturnList);
        for (let index3 = 0; index3 < dbreturnList.length; index3++) {
          ////console.log(dbreturnList[index3].errorcode, noError);
          if (dbreturnList[index3].errorcode !== noError) {
            errorInsert = true;
          }
        }

        //////console.log("errorInsert: ", errorInsert);

        if (!errorInsert) {
          await this.refreshUsers();
          // error check ok
          this.setState({
            inputTextError: undefined,
            isCheckOK: true,
            isChangePassword: false,
            isUploadImage: false,
            logonToChange: "",
            useridToChange: "",
            userNameToChange: "",
            isNew: false,
            isDelete: false,
            isAvailable: false,
            isOverrideAvailable: false,
            userRecord: undefined,
          });
        } else {
          this.scrollTop();
          for (let index2 = 0; index2 < dbreturnList.length; index2++) {
            if (dbreturnList[index2].errorcode === "E96") {
              inputTextError.push({
                key: index2,
                errorText:
                  "Er zijn geen licenties meer beschikbaar voor SAM. Gelieve de beheerder van jouw instelling te contacteren.",
              });
            }
          }
          this.setState({
            inputTextError,
            isCheckOK: false,
            isChangePassword: false,
            isUploadImage: false,
            logonToChange: "",
            useridToChange: "",
            userNameToChange: "",
            isNew: true,
            isDelete: false,
            isAvailable: false,
            isOverrideAvailable: false,
            userRecord: undefined,
          });
        }
      } else {
        this.scrollTop();

        this.setState({
          inputTextError,
          isCheckOK: false,
          isChangePassword: false,
          isUploadImage: false,
          logonToChange: "",
          useridToChange: "",
          userNameToChange: "",
          isNew: true,
          isDelete: false,
          isAvailable: false,
          isOverrideAvailable: false,
          userRecord: undefined,
        });
      }
    }
  };

  handleCancelItem = () => {
    //
    // Refresh
    //

    this.setState({
      inputTextError: undefined,
      isChangePassword: false,
      isUploadImage: false,
      logonToChange: "",
      useridToChange: "",
      userNameToChange: "",
      isNew: false,
      isDelete: false,
    });
  };

  handleConfirmSameUser = async () => {
    //
    // allow create same user
    //
    this.setState({
      isOverrideAvailable: true,
    });
    if (this.state.isNew) {
      await this.handleNewItem(this.state.userRecord, true);
    } else {
      await this.changeItem(this.state.userRecord, true);
    }
  };
  handleCancelConfirmSameUser = () => {
    //
    // don't create same user
    //
    this.setState({
      isOverrideAvailable: false,
      isAvailable: false,
    });
  };

  handleAdminUser = () => {
    //
    // show administrators and teachers
    //
    if (this.state.isAdminUser) {
      this.setState({
        inputTextError: undefined,
        isAdminUser: false,
        isUser: false,
        isChangePassword: false,
        isUploadImage: false,
        logonToChange: "",
        useridToChange: "",
        userNameToChange: "",
        isNew: false,
        isDelete: false,
      });
    } else {
      this.setState({
        inputTextError: undefined,
        isAdminUser: true,
        isUser: false,
        isChangePassword: false,
        isUploadImage: false,
        logonToChange: "",
        useridToChange: "",
        userNameToChange: "",
        isNew: false,
        isDelete: false,
      });
    }
  };

  handleUser = () => {
    //
    // show students
    //

    if (this.state.isUser) {
      this.setState({
        inputTextError: undefined,
        isAdminUser: false,
        isUser: false,
        isChangePassword: false,
        isUploadImage: false,
        logonToChange: "",
        useridToChange: "",
        userNameToChange: "",
        isNew: false,
        isDelete: false,
      });
    } else {
      this.setState({
        inputTextError: undefined,
        isAdminUser: false,
        isUser: true,
        isChangePassword: false,
        isUploadImage: false,
        logonToChange: "",
        useridToChange: "",
        userNameToChange: "",
        isNew: false,
        isDelete: false,
      });
    }
  };

  checkUserExist = (firstname, lastname, userid) => {
    //
    // check if username is already available
    //

    let isAvailable = false;

    const { allListArray } = this.state;

    //console.log(allListArray);
    const userIndex = allListArray
      .map(function (element) {
        return element.lastname;
      })
      .indexOf(lastname);

    if (userIndex === -1) {
      isAvailable = false;
    } else {
      if (
        allListArray[userIndex].firstname === firstname &&
        allListArray[userIndex]._id !== userid
      ) {
        isAvailable = true;
      } else {
        isAvailable = false;
      }
    }
    return isAvailable;
  };

  scrollTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  render() {
    //
    // render task screen
    //

    ////////console.log("render(): ");

    // get local state variables
    const {
      listArray13,
      listArray,
      downloadListArray,
      downloadListArray13,
      isLoaded,
      isAdminUser,
      isUser,
      searchQuery,
      isChangePassword,
      isUploadImage,
      isImageSaved,
      logonToChange,
      useridToChange,
      userNameToChange,
      fileName1,
      fileName2,
      isNew,
      inputTextError,
      isDelete,
      deleteRecord,
      imageRecord,
      isAvailable,
      isOverrideAvailable,
      userRecord,
    } = this.state;
    const { session, isAdmin, groupList } = this.props;
    ////console.log("render isDelete: ", isDelete);

    return (
      <React.Fragment>
        {/* error messages */}
        {typeof inputTextError !== "undefined" && (
          <ErrorTable
            inputTextError={inputTextError}
            title={errorTableTitle1}
          />
        )}

        {!isImageSaved && (
          <div className={classWarningMessage}>{stepMessage31}</div>
        )}

        {/* navigation bar */}
        <NavBar />

        {/* title */}
        <h4>
          <span className={classTitle}>{userTitle}</span>
        </h4>

        {/* change password */}
        {isChangePassword && (
          <div className={classPasswordTitle}>
            <ChangePasswordForm
              session={session}
              logon={logonToChange}
              userid={useridToChange}
              name={userNameToChange}
            />
          </div>
        )}
        {/* upload image */}
        {isUploadImage && (
          <div className={classImageTitle}>
            <UploadImageForm
              session={session}
              imageRecord={imageRecord}
              handleImageSave={this.handleImageSave}
              cancelImageUpload={this.cancelImageUpload}
            />
          </div>
        )}

        {/* new user */}
        {isNew && (
          <div className={classTables}>
            <NewUserForm
              newItem={this.handleNewItem}
              cancelItem={this.handleCancelItem}
              groupList={groupList}
              isUser={isUser}
              isAdminUser={isAdminUser}
            />
          </div>
        )}

        {/* confirm same user create */}
        {isAvailable && !isOverrideAvailable && (
          <div className={classTables}>
            <SameUserForm
              confirmSameUser={this.handleConfirmSameUser}
              cancelSameUser={this.handleCancelConfirmSameUser}
              item={userRecord}
            />
          </div>
        )}

        {/* confirm user delete */}
        {isDelete && (
          <div className={classTables}>
            <DeleteUserForm
              confirmDeleteItem={this.handleConfirmDeleteItem}
              cancelItem={this.handleCancelItem}
              item={deleteRecord}
            />
          </div>
        )}

        {/* table admin and teachers */}
        {isAdmin && (
          <div>
            <button
              type="button"
              className={selectionClass}
              onClick={() => this.handleAdminUser()}
            >
              {userTitle2}
            </button>
          </div>
        )}
        {isAdmin && isAdminUser && (
          <TopUserButton
            listArray={downloadListArray13}
            handleRefresh={this.handleRefresh}
            handleNew={this.handleNew}
            fileName={fileName1}
          />
        )}
        {isLoaded && isAdmin && isAdminUser && (
          <div>
            <div className={classTables}>
              <UserTable
                listArray={listArray13}
                changeItem={this.changeItem}
                copyItem={this.copyItem}
                deleteItem={this.deleteItem}
                changePassword={this.changePassword}
                isChangePassword={isChangePassword}
                handleChangePassword={this.handleChangePassword}
                session={session}
                changeImage={this.changeImage}
                title={userTitle2}
                isUser={isUser}
                isAdminUser={isAdminUser}
                groupList={groupList}
                isAdmin={isAdmin}
              />
            </div>
          </div>
        )}
        {/* table students */}
        <div>
          <button
            type="button"
            className={selectionClass}
            onClick={() => this.handleUser()}
          >
            {userTitle3}
          </button>
        </div>
        {isAdmin && isUser && (
          <TopUserButton
            listArray={downloadListArray}
            handleRefresh={this.handleRefresh}
            handleNew={this.handleNew}
            fileName={fileName2}
          />
        )}
        {isLoaded && isUser && (
          <div className={classTables}>
            <SearchBox value={searchQuery} onChange={this.handleSearch} />
            <UserTable
              listArray={listArray}
              changeItem={this.changeItem}
              copyItem={this.copyItem}
              deleteItem={this.deleteItem}
              changePassword={this.changePassword}
              isChangePassword={isChangePassword}
              handleChangePassword={this.handleChangePassword}
              session={session}
              changeImage={this.changeImage}
              title={userTitle3}
              isUser={isUser}
              isAdminUser={isAdminUser}
              groupList={groupList}
              isAdmin={isAdmin}
            />
          </div>
        )}
      </React.Fragment>
    );
  }

  /*****************/
  /* row functions */
  /*****************/
  changeItem = async (itemData, isOverrideAvailable) => {
    //
    // change item
    //
    //console.log("changeItem: ", itemData);

    let inputTextError = [];
    let isAvailable = false;

    const { session } = this.props;

    if (!isOverrideAvailable) {
      // check user is already created
      isAvailable = this.checkUserExist(
        itemData.firstname,
        itemData.lastname,
        itemData._id
      );
    }

    //console.log("isAvailable: ", isAvailable);

    if (isAvailable && !isOverrideAvailable) {
      this.scrollTop();
      this.setState({
        isAvailable,
        userRecord: itemData,
      });
    } else {
      // group dropdown
      if (
        typeof itemData.grouptext1 !== "undefined" &&
        typeof itemData.grouptext1.id !== "undefined" &&
        typeof itemData.grouptext1.label !== "undefined"
      ) {
        itemData.groupid1 = itemData.grouptext1.id;
      }

      if (
        typeof itemData.attribute1 !== "undefined" &&
        typeof itemData.attribute1.id !== "undefined" &&
        typeof itemData.attribute1.label !== "undefined"
      ) {
        itemData.attribute1 = itemData.attribute1.label;
      }

      if (
        typeof itemData.attribute2 !== "undefined" &&
        typeof itemData.attribute2.id !== "undefined" &&
        typeof itemData.attribute2.label !== "undefined"
      ) {
        itemData.attribute2 = itemData.attribute2.label;
      }

      if (
        typeof itemData.attribute3 !== "undefined" &&
        typeof itemData.attribute3.id !== "undefined" &&
        typeof itemData.attribute3.label !== "undefined"
      ) {
        itemData.attribute3 = itemData.attribute3.label;
      }

      if (
        typeof itemData.attribute4 !== "undefined" &&
        typeof itemData.attribute4.id !== "undefined" &&
        typeof itemData.attribute4.label !== "undefined"
      ) {
        itemData.attribute4 = itemData.attribute4.label;
      }

      if (
        typeof itemData.attribute5 !== "undefined" &&
        typeof itemData.attribute5.id !== "undefined" &&
        typeof itemData.attribute5.label !== "undefined"
      ) {
        itemData.attribute5 = itemData.attribute5.label;
      }

      if (
        typeof itemData.attribute6 !== "undefined" &&
        typeof itemData.attribute6.id !== "undefined" &&
        typeof itemData.attribute6.label !== "undefined"
      ) {
        itemData.attribute6 = itemData.attribute6.label;
      }

      if (
        typeof itemData.attribute7 !== "undefined" &&
        typeof itemData.attribute7.id !== "undefined" &&
        typeof itemData.attribute7.label !== "undefined"
      ) {
        itemData.attribute7 = itemData.attribute7.label;
      }

      if (
        typeof itemData.attribute8 !== "undefined" &&
        typeof itemData.attribute8.id !== "undefined" &&
        typeof itemData.attribute8.label !== "undefined"
      ) {
        itemData.attribute8 = itemData.attribute8.label;
      }

      if (
        typeof itemData.attribute9 !== "undefined" &&
        typeof itemData.attribute9.id !== "undefined" &&
        typeof itemData.attribute9.label !== "undefined"
      ) {
        itemData.attribute9 = itemData.attribute9.label;
      }

      if (
        typeof itemData.attribute10 !== "undefined" &&
        typeof itemData.attribute10.id !== "undefined" &&
        typeof itemData.attribute10.label !== "undefined"
      ) {
        itemData.attribute10 = itemData.attribute10.label;
      }

      // validate firstname, lastname, groupid
      inputTextError = validateUserCreate(
        itemData.firstname,
        itemData.lastname,
        itemData.groupid1,
        inputTextError
      );

      //////console.log("inputTextError: ", inputTextError);

      const convertedFirstname = checkCharacters(itemData.firstname);
      const convertedLastname = checkCharacters(itemData.lastname);

      if (inputTextError.length === 0) {
        const jsonList = await getUser.updateUser(
          session,
          itemData._id,
          itemData.roleid,
          convertedFirstname,
          convertedLastname,
          itemData.groupid1,
          itemData.attribute1,
          itemData.attribute2,
          itemData.attribute3,
          itemData.attribute4,
          itemData.attribute5,
          itemData.attribute6,
          itemData.attribute7,
          itemData.attribute8,
          itemData.attribute9,
          itemData.attribute10,
          itemData.pincode
        );
        const dbreturnList = jsonList.jsonReturn;
        if (dbreturnList[0].errorcode !== noError) {
          inputTextError.push({
            key: 1,
            errorText: dbreturnList[0].errorcode + " " + dbreturnList[0].node,
          });
        }
        if (inputTextError.length === 0) {
          await this.refreshUsers();
          this.setState({
            inputTextError: undefined,
            isChangePassword: false,
            logonToChange: "",
            useridToChange: "",
            userNameToChange: "",
            isNew: false,
            isDelete: false,
            isAvailable: false,
            isOverrideAvailable: false,
            userRecord: undefined,
          });
        } else {
          // error
          this.setState({
            inputTextError,
            isChangePassword: false,
            logonToChange: "",
            useridToChange: "",
            userNameToChange: "",
            isNew: false,
            isDelete: false,
            isAvailable: false,
            isOverrideAvailable: false,
            userRecord: undefined,
          });
        }
      } else {
        // error
        this.setState({
          inputTextError,
          isChangePassword: false,
          logonToChange: "",
          useridToChange: "",
          userNameToChange: "",
          isNew: false,
          isDelete: false,
          isAvailable: false,
          isOverrideAvailable: false,
          userRecord: undefined,
        });
      }
    }
  };

  copyItem = async (itemData) => {
    //
    // copy item
    //
    ////console.log("copyItem: ", itemData);

    let inputTextError = [];

    const { session } = this.props;
    let errorInsert = false;

   // itemData.firstname = "1K - " + itemData.firstname;
   // itemData.lastname = "1K - " + itemData.lastname;
    const convertedFirstname = checkCharacters(itemData.firstname);
    const convertedLastname = checkCharacters(itemData.lastname);

    const jsonList = await getUser.createUser(
      session,
      itemData.roleid,
      itemData.groupid1,
      convertedFirstname,
      convertedLastname,
      itemData.logactive,
      itemData.actdb
    );
    const dbreturnList = jsonList.jsonReturn;
    ////console.log(dbreturnList);
    for (let index3 = 0; index3 < dbreturnList.length; index3++) {
      ////console.log(dbreturnList[index3].errorcode, noError);
      if (dbreturnList[index3].errorcode !== noError) {
        errorInsert = true;
      }
    }
    if (!errorInsert) {
      await this.refreshUsers();
      // error check ok
      this.setState({
        inputTextError: undefined,
        isCheckOK: true,
        isChangePassword: false,
        logonToChange: "",
        useridToChange: "",
        userNameToChange: "",
        isNew: false,
        isDelete: false,
      });
    } else {
      for (let index2 = 0; index2 < dbreturnList.length; index2++) {
        if (dbreturnList[index2].errorcode === "E96") {
          inputTextError.push({
            key: index2,
            errorText:
              "Er zijn geen licenties meer beschikbaar voor SAM. Gelieve de beheerder van jouw instelling te contacteren.",
          });
        }
      }
      this.scrollTop();
      this.setState({
        inputTextError,
        isCheckOK: false,
        isChangePassword: false,
        logonToChange: "",
        useridToChange: "",
        userNameToChange: "",
        isNew: false,
        isDelete: false,
      });
    }
  };
  deleteItem = (itemData) => {
    //
    // show delete confirm table
    //
    ////console.log("deleteItem: ", itemData);

    this.scrollTop();

    this.setState({
      isChangePassword: false,
      logonToChange: "",
      useridToChange: "",
      userNameToChange: "",
      isNew: false,
      isDelete: true,
      isAvailable: false,
      isOverrideAvailable: false,
      deleteRecord: itemData,
    });
  };

  handleConfirmDeleteItem = async (item) => {
    //
    // delete user on all nodes and also delete all tasks
    //

    //console.log("handleConfirmDeleteItem: ", item);

    let errorInsert = false;
    let inputTextError = [];
    let isRenamed = false;
    let dbreturnList2 = [];

    // delete
    const { session } = this.props;

    // teacher: if courses: move them to SAMSUPPORT id first
    console.log();
    if (item["item"].roleid === "3") {
      const jsonList = await getUser.moveTeacher(session, item["item"]._id);
      if (jsonList.jsonReturn[0].errorcode === noError) {
        isRenamed = true;
      } else {
        isRenamed = false;
        dbreturnList2 = jsonList.jsonReturn;
      }
    } else {
      isRenamed = true;
    }

    if (isRenamed) {
      // delete user
      const jsonList = await getUser.deleteUser(
        session,
        item["item"]._id,
        item["item"].roleid
      );
      const dbreturnList = jsonList.jsonReturn;

      for (let index3 = 0; index3 < dbreturnList.length; index3++) {
        ////console.log(dbreturnList[index3].errorcode, noError);
        if (dbreturnList[index3].errorcode !== noError) {
          errorInsert = true;
        }
      }

      if (!errorInsert) {
        // refresh data
        await this.refreshUsers();
        // error
        this.setState({
          inputTextError: undefined,
          isChangePassword: false,
          logonToChange: "",
          useridToChange: "",
          userNameToChange: "",
          isNew: false,
          isDelete: false,
          deleteRecord: undefined,
        });
      } else {
        this.scrollTop();
        // errorList
        for (let index2 = 0; index2 < dbreturnList.length; index2++) {
          inputTextError.push({
            key: index2,
            errorText:
              dbreturnList[index2].errorcode +
              " - " +
              dbreturnList[index2].message,
          });
        }
        // error
        this.setState({
          inputTextError,
          isChangePassword: false,
          logonToChange: "",
          useridToChange: "",
          userNameToChange: "",
          isNew: false,
          isDelete: false,
          deleteRecord: undefined,
        });
      }
    } else {
      this.scrollTop();
      // errorList
      for (let indx = 0; indx < dbreturnList2.length; indx++) {
        inputTextError.push({
          key: indx,
          errorText:
            dbreturnList2[indx].errorcode + " - " + dbreturnList2[indx].message,
        });
      }
      // error
      this.setState({
        inputTextError,
        isChangePassword: false,
        logonToChange: "",
        useridToChange: "",
        userNameToChange: "",
        isNew: false,
        isDelete: false,
        deleteRecord: undefined,
      });
    }
  };

  changePassword = (itemData) => {
    //
    // switch change password on
    //

    ////console.log(" changePassword: ", itemData);

    this.setState({
      isAdminUser: false,
      isUser: false,
      isChangePassword: true,
      isUploadImage: false,
      logonToChange: itemData.logon,
      useridToChange: itemData._id,
      userNameToChange: itemData.firstname + " " + itemData.lastname,
      isNew: false,
      isDelete: false,
    });
  };

  changeImage = async (itemData) => {
    //
    // change image
    //
    ////console.log("changeImage ", itemData);

    const imageRecord = this.state.imageRecord;
    imageRecord.type = prefixImageUser;
    imageRecord.id = itemData._id;

    if (itemData.image === "" || typeof itemData.image === "undefined") {
      imageRecord.new = true;
      imageRecord.oldname = undefined;
    } else {
      imageRecord.new = false;
      imageRecord.oldname = itemData.image;
      imageRecord.oldurl = itemData.imageurl;
    }

    imageRecord.description = itemData.firstname + " " + itemData.lastname;

    imageRecord.minWidth = minWidthImageUser;
    imageRecord.maxWidth = maxWidthImageUser;
    imageRecord.minHeight = minHeightImageUser;
    imageRecord.maxHeight = maxHeightImageUser;

    ////console.log("changeImage ", imageRecord);

    this.scrollTop();

    this.setState({
      isUploadImage: true,
      imageRecord,
      isChangePassword: false,
      logonToChange: "",
      useridToChange: "",
      userNameToChange: "",
      isNew: false,
      isDelete: false,
    });
  };

  handleImageSave = async (uploadedImage) => {
    //
    // save to server
    //

    const { session } = this.props;
    const { imageRecord } = this.state;
    let listArray = [...this.state.listArray];
    let allListArray = [...this.state.allListArray];

    let deleteFilePath = "";
    let errorReturn = "E00";

    // save new
    const result = await putImage.uploadImage(session.imagephp, uploadedImage);

    if (result.jsonReturn[0].errorcode === noError) {
      // update url in db and delete old
      const newImageUrl = session.imageurl2 + result.jsonReturn[0].file;
      if (typeof imageRecord.oldname !== "undefined") {
        deleteFilePath = session.imagedir + imageRecord.oldname;
      } else {
        deleteFilePath = "";
      }

      switch (imageRecord.type) {
        case prefixImageUser:
          const result1 = await putImage.updateUserImage(
            session,
            imageRecord.id,
            newImageUrl,
            result.jsonReturn[0].file,
            deleteFilePath
          );
          errorReturn = result1.jsonReturn[0].errorcode;
          if (errorReturn === noError) {
            // update user arrays
            const imageFromUrl = newImageUrl.split("/");
            let index = listArray.findIndex((el) => el._id === imageRecord.id);
            let index2 = allListArray.findIndex(
              (el) => el._id === imageRecord.id
            );

            listArray[index] = {
              ...listArray[index],
              imageurl: newImageUrl,
            };
            listArray[index] = {
              ...listArray[index],
              image: imageFromUrl[5],
            };
            allListArray[index2] = {
              ...allListArray[index2],
              imageurl: newImageUrl,
            };
            allListArray[index2] = {
              ...allListArray[index2],
              image: imageFromUrl[5],
            };

            //console.log(listArray[index], allListArray[index2]);

            // update DB
            await this.refreshUsers();
          }
          break;
        /*   case prefixImageProduct:
          const result2 = await putImage.updateProductImage(
            session,
            imageRecord.id,
            newImageUrl,
            result.jsonReturn[0].file,
            deleteFilePath
          );
          errorReturn = result2.jsonReturn[0].errorcode;
          if (errorReturn === noError) {
            // update material arrays
            const imageFromUrl = newImageUrl.split("/");
            let index = materialList.findIndex(
              (el) => el.productid1 === imageRecord.id
            );
            let index2 = allMaterialList.findIndex(
              (el) => el.productid1 === imageRecord.id
            );

            materialList[index] = {
              ...materialList[index],
              imageurl: newImageUrl,
            };
            materialList[index] = {
              ...materialList[index],
              image: imageFromUrl[5],
            };
            allMaterialList[index2] = {
              ...allMaterialList[index2],
              imageurl: newImageUrl,
            };
            allMaterialList[index2] = {
              ...allMaterialList[index2],
              image: imageFromUrl[5],
            };

            //console.log(materialList[index], allMaterialList[index2]);

            // update in DB
            this.refreshMaterial();
          }
          break;
         case prefixImageTask:
          const result3 = await putImage.updateTaskImage(
            session,
            imageRecord.id,
            newImageUrl,
            result.jsonReturn[0].file,
            deleteFilePath
          );
          errorReturn = result3.jsonReturn[0].errorcode;
          if (errorReturn === noError) {
            // update headerList arrays
            const imageFromUrl = newImageUrl.split("/");
            headerList1[0].imageurl = newImageUrl;
            headerList1[0].image = imageFromUrl[5];
            headerList2[0].imageurl = newImageUrl;
            headerList2[0].image = imageFromUrl[5];
          }
          break;
        case prefixImageStep:
          const result4 = await putImage.updateStepImage(
            session,
            imageRecord.id,
            newImageUrl,
            result.jsonReturn[0].file,
            deleteFilePath,
            imageRecord.subid
          );
          errorReturn = result4.jsonReturn[0].errorcode;
          if (errorReturn === noError) {
            // update stepList arrays
            const imageFromUrl = newImageUrl.split("/");
            let index = stepList1.findIndex(
              (el) => el.subtaskid3 === imageRecord.id
            );
            let index2 = stepList2.findIndex(
              (el) => el.subtaskid3 === imageRecord.id
            );

            if (imageRecord.subid === "1") {
              stepList1[index] = {
                ...stepList1[index],
                imageurl1: newImageUrl,
              };
              stepList1[index] = {
                ...stepList1[index],
                image1: imageFromUrl[5],
              };
              stepList2[index2] = {
                ...stepList2[index2],
                imageurl1: newImageUrl,
              };
              stepList2[index2] = {
                ...stepList2[index2],
                image1: imageFromUrl[5],
              };
            } else {
              stepList1[index] = {
                ...stepList1[index],
                imageurl2: newImageUrl,
              };
              stepList1[index] = {
                ...stepList1[index],
                image2: imageFromUrl[5],
              };
              stepList2[index2] = {
                ...stepList2[index2],
                imageurl2: newImageUrl,
              };
              stepList2[index2] = {
                ...stepList2[index2],
                image2: imageFromUrl[5],
              };
            }
            //console.log(stepList1[index], stepList2[index2]);
          }
          break;*/
        default:
          break;
      }

      ////console.log("errorRetrun: ", errorReturn);
      if (errorReturn === noError) {
        // update image record
        imageRecord.oldname = result.jsonReturn[0].file;

        // success message
        this.setState({
          listArray,
          allListArray,
          isUploadImage: false,
          isImageSaved: true,
          imageRecord,
        });
      } else {
        // error message
        this.setState({
          isImageSaved: false,
        });
      }
    } else {
      // error
      this.setState({
        isImageSaved: false,
      });
    }
  };

  cancelImageUpload = () => {
    //
    // Cancel imagescreen
    //

    this.setState({
      isUploadImage: false,
    });
  };
}

export default Users;
