//
// Purpose: SAMB - check all WF tables before save
// Author: Ronny Van Elewyck
// Version: 1.1
// Creation Date: 03.06.2020
// Modification Date: 04.12.2020
//

// installed components
//import Joi from "joi-browser";

// created
import {
  validateStepTitle,
  validateStepTextLine,
  validateProduct,
  validateIngredient,
  checkHeaderInput,
} from "./checkTextInput";
import { checkCharacters } from "./specialCharacters";

// config files
import { prd, saveDBError1 } from "../config.json";

export function checkHeaderList(inList, outList, typeid, session) {
  //
  // validate Header before save
  //
  // input: original array and changed array
  // output:
  //        errorList: array of errors in arrays
  //        uploadList: array used for updating the database
  //

  ////console.log("checkHeaderList inList: ", inList);
  ////console.log("checkHeaderList outList: ", outList);

  let errorList = [];
  let uploadList = [];
  let isChanged = false;
  let inListOut = [];
  let node = "";
  let text1Converted = "";
  let text2Converted = "";

  if (outList.length > 1) {
    // error: there is only 1 header
    // fatal error
    errorList.push({
      key: 1,
      errorText: saveDBError1,
    });
  } else {
    // change inList to outList format
    inListOut.push({
      mastertaskid1: inList[0].mastertaskid1,
      mastertasktext: inList[0].mastertasktext,
      mastertasktext2: inList[0].mastertasktext2,
      typeid1: inList[0].typeid1,
      typetext: inList[0].typetext,
      imageurl: inList[0].imageurl,
      image: inList[0].image,
    });

    ////console.log("checkHeaderList inListOut: ", inListOut);

    if (JSON.stringify(inListOut) === JSON.stringify(outList)) {
      // nothing changed, nothing to update
      isChanged = false;
    } else {
      isChanged = true;
      if (inList[0].mastertaskid1 !== outList[0].mastertaskid1) {
        // error: id's must be the same
        // fatal error
        errorList.push({
          key: 1,
          errorText: saveDBError1,
        });
      } else {
        const result = checkHeaderInput(outList, inList);
        ////console.log("result.errorList.length: ", result.errorList.length);
        if (result.errorList.length > 0) {
          // error
          uploadList = [];
          let errorListTemp = result.errorList;
          errorList = [];
          for (let index = 0; index < errorListTemp.length; index++) {
            errorList.push({
              key: index,
              errorText: errorListTemp[index].errorText,
            });
          }
          errorListTemp = [];
        } else {
          // prepare update record
          if (session.actdb === prd) {
            node = session.prd;
          } else {
            node = session.dev;
          }
          text1Converted = checkCharacters(outList[0].mastertasktext);
          text2Converted = checkCharacters(outList[0].mastertasktext2);
          uploadList.push({
            p1: node,
            p2: outList[0].mastertaskid1,
            p3: typeid.toString(),
            p4: "X",
            p5: text1Converted,
            p6: text2Converted,
            p7: inList[0].imageurl,
            p8: inList[0].numberofsteps,
            p9: inList[0].step1active,
            p10: inList[0].step2active,
            p11: inList[0].step3active,
            p12: inList[0].step4active,
          });
        }
      }
    }
  }

  ////console.log("checkHeaderList errorList : ", errorList);
  ////console.log("checkHeaderList uploadList : ", uploadList);
  //console.log("checkHeaderList isChanged : ", isChanged);

  return {
    errorList,
    uploadList,
    isChanged,
  };
}

export function checkStepList(inList, outList, mastertaskid1, session) {
  //
  // validate Step before save
  //
  // input: original array and changed array
  // output:
  //        errorList: array of errors in arrays
  //        uploadList: array used for updating the database
  //

  //console.log("checkStepList inList: ", inList);
  //console.log("checkStepList outList: ", outList);

  let errorList = [];
  let uploadList = [];
  let insertList = [];
  let isDeleted = false;
  let inListOut1 = [];
  let inListOut2 = [];
  let isChanged = false;
  let node = "";
  let subtaskid3 = "";
  let p6Converted = "";
  let p7Converted = "";
  let p8Converted = "";
  let p9Converted = "";
  let p10Converted = "";
  let p11Converted = "";
  let p12Converted = "";
  let p13Converted = "";
  let p14Converted = "";
  let p15Converted = "";
  let p16Converted = "";
  let p17Converted = "";
  let p18Converted = "";
  let recupImageurl1 = "";
  let recupImage1 = "";
  let recupImageurl2 = "";
  let recupImage2 = "";
  let recupMovieurl = "";
  let recupMovie = "";
  let isNew = false;

  if (session.actdb === prd) {
    node = session.prd;
  } else {
    node = session.dev;
  }

  if (outList.length > 0) {
    // change inList to outList format
    for (let index = 0; index < inList.length; index++) {
      inListOut1.push({
        subtaskid3: inList[index].subtaskid3,
        order: inList[index].order,
        screenformatid: inList[index].screenformatid,
        screenformatlabel: inList[index].screenformatlabel,
        steptitle: inList[index].steptitle,
        text1: inList[index].text1,
        text2: inList[index].text2,
        text3: inList[index].text3,
        text4: inList[index].text4,
        text5: inList[index].text5,
        text6: inList[index].text6,
        text7: inList[index].text7,
        text8: inList[index].text8,
        text9: inList[index].text9,
        text10: inList[index].text10,
      });
    }
    for (let index = 0; index < outList.length; index++) {
      inListOut2.push({
        subtaskid3: outList[index].subtaskid3,
        order: outList[index].order,
        screenformatid: outList[index].screenformatid,
        screenformatlabel: outList[index].screenformatlabel,
        steptitle: outList[index].steptitle,
        text1: outList[index].text1,
        text2: outList[index].text2,
        text3: outList[index].text3,
        text4: outList[index].text4,
        text5: outList[index].text5,
        text6: outList[index].text6,
        text7: outList[index].text7,
        text8: outList[index].text8,
        text9: outList[index].text9,
        text10: outList[index].text10,
      });
    }
    //console.log("checkStepList inListOut1: ", inListOut1);
    //console.log("checkStepList inListOut2: ", inListOut2);

    if (JSON.stringify(inListOut1) === JSON.stringify(inListOut2)) {
      // nothing changed, nothing to update
      isChanged = false;
    } else {
      isChanged = true;
      for (let index = 0; index < outList.length; index++) {
        // validate step title, order and screen format
        errorList = validateStepTitle(
          outList[index].steptitle,
          outList[index].order,
          outList[index].screenformatid,
          errorList
        );
        // check text validity
        /* USING EVAL IS NOT SECURE for (let i = 1; i < 11; i++) {
      const textName = "tekst" + i;
      const textLine = "uploadList[index].text" + i;
      errorList = validateStepTextLine(
        eval(textLine),
        errorList,
        textName,
        uploadList[index].screenformatid,
        uploadList[index].order
      );
    }*/
        errorList = validateStepTextLine(
          outList[index].text1,
          errorList,
          "tekst1",
          outList[index].screenformatid,
          outList[index].order
        );
        errorList = validateStepTextLine(
          outList[index].text2,
          errorList,
          "tekst2",
          outList[index].screenformatid,
          outList[index].order
        );
        errorList = validateStepTextLine(
          outList[index].text3,
          errorList,
          "tekst3",
          outList[index].screenformatid,
          outList[index].order
        );
        errorList = validateStepTextLine(
          outList[index].text4,
          errorList,
          "tekst4",
          outList[index].screenformatid,
          outList[index].order
        );
        errorList = validateStepTextLine(
          outList[index].text5,
          errorList,
          "tekst5",
          outList[index].screenformatid,
          outList[index].order
        );
        errorList = validateStepTextLine(
          outList[index].text6,
          errorList,
          "tekst6",
          outList[index].screenformatid,
          outList[index].order
        );
        errorList = validateStepTextLine(
          outList[index].text7,
          errorList,
          "tekst7",
          outList[index].screenformatid,
          outList[index].order
        );
        errorList = validateStepTextLine(
          outList[index].text8,
          errorList,
          "tekst8",
          outList[index].screenformatid,
          outList[index].order
        );
        errorList = validateStepTextLine(
          outList[index].text9,
          errorList,
          "tekst9",
          outList[index].screenformatid,
          outList[index].order
        );
        errorList = validateStepTextLine(
          outList[index].text10,
          errorList,
          "tekst10",
          outList[index].screenformatid,
          outList[index].order
        );
      }

      if (errorList.length > 0) {
        uploadList = [];
        let errorListTemp = errorList;
        errorList = [];
        for (let index = 0; index < errorListTemp.length; index++) {
          errorList.push({
            key: index,
            errorText: errorListTemp[index].errorText,
          });
        }
        errorListTemp = [];
      } else {
        //

        if (inList.length === outList.length) {
          // recup of id's
          isDeleted = false;
          // make update table

          ////////console.log("outList before : ", outList);
          for (let j = 0; j < outList.length; j++) {
            // try to find matching subtaskid3
            const checkInList = inListOut1.filter(
              (m) => m.subtaskid3 === outList[j].subtaskid3
            );

            if (typeof checkInList[0] !== "undefined") {
              if (
                JSON.stringify(checkInList[0]) !== JSON.stringify(outList[j])
              ) {
                // match found
                p7Converted = "stap " + outList[j].order;
                p8Converted = checkCharacters(outList[j].steptitle);
                p9Converted = checkCharacters(outList[j].text1);
                p10Converted = checkCharacters(outList[j].text2);
                p11Converted = checkCharacters(outList[j].text3);
                p12Converted = checkCharacters(outList[j].text4);
                p13Converted = checkCharacters(outList[j].text5);
                p14Converted = checkCharacters(outList[j].text6);
                p15Converted = checkCharacters(outList[j].text7);
                p16Converted = checkCharacters(outList[j].text8);
                p17Converted = checkCharacters(outList[j].text9);
                p18Converted = checkCharacters(outList[j].text10);
                subtaskid3 = checkInList[0].subtaskid3;
                // update list
                uploadList.push({
                  p1: node,
                  p2: subtaskid3,
                  p3: "X",
                  p4: mastertaskid1,
                  p5: outList[j].order,
                  p6: outList[j].screenformatid,
                  p7: p7Converted,
                  p8: p8Converted,
                  p9: p9Converted,
                  p10: p10Converted,
                  p11: p11Converted,
                  p12: p12Converted,
                  p13: p13Converted,
                  p14: p14Converted,
                  p15: p15Converted,
                  p16: p16Converted,
                  p17: p17Converted,
                  p18: p18Converted,
                });
              }

              // delete record from inList
              const deleteIndex = inList
                .map(function (element) {
                  return element.subtaskid3;
                })
                .indexOf(subtaskid3);
              inList.splice(deleteIndex, 1);
            } else {
              // at least one entry is new
              isNew = true;
            }
            // redo work because at least one entry is not matching
            if (isNew) {
              insertList = [];
              isDeleted = true;
              for (let j = 0; j < outList.length; j++) {
                // create no match list
                p6Converted = "stap " + outList[j].order;
                p7Converted = checkCharacters(outList[j].steptitle);
                p8Converted = checkCharacters(outList[j].text1);
                p9Converted = checkCharacters(outList[j].text2);
                p10Converted = checkCharacters(outList[j].text3);
                p11Converted = checkCharacters(outList[j].text4);
                p12Converted = checkCharacters(outList[j].text5);
                p13Converted = checkCharacters(outList[j].text6);
                p14Converted = checkCharacters(outList[j].text7);
                p15Converted = checkCharacters(outList[j].text8);
                p16Converted = checkCharacters(outList[j].text9);
                p17Converted = checkCharacters(outList[j].text10);
                // try to recup the images from inList based on order number
                const checkInList = inList.filter(
                  (m) => m.order === outList[j].order
                );
                //console.log("checkStepList checkInList j: ", checkInList);
                if (typeof checkInList[0] !== "undefined") {
                  // match found
                  recupImageurl1 = checkInList[0].imageurl1;
                  recupImage1 = checkInList[0].image1;
                  recupImageurl2 = checkInList[0].imageurl2;
                  recupImage2 = checkInList[0].image2;
                  recupMovieurl = checkInList[0].movieurl;
                  recupMovie = checkInList[0].movie;
                }
                // insert list
                insertList.push({
                  p1: node,
                  p2: "X",
                  p3: mastertaskid1,
                  p4: outList[j].order,
                  p5: outList[j].screenformatid,
                  p6: p6Converted,
                  p7: p7Converted,
                  p8: p8Converted,
                  p9: p9Converted,
                  p10: p10Converted,
                  p11: p11Converted,
                  p12: p12Converted,
                  p13: p13Converted,
                  p14: p14Converted,
                  p15: p15Converted,
                  p16: p16Converted,
                  p17: p17Converted,
                  p18: recupImageurl1,
                  p19: recupImage1,
                  p20: recupImageurl2,
                  p21: recupImage2,
                  p22: recupMovieurl,
                  p23: recupMovie,
                });
              }
            }
          }
        } else {
          // delete old id's and create new
          insertList = [];
          isDeleted = true;
          // insertlist

          for (let z = 0; z < outList.length; z++) {
            // title and textlines
            p6Converted = "stap " + outList[z].order;
            p7Converted = checkCharacters(outList[z].steptitle);
            p8Converted = checkCharacters(outList[z].text1);
            p9Converted = checkCharacters(outList[z].text2);
            p10Converted = checkCharacters(outList[z].text3);
            p11Converted = checkCharacters(outList[z].text4);
            p12Converted = checkCharacters(outList[z].text5);
            p13Converted = checkCharacters(outList[z].text6);
            p14Converted = checkCharacters(outList[z].text7);
            p15Converted = checkCharacters(outList[z].text8);
            p16Converted = checkCharacters(outList[z].text9);
            p17Converted = checkCharacters(outList[z].text10);
            // try to recup the images from inList based on order number
            const checkInList = inList.filter(
              (m) => m.order === outList[z].order
            );
            //console.log("checkStepList checkInList z: ", checkInList);
            if (typeof checkInList[0] !== "undefined") {
              // match found
              recupImageurl1 = checkInList[0].imageurl1;
              recupImage1 = checkInList[0].image1;
              recupImageurl2 = checkInList[0].imageurl2;
              recupImage2 = checkInList[0].image2;
              recupMovieurl = checkInList[0].movieurl;
              recupMovie = checkInList[0].movie;
            }
            insertList.push({
              p1: node,
              p2: "X",
              p3: mastertaskid1,
              p4: outList[z].order,
              p5: outList[z].screenformatid,
              p6: p6Converted,
              p7: p7Converted,
              p8: p8Converted,
              p9: p9Converted,
              p10: p10Converted,
              p11: p11Converted,
              p12: p12Converted,
              p13: p13Converted,
              p14: p14Converted,
              p15: p15Converted,
              p16: p16Converted,
              p17: p17Converted,
              p18: recupImageurl1,
              p19: recupImage1,
              p20: recupImageurl2,
              p21: recupImage2,
              p22: recupMovieurl,
              p23: recupMovie,
            });
          }
        }
      }
    }
  } else {
    // no steps nothing to update
    isChanged = false;
  }

  //console.log("checkStepList errorList : ", errorList);
  //console.log("checkStepList uploadList : ", uploadList);
  //console.log("checkStepList insertList : ", insertList);
  //console.log("checkStepList isDeleted : ", isDeleted);
  //console.log("checkStepList isChanged : ", isChanged);

  return {
    errorList,
    uploadList,
    insertList,
    isDeleted,
    isChanged,
  };
}

export function checkProductList(
  inList,
  outList,
  mastertaskid1,
  session,
  productIdCheck
) {
  //
  // validate Product before save
  //
  // input: original array and changed array
  // output:
  //        errorList: array of errors in arrays
  //        uploadList: array used for updating the database
  //

  //console.log("checkProductList inList: ", inList);
  //console.log("checkProductList outList: ", outList);

  let errorList = [];
  let uploadList = [];
  let insertList = [];
  let isDeleted = false;
  let inListOut = [];
  let isChanged = false;
  let node = "";
  //let subtaskid1 = 0;

  if (session.actdb === prd) {
    node = session.prd;
  } else {
    node = session.dev;
  }

  if (outList.length > 0) {
    // change inList to outList format
    for (let index = 0; index < inList.length; index++) {
      inListOut.push({
        subtaskid1: inList[index].subtaskid1,
        order: inList[index].order,
        productid1: inList[index].productid1,
        producttext: inList[index].producttext,
        quantity: inList[index].quantity,
      });
    }
    if (JSON.stringify(inListOut) === JSON.stringify(outList)) {
      // nothing changed, nothing to update
      isChanged = false;
    } else {
      isChanged = true;

      for (let index = 0; index < outList.length; index++) {
        errorList = validateProduct(
          outList[index].order,
          outList[index].productid1,
          outList[index].quantity,
          productIdCheck,
          errorList
        );
      }

      if (errorList.length > 0) {
        uploadList = [];
        let errorListTemp = errorList;
        errorList = [];
        for (let index = 0; index < errorListTemp.length; index++) {
          errorList.push({
            key: index,
            errorText: errorListTemp[index].errorText,
          });
        }
        errorListTemp = [];
      } else {
        // no errors
        /*  if (inList.length === outList.length) {
          // update
          // recup of id's
          isDeleted = false;
          // make update table
          for (let j = 0; j < outList.length; j++) {
            // try to find matching subtaskid1
            const checkInList = inListOut.filter(
              (m) => m.subtaskid1 === outList[j].subtaskid1
            );
            if (typeof checkInList[0] !== "undefined") {
              // match found
              if (
                JSON.stringify(checkInList[0]) !== JSON.stringify(outList[j])
              ) {
                // match found
                subtaskid1 = checkInList[0].subtaskid1;
                // update list
                uploadList.push({
                  p1: node,
                  p2: subtaskid1,
                  p3: "X",
                  p4: mastertaskid1,
                  p5: outList[j].productid1,
                  p6: outList[j].order,
                  p7: outList[j].quantity,
                });
              }

              // delete record from inList
              const deleteIndex = inList
                .map(function (element) {
                  return element.subtaskid1;
                })
                .indexOf(subtaskid1);
              inList.splice(deleteIndex, 1);
            } else {
              // no match found
              // insert list
              insertList.push({
                p1: node,
                p2: "X",
                p3: mastertaskid1,
                p4: outList[j].productid1,
                p5: outList[j].order,
                p6: outList[j].quantity,
              });
            }
          }

          if (inList.length > 0) {
            // as long as you can not add or delete on screen
            // this is not needed
            //////console.log("special delete needed: ", inList);
          }
        } else {*/
        // delete and insert
        isDeleted = true;

        for (let z = 0; z < outList.length; z++) {
          insertList.push({
            p1: node,
            p2: "X",
            p3: mastertaskid1,
            p4: outList[z].productid1,
            p5: outList[z].order,
            p6: outList[z].quantity,
          });
        }
        //}
      }
    }
  } else {
    // no steps nothing to update
    isChanged = false;
  }

  //console.log("checkProductList errorList : ", errorList);
  //console.log("checkProductList uploadList : ", uploadList);
  //console.log("checkProductList insertList : ", insertList);
  //console.log("checkProductList isDeleted : ", isDeleted);
  //console.log("checkProductList isChanged : ", isChanged);

  return {
    errorList,
    uploadList,
    insertList,
    isDeleted,
    isChanged,
  };
}

export function checkIngredientList(
  inList,
  outList,
  mastertaskid1,
  session,
  productIdCheck,
  uomIdCheck
) {
  //
  // validate Ingredient before save
  //
  // input: original array and changed array
  // output:
  //        errorList: array of errors in arrays
  //        uploadList: array used for updating the database
  //

  //console.log("checkIngredientList inList: ", inList);
  //console.log("checkIngredientList outList: ", outList);

  let errorList = [];
  let uploadList = [];
  let insertList = [];
  let isDeleted = false;
  let inListOut = [];
  let isChanged = false;
  let node = "";
  //let subtaskid2 = 0;

  if (session.actdb === prd) {
    node = session.prd;
  } else {
    node = session.dev;
  }

  if (outList.length > 0) {
    // change inList to outList format
    for (let index = 0; index < inList.length; index++) {
      inListOut.push({
        subtaskid2: inList[index].subtaskid2,
        order: inList[index].order,
        productid1: inList[index].productid1,
        producttext: inList[index].producttext,
        quantity: inList[index].quantity.toString().replace(/[.]+/, ","),
        uomid: inList[index].uomid,
        uom: inList[index].uom,
      });
    }
    if (JSON.stringify(inListOut) === JSON.stringify(outList)) {
      // nothing changed, nothing to update
      isChanged = false;
    } else {
      isChanged = true;

      for (let index = 0; index < outList.length; index++) {
        errorList = validateIngredient(
          outList[index].order,
          outList[index].productid1,
          outList[index].quantity,
          productIdCheck,
          errorList,
          outList[index].uomid,
          uomIdCheck
        );
      }

      if (errorList.length > 0) {
        uploadList = [];
        let errorListTemp = errorList;
        errorList = [];
        for (let index = 0; index < errorListTemp.length; index++) {
          errorList.push({
            key: index,
            errorText: errorListTemp[index].errorText,
          });
        }
        errorListTemp = [];
      } else {
        // no errors
        /* if (inList.length === outList.length) {
          // update
          // recup of id's
          isDeleted = false;
          // make update table
          for (let j = 0; j < outList.length; j++) {
            // try to find matching subtaskid1
            const checkInList = inListOut.filter(
              (m) => m.subtaskid2 === outList[j].subtaskid2
            );
            if (typeof checkInList[0] !== "undefined") {
              // match found
              if (
                JSON.stringify(checkInList[0]) !== JSON.stringify(outList[j])
              ) {
                // match found
                subtaskid2 = checkInList[0].subtaskid2;
                // update list
                const qtyString = outList[j].quantity
                  .toString()
                  .replace(/[,]+/, ".");
                const qtyDecimal = parseFloat(qtyString);
                uploadList.push({
                  p1: node,
                  p2: subtaskid2,
                  p3: "X",
                  p4: mastertaskid1,
                  p5: outList[j].productid1,
                  p6: outList[j].order,
                  p7: 0,
                  p8: qtyDecimal,
                  p9: outList[j].uomid,
                });
              }

              // delete record from inList
              const deleteIndex = inList
                .map(function (element) {
                  return element.subtaskid2;
                })
                .indexOf(subtaskid2);
              inList.splice(deleteIndex, 1);
            } else {
              // no match found
              // insert list
              const qtyString = outList[j].quantity
                .toString()
                .replace(/[,]+/, ".");
              const qtyDecimal = parseFloat(qtyString);
              insertList.push({
                p1: node,
                p2: "X",
                p3: mastertaskid1,
                p4: outList[j].productid1,
                p5: outList[j].order,
                p6: 0,
                p7: qtyDecimal,
                p8: outList[j].uomid,
              });
            }
          }

          if (inList.length > 0) {
            // as long as you can not add or delete on screen
            // this is not needed
            //////console.log("special delete needed: ", inList);
          }
        } else {*/
        // delete and insert
        isDeleted = true;

        for (let z = 0; z < outList.length; z++) {
          const qtyString = outList[z].quantity.toString().replace(/[,]+/, ".");
          const qtyDecimal = parseFloat(qtyString);
          insertList.push({
            p1: node,
            p2: "X",
            p3: mastertaskid1,
            p4: outList[z].productid1,
            p5: outList[z].order,
            p6: 0,
            p7: qtyDecimal,
            p8: outList[z].uomid,
          });
        }
        // }
      }
    }
  } else {
    // no steps nothing to update
    isChanged = false;
  }

  //console.log("checkIngredientList errorList : ", errorList);
  //console.log("checkIngredientList uploadList : ", uploadList);
  //console.log("checkIngredientList insertList : ", insertList);
  //console.log("checkIngredientList isDeleted : ", isDeleted);
  //console.log("checkIngredientList isChanged : ", isChanged);

  return {
    errorList,
    uploadList,
    insertList,
    isDeleted,
    isChanged,
  };
}

// eslint-disable-next-line
export default {
  checkHeaderList,
  checkStepList,
  checkProductList,
  checkIngredientList,
};
