//
// Purpose: SAMB - movie service
// Author: Ronny Van Elewyck
// Version: 1.1
// Creation Date: 22.06.2020
// Modification Date: 04.12.2020
//

import axios from "axios";
import { basisUrlSam, prd, p1, p2, p3, p4, p5 } from "../config.json";

export async function uploadMP4(mp4PHP, uploadedMP4) {
  //
  // upload mp4
  //

  const data = new FormData();
  data.append("file", uploadedMP4);

  const postPHP = basisUrlSam + mp4PHP;

  //console.log(postPHP);

  const { data: response } = await axios.post(postPHP, data, {});
  const jsonReturn = response.DBRETURN;
  //console.log(response.DBRETURN);

  return {
    jsonReturn,
  };
}

export async function updateStepMP4(
  session,
  subtaskid3,
  movieurl,
  movie,
  deleteFilePath
) {
  //
  // update step movie(s) and delete old
  //

  // variables
  let param1 = "";
  const getPHP = "updateStepMP4.php";

  // sam instance
  if (session.actdb === prd) {
    param1 = session.prd;
  } else {
    param1 = session.dev;
  }

  // url
  const urlPut =
    basisUrlSam +
    getPHP +
    p1 +
    param1 +
    p2 +
    subtaskid3 +
    p3 +
    movieurl +
    p4 +
    movie +
    p5 +
    deleteFilePath;

  //console.log(urlPut);

  // async call
  const { data: response } = await axios.get(urlPut);
  const jsonReturn = response.DBRETURN;

  return {
    jsonReturn,
  };
}

// eslint-disable-next-line
export default {
  uploadMP4,
  updateStepMP4,
};
