//
// Purpose: SAMB - error Row
// Author: Ronny Van Elewyck
// Version: 1.0
// Creation Date: 27.05.2020
// Modification Date: 27.05.2020
//

// installed components
import React from "react";

const ErrorRow = ({ item }) => {
  //
  // format row of error table
  //

  return (
    <tr className="table-warning">
      <td>{item.errorText}</td>
    </tr>
  );
};

export default ErrorRow;
