//
// Purpose: SAMB - step table component
// Author: Ronny Van Elewyck
// Version: 1.1
// Creation Date: 28.05.2020
// Modification Date: 12.04.2020
//

// installed components
import React from "react";
/* import {
  isMobile,
  isTablet,
  isSafari,
  isChrome,
} from "react-device-detect"; */

// created components
import StepRow from "./stepRow";

const StepTable = ({
  stepList2,
  saveStepItem,
  deleteStepItem,
  screenList,
  stepErrorMessage,
  classErrorMessage,
  changeImage1Step,
  changeImage2Step,
  changeMovieStep,
  refreshStepItem,
  isRefresh,
}) => {

  // size col1 based on device
  let col1ClassName = "";
  let col2ClassName = "";
/*  if ((isMobile) && (!isTablet)) {
    // smartphones
    col1ClassName = ""
    col2ClassName = "col-md-auto"
  } else {
    if ((isSafari) || (isTablet && isChrome)){
      // this was default
      col1ClassName = ""
      col2ClassName = "col-lg-2"
    } else {
      col1ClassName = "col-sm-1"
      col2ClassName = "col-md-auto"
    }
  }*/
  
  return (
    <div className="table-responsive">
      <table className="table table-bordered">
        <thead className="thead-light">
          <tr>
            <th className={col1ClassName}>  </th>
            <th className={col2ClassName}>stap informatie</th>
          </tr>
        </thead>
        <tbody>
          {stepList2.map((item) => (
            <StepRow
              key={item.subtaskid3}
              item={item}
              saveStepItem={saveStepItem}
              deleteStepItem={deleteStepItem}
              screenList={screenList}
              stepErrorMessage={stepErrorMessage}
              classErrorMessage={classErrorMessage}
              changeImage1Step={changeImage1Step}
              changeImage2Step={changeImage2Step}
              changeMovieStep={changeMovieStep}
              refreshStepItem={refreshStepItem}
              isRefresh={isRefresh}
            />
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default StepTable;
