//
// Purpose: SAMB - authorization service
// Author: Ronny Van Elewyck
// Version: 1.1
// Creation Date: 25.02.2020
// Modification Date: 04.12.2020
//

import axios from "axios";
import { encrypt } from "../utils/encrypt";
import { browserInfo } from "../utils/browserInfo";
import {
  basisUrlNode,
  basisUrlSam,
  prd,
  p1,
  p2,
  p3,
  p4,
  p5,
  versionApp,
  app,
} from "../config.json";

export async function cleanSession(username) {
  //
  // clean up session
  //

  // cleanup sessions url
  const cleanupSessionsPHP = "cleanupSessions.php";
  const urlCleanUpSession = basisUrlNode + cleanupSessionsPHP + p1 + username;

  // async url
  await axios.get(urlCleanUpSession);
}

export async function login(username, password) {
  //
  // check login and password
  //

  // variables
  let session = [];

  // cleanup sessions url
  const cleanupSessionsPHP = "cleanupSessions.php";
  const urlCleanUpSession = basisUrlNode + cleanupSessionsPHP + p1 + username;

  //console.log("urlCleanUpSession: ",urlCleanUpSession);
  // async url
  const { data: response } = await axios.get(urlCleanUpSession);

  if (response.DBRETURN[0].errorcode === "E00") {
    // login url
    const loginPHP = "reactjsLogin.php";
    const encryptedPassWord = encrypt(password);
    const browser = browserInfo();
    const urlCheckPassword =
      basisUrlNode +
      loginPHP +
      p1 +
      username +
      p2 +
      encryptedPassWord +
      p3 +
      versionApp +
      p4 +
      app +
      p5 +
      browser;

    const { data: response } = await axios.get(urlCheckPassword);

    session = response.SESSIONDETAILS;
  } else {
    session.push({
      errorcode: "E66",
    });
  }

  return session;
}

export async function samDetails(session) {
  //
  // get sam details for user
  //

  // variables
  let param1 = "";
  const getSamUserPHP = "getAdminUser.php";

  // sam instance
  if (session.actdb === prd) {
    param1 = session.prd;
  } else {
    param1 = session.dev;
  }
  // url
  const urlGetUserSam =
    basisUrlSam +
    getSamUserPHP +
    p1 +
    param1 +
    p2 +
    session.compid +
    p3 +
    session.roleid +
    p4 +
    session.userid;

  //console.log("urlGetUserSam: ", urlGetUserSam);

  // async call
  const { data: response } = await axios.get(urlGetUserSam);
  const user = response.USERSAM;

  return user;
}

export async function companyDetails(session) {
  //
  // get company details
  //

  // variables
  const param1 = "node1.php";
  const getPHP = "getCompany.php";

  // url
  const urlGet =
    basisUrlNode + getPHP + p1 + param1 + p2 + "X" + p3 + session.compid;

  //console.log("urlGet: ",urlGet);

  // async call
  const { data: response } = await axios.get(urlGet);
  const user = response.COMPANYNODE;

  return user;
}

/* moved to node service export async function sessionDetails(compid) {
  //
  // get session details
  //

  // variables
  const getPHP = "getSessions.php";

  // url
  const urlGet = basisUrlNode + getPHP + p1 + compid;

  //console.log("urlGet: ", urlGet);

  // async call
  const { data: response } = await axios.get(urlGet);
  const sessionList = response.SESSION;
  //console.log("sessionList: ", sessionList);
  return sessionList;
}*/

export async function changePassword(userid, compid, password) {
  //
  // change password
  //

  const putPHP = "changePassword.php";

  // url
  const urlPut =
    basisUrlNode + putPHP + p1 + userid + p2 + compid + p3 + password;

  //console.log(urlPut);

  // async call
  const { data: response } = await axios.get(urlPut);
  const jsonReturn = response.DBRETURN;

  return {
    jsonReturn,
  };
}

// eslint-disable-next-line
export default {
  cleanSession,
  login,
  samDetails,
  companyDetails,
  changePassword,
};
