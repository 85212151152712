//
// Purpose: SAMB - uom table component
// Author: Ronny Van Elewyck
// Version: 1.0
// Creation Date: 10.06.2020
// Modification Date: 27.08.2020
//

// installed components
import React from "react";

// created components
import UomRow from "./uomRow";

const UomTable = ({ uomList2, changeItem, copyItem, deleteItem, isAdmin }) => {
  return (
    <div className="table-responsive">
      <table className="table table-bordered">
        <thead className="thead-light">
          <tr>
            <th></th>
            {isAdmin && <th></th>}
            {isAdmin && <th></th>}
            {isAdmin && <th></th>}
            <th></th>
          </tr>
        </thead>
        <tbody>
          {uomList2.map((item) => (
            <UomRow
              key={item.uomid}
              item={item}
              changeItem={changeItem}
              copyItem={copyItem}
              deleteItem={deleteItem}
              isAdmin={isAdmin}
            />
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default UomTable;
