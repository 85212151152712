//
// Purpose: SAMB - browser info for logging
// Author: Ronny Van Elewyck
// Version: 1.0
// Creation Date: 25.02.2020
// Modification Date: 25.02.2020
//

import Bowser from "bowser";

export function browserInfo() {
  //
  // get browserinfo for loggin purpose
  //
  const result = Bowser.getParser(window.navigator.userAgent);

  const browser =
    result.parsedResult.browser.name +
    "-" +
    result.parsedResult.browser.version +
    "-" +
    result.parsedResult.os.name +
    "-" +
    result.parsedResult.os.version +
    "-" +
    result.parsedResult.os.versionName +
    "-" +
    result.parsedResult.platform.type +
    "-" +
    result.parsedResult.platform.vendor;
  return browser;
}
