//
// Purpose: SAMB - uom Row
// Author: Ronny Van Elewyck
// Version: 1.0
// Creation Date: 10.06.2020
// Modification Date: 22.10.2020
//

// installed components
import React from "react";
import Tooltip from "react-bootstrap/Tooltip";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";

// config files
import { saveClass5, copyClass5, deleteClass5 } from "../../config.json";

const UomRow = ({ item, changeItem, copyItem, deleteItem, isAdmin }) => {
  //
  // format row of table
  //

  function renderTooltip(title) {
    return <Tooltip id="button-tooltip">{title}</Tooltip>;
  }

  const [uom, setValue1] = React.useState(item.uom);
  const [uomlong, setValue2] = React.useState(item.uomlong);

  const [isChecked, setChecked] = React.useState(false);

  let sizeLine1 = 16;
  let sizeLine2 = 32;

  return (
    <tr>
      <td>
        <div>
          <OverlayTrigger
            placement="left"
            delay={{ show: 250, hide: 400 }}
            overlay={renderTooltip("detail open/dicht")}
          >
            <div>
              <input
                type="checkbox"
                checked={isChecked}
                onChange={() => setChecked(!isChecked)}
              />
            </div>
          </OverlayTrigger>
        </div>
      </td>
      {!isChecked && <td>{item.uomid}</td>}
      {!isChecked && <td>actief:{item.active}</td>}
      {!isChecked && <td>{item.uom}</td>}
      {!isChecked && <td>{item.uomlong}</td>}

      {isChecked && isAdmin && (
        <td>
          {" "}
          <div>
            <OverlayTrigger
              placement="left"
              delay={{ show: 250, hide: 400 }}
              overlay={renderTooltip("bewaar")}
            >
              <div>
                <button
                  className={saveClass5}
                  onClick={() => changeItem({ ...item, uom, uomlong })}
                ></button>{" "}
              </div>
            </OverlayTrigger>
          </div>
        </td>
      )}
      {isChecked && isAdmin && (
        <td>
          {" "}
          <div>
            <OverlayTrigger
              placement="left"
              delay={{ show: 250, hide: 400 }}
              overlay={renderTooltip("kopieer")}
            >
              <div>
                <button
                  className={copyClass5}
                  onClick={() => copyItem({ ...item })}
                ></button>{" "}
              </div>
            </OverlayTrigger>
          </div>
        </td>
      )}
      {isChecked && isAdmin && (
        <td>
          {" "}
          <div>
            <OverlayTrigger
              placement="left"
              delay={{ show: 250, hide: 400 }}
              overlay={renderTooltip("verwijder/deactiveer")}
            >
              <div>
                <button
                  className={deleteClass5}
                  onClick={() => deleteItem({ ...item })}
                ></button>{" "}
              </div>
            </OverlayTrigger>
          </div>
        </td>
      )}
      {isChecked && (
        <td>
          <div>
            <b>nummer: </b>
            {item.uomid}
          </div>
          <div>
            <b>actief: </b>
            {item.active}
          </div>
          <div>
            <b>omschrijving: </b>
            {isAdmin && (
              <input
                type="text"
                className="form-control"
                value={uom}
                onChange={(e) => setValue1(e.currentTarget.value)}
                maxLength={sizeLine1}
              />
            )}
            {!isAdmin && <h6>{item.uom}</h6>}
          </div>
          <div>
            <b>lange omschrijving: </b>
            {isAdmin && (
              <input
                type="text"
                className="form-control"
                value={uomlong}
                onChange={(e) => setValue2(e.currentTarget.value)}
                maxLength={sizeLine2}
              />
            )}
            {!isAdmin && <h6>{item.uomlong}</h6>}
          </div>
        </td>
      )}
    </tr>
  );
};

export default UomRow;
