//
// Purpose: SAMB - product service
// Author: Ronny Van Elewyck
// Version: 1.2
// Creation Date: 7.04.2020
// Modification Date: 07.02.2021
//

import axios from "axios";
import {
  basisUrlSam,
  prd,
  p1,
  p2,
  p3,
  p4,
  p5,
  p6,
  p7,
  p8,
  p9,
  p10,
  p11,
  p12,
  p13,
  p14,
  p15,
  p16,
  p17,
  p18,
  p19,
  p20,
  p21,
  p22,
  p23,
  p24,
  p25,
  p26,
  p27,
} from "../config.json";

export async function productDetails(session) {
  //
  // get all products
  //

  // variables
  let param1 = "";
  const getPHP = "getProducts.php";

  // sam instance
  if (session.actdb === prd) {
    param1 = session.prd;
  } else {
    param1 = session.dev;
  }
  // url
  const urlGet = basisUrlSam + getPHP + p1 + param1;

  //console.log("urlGet: ", urlGet);

  // async call
  const { data: response } = await axios.get(urlGet);
  const jsonReturn = response.DBRETURN;
  const productList = response.ALLPRODUCTS;

  return {
    jsonReturn,
    productList,
  };
}

export async function allAllergen(session) {
  //
  // get all allergen for dropdown
  //

  // variables
  let param1 = "";
  const getPHP = "getAllergen2.php";

  // sam instance
  if (session.actdb === prd) {
    param1 = session.prd;
  } else {
    param1 = session.dev;
  }
  // url
  const urlGet = basisUrlSam + getPHP + p1 + param1;

  ////console.log("urlGet: ",urlGet);

  // async call
  const { data: response } = await axios.get(urlGet);
  const jsonReturn = response.DBRETURN;
  const allergenList = response.ALLALLERGEN;

  return {
    jsonReturn,
    allergenList,
  };
}

export async function allUom(session) {
  //
  // get all uom for dropdown
  //

  // variables
  let param1 = "";
  const getPHP = "getUom.php";

  // sam instance
  if (session.actdb === prd) {
    param1 = session.prd;
  } else {
    param1 = session.dev;
  }
  // url
  const urlGet = basisUrlSam + getPHP + p1 + param1;

  ////console.log("urlGet: ",urlGet);

  // async call
  const { data: response } = await axios.get(urlGet);
  const jsonReturn = response.DBRETURN;
  const uomList = response.ALLUOM;

  return {
    jsonReturn,
    uomList,
  };
}

export async function uomDetails(session) {
  //
  // get all uom
  //

  // variables
  let param1 = "";
  const getPHP = "getUom2.php";

  // sam instance
  if (session.actdb === prd) {
    param1 = session.prd;
  } else {
    param1 = session.dev;
  }
  // url
  const urlGet = basisUrlSam + getPHP + p1 + param1;

  ////console.log("urlGet: ",urlGet);

  // async call
  const { data: response } = await axios.get(urlGet);
  const jsonReturn = response.DBRETURN;
  const uomList = response.ALLUOM;

  return {
    jsonReturn,
    uomList,
  };
}

export async function insertProduct(productRow) {
  //
  // insert goal
  //

  // variables
  const getPHP = "insertProduct.php";

  // url
  const urlPut =
    basisUrlSam +
    getPHP +
    p1 +
    productRow.p1 +
    p2 +
    productRow.p2 +
    p3 +
    productRow.p3 +
    p4 +
    productRow.p4 +
    p5 +
    productRow.p5 +
    p6 +
    productRow.p6 +
    p7 +
    productRow.p7 +
    p8 +
    productRow.p8 +
    p9 +
    productRow.p9 +
    p10 +
    productRow.p10 +
    p11 +
    productRow.p11 +
    p12 +
    productRow.p12 +
    p13 +
    productRow.p13 +
    p14 +
    productRow.p14 +
    p15 +
    productRow.p15 +
    p16 +
    productRow.p16 +
    p17 +
    productRow.p17 +
    p18 +
    productRow.p18 +
    p19 +
    productRow.p19 +
    p20 +
    productRow.p20 +
    p21 +
    productRow.p21 +
    p22 +
    productRow.p22 +
    p23 +
    productRow.p23 +
    p24 +
    productRow.p24 +
    p25 +
    productRow.p25;

  ////console.log(urlPut);

  // async call
  const { data: response } = await axios.get(urlPut);
  const jsonReturn = response.DBRETURN;

  return {
    jsonReturn,
  };
}

export async function updateProduct(productRow) {
  //
  // insert goal
  //

  // variables
  const getPHP = "updateProduct.php";

  // url
  const urlPut =
    basisUrlSam +
    getPHP +
    p1 +
    productRow.p1 +
    p2 +
    productRow.p2 +
    p3 +
    productRow.p3 +
    p4 +
    productRow.p4 +
    p5 +
    productRow.p5 +
    p6 +
    productRow.p6 +
    p7 +
    productRow.p7 +
    p8 +
    productRow.p8 +
    p9 +
    productRow.p9 +
    p10 +
    productRow.p10 +
    p11 +
    productRow.p11 +
    p12 +
    productRow.p12 +
    p13 +
    productRow.p13 +
    p14 +
    productRow.p14 +
    p15 +
    productRow.p15 +
    p16 +
    productRow.p16 +
    p17 +
    productRow.p17 +
    p18 +
    productRow.p18 +
    p19 +
    productRow.p19 +
    p20 +
    productRow.p20 +
    p21 +
    productRow.p21 +
    p22 +
    productRow.p22 +
    p23 +
    productRow.p23 +
    p24 +
    productRow.p24 +
    p25 +
    productRow.p25 +
    p26 +
    productRow.p26 +
    p27 +
    productRow.p27;

  //console.log(urlPut);

  // async call
  const { data: response } = await axios.get(urlPut);
  const jsonReturn = response.DBRETURN;

  return {
    jsonReturn,
  };
}

export async function insertUom(session, uom, uomlong) {
  //
  // insert user course
  //

  // variables
  let param1 = "";
  const getPHP = "insertUom.php";

  // sam instance
  if (session.actdb === prd) {
    param1 = session.prd;
  } else {
    param1 = session.dev;
  }
  // url
  const urlPut = basisUrlSam + getPHP + p1 + param1 + p2 + uom + p3 + uomlong;

  ////console.log(urlPut);

  // async call
  const { data: response } = await axios.get(urlPut);
  const jsonReturn = response.DBRETURN;

  return {
    jsonReturn,
  };
}

export async function updateUom(session, uomid, active, uom, uomlong) {
  //
  // insert user course
  //

  // variables
  let param1 = "";
  const getPHP = "updateUom.php";

  // sam instance
  if (session.actdb === prd) {
    param1 = session.prd;
  } else {
    param1 = session.dev;
  }
  // url
  const urlPut =
    basisUrlSam +
    getPHP +
    p1 +
    param1 +
    p2 +
    uomid +
    p3 +
    active +
    p4 +
    uom +
    p5 +
    uomlong;

  ////console.log(urlPut);

  // async call
  const { data: response } = await axios.get(urlPut);
  const jsonReturn = response.DBRETURN;

  return {
    jsonReturn,
  };
}

export async function deleteUom(session, uomid) {
  //
  // delete unused uom
  //

  // variables
  let param1 = "";
  const deletePHP = "deleteUnusedUom.php";

  // sam instance
  if (session.actdb === prd) {
    param1 = session.prd;
  } else {
    param1 = session.dev;
  }
  // url
  const urlDelete = basisUrlSam + deletePHP + p1 + param1 + p2 + uomid;

  //console.log(urlDelete);

  // async call
  const { data: response } = await axios.get(urlDelete);
  const jsonReturn = response.DBRETURN;
  const productReturn = response.PRODUCTS;
  const subtask2Return = response.SUBTASK2;
  return {
    jsonReturn,
    productReturn,
    subtask2Return,
  };
}

export async function deleteProduct(session, productid1) {
  //
  // delete unused product
  //

  // variables
  let param1 = "";
  const deletePHP = "deleteUnusedProduct.php";

  // sam instance
  if (session.actdb === prd) {
    param1 = session.prd;
  } else {
    param1 = session.dev;
  }
  // url
  const urlDelete = basisUrlSam + deletePHP + p1 + param1 + p2 + productid1;

  //console.log(urlDelete);

  // async call
  const { data: response } = await axios.get(urlDelete);
  const jsonReturn = response.DBRETURN;
  const subtask1Return = response.SUBTASK1;
  const subtask2Return = response.SUBTASK2;
  return {
    jsonReturn,
    subtask1Return,
    subtask2Return,
  };
}

export async function whereUsedProduct(session, productid1) {
  //
  // where used product
  //

  // variables
  let param1 = "";
  const getPHP = "whereUsedProducts.php";

  // sam instance
  if (session.actdb === prd) {
    param1 = session.prd;
  } else {
    param1 = session.dev;
  }
  // url
  const urlGet = basisUrlSam + getPHP + p1 + param1 + p2 + productid1;

  //console.log(urlGet);

  // async call
  const { data: response } = await axios.get(urlGet);
  const jsonReturn = response.DBRETURN;
  const whereUsedReturn = response.WHEREUSED;
  return {
    jsonReturn,
    whereUsedReturn,
  };
}

// eslint-disable-next-line
export default {
  whereUsedProduct,
  productDetails,
  allAllergen,
  allUom,
  updateProduct,
  insertProduct,
  uomDetails,
  updateUom,
  insertUom,
  deleteProduct,
  deleteUom,
};
