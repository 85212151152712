//
// Purpose: SAMB - group Row
// Author: Ronny Van Elewyck
// Version: 1.0
// Creation Date: 04.06.2020
// Modification Date: 26.08.2020
//

// installed components
import React from "react";
import Tooltip from "react-bootstrap/Tooltip";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";

// config files
import { saveClass5, copyClass5, deleteClass5 } from "../../config.json";

const GroupRow = ({ item, changeItem, copyItem, deleteItem, isAdmin }) => {
  //
  // format row of table
  //

  const [grouptext, setValue1] = React.useState(item.grouptext);

  let sizeLine = 20;

  function renderTooltip(title) {
    return <Tooltip id="button-tooltip">{title}</Tooltip>;
  }

  return (
    <tr>
      {isAdmin && (
        <td>
          <div>
            <OverlayTrigger
              placement="left"
              delay={{ show: 250, hide: 400 }}
              overlay={renderTooltip("bewaar")}
            >
              <div>
                <button
                  className={saveClass5}
                  onClick={() => changeItem({ ...item, grouptext })}
                ></button>
              </div>
            </OverlayTrigger>
          </div>
        </td>
      )}
      {isAdmin && (
        <td>
          <div>
            <OverlayTrigger
              placement="left"
              delay={{ show: 250, hide: 400 }}
              overlay={renderTooltip("kopieer")}
            >
              <div>
                <button
                  className={copyClass5}
                  onClick={() => copyItem({ ...item })}
                ></button>
              </div>
            </OverlayTrigger>
          </div>
        </td>
      )}
      {isAdmin && (
        <td>
          <div>
            <OverlayTrigger
              placement="left"
              delay={{ show: 250, hide: 400 }}
              overlay={renderTooltip("verwijder/activeer/deactiveer")}
            >
              <div>
                <button
                  className={deleteClass5}
                  onClick={() => deleteItem({ ...item })}
                ></button>{" "}
              </div>
            </OverlayTrigger>
          </div>
        </td>
      )}
      <td>
        <div>
          <b>nummer: </b>
          {item.groupid1}
        </div>
        <div>
          <b>actief: </b>
          {item.active}
        </div>
        <div>
          <b>naam: </b>
          {isAdmin && (
            <input
              type="text"
              className="form-control"
              value={grouptext}
              onChange={(e) => setValue1(e.currentTarget.value)}
              maxLength={sizeLine}
            />
          )}
          {!isAdmin && <h6>{item.grouptext}</h6>}
        </div>
      </td>
    </tr>
  );
};

export default GroupRow;
