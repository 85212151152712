//
// Purpose: SAMB - login
// Author: Ronny Van Elewyck
// Version: 1.0
// Creation Date: 09.03.2020
// Modification Date: 10.09.2020
//

// installed components
import React from "react";
import Joi from "joi-browser";
import { Beforeunload } from "react-beforeunload";

// created components
import Form from "./common/form";

// rest service
import auth from "../services/authService";

// config files
import {
  errorMessage1,
  errorMessage2,
  errorMessage3,
  //errorMessage4,
  companyImage,
  companyText,
  logoHeight,
  logoWidth,
  sam1Image,
  sam1Text,
  sam1Height,
  sam1Width,
  versionApp,
  loginError1,
  loginError2,
  classVersion,
  //privacyNotice,
  //privacyUrl,
  //serviceNotice,
  //serviceUrl,
  noError,
} from "../config.json";

class LoginForm extends Form {
  //
  // login form
  //

  // local data storage
  state = {
    data: { username: "", password: "" },
    errors: {},
    errorFlag: 0,
  };

  // will not change so no need to be part of the state
  // schema used to check validity
  schema = {
    username: Joi.string()
      .required()
      .error(() => {
        return {
          message: loginError1,
        };
      }),
    password: Joi.string()
      .required()
      .error(() => {
        return {
          message: loginError2,
        };
      }),
  };

  doSubmit = async () => {
    //
    // executed after submitting logon and password
    //

    // from loacl state
    const userName = this.state.data.username;
    const passWord = this.state.data.password;

    // execute rest service
    const session = await auth.login(userName, passWord);

    //console.log("loginForm: ", session[0]);

    //if (session[0].sambversion === versionApp) {
    // check return
    if (session[0].errorcode === noError) {
      // check if role is 'beheer' or 'begeleider'
      if (session[0].roleid === "1" || session[0].roleid === "3") {
        // execute rest service
        const user = await auth.samDetails(session[0]);
        if (user[0].errorcode === noError) {
          this.setState({ flag: 1 });
          // set 'beheer' or 'begeleider' global paramaters
          this.props.onHandleLogin(session[0].roleid, session[0].logon);
          // save seccion parameters global in App.js
          this.props.onSaveSession(session[0]);
          // save user (login) parameters global in App.js
          this.props.onSaveUser(user[0]);
        } else {
          // no lisence or other error
          const errors = { ...this.state.errors };
          errors.username = errorMessage3;
          // rest service to clean up
          await auth.cleanSession(userName);
          // update local state
          this.setState({ errors, flag: 2 });
        }
      } else {
        // is not role is 'beheer' or 'begeleider'
        const errors = { ...this.state.errors };
        errors.username = errorMessage2;
        // rest service to clean up
        await auth.cleanSession(userName);
        // update local state
        this.setState({ errors, flag: 2 });
      }
    } else {
      // user or password error
      const errors = { ...this.state.errors };
      errors.username = errorMessage1;
      // update local state
      this.setState({ errors, flag: 2 });
    }
    /* } else {
      // user or password error
      const errors = { ...this.state.errors };
      errors.username = errorMessage4;
      errors.password = errorMessage4;
      // update local state
      this.setState({ errors, flag: 2 });
    }*/
  };

  render() {
    return (
      <div>
        {/* show message on refresh in browser */}
        <Beforeunload onBeforeunload={(event) => event.preventDefault()} />
        {/* make sure new version of javascript is downloaded from server */}

        <br></br>
        {/* error message */}

        {/* header with school and vendor */}
        <div className="d-flex">
          <div>
            <img
              src={sam1Image}
              alt={sam1Text}
              height={sam1Height}
              width={sam1Width}
            />
          </div>
          <div className="ml-auto">
            <img
              src={companyImage}
              alt={companyText}
              height={logoHeight}
              width={logoWidth}
            />
          </div>
        </div>
        <br></br>
        {/* logon input and button */}
        <form onSubmit={this.handleSubmit}>
          {this.renderInput("username", "gebruiker")}
          {this.renderInput("password", "paswoord", "password")}
          {this.renderButton("aanmelden")}
        </form>

        {/* version information */}
        <br></br>
        <h4 className={classVersion}>{'SAMB versie ' + versionApp}</h4>
      </div>
    );
  }
}

export default LoginForm;
