//
// Purpose: SAMB - company Row
// Author: Ronny Van Elewyck
// Version: 1.1
// Creation Date: 04.06.2020
// Modification Date: 04.03.2021
//

// installed components
import React from "react";
import { isMobile } from "react-device-detect";

// config file
import {
  classMobile1,
} from "../../config.json";


const CompanyRow = ({ item }) => {
  //
  // format row of table
  //

  return (
    <tr>
      {!isMobile && <td>{item.text1}</td>}
      {!isMobile && (
        <td>
          <img
            src={item.imageurl}
            alt="logo instelling onbeschikbaar"
            height="100"
            width="200"
          />
        </td>
      )}
      {!isMobile && (
        <td>
          <div className="mt-2">
            <b>beheerder: </b> {item.nbinuse1}/{item.nbuser1}
          </div>
          <div className="mt-2">
            <b>begeleider: </b>
            {item.nbinuse3}/{item.nbuser3}
          </div>
          <div className="mt-2">
            <b>leerling: </b> {item.nbinuse2}/{item.nbuser2}
          </div>
        </td>
      )}
      {!isMobile && (
        <td>
          <div className="mt-2">
            <b>SAM instellingen beschermen:</b> {item.protectsettings}
          </div>
          <div className="mt-2">
            <b>pincode: </b> {item.pincode}
          </div>
        </td>
      )}

      {isMobile && (
        <td >
          <h5 className={classMobile1}>
            <b>{item.text1}</b>
          </h5>
          <div className={classMobile1}>          
          <img
            src={item.imageurl}
            alt="logo instelling onbeschikbaar"
            height="80"
            width="160"
          />
          </div>
          <h6 className={classMobile1}>
            beheerder: {item.nbinuse1}/{item.nbuser1}{" "}
          </h6>
          <h6 className={classMobile1}>
            begeleider:{item.nbinuse3}/{item.nbuser3}
          </h6>
          <h6 className={classMobile1}>
            leerling: {item.nbinuse2}/{item.nbuser2}{" "}
          </h6>
          <h6 className={classMobile1}>bescherm SAM: {item.protectsettings}</h6>
          <h6 className={classMobile1}>pincode: {item.pincode}</h6>
        </td>
      )}
    </tr>
  );
};

export default CompanyRow;
