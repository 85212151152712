//
// Purpose: SAMB - goal service
// Author: Ronny Van Elewyck
// Version: 1.1
// Creation Date: 05.03.2020
// Modification Date: 04.12.2020
//

import axios from "axios";
import { basisUrlSam, prd, p1, p2, p3, p4, p5 } from "../config.json";

export async function goalDetails(session) {
  //
  // get all goal details for company
  //

  // variables
  let param1 = "";
  const getPHP = "getAllGoals.php";

  // sam instance
  if (session.actdb === prd) {
    param1 = session.prd;
  } else {
    param1 = session.dev;
  }
  // url
  const urlGet = basisUrlSam + getPHP + p1 + param1;

  //console.log(urlGet);

  // async call
  const { data: response } = await axios.get(urlGet);
  const jsonReturn = response.DBRETURN;
  const goalsList = response.ALLGOALS;

  return {
    jsonReturn,
    goalsList,
  };
}

export async function insertGoal(session, groupid, mastercourseid, goaltext) {
  //
  // insert goal
  //

  // variables
  let param1 = "";
  const getPHP = "insertGoal.php";

  // sam instance
  if (session.actdb === prd) {
    param1 = session.prd;
  } else {
    param1 = session.dev;
  }
  // url
  const urlPut =
    basisUrlSam +
    getPHP +
    p1 +
    param1 +
    p2 +
    groupid +
    p3 +
    mastercourseid +
    p4 +
    goaltext;

  //console.log(urlPut);

  // async call
  const { data: response } = await axios.get(urlPut);
  const jsonReturn = response.DBRETURN;

  return {
    jsonReturn,
  };
}

export async function updateGoal(
  session,
  goalid1,
  groupid,
  mastercourseid,
  goaltext
) {
  //
  // update goal
  //

  // variables
  let param1 = "";
  const getPHP = "updateGoal.php";

  // sam instance
  if (session.actdb === prd) {
    param1 = session.prd;
  } else {
    param1 = session.dev;
  }
  // url
  const urlPut =
    basisUrlSam +
    getPHP +
    p1 +
    param1 +
    p2 +
    goalid1 +
    p3 +
    groupid +
    p4 +
    mastercourseid +
    p5 +
    goaltext;

  //console.log(urlPut);

  // async call
  const { data: response } = await axios.get(urlPut);
  const jsonReturn = response.DBRETURN;

  return {
    jsonReturn,
  };
}

export async function deleteGoal(session, goalid1) {
  //
  // delete goal row
  //

  // variables
  let param1 = "";
  const putPHP = "deleteGoal.php";

  // sam instance
  if (session.actdb === prd) {
    param1 = session.prd;
  } else {
    param1 = session.dev;
  }
  // url
  const urlPut = basisUrlSam + putPHP + p1 + param1 + p2 + goalid1;

  //console.log(urlPut);

  // async call
  const { data: response } = await axios.get(urlPut);
  const jsonReturn = response.DBRETURN;

  return {
    jsonReturn,
  };
}

export async function deleteMassGoal(session, groupid, mastercourseid) {
  //
  // delete goal row
  //

  // variables
  let param1 = "";
  const putPHP = "deleteGoal2.php";

  // sam instance
  if (session.actdb === prd) {
    param1 = session.prd;
  } else {
    param1 = session.dev;
  }
  // url
  const urlPut =
    basisUrlSam + putPHP + p1 + param1 + p2 + groupid + p3 + mastercourseid;

  //console.log(urlPut);

  // async call
  const { data: response } = await axios.get(urlPut);
  const jsonReturn = response.DBRETURN;

  return {
    jsonReturn,
  };
}

// eslint-disable-next-line
export default {
  goalDetails,
  insertGoal,
  updateGoal,
  deleteGoal,
  deleteMassGoal,
};
