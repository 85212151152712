//
// Purpose: SAMB - materials screen
// Author: Ronny Van Elewyck
// Version: 1.2
// Creation Date: 07.04.2020
// Modification Date: 12.02.2021
//

// installed components
import React, { Component } from "react";
import _ from "lodash";

// created components
import NavBar from "./navBar";
import MaterialTable from "./common/materialTable";
import UomTable from "./common/uomTable";
import TopMaterialButton from "./common/topMaterialButton";
import TopUOMButton from "./common/topUOMButton";
import TopWaitingMessage from "./common/topWaitingMessage";
import UploadImageForm from "./common/uploadImageForm";
import NewMaterialForm from "./common/newMaterialForm";
import NewUOMForm from "./common/newUOMForm";
import NodeTable from "./common/nodeTable";
import ExportCSV from "./common/exportCSV";

// utilities
import { createImageRecord } from "../utils/imageHelpers";
import { checkCharacters } from "../utils/specialCharacters";

// rest services
import getProduct from "../services/productService";
import putImage from "../services/imageService";

// config files
import {
  noError,
  materialsTitle,
  classTables,
  classTitle,
  materialMessage1,
  prd,
  selectionClass,
  classImageTitle,
  stepMessage31,
  classWarningMessage,
  downloadClass5,
} from "../config.json";
import {
  prefixImageProduct,
  minWidthImageProduct,
  maxWidthImageProduct,
  minHeightImageProduct,
  maxHeightImageProduct,
} from "../imageConfig.json";
import imageService from "../services/imageService";

class Materials extends Component {
  //
  // materials screen
  //

  // local data
  state = {
    materialList: [],
    allMaterialList: [],
    downloadMaterialList: [],
    uomList: [],
    uomList2: [],
    downloadUOMList: [],
    allUomList2: [],
    searchQuery: "",
    searchQueryUOM: "",
    isLoaded: true,
    fileName1: "SAMDOWNLOADMATERIAAL",
    fileName2: "SAMDOWNLOADUOM",
    isUom: false,
    isMaterial: false,
    imageRecord: undefined,
    isUploadImage: false,
    isImageSaved: true,
    isNewMaterial: false,
    isNewUOM: false,
    numberOfMaterial: 30,
    fileName: "SAMBDOWNLOAD",
    whereUsedList: [],
  };

  async componentDidMount() {
    //
    // mount after
    //
    let materialList = [];
    let allMaterialList = [];
    let uomList = [];
    let uomList2 = [];
    let allUomList2 = [];
    let downloadMaterialList = [];
    let downloadUOMList = [];

    //console.log("componentDidMount");

    // prep image record
    const imageRecord = createImageRecord();
    this.setState({
      imageRecord,
    });

    // always refresh material
    this.refreshMaterial();

    if (typeof this.props.materialList !== "undefined") {
      allMaterialList = this.props.materialList;
      //materialList = allMaterialList.slice(0, this.state.numberOfMaterial);
      materialList = allMaterialList;
    }

    if (typeof this.props.uomList !== "undefined") {
      uomList = _.sortBy(this.props.uomList, function (item) {
        return item.uom;
      });
    }

    if (typeof this.props.uomList2 !== "undefined") {
      uomList2 = _.sortBy(this.props.uomList2, function (item) {
        return item.uom;
      });
      allUomList2 = uomList2;
    }
    ////console.log(uomList, uomList2);

    // download version for list
    downloadMaterialList = this.createDownloadList1(materialList);
    // download version for list
    downloadUOMList = this.createDownloadList2(uomList2);

    this.setState({
      materialList,
      allMaterialList,
      downloadMaterialList,
      downloadUOMList,
      uomList,
      uomList2,
      allUomList2,
    });
  }

  createDownloadList1 = (listArray) => {
    //
    // create download list material
    //

    let downloadList = [];

    for (let index = 0; index < listArray.length; index++) {
      downloadList.push({
        nummer: listArray[index].productid1,
        omschrijving: listArray[index].producttext,
        soort: listArray[index].producttype,
        foto: listArray[index].imageurl,
        eenheid1: listArray[index].uomshort,
        eenheid2: listArray[index].uomlong,
        allergeen1: listArray[index].text1,
        allergeen2: listArray[index].text2,
        allergeen3: listArray[index].text3,
        allergeen4: listArray[index].text4,
        allergeen5: listArray[index].text5,
        allergeen6: listArray[index].text6,
        allergeen7: listArray[index].text7,
        allergeen8: listArray[index].text8,
        allergeen9: listArray[index].text9,
        allergeen10: listArray[index].text10,
        allergeen11: listArray[index].text11,
        allergeen12: listArray[index].text12,
        allergeen13: listArray[index].text13,
        allergeen14: listArray[index].text14,
        allergeen15: listArray[index].text15,
        actief: listArray[index].active,
      });
    }

    return downloadList;
  };

  createDownloadList2 = (listArray) => {
    //
    // create download list UOM
    //

    let downloadList = [];

    for (let index = 0; index < listArray.length; index++) {
      downloadList.push({
        nummer: listArray[index].uomid,
        eenheid1: listArray[index].uom,
        eenheid2: listArray[index].uomlong,
        actief: listArray[index].active,
      });
    }

    return downloadList;
  };

  handleSearch = (query) => {
    //
    // execute search filter
    //

    let materialList = [];
    let downloadMaterialList = [];

    const { allMaterialList } = this.state;

    if (query !== "") {
      materialList = allMaterialList.filter((m) =>
        m.searchfield.toLowerCase().includes(query.toLowerCase())
      );
      if (materialList.length === 0) {
        materialList = allMaterialList.filter((m) =>
          m.producttext.toLowerCase().includes(query.toLowerCase())
        );
      }

      // download version for list
      downloadMaterialList = this.createDownloadList1(materialList);
    } else {
      this.refreshMaterial();
    }

    this.setState({
      materialList,
      downloadMaterialList,
      searchQuery: query,
    });
  };

  handleSearchUOM = (query) => {
    //
    // execute search filter
    //

    let uomList2 = [];
    let downloadUOMList = [];
    const { allUomList2 } = this.state;

    ////console.log("allUomList2: ", allUomList2);
    if (query !== "") {
      uomList2 = allUomList2.filter((m) =>
        m.uom.toLowerCase().includes(query.toLowerCase())
      );
      if (uomList2.length === 0) {
        uomList2 = allUomList2.filter((m) =>
          m.uomlong.toLowerCase().includes(query.toLowerCase())
        );
      }
    } else {
      uomList2 = allUomList2;
    }

    // download version for list
    downloadUOMList = this.createDownloadList1(uomList2);

    this.setState({
      downloadUOMList,
      uomList2,
      searchQueryUOM: query,
    });
  };

  refreshUOM = async () => {
    //
    // refresh uom data
    //
    this.setState({
      uomList: [],
      uomList2: [],
      allUomList2: [],
      isLoaded: false,
    });

    let uomList = [];
    let uomList2 = [];
    let allUomList2 = [];
    let downloadUOMList = [];

    const { session } = this.props;

    // uom get dropdown
    const result3 = await getProduct.allUom(session);
    const dbreturnList = result3.jsonReturn;

    if (dbreturnList[0].errorcode === noError) {
      uomList = _.sortBy(result3.uomList, function (item) {
        return item.uom;
      });

      // uom get details
      const result4 = await getProduct.uomDetails(session);
      const dbreturnList = result4.jsonReturn;
      if (dbreturnList[0].errorcode === noError) {
        // sort
        uomList2 = _.sortBy(result4.uomList, function (item) {
          return item.uom;
        });
        allUomList2 = uomList2;

        // download version for list
        downloadUOMList = this.createDownloadList2(uomList2);

        // global save
        this.props.onSaveUOMList(uomList);
        this.props.onSaveUOMList2(uomList2);

        // local state
        this.setState({
          uomList,
          uomList2,
          allUomList2,
          downloadUOMList,
          isLoaded: true,
        });
      } else {
        this.setState({
          uomList,
          uomList2,
          allUomList2,
          downloadUOMList,
          isLoaded: false,
        });
      }
    } else {
      this.setState({
        uomList,
        uomList2,
        allUomList2,
        downloadUOMList,
        isLoaded: false,
      });
    }
  };

  refreshMaterial = async () => {
    //
    // refresh material data
    //

    //console.log("refreshMaterial");

    this.setState({
      materialList: [],
      allMaterialList: [],
      isLoaded: false,
      isUploadImage: false,
      isWhereUsed: false,
    });

    let materialList = [];
    let allMaterialList = [];
    let downloadMaterialList = [];

    const { session } = this.props;

    // products get
    const result1 = await getProduct.productDetails(session);
    const dbreturnList = result1.jsonReturn;

    if (dbreturnList[0].errorcode === noError) {
      allMaterialList = _.sortBy(result1.productList, function (item) {
        return item.searchfield;
      });

      this.props.onSaveMaterialList(allMaterialList);
      //materialList = allMaterialList.slice(0, this.state.numberOfMaterial);
      materialList = allMaterialList;
      // download version for list
      downloadMaterialList = this.createDownloadList1(materialList);

      this.setState({
        downloadMaterialList,
        materialList,
        allMaterialList,
        isLoaded: true,
      });
    } else {
      this.setState({
        downloadMaterialList,
        materialList,
        allMaterialList,
        isLoaded: false,
      });
    }
  };

  handleMaterial = () => {
    //
    // show material input screen
    //

    if (this.state.isMaterial) {
      this.setState({
        isUom: false,
        isMaterial: false,
        isUploadImage: false,
        isWhereUsed: false,
      });
    } else {
      this.setState({
        materialList: this.state.allMaterialList,
        isUom: false,
        isMaterial: true,
        isUploadImage: false,
        isWhereUsed: false,
      });
    }
  };

  handleUom = () => {
    //
    // show uom input screen
    //

    if (this.state.isUom) {
      this.setState({
        isUom: false,
        isMaterial: false,
        isUploadImage: false,
        isWhereUsed: false,
      });
    } else {
      this.setState({
        uomList2: this.state.allUomList2,
        isUom: true,
        isMaterial: false,
        isUploadImage: false,
        isWhereUsed: false,
      });
    }
  };

  handleNewUOM = () => {
    //
    // new
    //
    ////console.log("handleNewUOM");
    this.scrollTop();
    this.setState({
      isNewUOM: true,
    });
  };

  handleCancelNewUOMItem = () => {
    //
    // Refresh
    //

    this.setState({
      isNewUOM: false,
    });
  };

  handleNewUOMItem = async (item) => {
    //
    // Refresh
    //

    ////console.log("handleNewUOMItem", item);
    await this.copyUom(item, "new");
    this.setState({
      isNewUOM: false,
    });
  };

  scrollTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  handleNewMaterial = () => {
    //
    // new
    //

    this.scrollTop();
    this.setState({
      isNewMaterial: true,
    });
  };

  handleCancelNewMaterialItem = () => {
    //
    // Refresh
    //

    this.setState({
      isNewMaterial: false,
    });
  };

  handleNewMaterialItem = async (item) => {
    //
    // Refresh
    //

    await this.copyItem(item, "new");
    this.setState({
      isNewMaterial: false,
    });
  };

  scrollTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  render() {
    //
    // render task screen
    //

    ////////console.log("render(): ");

    // get local state variables
    const {
      materialList,
      allMaterialList,
      uomList,
      uomList2,
      //allUomList2,
      downloadMaterialList,
      downloadUOMList,
      isLoaded,
      fileName1,
      fileName2,
      isUom,
      isMaterial,
      imageRecord,
      isUploadImage,
      isImageSaved,
      searchQuery,
      searchQueryUOM,
      isNewMaterial,
      isNewUOM,
      isWhereUsed,
      whereUsedList,
      fileName,
    } = this.state;
    const { session, isAdmin, allergenList } = this.props;

    // don't render if not available
    if (typeof allMaterialList[0] === "undefined") {
      //////console.log("render: ", new Date());
      return null;
    }

    return (
      <React.Fragment>
        {/* error messages */}

        {!isLoaded && <TopWaitingMessage messageToShow={materialMessage1} />}
        {!isImageSaved && (
          <div className={classWarningMessage}>{stepMessage31}</div>
        )}

        {/* navigation bar */}
        <NavBar />

        {/* title */}
        <h4>
          <span className={classTitle}>{materialsTitle}</span>
        </h4>

        {/* whereused product */}
        {isAdmin && isWhereUsed && (
          <div className={classTables}>
            <h3>Waar wordt materiaal gebruikt?</h3>

            <ExportCSV
              csvData={whereUsedList}
              fileName={fileName}
              className={downloadClass5}
            />
            <NodeTable nodeList={whereUsedList} />
          </div>
        )}

        {/* upload image */}
        {isAdmin && isUploadImage && (
          <div className={classImageTitle}>
            <UploadImageForm
              session={session}
              imageRecord={imageRecord}
              handleImageSave={this.handleImageSave}
              cancelImageUpload={this.cancelImageUpload}
            />
          </div>
        )}

        {/* new material */}
        {isAdmin && isNewMaterial && (
          <div className={classTables}>
            <NewMaterialForm
              newItem={this.handleNewMaterialItem}
              cancelItem={this.handleCancelNewMaterialItem}
              uomList={uomList}
              allergenList={allergenList}
            />
          </div>
        )}

        {/* new uom */}
        {isAdmin && isNewUOM && (
          <div className={classTables}>
            <NewUOMForm
              newItem={this.handleNewUOMItem}
              cancelItem={this.handleCancelNewUOMItem}
            />
          </div>
        )}

        {/* material data  */}
        <div>
          <button
            type="button"
            className={selectionClass}
            onClick={() => this.handleMaterial()}
          >
            Materialen/Producten
          </button>
        </div>
        {/* material table */}
        {isMaterial && (
          <div>
            <TopMaterialButton
              listArray={downloadMaterialList}
              handleRefresh={this.handleSearch}
              fileName={fileName1}
              screenFilter={searchQuery}
              handleNew={this.handleNewMaterial}
              isAdmin={isAdmin}
            />
          </div>
        )}

        {isLoaded && isMaterial && (
          <div className={classTables}>
            <MaterialTable
              materialList={materialList}
              changeItem={this.changeItem}
              copyItem={this.copyItem}
              deleteItem={this.deleteItem}
              allergenList={allergenList}
              uomList={uomList}
              changeImage={this.changeImage}
              showWhereUsed={this.showWhereUsed}
              isAdmin={isAdmin}
            />
          </div>
        )}

        {/* uom data */}
        <div>
          <button
            type="button"
            className={selectionClass}
            onClick={() => this.handleUom()}
          >
            Eenheden
          </button>
        </div>
        {/* uom table */}
        {isUom && (
          <div>
            <TopUOMButton
              listArray={downloadUOMList}
              handleRefresh={this.handleSearchUOM}
              fileName={fileName2}
              screenFilter={searchQueryUOM}
              handleNew={this.handleNewUOM}
              isAdmin={isAdmin}
            />
          </div>
        )}
        {isLoaded && isUom && (
          <div className={classTables}>
            <UomTable
              uomList2={uomList2}
              changeItem={this.changeUom}
              copyItem={this.copyUom}
              deleteItem={this.deleteUomItem}
              isAdmin={isAdmin}
            />
          </div>
        )}
      </React.Fragment>
    );
  }

  /*****************/
  /* row functions */
  /*****************/
  checkAllergen(allergen, allergenText) {
    const { allergenList } = this.props;

    let allergenId = "0";

    if (allergenText === allergen) {
      for (let index = 0; index < allergenList.length; index++) {
        if (allergenList[index].label === allergenText) {
          allergenId = allergenList[index].id.toString();
          break;
        }
      }
    }
    if (allergenId === "0") {
      if (allergen !== null && typeof allergen !== "undefined") {
        allergenId = allergen.id;
      }
    }

    return allergenId;
  }

  changeItem = async (itemData) => {
    //
    // change item
    //
    let node = "";
    let allergen1 = "0";
    let allergen2 = "0";
    let allergen3 = "0";
    let allergen4 = "0";
    let allergen5 = "0";
    let allergen6 = "0";
    let allergen7 = "0";
    let allergen8 = "0";
    let allergen9 = "0";
    let allergen10 = "0";
    let allergen11 = "0";
    let allergen12 = "0";
    let allergen13 = "0";
    let allergen14 = "0";
    let allergen15 = "0";
    let allergen16 = "0";
    let allergen17 = "0";
    let allergen18 = "0";
    let allergen19 = "0";
    let allergen20 = "0";

    this.setState({
      materialList: [],
      allMaterialList: [],
      isUpdateError: false,
      isUploadImage: false,
      isWhereUsed: false,
    });

    const { session } = this.props;

    if (session.actdb === prd) {
      node = session.prd;
    } else {
      node = session.dev;
    }

    // producttype dropdown
    if (
      itemData.producttype !== null &&
      typeof itemData.producttype.id !== "undefined" &&
      typeof itemData.producttype.label !== "undefined"
    ) {
      itemData.producttype = itemData.producttype.label;
    }
    // uom dropdown
    if (itemData.uomid === null) {
      itemData.uomid = 0;
    }
    if (itemData.uomlong === null) {
      itemData.uomlong = 0;
    }
    if (
      typeof itemData.uomlong.id !== "undefined" &&
      typeof itemData.uomlong.label !== "undefined"
    ) {
      itemData.uomid = itemData.uomlong.id;
    }

    if (itemData.producttype === "ingredienten") {
      allergen1 = this.checkAllergen(itemData.allergen1, itemData.text1);
      allergen2 = this.checkAllergen(itemData.allergen2, itemData.text2);
      allergen3 = this.checkAllergen(itemData.allergen3, itemData.text3);
      allergen4 = this.checkAllergen(itemData.allergen4, itemData.text4);
      allergen5 = this.checkAllergen(itemData.allergen5, itemData.text5);
      allergen6 = this.checkAllergen(itemData.allergen6, itemData.text6);
      allergen7 = this.checkAllergen(itemData.allergen7, itemData.text7);
      allergen8 = this.checkAllergen(itemData.allergen8, itemData.text8);
      allergen9 = this.checkAllergen(itemData.allergen9, itemData.text9);
      allergen10 = this.checkAllergen(itemData.allergen10, itemData.text10);
      allergen11 = this.checkAllergen(itemData.allergen11, itemData.text11);
      allergen12 = this.checkAllergen(itemData.allergen12, itemData.text12);
      allergen13 = this.checkAllergen(itemData.allergen13, itemData.text13);
      allergen14 = this.checkAllergen(itemData.allergen14, itemData.text14);
      allergen15 = this.checkAllergen(itemData.allergen15, itemData.text15);
    }

    // special characters
    const convertedGroupText = checkCharacters(itemData.producttext);

    const productRow = {
      p1: node,
      p2: itemData.productid1,
      p3: itemData.active,
      p4: convertedGroupText,
      p5: itemData.producttype,
      p6: itemData.price,
      p7: itemData.uomid,
      p8: allergen1,
      p9: allergen2,
      p10: allergen3,
      p11: allergen4,
      p12: allergen5,
      p13: allergen6,
      p14: allergen7,
      p15: allergen8,
      p16: allergen9,
      p17: allergen10,
      p18: allergen11,
      p19: allergen12,
      p20: allergen13,
      p21: allergen14,
      p22: allergen15,
      p23: allergen16,
      p24: allergen17,
      p25: allergen18,
      p26: allergen19,
      p27: allergen20,
    };

    // update active not-active in product
    const jsonList = await getProduct.updateProduct(productRow);
    const dbreturnList = jsonList.jsonReturn;

    if (dbreturnList[0].errorcode === noError) {
      // refresh data
      await this.refreshMaterial(itemData.productid1);
    } else {
      // error
      this.setState({
        isUpdateError: true,
      });
    }
  };

  copyItem = async (itemData, type) => {
    //
    // copy item
    //
    //////console.log("copyItem: ", itemData);

    let node = "";

    this.setState({
      materialList: [],
      allMaterialList: [],
      isUpdateError: false,
      isUploadImage: false,
      isWhereUsed: false,
    });

    const { session } = this.props;

    if (session.actdb === prd) {
      node = session.prd;
    } else {
      node = session.dev;
    }

    // producttype dropdown
    if (
      typeof itemData.producttype.id !== "undefined" &&
      typeof itemData.producttype.label !== "undefined"
    ) {
      itemData.producttype = itemData.producttype.label;
    }

    if (type !== "new") {
      // uom not copied if copy
      itemData.uomid = 0;
    } else {
      if (
        typeof itemData.uomid.id !== "undefined" &&
        typeof itemData.uomid.label !== "undefined"
      ) {
        itemData.uomid = itemData.uomid.id;
      }
    }

    if (itemData.producttype === "ingredienten") {
      // allergen dropdown
      if (
        typeof itemData.allergen1.id !== "undefined" &&
        typeof itemData.allergen1.label !== "undefined"
      ) {
        itemData.allergen1 = itemData.allergen1.id;
      }
      if (
        typeof itemData.allergen2.id !== "undefined" &&
        typeof itemData.allergen2.label !== "undefined"
      ) {
        itemData.allergen2 = itemData.allergen2.id;
      }
      if (
        typeof itemData.allergen3.id !== "undefined" &&
        typeof itemData.allergen3.label !== "undefined"
      ) {
        itemData.allergen3 = itemData.allergen3.id;
      }
      if (
        typeof itemData.allergen4.id !== "undefined" &&
        typeof itemData.allergen4.label !== "undefined"
      ) {
        itemData.allergen4 = itemData.allergen4.id;
      }
      if (
        typeof itemData.allergen5.id !== "undefined" &&
        typeof itemData.allergen5.label !== "undefined"
      ) {
        itemData.allergen5 = itemData.allergen5.id;
      }
      if (
        typeof itemData.allergen6.id !== "undefined" &&
        typeof itemData.allergen6.label !== "undefined"
      ) {
        itemData.allergen6 = itemData.allergen6.id;
      }
      if (
        typeof itemData.allergen7.id !== "undefined" &&
        typeof itemData.allergen7.label !== "undefined"
      ) {
        itemData.allergen7 = itemData.allergen7.id;
      }
      if (
        typeof itemData.allergen8.id !== "undefined" &&
        typeof itemData.allergen8.label !== "undefined"
      ) {
        itemData.allergen8 = itemData.allergen8.id;
      }
      if (
        typeof itemData.allergen9.id !== "undefined" &&
        typeof itemData.allergen9.label !== "undefined"
      ) {
        itemData.allergen9 = itemData.allergen9.id;
      }
      if (
        typeof itemData.allergen10.id !== "undefined" &&
        typeof itemData.allergen10.label !== "undefined"
      ) {
        itemData.allergen10 = itemData.allergen10.id;
      }
      if (
        typeof itemData.allergen11.id !== "undefined" &&
        typeof itemData.allergen11.label !== "undefined"
      ) {
        itemData.allergen11 = itemData.allergen11.id;
      }
      if (
        typeof itemData.allergen12.id !== "undefined" &&
        typeof itemData.allergen12.label !== "undefined"
      ) {
        itemData.allergen12 = itemData.allergen12.id;
      }
      if (
        typeof itemData.allergen13.id !== "undefined" &&
        typeof itemData.allergen13.label !== "undefined"
      ) {
        itemData.allergen13 = itemData.allergen13.id;
      }
      if (
        typeof itemData.allergen14.id !== "undefined" &&
        typeof itemData.allergen14.label !== "undefined"
      ) {
        itemData.allergen14 = itemData.allergen14.id;
      }
      if (
        typeof itemData.allergen15.id !== "undefined" &&
        typeof itemData.allergen15.label !== "undefined"
      ) {
        itemData.allergen15 = itemData.allergen15.id;
      }
    } else {
      itemData.allergen1 = 0;
      itemData.allergen2 = 0;
      itemData.allergen3 = 0;
      itemData.allergen4 = 0;
      itemData.allergen5 = 0;
      itemData.allergen6 = 0;
      itemData.allergen7 = 0;
      itemData.allergen8 = 0;
      itemData.allergen9 = 0;
      itemData.allergen10 = 0;
      itemData.allergen11 = 0;
      itemData.allergen12 = 0;
      itemData.allergen13 = 0;
      itemData.allergen14 = 0;
      itemData.allergen15 = 0;
    }

    itemData.allergen16 = 0;
    itemData.allergen17 = 0;
    itemData.allergen18 = 0;
    itemData.allergen19 = 0;
    itemData.allergen20 = 0;

    /*  if (type !== "new") {
      itemData.producttext = "1K - " + itemData.producttext;
    }*/

    // special characters
    const convertedGroupText = checkCharacters(itemData.producttext);

    const productRow = {
      p1: node,
      p2: convertedGroupText,
      p3: itemData.producttype,
      p4: itemData.price,
      p5: itemData.uomid,
      p6: itemData.allergen1,
      p7: itemData.allergen2,
      p8: itemData.allergen3,
      p9: itemData.allergen4,
      p10: itemData.allergen5,
      p11: itemData.allergen6,
      p12: itemData.allergen7,
      p13: itemData.allergen8,
      p14: itemData.allergen9,
      p15: itemData.allergen10,
      p16: itemData.allergen11,
      p17: itemData.allergen12,
      p18: itemData.allergen13,
      p19: itemData.allergen14,
      p20: itemData.allergen15,
      p21: itemData.allergen16,
      p22: itemData.allergen17,
      p23: itemData.allergen18,
      p24: itemData.allergen19,
      p25: itemData.allergen20,
    };

    // update active not-active in product
    const jsonList = await getProduct.insertProduct(productRow);
    const dbreturnList = jsonList.jsonReturn;

    if (dbreturnList[0].errorcode === noError) {
      // refresh data
      await this.refreshMaterial(itemData.producttext);
    } else {
      // error
      this.setState({
        isUpdateError: true,
      });
    }
  };

  deleteItem = async (itemData) => {
    //
    // delete or inactivate item
    //

    let delImage = "";
    let node = "";
    let isUpdateError = false;
    this.setState({
      materialList: [],
      allMaterialList: [],
      isUploadImage: false,
      isWhereUsed: false,
    });

    const { session } = this.props;

    // try to delete material in database
    const jsonList = await getProduct.deleteProduct(
      session,
      itemData.productid1
    );

    if (jsonList.jsonReturn[0].errorcode === noError) {
      isUpdateError = false;
      // refresh data
      await this.refreshMaterial(itemData.producttext);
      // delete image
      if (itemData.image === null || itemData.image === "") {
        if (itemData.imageurl !== null) {
          const splitArray = itemData.imageurl.split("/");
          delImage = splitArray[5];
        }
      } else {
        delImage = itemData.image;
      }
      await imageService.deleteImage(session, delImage);
    } else {
      // show return json usage in array on screen?
      // put code here

      // deactivate/activate
      if (session.actdb === prd) {
        node = session.prd;
      } else {
        node = session.dev;
      }

      if (itemData.active === "X") {
        itemData.active = "";
      } else {
        itemData.active = "X";
      }

      const productRow = {
        p1: node,
        p2: itemData.productid1,
        p3: itemData.active,
        p4: itemData.producttext,
        p5: itemData.producttype,
        p6: itemData.price,
        p7: itemData.uomid,
        p8: itemData.allergen1,
        p9: itemData.allergen2,
        p10: itemData.allergen3,
        p11: itemData.allergen4,
        p12: itemData.allergen5,
        p13: itemData.allergen6,
        p14: itemData.allergen7,
        p15: itemData.allergen8,
        p16: itemData.allergen9,
        p17: itemData.allergen10,
        p18: itemData.allergen11,
        p19: itemData.allergen12,
        p20: itemData.allergen13,
        p21: itemData.allergen14,
        p22: itemData.allergen15,
        p23: itemData.allergen16,
        p24: itemData.allergen17,
        p25: itemData.allergen18,
        p26: itemData.allergen19,
        p27: itemData.allergen20,
      };

      // update active not-active in product
      const jsonList = await getProduct.updateProduct(productRow);
      const dbreturnList = jsonList.jsonReturn;

      if (dbreturnList[0].errorcode === noError) {
        isUpdateError = false;
        // refresh data
        await this.refreshMaterial(itemData.producttext);
      } else {
        // error
        isUpdateError = true;
      }
    }

    this.setState({
      isUpdateError,
    });
  };

  changeUom = async (itemData) => {
    //
    // change item
    //
    //////console.log("changeUom: ", itemData);

    this.setState({
      uomList2: [],
      isUpdateError: false,
      isUploadImage: false,
      isWhereUsed: false,
    });

    const { session } = this.props;

    // update groups
    const jsonList = await getProduct.updateUom(
      session,
      itemData.uomid,
      itemData.active,
      itemData.uom,
      itemData.uomlong
    );
    const dbreturnList = jsonList.jsonReturn;

    if (dbreturnList[0].errorcode === noError) {
      // refresh data
      await this.refreshUOM();
    } else {
      // error
      this.setState({
        isUpdateError: true,
      });
    }
  };
  copyUom = async (itemData, type) => {
    //
    // copy item
    //
    //////console.log("copyUom: ", itemData);

    this.setState({
      uomList2: [],
      allUomList2: [],
      isUpdateError: false,
      isUploadImage: false,
      isWhereUsed: false,
    });

    const { session } = this.props;

    /*  if (type !== "new") {
      itemData.uom = "1K - " + itemData.uom;
      itemData.uomlong = "1K - " + itemData.uomlong;
    }*/

    // update groups
    const jsonList = await getProduct.insertUom(
      session,
      itemData.uom,
      itemData.uomlong
    );
    const dbreturnList = jsonList.jsonReturn;

    if (dbreturnList[0].errorcode === noError) {
      // refresh data
      await this.refreshUOM();
    } else {
      // error
      this.setState({
        isUpdateError: true,
      });
    }
  };
  deleteUomItem = async (itemData) => {
    //
    // inactivate item
    //
    //////console.log("deleteUomItem: ", itemData);

    let isUpdateError = false;

    this.setState({
      uomList2: [],
      isUploadImage: false,
      isWhereUsed: false,
    });
    const { session } = this.props;

    const jsonList = await getProduct.deleteUom(session, itemData.uomid);
    if (jsonList.jsonReturn[0].errorcode === noError) {
      isUpdateError = false;
      // refresh data
      await this.refreshUOM();
    } else {
      if (itemData.active === "X") {
        itemData.active = "";
      } else {
        itemData.active = "X";
      }

      // update groups
      const jsonList = await getProduct.updateUom(
        session,
        itemData.uomid,
        itemData.active,
        itemData.uom,
        itemData.uomlong
      );
      const dbreturnList = jsonList.jsonReturn;

      if (dbreturnList[0].errorcode === noError) {
        isUpdateError = false;
        // refresh data
        await this.refreshUOM();
      } else {
        // error
        isUpdateError = true;
      }
    }

    this.setState({
      isUpdateError,
    });
  };

  changeImage = async (itemData) => {
    //
    // change image
    //
    ////console.log("changeImage ", itemData);

    const imageRecord = this.state.imageRecord;
    imageRecord.type = prefixImageProduct;
    imageRecord.id = itemData.productid1;

    if (
      itemData.image === "" ||
      typeof itemData.image === "undefined" ||
      itemData.image === null
    ) {
      imageRecord.new = true;
      imageRecord.oldname = undefined;
    } else {
      imageRecord.new = false;
      imageRecord.oldname = itemData.image;
      imageRecord.oldurl = itemData.imageurl;
    }

    imageRecord.description = itemData.producttext;

    imageRecord.minWidth = minWidthImageProduct;
    imageRecord.maxWidth = maxWidthImageProduct;
    imageRecord.minHeight = minHeightImageProduct;
    imageRecord.maxHeight = maxHeightImageProduct;

    ////console.log("changeImage ", imageRecord);

    this.scrollTop();

    this.setState({
      isUploadImage: true,
      imageRecord,
      screenFilter: itemData.productid1,
    });
  };

  showWhereUsed = async (itemData) => {
    //
    // show whre product is used
    //
    //console.log("showWhereUsed ", itemData);

    let isUom = false;
    let isMaterial = false;
    let isWhereUsed = false;
    let whereUsedList = [];

    this.setState({
      whereUsedList,
      isWhereUsed,
      isUom,
      isMaterial,
    });

    const { session } = this.props;

    const result = await getProduct.whereUsedProduct(
      session,
      itemData.productid1
    );
    if (result.jsonReturn[0].errorcode === noError) {
      const whereUsed = result.whereUsedReturn;
      for (let index = 0; index < whereUsed.length; index++) {
        whereUsedList.push({
          key: index,
          text:
            " Materiaal (" +
            whereUsed[index].productid1 +
            " " +
            whereUsed[index].producttext +
            ") gebruikt in stappenplan (" +
            whereUsed[index].mastertaskid1 +
            " " +
            whereUsed[index].mastertasktext +
            ")",
        });
      }
      isWhereUsed = true;
    } else {
      isWhereUsed = false;
    }
    this.scrollTop();

    this.setState({
      isWhereUsed,
      whereUsedList,
    });
  };

  handleImageSave = async (uploadedImage) => {
    //
    // save to server
    //

    const { session } = this.props;
    const { imageRecord } = this.state;
    let materialList = [...this.state.materialList];
    let allMaterialList = [...this.state.allMaterialList];

    let deleteFilePath = "";
    let errorReturn = "E00";

    // save new
    const result = await putImage.uploadImage(session.imagephp, uploadedImage);

    if (result.jsonReturn[0].errorcode === noError) {
      // update url in db and delete old
      const newImageUrl = session.imageurl2 + result.jsonReturn[0].file;
      if (typeof imageRecord.oldname !== "undefined") {
        deleteFilePath = session.imagedir + imageRecord.oldname;
      } else {
        deleteFilePath = "";
      }

      switch (imageRecord.type) {
        /*  case prefixImageUser:
          const result1 = await putImage.updateUserImage(
            session,
            imageRecord.id,
            newImageUrl,
            result.jsonReturn[0].file,
            deleteFilePath
          );
          errorReturn = result1.jsonReturn[0].errorcode;
          break;*/
        case prefixImageProduct:
          const result2 = await putImage.updateProductImage(
            session,
            imageRecord.id,
            newImageUrl,
            result.jsonReturn[0].file,
            deleteFilePath
          );
          errorReturn = result2.jsonReturn[0].errorcode;
          if (errorReturn === noError) {
            // update material arrays
            const imageFromUrl = newImageUrl.split("/");
            let index = materialList.findIndex(
              (el) => el.productid1 === imageRecord.id
            );
            let index2 = allMaterialList.findIndex(
              (el) => el.productid1 === imageRecord.id
            );

            materialList[index] = {
              ...materialList[index],
              imageurl: newImageUrl,
            };
            materialList[index] = {
              ...materialList[index],
              image: imageFromUrl[5],
            };
            allMaterialList[index2] = {
              ...allMaterialList[index2],
              imageurl: newImageUrl,
            };
            allMaterialList[index2] = {
              ...allMaterialList[index2],
              image: imageFromUrl[5],
            };

            //console.log(materialList[index], allMaterialList[index2]);

            // update in DB
            this.refreshMaterial();
          }
          break;
        /*  case prefixImageTask:
          const result3 = await putImage.updateTaskImage(
            session,
            imageRecord.id,
            newImageUrl,
            result.jsonReturn[0].file,
            deleteFilePath
          );
          errorReturn = result3.jsonReturn[0].errorcode;
          if (errorReturn === noError) {
            // update headerList arrays
            const imageFromUrl = newImageUrl.split("/");
            headerList1[0].imageurl = newImageUrl;
            headerList1[0].image = imageFromUrl[5];
            headerList2[0].imageurl = newImageUrl;
            headerList2[0].image = imageFromUrl[5];
          }
          break;
        case prefixImageStep:
          const result4 = await putImage.updateStepImage(
            session,
            imageRecord.id,
            newImageUrl,
            result.jsonReturn[0].file,
            deleteFilePath,
            imageRecord.subid
          );
          errorReturn = result4.jsonReturn[0].errorcode;
          if (errorReturn === noError) {
            // update stepList arrays
            const imageFromUrl = newImageUrl.split("/");
            let index = stepList1.findIndex(
              (el) => el.subtaskid3 === imageRecord.id
            );
            let index2 = stepList2.findIndex(
              (el) => el.subtaskid3 === imageRecord.id
            );

            if (imageRecord.subid === "1") {
              stepList1[index] = {
                ...stepList1[index],
                imageurl1: newImageUrl,
              };
              stepList1[index] = {
                ...stepList1[index],
                image1: imageFromUrl[5],
              };
              stepList2[index2] = {
                ...stepList2[index2],
                imageurl1: newImageUrl,
              };
              stepList2[index2] = {
                ...stepList2[index2],
                image1: imageFromUrl[5],
              };
            } else {
              stepList1[index] = {
                ...stepList1[index],
                imageurl2: newImageUrl,
              };
              stepList1[index] = {
                ...stepList1[index],
                image2: imageFromUrl[5],
              };
              stepList2[index2] = {
                ...stepList2[index2],
                imageurl2: newImageUrl,
              };
              stepList2[index2] = {
                ...stepList2[index2],
                image2: imageFromUrl[5],
              };
            }
            //console.log(stepList1[index], stepList2[index2]);
          }
          break;*/
        default:
          break;
      }

      ////console.log("errorRetrun: ", errorReturn);
      if (errorReturn === noError) {
        // update image record
        imageRecord.oldname = result.jsonReturn[0].file;

        // success message
        this.setState({
          materialList,
          allMaterialList,
          isUploadImage: false,
          isWhereUsed: false,
          isImageSaved: true,
          imageRecord,
        });
      } else {
        // error message
        this.setState({
          isImageSaved: false,
        });
      }
    } else {
      // error
      this.setState({
        isImageSaved: false,
      });
    }
  };

  cancelImageUpload = () => {
    //
    // Cancel imagescreen
    //

    this.setState({
      isUploadImage: false,
    });
  };
}

export default Materials;
