//
// Purpose: SAMB - WF table component
// Author: Ronny Van Elewyck
// Version: 1.1
// Creation Date: 04.06.2020
// Modification Date: 30.01.2021
//

// installed components
import React from "react";

// created components
import WFRow from "./wfRow";

const WFTable = ({ listArray, copyItem, deleteItem, isAdmin }) => {
  return (
    <div className="table-responsive">
      <table className="table table-bordered">
        <thead className="thead-light">
          <tr>
            {isAdmin && <th scope="col"></th>}
            {isAdmin && <th scope="col"></th>}
            <th scope="col"></th>
            {isAdmin && <th scope="col"></th>}
            <th scope="col">soort</th>
            <th scope="col">titel</th>
            <th scope="col">ondertitel</th>
            <th scope="col">foto</th>
            <th scope="col">id</th>
          </tr>
        </thead>
        <tbody>
          {listArray.map((item) => (
            <WFRow
              key={item.mastertaskid1}
              item={item}
              copyItem={copyItem}
              deleteItem={deleteItem}
              isAdmin={isAdmin}
            />
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default WFTable;
