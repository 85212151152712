//
// Purpose: SAMB - steps (workflows) screen
// Author: Ronny Van Elewyck
// Version: 1.1
// Creation Date: 08.05.2020
// Modification Date: 29.01.2021
//

// installed components
import React, { Component } from "react";
import _ from "lodash";
import Tooltip from "react-bootstrap/Tooltip";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";

// created components
import NavBar from "./navBar";
import ExportCSV from "./common/exportCSV";
import SearchBox from "./common/searchBox";
import WFTable from "./common/wfTable";
import DeleteWFForm from "./common/deleteWFForm";
import NewWFForm from "./common/newWFForm";
import ErrorTable from "./common/errorTable";
import TopWaitingMessage from "./common/topWaitingMessage";

// utilities
//import { checkCharacters } from "../utils/specialCharacters";

// rest services
import getTask from "../services/taskService";

// config files
import {
  stepMessage1,
  //stepMessage2,
  stepMessage4,
  stepMessage6,
  stepMessage7,
  stepMessage8,
  stepMessage9,
  stepMessage11,
  stepMessage60,
  noError,
  classTopButtons,
  classTitle,
  classErrorMessage,
  classTables,
  refreshClass5,
  stepsTitle,
  downloadClass5,
  newClass5,
  errorTableTitle1,
  //basisUrl,
  //pdfFile,
  //prd,
} from "../config.json";

class Steps extends Component {
  //
  // steps screen
  //

  // local data
  state = {
    listArray: [],
    allListArray: [],
    downloadListArray: [],
    searchQuery: "",
    isLoaded: false,
    fileName: "SAMDOWNLOADSTAPPEN",
    isEmptyTaskList: true,
    //copySuccessFlag: false,
    copyErrorFlag: false,
    copy1SuccessFlag: false,
    copy1ErrorFlag: false,
    copy1Step1Failed: false,
    copy1Step2Failed: false,
    copy1Step3Failed: false,
    delete1SuccessFlag: false,
    delete1ErrorFlag: false,
    deleteErrorCode: "",
    isAskDeleteSure: false,
    deleteRecord: undefined,
    isNew: false,
    inputTextError: undefined,
    isErrorType: false,
  };

  async componentDidMount() {
    //
    // mount after render
    //

    let downloadListArray = [];

    //console.log("componentDidMount(): this", this);
    const { masterTaskList } = this.props;

    if (masterTaskList.length > 0) {
      // download version for list
      downloadListArray = this.createDownloadList(masterTaskList);

      this.setState({
        downloadListArray,
        listArray: masterTaskList,
        allListArray: masterTaskList,
        isLoaded: true,
        isEmptyTaskList: false,
      });
    } else {
      this.setState({
        downloadListArray: [],
        listArray: [],
        allListArray: [],
        isLoaded: false,
      });
    }

    await this.handleRefresh();
  }

  createDownloadList = (listArray) => {
    //
    // create download list
    //

    let downloadList = [];

    for (let index = 0; index < listArray.length; index++) {
      downloadList.push({
        identificatie: listArray[index].mastertaskid1,
        titel: listArray[index].mastertasktext,
        ondertitel: listArray[index].mastertasktext2,
        soort: listArray[index].typetext,
        foto: listArray[index].imageurl,
      });
    }

    return downloadList;
  };

  handleSearch = (query) => {
    //
    // execute search filter
    //
    let listArray = [];
    let downloadListArray = [];
    const { allListArray } = this.state;

    if (query !== "") {
      listArray = allListArray.filter((m) =>
        m.searchfield.toLowerCase().includes(query.toLowerCase())
      );
    } else {
      listArray = allListArray;
    }

    listArray = _.sortBy(listArray, function (item) {
      return item.mastertasktext;
    });

    // download version for list
    downloadListArray = this.createDownloadList(listArray);

    this.setState({
      downloadListArray,
      listArray,
      searchQuery: query,
    });
  };

  handleRefresh = async () => {
    //
    // Refresh
    //

    let downloadListArray = [];

    const { session } = this.props;

    //console.log("handleRefresh");
    // reset to trigger screen refresh
    this.setState({
      listArray: [],
      allListArray: [],
      isLoaded: false,
      isEmptyTaskList: false,
    });

    // tasks get
    const jsonList = await getTask.masterTask(session);
    const dbreturnList = jsonList.jsonReturn;
    const masterTaskList = jsonList.masterTaskList;
    if (dbreturnList[0].errorcode === noError) {
      // tasks save in App.js
      this.props.onSaveMasterTaskList(masterTaskList);
      if (masterTaskList.length > 0) {
        // sort
        const sortedArray = _.sortBy(masterTaskList, function (item) {
          return item.mastertasktext;
        });

        // download version for list
        downloadListArray = this.createDownloadList(sortedArray);
        // set state
        this.setState({
          downloadListArray,
          listArray: sortedArray,
          allListArray: sortedArray,
          isLoaded: true,
        });
      } else {
        // no task message
        this.setState({
          isLoaded: true,
          isEmptyTaskList: true,
        });
      }
    } else {
      // refresh error
      this.setState({
        downloadListArray: [],
        listArray: [],
        allListArray: [],
        isLoaded: false,
        isEmptyTaskList: false,
      });
    }
  };

  handleNew = () => {
    //
    // new
    //

    this.scrollTop();
    this.setState({
      isNew: true,
    });
  };

  renderTooltip(title) {
    return <Tooltip id="button-tooltip">{title}</Tooltip>;
  }

  render() {
    //
    // render task screen
    //

    //console.log("render(): ");

    // get local state variables
    const {
      listArray,
      downloadListArray,
      isLoaded,
      fileName,
      searchQuery,
      isEmptyTaskList,
      copyErrorFlag,
      copy1ErrorFlag,
      copy1Step1Failed,
      copy1Step2Failed,
      copy1Step3Failed,
      delete1ErrorFlag,
      deleteErrorCode,
      isAskDeleteSure,
      deleteRecord,
      isNew,
      inputTextError,
      isErrorType,
    } = this.state;

    const { isAdmin } = this.props;

    return (
      <React.Fragment>
        {/* error messages */}
        {isEmptyTaskList && (
          <div className="alert alert-warning">{stepMessage1}</div>
        )}
        {!isLoaded && <TopWaitingMessage messageToShow={stepMessage1} />}
        {copyErrorFlag && (
          <div className={classErrorMessage}>{stepMessage4}</div>
        )}
        {copy1ErrorFlag && (
          <div className={classErrorMessage}>{stepMessage6}</div>
        )}
        {isErrorType && (
          <div className={classErrorMessage}>{stepMessage60}</div>
        )}
        {copy1Step1Failed && (
          <div className={classErrorMessage}>{stepMessage7}</div>
        )}
        {copy1Step2Failed && (
          <div className={classErrorMessage}>{stepMessage8}</div>
        )}
        {copy1Step3Failed && (
          <div className={classErrorMessage}>{stepMessage9}</div>
        )}
        {delete1ErrorFlag && (
          <div className={classErrorMessage}>
            {stepMessage11 + deleteErrorCode}
          </div>
        )}
        {typeof inputTextError !== "undefined" && (
          <ErrorTable
            inputTextError={inputTextError}
            title={errorTableTitle1}
          />
        )}

        {/* navigation bar */}
        <NavBar />

        {/* confirm wf delete */}
        {isAdmin && isAskDeleteSure && (
          <div className={classTables}>
            <DeleteWFForm
              confirmDeleteItem={this.handleConfirmDeleteItem}
              cancelItem={this.handleCancelItem}
              item={deleteRecord}
            />
          </div>
        )}
        {/* new wf */}
        {isAdmin && isNew && (
          <div className={classTables}>
            <NewWFForm
              newItem={this.handleNewItem}
              cancelItem={this.handleCancelNewItem}
            />
          </div>
        )}

        {/* title */}
        <h4>
          <span className={classTitle}>{stepsTitle}</span>
        </h4>

        {/* button row for global actions */}

        <div className={classTopButtons}>
          <form className="form-inline">
            {isAdmin && (
              <OverlayTrigger
                placement="left"
                delay={{ show: 250, hide: 400 }}
                overlay={this.renderTooltip("nieuw")}
              >
                <div>
                  <button
                    type="button"
                    className={newClass5}
                    onClick={() => this.handleNew()}
                  ></button>
                </div>
              </OverlayTrigger>
            )}

            <OverlayTrigger
              placement="left"
              delay={{ show: 250, hide: 400 }}
              overlay={this.renderTooltip("ververs")}
            >
              <button
                type="button"
                className={refreshClass5}
                onClick={() => this.handleRefresh()}
              ></button>
            </OverlayTrigger>
            <ExportCSV
              csvData={downloadListArray}
              fileName={fileName}
              className={downloadClass5}
            />
          </form>
        </div>

        {/* table */}
        {isLoaded && (
          <div className={classTables}>
            <SearchBox value={searchQuery} onChange={this.handleSearch} />
            <WFTable
              listArray={listArray}
              copyItem={this.copyItem}
              deleteItem={this.deleteItem}
              isAdmin={isAdmin}
            />
          </div>
        )}
      </React.Fragment>
    );
  }

  /*****************/
  /* row functions */
  /*****************/
  copyItem = async (itemData) => {
    //
    // copy full workflow
    //

    const { session } = this.props;

    const node1 = session.prd;
    const node2 = session.prd;
    const force = "X";

    // reset to trigger screen refresh
    this.setState({ copyErrorFlag: false });

    await getTask.copyCompleteWF(node1, node2, itemData.mastertaskid1, force);

    // refresh list
    await this.handleRefresh();
  };
  /*  copyItem = async (itemData) => {
    //
    // insert steps in database
    //
    //console.log("copyItem itemData: ", itemData);

    const { session } = this.props;

    // reset to trigger screen refresh
    this.setState({ copySuccessFlag: false, copyErrorFlag: false });

    // special characters
    const copyText = "1K - " + itemData.mastertasktext;
    const convertedText = checkCharacters(copyText);
    // copy mastertask
    const jsonReturn = await getTask.copyMastertask(
      session,
      itemData.mastertaskid1,
      convertedText
    );

    // retuncode
    const jsonList = jsonReturn["jsonReturn"];
    if (jsonList[0].errorcode === noError) {
      switch (itemData.typeid1) {
        case "1":
          // recipe
          await this.handleType1Copy(jsonList[0].lastid, itemData, session);
          break;
        case "2":
          // standard WF
          await this.handleType2Copy(jsonList[0].lastid, itemData, session);
          break;
        case "3":
          // maintenance
          await this.handleType3Copy(jsonList[0].lastid, itemData, session);
          break;
        default:
          // error
          break;
      }
      await this.handleRefresh();
    } else {
      this.setState({ copySuccessFlag: false, copyErrorFlag: true });
    }
  };*/

  handleType1Delete = async (session, mastertaskid) => {
    //
    // delete recipe
    //

    //console.log("handleType1Delete");

    this.setState({
      delete1SuccessFlag: false,
      delete1ErrorFlag: false,
      deleteErrorCode: "",
    });

    const jsonReturn = await getTask.deleteMastertask1(session, mastertaskid);
    const jsonList = jsonReturn["jsonReturn"];
    if (jsonList[0].errorcode === noError) {
      // refresh screen
      await this.handleRefresh();
      // set state
      this.setState({
        delete1SuccessFlag: true,
        delete1ErrorFlag: false,
        deleteErrorCode: "",
      });
    } else {
      switch (jsonList[0].errorcode) {
        case "E99":
          // select on Tasks failed
          this.setState({
            delete1SuccessFlag: false,
            delete1ErrorFlag: true,
            deleteErrorCode: " opzoeken taken met stappenplan is mislukt.",
          });
          break;
        case "E98":
          // there are still tasks with this masterplan: delete not allowed
          this.setState({
            delete1SuccessFlag: false,
            delete1ErrorFlag: true,
            deleteErrorCode:
              " aantal taken met stappenplan: " + jsonList[0].numberofrowsfound,
          });
          break;
        case "E97":
          // WFSteps issue
          this.setState({
            delete1SuccessFlag: false,
            delete1ErrorFlag: true,
            deleteErrorCode: " WFSteps tabel probleem.",
          });
          break;
        case "E96":
          // Subtask2 issue
          this.setState({
            delete1SuccessFlag: false,
            delete1ErrorFlag: true,
            deleteErrorCode: " Subtask2 tabel probleem.",
          });
          break;
        case "E95":
          // Subtask1 issue
          this.setState({
            delete1SuccessFlag: false,
            delete1ErrorFlag: true,
            deleteErrorCode: " Subtask1 tabel probleem.",
          });
          break;
        case "E94":
          // Mastertask1 issue
          this.setState({
            delete1SuccessFlag: false,
            delete1ErrorFlag: true,
            deleteErrorCode: " Mastertask1 tabel probleem.",
          });
          break;
        case "E93":
          // Mastertask1 issue
          this.setState({
            delete1SuccessFlag: false,
            delete1ErrorFlag: true,
            deleteErrorCode: " Mastertask1 tabel probleem.",
          });
          break;
        case "E92":
          // Parameter issue
          this.setState({
            delete1SuccessFlag: false,
            delete1ErrorFlag: true,
            deleteErrorCode: " stappenplan niet aanwezig in databank.",
          });
          break;
        default:
          // error
          break;
      }
    }
  };

  handleType2Delete = async (session, mastertaskid) => {
    //
    // delete standard workflow
    //

    //console.log("handleType2Delete");

    this.setState({
      delete1SuccessFlag: false,
      delete1ErrorFlag: false,
      deleteErrorCode: "",
    });

    const jsonReturn = await getTask.deleteMastertask2(session, mastertaskid);
    const jsonList = jsonReturn["jsonReturn"];
    if (jsonList[0].errorcode === noError) {
      // refresh screen
      await this.handleRefresh();
      // set state
      this.setState({
        delete1SuccessFlag: true,
        delete1ErrorFlag: false,
        deleteErrorCode: "",
      });
    } else {
      switch (jsonList[0].errorcode) {
        case "E99":
          // select on Tasks failed
          this.setState({
            delete1SuccessFlag: false,
            delete1ErrorFlag: true,
            deleteErrorCode: " opzoeken taken met stappenplan is mislukt.",
          });
          break;
        case "E98":
          // there are still tasks with this masterplan: delete not allowed
          this.setState({
            delete1SuccessFlag: false,
            delete1ErrorFlag: true,
            deleteErrorCode:
              " aantal taken met stappenplan: " + jsonList[0].numberofrowsfound,
          });
          break;
        case "E97":
          // WFSteps issue
          this.setState({
            delete1SuccessFlag: false,
            delete1ErrorFlag: true,
            deleteErrorCode: " WFSteps tabel probleem.",
          });
          break;
        case "E96":
          // Subtask2 issue
          this.setState({
            delete1SuccessFlag: false,
            delete1ErrorFlag: true,
            deleteErrorCode: " Mastertask1 tabel probleem.",
          });
          break;
        case "E93":
          // Mastertask1 issue
          this.setState({
            delete1SuccessFlag: false,
            delete1ErrorFlag: true,
            deleteErrorCode: " Mastertask1 tabel probleem.",
          });
          break;
        case "E92":
          // Parameter issue
          this.setState({
            delete1SuccessFlag: false,
            delete1ErrorFlag: true,
            deleteErrorCode: " stappenplan niet aanwezig in databank.",
          });
          break;
        default:
          // error
          break;
      }
    }
  };

  handleType3Delete = async (session, mastertaskid) => {
    //
    // delete maintenance workflow
    //

    //console.log("handleType3Delete");

    this.setState({
      delete1SuccessFlag: false,
      delete1ErrorFlag: false,
      deleteErrorCode: "",
    });

    const jsonReturn = await getTask.deleteMastertask3(session, mastertaskid);
    const jsonList = jsonReturn["jsonReturn"];
    if (jsonList[0].errorcode === noError) {
      // refresh screen
      await this.handleRefresh();
      // set state
      this.setState({
        delete1SuccessFlag: true,
        delete1ErrorFlag: false,
        deleteErrorCode: "",
      });
    } else {
      switch (jsonList[0].errorcode) {
        case "E99":
          // select on Tasks failed
          this.setState({
            delete1SuccessFlag: false,
            delete1ErrorFlag: true,
            deleteErrorCode: " opzoeken taken met stappenplan is mislukt.",
          });
          break;
        case "E98":
          // there are still tasks with this masterplan: delete not allowed
          this.setState({
            delete1SuccessFlag: false,
            delete1ErrorFlag: true,
            deleteErrorCode:
              " aantal taken met stappenplan: " + jsonList[0].numberofrowsfound,
          });
          break;
        case "E97":
          // WFSteps issue
          this.setState({
            delete1SuccessFlag: false,
            delete1ErrorFlag: true,
            deleteErrorCode: " WFSteps tabel probleem.",
          });
          break;
        case "E96":
          // Subtask1 issue
          this.setState({
            delete1SuccessFlag: false,
            delete1ErrorFlag: true,
            deleteErrorCode: " Subtask1 tabel probleem.",
          });
          break;
        case "E94":
          // Mastertask1 issue
          this.setState({
            delete1SuccessFlag: false,
            delete1ErrorFlag: true,
            deleteErrorCode: " Mastertask1 tabel probleem.",
          });
          break;
        case "E93":
          // Mastertask1 issue
          this.setState({
            delete1SuccessFlag: false,
            delete1ErrorFlag: true,
            deleteErrorCode: " Mastertask1 tabel probleem.",
          });
          break;
        case "E92":
          // Parameter issue
          this.setState({
            delete1SuccessFlag: false,
            delete1ErrorFlag: true,
            deleteErrorCode: " stappenplan niet aanwezig in databank.",
          });
          break;
        default:
          // error
          break;
      }
    }
  };

  handleType1Copy = async (lastid, itemData, session) => {
    //
    // copy recipe
    //

    this.setState({
      copy1SuccessFlag: false,
      copy1ErrorFlag: false,
      copy1Step1Failed: false,
      copy1Step2Failed: false,
      copy1Step3Failed: false,
    });

    let newMastertaskid = 0;

    if (lastid !== 0) {
      newMastertaskid = lastid;
      // copy subtask1
      const jsonReturn2 = await getTask.copySubTask1(
        session,
        itemData.mastertaskid1,
        newMastertaskid
      );
      const jsonList2 = jsonReturn2["jsonReturn"];
      if (jsonList2[0].errorcode === noError) {
        // copy subtask2
        const jsonReturn3 = await getTask.copySubTask2(
          session,
          itemData.mastertaskid1,
          newMastertaskid
        );
        const jsonList3 = jsonReturn3["jsonReturn"];
        if (jsonList3[0].errorcode === noError) {
          // copy WfSteps
          const jsonReturn4 = await getTask.copyWFSteps(
            session,
            itemData.mastertaskid1,
            newMastertaskid
          );
          const jsonList4 = jsonReturn4["jsonReturn"];
          if (jsonList4[0].errorcode === noError) {
            this.setState({ copy1SuccessFlag: true, copy1ErrorFlag: false });
          } else {
            this.setState({
              copy1SuccessFlag: false,
              copy1ErrorFlag: true,
              copy1Step3Failed: true,
            });
          }
        } else {
          this.setState({
            copy1SuccessFlag: false,
            copy1ErrorFlag: true,
            copy1Step2Failed: true,
          });
        }
      } else {
        this.setState({
          copy1SuccessFlag: false,
          copy1ErrorFlag: true,
          copy1Step1Failed: true,
        });
      }
    } else {
      this.setState({ copy1SuccessFlag: false, copy1ErrorFlag: true });
    }
  };

  handleType2Copy = async (lastid, itemData, session) => {
    //
    // copy standard workflow
    //

    this.setState({
      copy1SuccessFlag: false,
      copy1ErrorFlag: false,
      copy1Step1Failed: false,
      copy1Step2Failed: false,
      copy1Step3Failed: false,
    });

    let newMastertaskid = 0;

    if (lastid !== 0) {
      newMastertaskid = lastid;

      // copy WfSteps
      const jsonReturn4 = await getTask.copyWFSteps(
        session,
        itemData.mastertaskid1,
        newMastertaskid
      );
      const jsonList4 = jsonReturn4["jsonReturn"];
      if (jsonList4[0].errorcode === noError) {
        this.setState({ copy1SuccessFlag: true, copy1ErrorFlag: false });
      } else {
        this.setState({
          copy1SuccessFlag: false,
          copy1ErrorFlag: true,
          copy1Step3Failed: true,
        });
      }
    } else {
      this.setState({ copy1SuccessFlag: false, copy1ErrorFlag: true });
    }
  };

  handleType3Copy = async (lastid, itemData, session) => {
    //
    // copy maintenace plan
    //

    this.setState({
      copy1SuccessFlag: false,
      copy1ErrorFlag: false,
      copy1Step1Failed: false,
      copy1Step2Failed: false,
      copy1Step3Failed: false,
    });

    let newMastertaskid = 0;

    if (lastid !== 0) {
      newMastertaskid = lastid;
      // copy subtask1
      const jsonReturn2 = await getTask.copySubTask1(
        session,
        itemData.mastertaskid1,
        newMastertaskid
      );
      const jsonList2 = jsonReturn2["jsonReturn"];
      if (jsonList2[0].errorcode === noError) {
        // copy WfSteps
        const jsonReturn4 = await getTask.copyWFSteps(
          session,
          itemData.mastertaskid1,
          newMastertaskid
        );
        const jsonList4 = jsonReturn4["jsonReturn"];
        if (jsonList4[0].errorcode === noError) {
          this.setState({ copy1SuccessFlag: true, copy1ErrorFlag: false });
        } else {
          this.setState({
            copy1SuccessFlag: false,
            copy1ErrorFlag: true,
            copy1Step3Failed: true,
          });
        }
      } else {
        this.setState({
          copy1SuccessFlag: false,
          copy1ErrorFlag: true,
          copy1Step1Failed: true,
        });
      }
    } else {
      this.setState({ copy1SuccessFlag: false, copy1ErrorFlag: true });
    }
  };

  deleteItem = (itemData) => {
    //
    // new steps in database
    //
    this.scrollTop();

    this.setState({
      isAskDeleteSure: true,
      deleteRecord: itemData,
    });
  };

  handleConfirmDeleteItem = async (item) => {
    //
    // delete mastertask and all wf steps
    //

    //console.log("handleConfirmDeleteItem: ", item["item"]);

    const { session } = this.props;

    switch (item["item"].typeid1) {
      case "1":
        // delete recipe
        await this.handleType1Delete(session, item["item"].mastertaskid1);
        break;
      case "2":
        // delete standard WF
        await this.handleType2Delete(session, item["item"].mastertaskid1);
        break;
      case "3":
        // delete maintenance
        await this.handleType3Delete(session, item["item"].mastertaskid1);
        break;
      default:
        // error
        break;
    }

    this.setState({
      isAskDeleteSure: false,
      deleteRecord: undefined,
    });
  };

  handleCancelItem = () => {
    //
    // Refresh
    //

    this.setState({
      isAskDeleteSure: false,
      deleteRecord: undefined,
    });
  };

  scrollTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  handleCancelNewItem = () => {
    //
    // Refresh
    //

    this.setState({
      isNew: false,
      isErrorType: false,
    });
  };

  handleNewItem = async (itemData) => {
    //
    // insert new step
    //

    if (itemData.typeid1.id === 0) {
      this.setState({
        isErrorType: true,
      });
    } else {
      const { session } = this.props;

      let inputTextError = [];
      this.setState({
        listArray: [],
        allListArray: [],
        isNew: false,
      });

      const result = await getTask.insertMastertask(
        session,
        itemData.typeid1.id,
        itemData.mastertasktext,
        itemData.mastertasktext2
      );

      //console.log(result);
      if (result.jsonReturn[0].errorcode === noError) {
        // refresh data
        this.handleRefresh();
        this.setState({
          inputTextError: undefined,
          isErrorType: false,
        });
      } else {
        // error
        inputTextError.push({
          key: 1,
          errorText:
            result.jsonReturn[0].errorcode + " - " + result.jsonReturn[0].node,
        });

        this.setState({
          inputTextError,
          isErrorType: false,
        });
      }
    }
  };
}

export default Steps;
