//
// Purpose: SAMB - same User
// Author: Ronny Van Elewyck
// Version: 1.0
// Creation Date: 10.09.2020
// Modification Date: 10.09.2020
//

// installed components
import React from "react";

// created components

// config files
import { saveClass5, cancelClass5 } from "../../config.json";

const SameUserForm = ({ item, cancelSameUser, confirmSameUser }) => {
  //
  // format row of table
  //

  const title = `Gebruiker: ${item.firstname} ${item.lastname} bestaat reeds!`;
  const message = `${item.firstname} ${item.lastname} toch aanmaken/aanpassen?`;

  return (
    <div className="table-responsive">
      <table className="table table-bordered">
        <tbody>
          <tr>
            <td>
              <h4>
                <span className="badge badge-danger d-flex justify-content-center">
                  {title}
                </span>
              </h4>

              <button
                className={cancelClass5}
                onClick={() => cancelSameUser()}
              ></button>
              <button
                className={saveClass5}
                onClick={() =>
                  confirmSameUser({
                    item,
                  })
                }
              ></button>
            </td>
          </tr>
          <tr>
            <td>
              <h4 className="ml-2 mr-2">{message}</h4>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};

export default SameUserForm;
