//
// Purpose: SAMB - material Row
// Author: Ronny Van Elewyck
// Version: 1.1
// Creation Date: 10.06.2020
// Modification Date: 12.02.2021
//

// installed components
import React from "react";
import { Typeahead } from "react-bootstrap-typeahead";
import "react-bootstrap-typeahead/css/Typeahead.css";
import Tooltip from "react-bootstrap/Tooltip";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";

// config files
import {
  saveClass5,
  copyClass5,
  deleteClass5,
  imageClass1,
  whereUsedClass1,
  productMessage6,
  classErrorMessage,
} from "../../config.json";

const MaterialRow = ({
  item,
  changeItem,
  copyItem,
  deleteItem,
  allergenList,
  uomList,
  changeImage,
  showWhereUsed,
  isAdmin,
}) => {
  //
  // format row of table
  //

  const productTypeList = [
    "algemeen",
    "keukenmateriaal",
    "materiaal",
    "ingredienten",
  ];

  const [producttext, setValue1] = React.useState(item.producttext);
  const [producttype, setValue2] = React.useState(item.producttype);
  const [uomlong, setValue3] = React.useState(item.uomlong);

  const [allergen1, setValue10] = React.useState(item.text1);
  const [allergen2, setValue11] = React.useState(item.text2);
  const [allergen3, setValue12] = React.useState(item.text3);
  const [allergen4, setValue13] = React.useState(item.text4);
  const [allergen5, setValue14] = React.useState(item.text5);
  const [allergen6, setValue15] = React.useState(item.text6);
  const [allergen7, setValue16] = React.useState(item.text7);
  const [allergen8, setValue17] = React.useState(item.text8);
  const [allergen9, setValue18] = React.useState(item.text9);
  const [allergen10, setValue19] = React.useState(item.text10);
  const [allergen11, setValue20] = React.useState(item.text11);
  const [allergen12, setValue21] = React.useState(item.text12);
  const [allergen13, setValue22] = React.useState(item.text13);
  const [allergen14, setValue23] = React.useState(item.text14);
  const [allergen15, setValue24] = React.useState(item.text15);

  const [isChecked, setChecked] = React.useState(false);

  const [showError1, setShowError1] = React.useState("");
  const [isError1, setError1] = React.useState(false);

  React.useEffect(() => {
    // check uppercase first letter
    const regex = /^[A-Z]/;
    const found = producttext.match(regex);

    if (found === null || producttext.length < 2 || producttext.length > 40) {
      setError1(true);
      setShowError1(productMessage6);
    } else {
      setError1(false);
      setShowError1("");
    }
  }, [producttext]);

  function renderTooltip(title) {
    return <Tooltip id="button-tooltip">{title}</Tooltip>;
  }

  return (
    <tr>
      <td>
        <div>
          <OverlayTrigger
            placement="left"
            delay={{ show: 250, hide: 400 }}
            overlay={renderTooltip("detail open/dicht")}
          >
            <div>
              <input
                type="checkbox"
                checked={isChecked}
                onChange={() => setChecked(!isChecked)}
              />
            </div>
          </OverlayTrigger>
        </div>
      </td>
      {!isError1 && isChecked && isAdmin && (
        <td>
          <div>
            <OverlayTrigger
              placement="left"
              delay={{ show: 250, hide: 400 }}
              overlay={renderTooltip("bewaar")}
            >
              <div>
                <button
                  className={saveClass5}
                  onClick={() =>
                    changeItem({
                      ...item,
                      producttext,
                      producttype,
                      uomlong,
                      allergen1,
                      allergen2,
                      allergen3,
                      allergen4,
                      allergen5,
                      allergen6,
                      allergen7,
                      allergen8,
                      allergen9,
                      allergen10,
                      allergen11,
                      allergen12,
                      allergen13,
                      allergen14,
                      allergen15,
                    })
                  }
                ></button>{" "}
              </div>
            </OverlayTrigger>
          </div>
        </td>
      )}
      {isChecked && isAdmin && (
        <td>
          <div>
            <OverlayTrigger
              placement="left"
              delay={{ show: 250, hide: 400 }}
              overlay={renderTooltip("kopieer")}
            >
              <div>
                <button
                  className={copyClass5}
                  onClick={() => copyItem({ ...item })}
                ></button>{" "}
              </div>
            </OverlayTrigger>
          </div>
        </td>
      )}
      {isChecked && isAdmin && (
        <td>
          {" "}
          <div>
            <OverlayTrigger
              placement="left"
              delay={{ show: 250, hide: 400 }}
              overlay={renderTooltip("delete/deactiveer")}
            >
              <div>
                <button
                  className={deleteClass5}
                  onClick={() => deleteItem({ ...item })}
                ></button>{" "}
              </div>
            </OverlayTrigger>
          </div>
        </td>
      )}
      {!isChecked && (
        <td>
          {item.productid1} {"(actief: " + item.active + ")"}
        </td>
      )}
      {!isChecked && <td>{item.producttext}</td>}
      {!isChecked && <td>{item.producttype}</td>}

      <td>
        {!isChecked && (
          <img
            src={item.imageurl}
            alt="geen afbeelding"
            width="70"
            height="55"
          />
        )}
        {isChecked && (
          <div>
            <b>nummer: </b>
            {item.productid1}
          </div>
        )}
        {isChecked && (
          <div>
            <b>actief: </b>
            {item.active}
          </div>
        )}
        {isChecked && (
          <div>
            <b>groep: </b>
            {isAdmin && (
              <Typeahead
                id={"producttype"}
                labelKey={"label"}
                options={productTypeList}
                placeholder={producttype}
                onChange={(selectedOptions) => setValue2(selectedOptions[0])}
                highlightOnlyResult={true}
                clearButton={true}
              />
            )}
            {!isAdmin && <h6>{item.producttype}</h6>}
          </div>
        )}
        {isChecked && (
          <div>
            {isError1 && <div className={classErrorMessage}>{showError1}</div>}
            <b>omschrijving: </b>
            {isAdmin && (
              <input
                type="text"
                className="form-control"
                value={producttext}
                onChange={(e) => setValue1(e.currentTarget.value)}
                size={40}
              />
            )}
            {!isAdmin && <h6>{item.producttext}</h6>}
          </div>
        )}
        {isChecked && (
          <div>
            <b>eenheid: </b>
            {isAdmin && (
              <Typeahead
                id={"uomlong"}
                labelKey={"label"}
                options={uomList}
                placeholder={uomlong}
                onChange={(selectedOptions) => setValue3(selectedOptions[0])}
                highlightOnlyResult={true}
                clearButton={true}
              />
            )}
            {!isAdmin && <h6>{item.uomlong}</h6>}
          </div>
        )}
        {isChecked && (
          <div>
            <div>
              {isAdmin && (
                <button
                  className={imageClass1}
                  onClick={() => changeImage({ ...item })}
                ></button>
              )}
              <img
                src={item.imageurl}
                alt="geen afbeelding"
                width="70"
                height="55"
              />
            </div>
            <div>
              {isAdmin && (
                <div>
                  waar wordt product gebruikt?
                  <button
                    className={whereUsedClass1}
                    onClick={() => showWhereUsed({ ...item })}
                  ></button>
                </div>
              )}
            </div>
          </div>
        )}
        {isChecked && isAdmin && producttype === "ingredienten" && (
          <div>
            <b>allergenen: </b>
            <Typeahead
              id={"allergen"}
              labelKey={"label"}
              options={allergenList}
              placeholder={allergen1}
              onChange={(selectedOptions) => setValue10(selectedOptions[0])}
              highlightOnlyResult={true}
              clearButton={true}
            />
            <Typeahead
              id={"allergen"}
              labelKey={"label"}
              options={allergenList}
              placeholder={allergen2}
              onChange={(selectedOptions) => setValue11(selectedOptions[0])}
              highlightOnlyResult={true}
              clearButton={true}
            />
            <Typeahead
              id={"allergen"}
              labelKey={"label"}
              options={allergenList}
              placeholder={allergen3}
              onChange={(selectedOptions) => setValue12(selectedOptions[0])}
              highlightOnlyResult={true}
              clearButton={true}
            />
            <Typeahead
              id={"allergen"}
              labelKey={"label"}
              options={allergenList}
              placeholder={allergen4}
              onChange={(selectedOptions) => setValue13(selectedOptions[0])}
              highlightOnlyResult={true}
              clearButton={true}
            />
            <Typeahead
              id={"allergen"}
              labelKey={"label"}
              options={allergenList}
              placeholder={allergen5}
              onChange={(selectedOptions) => setValue14(selectedOptions[0])}
              highlightOnlyResult={true}
              clearButton={true}
            />
            <Typeahead
              id={"allergen"}
              labelKey={"label"}
              options={allergenList}
              placeholder={allergen6}
              onChange={(selectedOptions) => setValue15(selectedOptions[0])}
              highlightOnlyResult={true}
              clearButton={true}
            />
            <Typeahead
              id={"allergen"}
              labelKey={"label"}
              options={allergenList}
              placeholder={allergen7}
              onChange={(selectedOptions) => setValue16(selectedOptions[0])}
              highlightOnlyResult={true}
              clearButton={true}
            />
            <Typeahead
              id={"allergen"}
              labelKey={"label"}
              options={allergenList}
              placeholder={allergen8}
              onChange={(selectedOptions) => setValue17(selectedOptions[0])}
              highlightOnlyResult={true}
              clearButton={true}
            />
            <Typeahead
              id={"allergen"}
              labelKey={"label"}
              options={allergenList}
              placeholder={allergen9}
              onChange={(selectedOptions) => setValue18(selectedOptions[0])}
              highlightOnlyResult={true}
              clearButton={true}
            />
            <Typeahead
              id={"allergen"}
              labelKey={"label"}
              options={allergenList}
              placeholder={allergen10}
              onChange={(selectedOptions) => setValue19(selectedOptions[0])}
              highlightOnlyResult={true}
              clearButton={true}
            />
            <Typeahead
              id={"allergen"}
              labelKey={"label"}
              options={allergenList}
              placeholder={allergen11}
              onChange={(selectedOptions) => setValue20(selectedOptions[0])}
              highlightOnlyResult={true}
              clearButton={true}
            />
            <Typeahead
              id={"allergen"}
              labelKey={"label"}
              options={allergenList}
              placeholder={allergen12}
              onChange={(selectedOptions) => setValue21(selectedOptions[0])}
              highlightOnlyResult={true}
              clearButton={true}
            />
            <Typeahead
              id={"allergen"}
              labelKey={"label"}
              options={allergenList}
              placeholder={allergen13}
              onChange={(selectedOptions) => setValue22(selectedOptions[0])}
              highlightOnlyResult={true}
              clearButton={true}
            />
            <Typeahead
              id={"allergen"}
              labelKey={"label"}
              options={allergenList}
              placeholder={allergen14}
              onChange={(selectedOptions) => setValue23(selectedOptions[0])}
              highlightOnlyResult={true}
              clearButton={true}
            />
            <Typeahead
              id={"allergen"}
              labelKey={"label"}
              options={allergenList}
              placeholder={allergen15}
              onChange={(selectedOptions) => setValue24(selectedOptions[0])}
              highlightOnlyResult={true}
              clearButton={true}
            />
          </div>
        )}
        {isChecked && !isAdmin && producttype === "ingredienten" && (
          <div>
            <b>allergenen: </b>
            <h6>{item.text1}</h6>
            <h6>{item.text2}</h6>
            <h6>{item.text3}</h6>
            <h6>{item.text4}</h6>
            <h6>{item.text5}</h6>
            <h6>{item.text6}</h6>
            <h6>{item.text7}</h6>
            <h6>{item.text8}</h6>
            <h6>{item.text9}</h6>
            <h6>{item.text10}</h6>
            <h6>{item.text11}</h6>
            <h6>{item.text12}</h6>
            <h6>{item.text13}</h6>
            <h6>{item.text14}</h6>
            <h6>{item.text15}</h6>
          </div>
        )}
      </td>
    </tr>
  );
};

export default MaterialRow;
