//
// Purpose: SAMB - overview Task Row
// Author: Ronny Van Elewyck
// Version: 1.1
// Creation Date: 04.06.2020
// Modification Date: 04.03.2021
//

// installed components
import React from "react";
import { isMobile } from "react-device-detect";

// config files

const OverviewTaskRow = ({ item }) => {
  //
  // format row of table
  //

  return (
    <tr>
      {!isMobile && <td>{item.coursedate}</td>}
      {!isMobile && <td>{item.coursetime}</td>}
      {!isMobile && <td>{item.grouptext}</td>}
      {!isMobile && <td>{item.mastercoursetext}</td>}
      {!isMobile && <td>{item.typetext}</td>}
      {!isMobile && <td>{item.mastertasktext}</td>}
      {!isMobile && <td>{item.teachername}</td>}
      {!isMobile && <td>{item.studentname}</td>}
      {!isMobile && <td>{item.taskstate}</td>}

      {isMobile && (
        <td>
          <h6>{item.coursedate}</h6>
          <h6>{item.coursetime}</h6>
          <h6>{item.grouptext}</h6>
          <h6>{item.mastercoursetext}</h6>
          <h6>{item.typetext}</h6>
          <h6>{item.mastertasktext}</h6>
          <h6>{item.teachername}</h6>
          <h6>{item.studentname}</h6>
          <h6>{item.taskstate}</h6>
        </td>
      )}
    </tr>
  );
};

export default OverviewTaskRow;
