//
// Purpose: SAMB - product table component
// Author: Ronny Van Elewyck
// Version: 1.0
// Creation Date: 28.05.2020
// Modification Date: 19.08.2020
//

// installed components
import React from "react";

// created components
import ProductRow from "./productRow";

const ProductTable = ({
  productList2,
  saveProductItem,
  deleteMaterialItem,
  productList,
}) => {
  return (
    <div className="table-responsive">
      <table className="table table-bordered">
        <thead className="thead-light">
          <tr>
            <th></th>
            <th>volgorde</th>
            <th>materiaal/product</th>
            <th>hoeveelheid</th>
          </tr>
        </thead>
        <tbody>
          {productList2.map((item) => (
            <ProductRow
              key={item.subtaskid1}
              item={item}
              saveProductItem={saveProductItem}
              deleteMaterialItem={deleteMaterialItem}
              productList={productList}
            />
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default ProductTable;
