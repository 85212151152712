//
// Purpose: SAMB - master course table component
// Author: Ronny Van Elewyck
// Version: 1.0
// Creation Date: 04.06.2020
// Modification Date: 04.06.2020
//

// installed components
import React from "react";

// created components
import MasterCourseRow from "./masterCourseRow";

const MasterCourseTable = ({
  listArray,
  changeItem,
  copyItem,
  deleteItem,
  isAdmin,
}) => {
  return (
    <div className="table-responsive">
      <table className="table table-bordered">
        <thead className="thead-light">
          <tr>
            {isAdmin && <th></th>}
            {isAdmin && <th></th>}
            {isAdmin && <th></th>}
            <th>vakken</th>
          </tr>
        </thead>
        <tbody>
          {listArray.map((item) => (
            <MasterCourseRow
              key={item.mastercourseid}
              item={item}
              changeItem={changeItem}
              copyItem={copyItem}
              deleteItem={deleteItem}
              isAdmin={isAdmin}
            />
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default MasterCourseTable;
