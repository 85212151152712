//
// Purpose: SAMB - new mastercourse
// Author: Ronny Van Elewyck
// Version: 1.0
// Creation Date: 02.09.2020
// Modification Date: 15.10.2020
//

// installed components
import React from "react";
import { Typeahead } from "react-bootstrap-typeahead";
import "react-bootstrap-typeahead/css/Typeahead.css";
import Tooltip from "react-bootstrap/Tooltip";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";

// created components

// config files
import {
  saveClass5,
  cancelClass5,
  mastercourseMessage1,
  mastercourseMessage2,
  classErrorMessage,
} from "../../config.json";

const NewMasterCourseForm = ({ newItem, cancelItem }) => {
  //
  // format row of table
  //

  const typeList = ["1LES", "2HW", "3VO"];

  let title = "Nieuwe vak aanmaken";

  const [label, setValue1] = React.useState("");
  const [coursetype, setValue2] = React.useState();

  const sizeLine3 = 50;

  const [showError1, setShowError1] = React.useState("");
  const [isError1, setError1] = React.useState(false);
  const [showError2, setShowError2] = React.useState("");
  const [isError2, setError2] = React.useState(false);

  React.useEffect(() => {
    if (label === "" || label.length < 3 || label.length > 50) {
      setError1(true);
      setShowError1(mastercourseMessage1);
    } else {
      setError1(false);
      setShowError1("");
    }
  }, [label]);

  React.useEffect(() => {
    if (typeof coursetype !== "undefined") {
      setError2(false);
      setShowError2("");
    } else {
      setError2(true);
      setShowError2(mastercourseMessage2);
    }
  }, [coursetype]);

  const addLineBreaks = (string) =>
    string.split("\n").map((text, index) => (
      <React.Fragment key={`${text}-${index}`}>
        {text}
        <br />
      </React.Fragment>
    ));

  function renderTooltip(title) {
    return <Tooltip id="button-tooltip">{title}</Tooltip>;
  }

  return (
    <div>
      <div>
        <OverlayTrigger
          placement="left"
          delay={{ show: 250, hide: 400 }}
          overlay={renderTooltip("terug")}
        >
          <button
            className={cancelClass5}
            onClick={() => cancelItem()}
          ></button>
        </OverlayTrigger>
        {!isError1 && !isError2 && (
          <OverlayTrigger
            placement="left"
            delay={{ show: 250, hide: 400 }}
            overlay={renderTooltip("bewaar")}
          >
            <button
              className={saveClass5}
              onClick={() =>
                newItem({
                  label,
                  coursetype,
                })
              }
            ></button>
          </OverlayTrigger>
        )}
      </div>

      <div className="table-responsive">
        <table className="table table-bordered">
          <tbody>
            <tr>
              <td>
                <h4>
                  <span className="badge badge-info d-flex justify-content-center">
                    {title}
                  </span>
                </h4>
              </td>
            </tr>
            <tr>
              <td>
                <b className="mt-1 mb-1">vak: </b>
                {isError1 && (
                  <div className={classErrorMessage}>{showError1}</div>
                )}
                <input
                  type="text"
                  className="form-control"
                  value={label}
                  onChange={(e) => setValue1(e.currentTarget.value)}
                  maxLength={sizeLine3}
                />
                <div>
                  <b className="mt-1 mb-1">type: </b>
                  {isError2 && (
                    <div className={classErrorMessage}>{showError2}</div>
                  )}
                  <Typeahead
                    id={"type"}
                    labelKey={"label"}
                    options={typeList}
                    onChange={(selectedOptions) =>
                      setValue2(selectedOptions[0])
                    }
                    highlightOnlyResult={true}
                    clearButton={true}
                  />
                </div>
                <h1>{addLineBreaks("")}</h1>
                <h1>{addLineBreaks("")}</h1>
                <h1>{addLineBreaks("")}</h1>
                <h1>{addLineBreaks("")}</h1>
                <h1>{addLineBreaks("")}</h1>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default NewMasterCourseForm;
