//
// Purpose: SAMB - task table component
// Author: Ronny Van Elewyck
// Version: 1.0
// Creation Date: 04.06.2020
// Modification Date: 27.08.2020
//

// installed components
import React from "react";

// created components
import TaskRow from "./taskRow";

const TaskTable = ({
  courseTaskList,
  readonly,
  goalsList,
  updateItem,
  deleteItem,
  createQRCODE,
}) => {
  return (
    <div className="table-responsive">
      <table className="table table-bordered">
        <thead className="thead-light">
          <tr>
            <th></th>
            {!readonly && <th></th>}
            {!readonly && <th></th>}
            <th className="col-ms-2"></th>
            <th></th>
            {!readonly && <th className="col-ms-2"></th>}
            {readonly && <th></th>}
          </tr>
        </thead>
        <tbody>
          {courseTaskList.map((item) => (
            <TaskRow
              key={item._id}
              item={item}
              goalsList={goalsList}
              updateItem={updateItem}
              deleteItem={deleteItem}
              readonly={readonly}
              createQRCODE={createQRCODE}
            />
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default TaskTable;
