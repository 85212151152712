//
// Purpose: SAMB - new task for course
// Author: Ronny Van Elewyck
// Version: 1.0
// Creation Date: 15.10.2020
// Modification Date: 15.10.2020
//

// installed components
import React from "react";
import { Typeahead } from "react-bootstrap-typeahead";
import "react-bootstrap-typeahead/css/Typeahead.css";
import Tooltip from "react-bootstrap/Tooltip";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";

// created components
import StudentSelectionTable from "./studentSelectionTable";

// config files
import {
  saveClass5,
  cancelClass5,
  classErrorMessage,
  activeClass5,
  inActiveClass5,
  mastercourseMessage3,
  mastercourseMessage4,
  mastercourseMessage5,
  mastercourseMessage6,
  goalMessage4,
} from "../../config.json";

const NewTaskForm = ({
  newItem,
  cancelItem,
  handleStudentActivate,
  handleStudentDeActivate,
  updateStudentItem,
  userCourseList,
  masterCourseList,
  goalsList,
}) => {
  //
  // new task
  //
  let isMasterCourseSelected = false;
  let isRecipe = false;

  const title = "Nieuwe taak voor les aanmaken";

  const [masterCourse, setValue1] = React.useState();
  const [order, setValue2] = React.useState("1");
  const [portion, setValue3] = React.useState("1,00");
  const [showTask, setValue4] = React.useState("ja");
  const [goal1, setValue5] = React.useState();
  const [goal2, setValue6] = React.useState();
  const [goal3, setValue7] = React.useState();
  const [goal4, setValue8] = React.useState();
  const [goal5, setValue9] = React.useState("");

  const [showError1, setShowError1] = React.useState("");
  const [isError1, setError1] = React.useState(false);
  const [showError2, setShowError2] = React.useState("");
  const [isError2, setError2] = React.useState(false);
  const [showError3, setShowError3] = React.useState("");
  const [isError3, setError3] = React.useState(false);
  const [showError4, setShowError4] = React.useState("");
  const [isError4, setError4] = React.useState(false);
  const [showError9, setShowError9] = React.useState("");
  const [isError9, setError9] = React.useState(false);

  if (typeof masterCourse !== "undefined") {
    isMasterCourseSelected = true;
    if (masterCourse.typeid1 === "1") {
      isRecipe = true;
    } else {
      isRecipe = false;
    }
  } else {
    isMasterCourseSelected = false;
  }

  React.useEffect(() => {
    if (typeof masterCourse !== "undefined") {
      setError1(false);
      setShowError1("");
    } else {
      setError1(true);
      setShowError1(mastercourseMessage3);
    }
  }, [masterCourse]);

  React.useEffect(() => {
    const regex = /^[0-9]*$/;
    const found = order.match(regex);

    if (found !== null && parseInt(order) > 0 && parseInt(order) < 51) {
      setError2(false);
      setShowError2("");
    } else {
      setError2(true);
      setShowError2(mastercourseMessage4);
    }
  }, [order]);

  const lineSizeOrder = 2;

  React.useEffect(() => {
    //const regex = /^\d+(\.\d{1,2})?$/;
    const regex = /^(0|[1-9]\d*)(,\d+)?$/;
    const found = portion.match(regex);

    if (found !== null) {
      setError3(false);
      setShowError3("");
    } else {
      setError3(true);
      setShowError3(mastercourseMessage5);
    }
  }, [portion]);

  const lineSizePortion = 13;

  React.useEffect(() => {
    if (showTask === "ja" || showTask === "nee") {
      setError4(false);
      setShowError4("");
    } else {
      setError4(true);
      setShowError4(mastercourseMessage6);
    }
  }, [showTask]);

  const lineSizeShow = 3;

  React.useEffect(() => {
    // check uppercase first letter
    const regex = /^[A-Z]/;
    const found = goal5.match(regex);

    if (goal5 === "") {
      setError9(false);
      setShowError9("");
    } else {
      if (found === null || goal5.lenght < 5 || goal5.lenght > 500) {
        setError9(true);
        setShowError9(goalMessage4);
      } else {
        setError9(false);
        setShowError9("");
      }
    }
  }, [goal5]);

  const lineSizeGoal = 500;

  function renderTooltip(title) {
    return <Tooltip id="button-tooltip">{title}</Tooltip>;
  }

  //console.log("masterCourse: ", masterCourse);

  return (
    <div>
      <div>
        <OverlayTrigger
          placement="left"
          delay={{ show: 250, hide: 400 }}
          overlay={renderTooltip("terug")}
        >
          <button
            className={cancelClass5}
            onClick={() => cancelItem()}
          ></button>
        </OverlayTrigger>

        {!isError1 && !isError2 && !isError3 && !isError4 && !isError9 && (
          <OverlayTrigger
            placement="left"
            delay={{ show: 250, hide: 400 }}
            overlay={renderTooltip("bewaar")}
          >
            <button
              className={saveClass5}
              onClick={() =>
                newItem({
                  masterCourse,
                  order,
                  portion,
                  showTask,
                  goal1,
                  goal2,
                  goal3,
                  goal4,
                  goal5,
                })
              }
            ></button>
          </OverlayTrigger>
        )}
      </div>
      <div>
        <h4>
          <span className="badge badge-info d-flex justify-content-center">
            {title}
          </span>
        </h4>
      </div>
      <div className="table-responsive">
        <table className="table table-bordered">
          <tbody>
            <tr>
              <td>
                {" "}
                <div className="d-flex justify-content-center">
                  <div>
                    <OverlayTrigger
                      placement="left"
                      delay={{ show: 250, hide: 400 }}
                      overlay={renderTooltip("activeer alle leerlingen")}
                    >
                      <div>
                        <button
                          type="button"
                          className={activeClass5}
                          onClick={() => handleStudentActivate()}
                        ></button>
                      </div>
                    </OverlayTrigger>
                  </div>
                  <div>
                    <OverlayTrigger
                      placement="left"
                      delay={{ show: 250, hide: 400 }}
                      overlay={renderTooltip("deactiveer alle leerlingen")}
                    >
                      <div>
                        <button
                          type="button"
                          className={inActiveClass5}
                          onClick={() => handleStudentDeActivate()}
                        ></button>
                      </div>
                    </OverlayTrigger>
                  </div>
                </div>
                <div className="row">
                  <StudentSelectionTable
                    userCourseList={userCourseList}
                    updateItem={(item) => updateStudentItem({ ...item })}
                  />
                </div>
              </td>
              <td>
                {" "}
                <div>
                  <b className="mt-1 mb-1">stappenplan: </b>
                  {isError1 && (
                    <div className={classErrorMessage}>{showError1}</div>
                  )}
                  <Typeahead
                    id={"step"}
                    labelKey={"label"}
                    options={masterCourseList}
                    onChange={(selectedOptions) =>
                      setValue1(selectedOptions[0])
                    }
                    highlightOnlyResult={true}
                    clearButton={true}
                  />
                </div>
                {isMasterCourseSelected && (
                  <div>
                    {" "}
                    <b className="mt-1 mb-1">volgorde: </b>{" "}
                    {isError2 && (
                      <div className={classErrorMessage}>{showError2}</div>
                    )}
                    <input
                      type="text"
                      className="form-control"
                      value={order}
                      onChange={(e) => setValue2(e.currentTarget.value)}
                      maxLength={lineSizeOrder}
                      size={lineSizeOrder}
                    />
                  </div>
                )}
                {isMasterCourseSelected && isRecipe && (
                  <div>
                    {" "}
                    <b className="mt-1 mb-1">portie: </b>{" "}
                    {isError3 && (
                      <div className={classErrorMessage}>{showError3}</div>
                    )}
                    <input
                      type="text"
                      className="form-control"
                      value={portion}
                      onChange={(e) => setValue3(e.currentTarget.value)}
                      maxLength={lineSizePortion}
                      size={lineSizePortion}
                    />
                  </div>
                )}
                {isMasterCourseSelected && (
                  <div>
                    {" "}
                    <b className="mt-1 mb-1">toon taak (ja/nee): </b>{" "}
                    {isError4 && (
                      <div className={classErrorMessage}>{showError4}</div>
                    )}
                    <input
                      type="text"
                      className="form-control"
                      value={showTask}
                      onChange={(e) => setValue4(e.currentTarget.value)}
                      maxLength={lineSizeShow}
                      size={lineSizeShow}
                    />
                  </div>
                )}
                {isMasterCourseSelected && (
                  <div>
                    {" "}
                    <div>
                      <b>doelstellingen (standaard): </b>
                      <Typeahead
                        id={"goal1"}
                        labelKey={"label"}
                        options={goalsList}
                        placeholder={goal1}
                        onChange={(selectedOptions) =>
                          setValue5(selectedOptions[0])
                        }
                        highlightOnlyResult={true}
                        clearButton={true}
                      />
                      <Typeahead
                        id={"goal2"}
                        labelKey={"label"}
                        options={goalsList}
                        placeholder={goal2}
                        onChange={(selectedOptions) =>
                          setValue6(selectedOptions[0])
                        }
                        highlightOnlyResult={true}
                        clearButton={true}
                      />
                      <Typeahead
                        id={"goal3"}
                        labelKey={"label"}
                        options={goalsList}
                        placeholder={goal3}
                        onChange={(selectedOptions) =>
                          setValue7(selectedOptions[0])
                        }
                        highlightOnlyResult={true}
                        clearButton={true}
                      />
                      <Typeahead
                        id={"goal4"}
                        labelKey={"label"}
                        options={goalsList}
                        placeholder={goal4}
                        onChange={(selectedOptions) =>
                          setValue8(selectedOptions[0])
                        }
                        highlightOnlyResult={true}
                        clearButton={true}
                      />
                    </div>
                    <div>
                      <b>vrije doelstelling: </b>
                      {isError9 && (
                        <div className={classErrorMessage}>{showError9}</div>
                      )}
                      <input
                        type="text"
                        className="form-control"
                        value={goal5}
                        onChange={(e) => setValue9(e.currentTarget.value)}
                        maxLength={lineSizeGoal}
                        size={lineSizeGoal}
                      />
                    </div>
                  </div>
                )}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default NewTaskForm;
