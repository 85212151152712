//
// Purpose: SAMB - ingredient table component
// Author: Ronny Van Elewyck
// Version: 1.0
// Creation Date: 28.05.2020
// Modification Date: 09.09.2020
//

// installed components
import React from "react";

// created components
import IngredientRow from "./ingredientRow";

const IngredientTable = ({
  ingredientList2,
  saveIngredientItem,
  deleteIngredientItem,
  ingredientList,
  uomList,
}) => {
  return (
    <div className="table-responsive">
      <table className="table table-bordered">
        <thead className="thead-light">
          <tr>
            <th></th>
            <th>volgorde</th>
            <th>ingrediënten</th>
            <th>hoeveelheid</th>
            <th>eenheid</th>
          </tr>
        </thead>
        <tbody>
          {ingredientList2.map((item) => (
            <IngredientRow
              key={item.subtaskid2}
              item={item}
              saveIngredientItem={saveIngredientItem}
              deleteIngredientItem={deleteIngredientItem}
              ingredientList={ingredientList}
              uomList={uomList}
            />
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default IngredientTable;
