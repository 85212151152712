//
// Purpose: SAMB - goal screen
// Author: Ronny Van Elewyck
// Version: 1.1
// Creation Date: 19.06.2020
// Modification Date: 27.05.2021
//

// installed components
import React, { Component } from "react";
import Papa from "papaparse";
import _ from "lodash";

// created components
import NavBar from "./navBar";
import GoalTable from "./common/goalTable";
import TopGoalButton from "./common/topGoalButton";
import ExportCSV from "./common/exportCSV";
import UploadCSV from "./common/uploadCSV";
import ErrorTable from "./common/errorTable";
import TopWaitingMessage from "./common/topWaitingMessage";
import NewGoalForm from "./common/newGoalForm";
import DeleteGoalForm from "./common/deleteGoalForm";
import SearchBox from "./common/searchBox";

// rest services
import getGoal from "../services/goalService";

// utilities
import { validateGoal, checkGoalInput } from "../utils/checkTextInput";
import { checkCharacters } from "../utils/specialCharacters";

// config files
import {
  noError,
  goalTitle,
  classTables,
  classTitle,
  goalMessage1,
  uploadClass5,
  downloadClass5,
  errorTableTitle1,
  saveDBError2,
  goalMessage7,
  goalMessage01,
  classWarningMessage,
} from "../config.json";

class Goals extends Component {
  //
  // goal screen
  //

  // local data
  state = {
    searchQuery: "",
    goalList1: [],
    goalList2: [],
    allGoalList2: [],
    goalList3: [],
    isLoaded: false,
    csvfile1: undefined,
    xlsxfile1: "SAMBdownload",
    isUpdateError: false,
    groupid: 0,
    mastercourseid: 0,
    grouptext: "",
    mastertasktext: "",
    isFileCSV: false,
    isCSVLoaded: false,
    isFileMissing: false,
    inputTextError: undefined,
    saveDBBusy: false,
    beforeSaveError: undefined,
    isNew: false,
    isAskDeleteSure: false,
    deleteRecord: undefined,
    isShowSave: false,
  };

  async componentDidMount() {
    //
    // mount component
    //
    ////console.log("componentDidMount(): ");
    //await this.refreshGoal();
  }

  refreshGoal = async (item) => {
    //
    // refresh goal data
    //
    // goalList1 : origional
    // goaList2 : screen
    // goalList3 : download/upload

    let groupid = item.groupid;
    let mastercourseid = item.mastercourseid;
    this.setState({
      groupid,
      mastercourseid,
      goalList1: [],
      goalList2: [],
      allGoalList2: [],
      goalList3: [],
      inputTextError: undefined,
      beforeSaveError: undefined,
    });

    let goalList1 = [];
    let goalList2 = [];
    let allGoalList2 = [];
    let goalList3 = [];
    let goalsList = [];

    const { session } = this.props;

    // goals get
    const jsonList = await getGoal.goalDetails(session);
    const dbreturnList = jsonList.jsonReturn;

    if (dbreturnList[0].errorcode === noError) {
      // filter
      goalList1 = jsonList.goalsList.filter(
        (m) => m.groupid === groupid && m.mastercourseid === mastercourseid
      );
      // sort
      /*  goalList1 = _.sortBy(jsonList.goalsList, function (item) {
        return item.mastercoursetext;
      });*/

      //console.log("goalList1", goalList1);

      // goaList2
      goalList2 = goalList1;
      allGoalList2 = goalList2;
      // goalList3
      for (let index = 0; index < goalList1.length; index++) {
        goalList3.push({
          klasnummer: goalList1[index].groupid,
          klasnaam: goalList1[index].grouptext,
          vaknummer: goalList1[index].mastercourseid,
          vaknaam: goalList1[index].mastercoursetext,
          doelnummer: goalList1[index].id,
          doelomschrijving: goalList1[index].label,
        });
      }

      // global refresh ofg goal arrays
      const result5 = await getGoal.goalDetails(session);
      if (result5.jsonReturn[0].errorcode === noError) {
        goalsList = result5.goalsList;
      }
      this.props.onSaveGoalsList(goalsList);

      // local state
      this.setState({
        groupid,
        mastercourseid,
        goalList1,
        goalList2,
        allGoalList2,
        goalList3,
        isLoaded: true,
      });
    } else {
      this.setState({
        goalList1: [],
        goalList2: [],
        allGoalList2: [],
        goalList3: [],
        isLoaded: false,
      });
    }
  };

  handleSearch = (query) => {
    //
    // execute search filter
    //
    let goalList2 = [];
    const { allGoalList2 } = this.state;

    if (query !== "") {
      goalList2 = allGoalList2.filter((m) =>
        m.searchfield.toLowerCase().includes(query.toLowerCase())
      );
    } else {
      goalList2 = allGoalList2;
    }

    goalList2 = _.sortBy(goalList2, function (item) {
      return parseInt(item.label);
    });

    this.setState({
      goalList2,
      searchQuery: query,
    });
  };

  handleSaveGoal = async () => {
    //
    // save changes in database
    //

    const { session } = this.props;
    const { goalList1, allGoalList2, groupid, mastercourseid } = this.state;
    this.setState({
      saveDBBusy: true,
    });

    let errorList = [];
    let deleteOK = true;

    if (JSON.stringify(goalList1) === JSON.stringify(allGoalList2)) {
      errorList.push({
        key: 1,
        errorText: goalMessage7,
      });
    } else {
      if (groupid !== 0 && mastercourseid !== 0) {
        // delete
        const result = await getGoal.deleteMassGoal(
          session,
          groupid,
          mastercourseid
        );
        if (result.jsonReturn[0].errorcode !== noError) {
          errorList.push({
            key: 1,
            errorText:
              result.jsonReturn[0].errorcode +
              "doelstelling db delete fout klas nummer: " +
              groupid +
              " vak nummer: " +
              mastercourseid,
          });
          deleteOK = false;
        }
        if (deleteOK) {
          // insert

          for (let index = 0; index < allGoalList2.length; index++) {
            // convert special characters
            const convertedGoalText = checkCharacters(
              allGoalList2[index].label
            );
            const result2 = await getGoal.insertGoal(
              session,
              groupid,
              mastercourseid,
              convertedGoalText
            );
            if (result2.jsonReturn[0].errorcode !== noError) {
              errorList.push({
                key: index + 1,
                errorText:
                  result2.jsonReturn[0].errorcode +
                  "doelstelling db fout klas nummer: " +
                  groupid +
                  " vak nummer: " +
                  mastercourseid +
                  " " +
                  allGoalList2[index].label,
              });
            } else {
              // refresh global table with goals
              const { groupid, mastercourseid } = this.state;
              const item = {
                groupid: groupid,
                mastercourseid: mastercourseid,
              };
              await this.refreshGoal(item);
            }
          }
        }
      }
    }

    if (typeof errorList[0] === "undefined") {
      // busy saving
      this.setState({
        saveDBBusy: false,
        beforeSaveError: undefined,
        isShowSave: false,
      });
    } else {
      ////console.log("modifyDatabase - errorList: ", errorList);
      this.setState({
        beforeSaveError: errorList,
        saveDBBusy: false,
        isShowSave: false,
      });
    }
  };

  handleFileInputChange = (e) => {
    //
    // input file
    //

    const file = e.target.files[0];

    //console.log("handleFileInputChange file: ", file);

    this.setState({
      csvfile1: undefined,
      isFileCSV: false,
      inputTextError: undefined,
    });

    if (file.type === "text/csv") {
      this.importCSV(file);
      this.setState({
        csvfile1: file,
        isFileCSV: false,
      });
    } else {
      this.setState({
        csvfile1: "undefined",
        isFileCSV: true,
      });
    }
  };

  importCSV = (csvfile1) => {
    this.setState({
      goalList2: [],
      allGoalList2: [],
      isCSVLoaded: false,
      isFileMissing: false,
    });

    if (typeof csvfile1 !== "undefined") {
      Papa.parse(csvfile1, {
        complete: this.saveCSVData,
        header: true,
      });
    } else {
      this.setState({
        isFileMissing: true,
      });
    }
  };

  saveCSVData = (results) => {
    //
    // upload goal data from file to screen with validation
    //

    const { groupList, masterCourseList } = this.props;
    const { groupid, mastercourseid } = this.state;
    let inputTextError = [];

    let goalList2 = this.state.goalList2;
    let allGoalList2 = this.state.allGoalList2;
    let goalList3 = this.state.goalList3;

    if (typeof results.error === "undefined") {
      // validate input file
      const result = checkGoalInput(
        results.data,
        groupid,
        mastercourseid,
        groupList,
        masterCourseList
      );
      inputTextError = result.errorList;
      allGoalList2 = result.tableList;
      goalList2 = allGoalList2;
      goalList3 = result.uploadList;

      if (inputTextError.length === 0) {
        // save in database

        this.setState({
          inputTextError: undefined,
          goalList2,
          allGoalList2,
          goalList3,
          isCSVLoaded: true,
          isLoaded: true,
          isShowSave: true,
        });
      } else {
        this.setState({
          inputTextError,
          goalList2: [],
          allGoalList2: [],
          goalList3: [],
          isCSVLoaded: false,
          isLoaded: false,
          isShowSave: false,
        });
      }
    } else {
      this.setState({
        goalList2,
        allGoalList2,
        goalList3,
        isCSVLoaded: false,
        isLoaded: false,
        isShowSave: false,
      });
    }
  };

  handleNew = () => {
    //
    // new
    //

    const { goalid, mastercourseid } = this.state;

    if (goalid !== 0 && mastercourseid !== 0) {
      this.scrollTop();

      this.setState({
        isNew: true,
      });
    } else {
      this.setState({
        isNew: false,
      });
    }
  };

  handleChangeGroupid = async (selectedOptions) => {
    //
    // handle group change
    //

    const { mastercourseid } = this.state;
    let groupid = 0;

    if (typeof selectedOptions[0] !== "undefined") {
      groupid = selectedOptions[0].id;

      if (groupid !== 0 && mastercourseid !== 0) {
        // refresh table from database
        const item = {
          groupid: groupid,
          mastercourseid: mastercourseid,
        };
        await this.refreshGoal(item);
      }
    }

    this.setState({
      groupid,
    });
  };

  handleChangeMastercourseid = async (selectedOptions) => {
    //
    // handle mastercourse change
    //

    const { groupid } = this.state;
    let mastercourseid = 0;

    if (typeof selectedOptions[0] !== "undefined") {
      mastercourseid = selectedOptions[0].id;

      if (groupid !== 0 && mastercourseid !== 0) {
        // refresh table from database
        const item = {
          groupid: groupid,
          mastercourseid: mastercourseid,
        };
        await this.refreshGoal(item);
      }
    }

    this.setState({
      mastercourseid,
    });
  };

  render() {
    //
    // render task screen
    //

    // get local state variables
    const {
      goalList2,
      goalList3,
      isLoaded,
      isUpdateError,
      groupid,
      mastercourseid,
      xlsxfile1,
      inputTextError,
      isCSVLoaded,
      beforeSaveError,
      saveDBBusy,
      isNew,
      isAskDeleteSure,
      deleteRecord,
      isShowSave,
      searchQuery,
    } = this.state;
    const { session, groupList, masterCourseList } = this.props;

    // don't render if not
    if (typeof session.companyimageurl === "undefined") {
      ////console.log("render: ", new Date());
      return null;
    }

    let csvfileReplace = "";

    return (
      <React.Fragment>
        {/* error messages */}
        {isUpdateError && (
          <div className="alert alert-danger">{goalMessage1}</div>
        )}

        {typeof beforeSaveError !== "undefined" && (
          <ErrorTable
            inputTextError={beforeSaveError}
            title={errorTableTitle1}
          />
        )}

        {saveDBBusy && <TopWaitingMessage messageToShow={saveDBError2} />}

        {isShowSave && (
          <div className={classWarningMessage}>{goalMessage01}</div>
        )}

        {/* navigation bar */}
        <NavBar />

        {/* confirm goal delete */}
        {isAskDeleteSure && (
          <div className={classTables}>
            <DeleteGoalForm
              confirmDeleteItem={this.handleConfirmDeleteItem}
              cancelItem={this.handleCancelDeleteItem}
              item={deleteRecord}
            />
          </div>
        )}

        {/* title */}
        <h4>
          <span className={classTitle}>{goalTitle}</span>
        </h4>

        {/* error messages */}
        {!isCSVLoaded && typeof inputTextError !== "undefined" && (
          <ErrorTable
            inputTextError={inputTextError}
            title={errorTableTitle1}
          />
        )}

        {/* new */}
        {groupid !== 0 && mastercourseid !== 0 && isNew && (
          <div className={classTables}>
            <NewGoalForm
              newItem={this.handleNewItem}
              cancelItem={this.handleCancelItem}
            />
          </div>
        )}

        {/* group and mastercourse selection */}
        <TopGoalButton
          handleSaveGoal={this.handleSaveGoal}
          handleNew={this.handleNew}
          groupList={groupList}
          masterCourseList={masterCourseList}
          groupid={groupid}
          handleChangeGroupid={this.handleChangeGroupid}
          mastercourseid={mastercourseid}
          handleChangeMastercourseid={this.handleChangeMastercourseid}
        />

        <center>
          <UploadCSV
            csvfileReplace={csvfileReplace}
            className={uploadClass5}
            onChange={(e) => this.handleFileInputChange(e)}
          />
          <ExportCSV
            csvData={goalList3}
            fileName={xlsxfile1}
            className={downloadClass5}
          />
        </center>

        {/* table */}
        {groupid !== 0 && mastercourseid !== 0 && isLoaded && (
          <div>
            {" "}
            <SearchBox value={searchQuery} onChange={this.handleSearch} />
            <div className={classTables}>
              <GoalTable
                listArray={goalList2}
                changeItem={this.changeItem}
                copyItem={this.copyItem}
                deleteItem={this.deleteItem}
                groupList={groupList}
                masterCourseList={masterCourseList}
              />
            </div>
          </div>
        )}
      </React.Fragment>
    );
  }

  /*****************/
  /* row functions */
  /*****************/
  handleConfirmDeleteItem = async (item) => {
    //
    // delete goal
    //

    //console.log("handleConfirmDeleteItem: ", item["item"]);

    const { session } = this.props;

    this.setState({
      listArray: [],
      allListArray: [],
      isUpdateError: false,
    });

    // delete goal
    const jsonList = await getGoal.deleteGoal(session, item["item"].id);
    const dbreturnList = jsonList.jsonReturn;

    if (dbreturnList[0].errorcode === noError) {
      // refresh data
      const combination = {
        groupid: item["item"].groupid,
        mastercourseid: item["item"].mastercourseid,
      };
      await this.refreshGoal(combination);
    } else {
      // error
      this.setState({
        isUpdateError: true,
      });
    }

    this.setState({
      isAskDeleteSure: false,
      deleteRecord: undefined,
    });
  };

  handleCancelDeleteItem = () => {
    //
    // Refresh
    //

    this.setState({
      isAskDeleteSure: false,
      deleteRecord: undefined,
    });
  };

  handleCancelItem = () => {
    //
    // Refresh
    //

    this.setState({ isNew: false });
  };

  handleNewItem = async (itemData) => {
    //
    // insert goal
    //

    const { session } = this.props;
    const { groupid, mastercourseid } = this.state;

    let inputTextError = [];
    this.setState({
      listArray: [],
      allListArray: [],
      isUpdateError: false,
      isNew: false,
    });

    if (groupid !== 0 && mastercourseid !== 0) {
      // check valid goal text
      inputTextError = validateGoal(
        groupid,
        mastercourseid,
        itemData.label,
        groupid,
        mastercourseid,
        inputTextError
      );
      if (inputTextError.length === 0) {
        // convert special characters
        const convertedGoalText = checkCharacters(itemData.label);
        // insert in db
        const result = await getGoal.insertGoal(
          session,
          groupid,
          mastercourseid,
          convertedGoalText
        );
        if (result.jsonReturn[0].errorcode === noError) {
          // refresh data
          const item = {
            groupid: groupid,
            mastercourseid: mastercourseid,
          };
          await this.refreshGoal(item);
          this.setState({ inputTextError: undefined, isUpdateError: false });
        } else {
          // error
          inputTextError.push({
            key: 1,
            errorText:
              result.jsonReturn[0].errorcode +
              result.jsonReturn[0].errormessage,
          });

          this.setState({
            isNew: true,
            inputTextError,
            isUpdateError: true,
          });
        }
      } else {
        //show error list
        let errorListTemp = inputTextError;
        inputTextError = [];
        for (let index = 0; index < errorListTemp.length; index++) {
          inputTextError.push({
            key: index,
            errorText: errorListTemp[index].errorText,
          });
        }
        errorListTemp = [];

        this.setState({
          isNew: true,
          inputTextError,
          isUpdateError: true,
        });
      }
    }
  };

  changeItem = async (itemData) => {
    //
    // change item
    //
    let inputTextError = [];
    this.setState({ listArray: [], allListArray: [], isUpdateError: false });

    const { session } = this.props;

    ////console.log("changeItem: ", itemData);

    // group dropdown
    if (
      typeof itemData.grouptext.id !== "undefined" &&
      typeof itemData.grouptext.label !== "undefined"
    ) {
      itemData.groupid = itemData.grouptext.id;
    }

    // mastercourse dropdown
    if (
      typeof itemData.mastercoursetext.id !== "undefined" &&
      typeof itemData.mastercoursetext.label !== "undefined"
    ) {
      itemData.mastercourseid = itemData.mastercoursetext.id;
    }

    // validate goal input

    inputTextError = validateGoal(
      itemData.groupid,
      itemData.mastercourseid,
      itemData.label,
      itemData.groupid,
      itemData.mastercourseid,
      inputTextError
    );
    if (inputTextError.length === 0) {
      // replace special characters
      // convert special characters
      const convertedGroupText = checkCharacters(itemData.label);

      // save changes
      // update goal
      const jsonList = await getGoal.updateGoal(
        session,
        itemData.id,
        itemData.groupid,
        itemData.mastercourseid,
        convertedGroupText
      );
      const dbreturnList = jsonList.jsonReturn;

      if (dbreturnList[0].errorcode === noError) {
        // refresh data
        const item = {
          groupid: itemData.groupid,
          mastercourseid: itemData.mastercourseid,
        };
        await this.refreshGoal(item);
        this.setState({
          inputTextError: undefined,
          isUpdateError: false,
        });
      } else {
        // error
        inputTextError.push({
          key: 1,
          errorText: dbreturnList[0].errorcode + dbreturnList[0].errormessage,
        });

        this.setState({
          inputTextError,
          isUpdateError: true,
        });
      }
    } else {
      //show error list
      let errorListTemp = inputTextError;
      inputTextError = [];
      for (let index = 0; index < errorListTemp.length; index++) {
        inputTextError.push({
          key: index,
          errorText: errorListTemp[index].errorText,
        });
      }
      errorListTemp = [];

      this.setState({
        inputTextError,
        isUpdateError: true,
      });
    }
  };

  deleteItem = (itemData) => {
    //
    // delete item
    //

    this.scrollTop();

    this.setState({
      isAskDeleteSure: true,
      deleteRecord: itemData,
    });
  };

  scrollTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };
}

export default Goals;
