//
// Purpose: SAMB - user service
// Author: Ronny Van Elewyck
// Version: 1.1
// Creation Date: 27.02.2020
// Modification Date: 04.12.2020
//

import axios from "axios";
import {
  basisUrlSam,
  basisUrlNode,
  prd,
  p1,
  p2,
  p3,
  p4,
  p5,
  p6,
  p7,
  p8,
  p9,
  p10,
  p11,
  p12,
  p13,
  p14,
  p15,
  p16,
  p17,
  p18,
} from "../config.json";

export async function userDetails(session, roleid) {
  //
  // get all sam user details for company
  //

  // variables
  let param1 = "";
  const getSamUserPHP = "getAllSamUsers.php";

  // sam instance
  if (session.actdb === prd) {
    param1 = session.prd;
  } else {
    param1 = session.dev;
  }
  // url
  const urlGetUserSam =
    basisUrlSam +
    getSamUserPHP +
    p1 +
    param1 +
    p2 +
    session.compid +
    p3 +
    roleid;

  // async call
  const { data: response } = await axios.get(urlGetUserSam);
  const jsonReturn = response.DBRETURN;
  const userList = response.ALLUSERS;

  ////console.log(userList);
  return {
    jsonReturn,
    userList,
  };
}

export async function userNodeDetails(session) {
  //
  // get all sam user details for company
  //

  // variables
  const param1 = "node1.php";
  const getPHP = "getUser.php";

  // url
  const urlGet =
    basisUrlNode + getPHP + p1 + param1 + p2 + p3 + p4 + session.compid;

  ////console.log("urlGet: ", urlGet);

  // async call
  const { data: response } = await axios.get(urlGet);
  const userNodeList = response.USERNODE;

  return {
    userNodeList,
  };
}

export async function deleteUser(session, userid, roleid) {
  //
  // get delete course
  //

  // variables
  let param1 = "";
  const getPHP = "deleteUserAllNodes.php";

  // sam instance
  if (session.actdb === prd) {
    param1 = session.prd;
  } else {
    param1 = session.dev;
  }
  // url
  const urlPut =
    basisUrlSam +
    getPHP +
    p1 +
    param1 +
    p2 +
    session.compid +
    p3 +
    userid +
    p4 +
    roleid;

  //console.log(urlPut);

  // async call
  const { data: response } = await axios.get(urlPut);
  const jsonReturn = response.DBRETURN;

  return {
    jsonReturn,
  };
}

export async function moveTeacher(session, teacherid) {
  //
  // get delete course
  //

  // variables
  let param1 = "";
  const getPHP = "deleteCourseTeacher.php";

  // sam instance
  if (session.actdb === prd) {
    param1 = session.prd;
  } else {
    param1 = session.dev;
  }
  // url
  const urlPut = basisUrlSam + getPHP + p1 + param1 + p2 + teacherid;

  //console.log(urlPut);

  // async call
  const { data: response } = await axios.get(urlPut);
  const jsonReturn = response.DBRETURN;
  const courseReturn = response.COURSE;

  return {
    jsonReturn,
    courseReturn,
  };
}

export async function createUser(
  session,
  roleid,
  groupid1,
  firstname,
  lastname,
  logactive,
  actdb
) {
  //
  // get delete course
  //

  // variables
  let param1 = "";
  const getPHP = "insertUserAllNodes.php";

  // sam instance
  if (session.actdb === prd) {
    param1 = session.prd;
  } else {
    param1 = session.dev;
  }
  // url
  const urlPut =
    basisUrlSam +
    getPHP +
    p1 +
    param1 +
    p2 +
    session.compid +
    p3 +
    roleid +
    p4 +
    groupid1 +
    p5 +
    firstname +
    p6 +
    lastname +
    p7 +
    logactive +
    p8 +
    actdb +
    p9 +
    session.logontxt;

  //console.log(urlPut);

  // async call
  const { data: response } = await axios.get(urlPut);
  const jsonReturn = response.DBRETURN;

  return {
    jsonReturn,
  };
}

export async function updateUser(
  session,
  userid,
  roleid,
  firstname,
  lastname,
  groupid1,
  attribute1,
  attribute2,
  attribute3,
  attribute4,
  attribute5,
  attribute6,
  attribute7,
  attribute8,
  attribute9,
  attribute10,
  pincode
) {
  //
  // get delete course
  //

  // variables
  let param1 = "";
  const getPHP = "updateUser.php";

  // sam instance
  if (session.actdb === prd) {
    param1 = session.prd;
  } else {
    param1 = session.dev;
  }
  // url
  const urlPut =
    basisUrlSam +
    getPHP +
    p1 +
    param1 +
    p2 +
    userid +
    p3 +
    roleid +
    p4 +
    session.compid +
    p5 +
    firstname +
    p6 +
    lastname +
    p7 +
    groupid1 +
    p8 +
    attribute1 +
    p9 +
    attribute2 +
    p10 +
    attribute3 +
    p11 +
    attribute4 +
    p12 +
    attribute5 +
    p13 +
    attribute6 +
    p14 +
    attribute7 +
    p15 +
    attribute8 +
    p16 +
    attribute9 +
    p17 +
    attribute10 +
    p18 +
    pincode;

  //console.log(urlPut);

  // async call
  const { data: response } = await axios.get(urlPut);
  const jsonReturn = response.DBRETURN;

  return {
    jsonReturn,
  };
}

// eslint-disable-next-line
export default {
  userDetails,
  userNodeDetails,
  deleteUser,
  moveTeacher,
  createUser,
  updateUser,
};
