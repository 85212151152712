//
// Purpose: SAMB - helpers for images
// Author: Ronny Van Elewyck
// Version: 1.1
// Creation Date: 12.06.2020
// Modification Date: 04.12.2020
//

export function createImageRecord() {
  //
  // create image record
  //
  const imageRecord = {
    type: "",
    minWidth: "",
    minHeight: "",
    maxWidth: "",
    maxHeight: "",
    subtype: "",
    id: "",
    subid: "",
    screenformat: "",
    description: "",
    new: true,
    oldname: undefined,
    oldurl: undefined,
  };

  return imageRecord;
}

/*export function checkImgSize2(file, minWidth, minHeight, maxWidth, maxHeight) {
  //
  // check image file size
  //

  let isWidthMax = false;
  let isHeightMax = false;
  let isWidthMin = false;
  let isHeightMin = false;
  let isWidthRatio = false;
  let isHeightRatio = false;

  // Get an object URL for the local file
  const blobURL = URL.createObjectURL(file);

  // Create an image and load the object URL
  const img = new Image();
  img.src = blobURL;

  img.onload = function () {
    URL.revokeObjectURL(blobURL);

    if (this.naturalWidth >= maxWidth) {
      isWidthMax = false;
    } else {
      isWidthMax = true;
    }

    if (this.naturalHeight >= maxHeight) {
      isHeightMax = false;
    } else {
      isHeightMax = true;
    }
    if (this.naturalWidth >= minWidth) {
      isWidthMin = true;
    } else {
      isWidthMin = false;
    }

    if (this.naturalHeight >= minHeight) {
      isHeightMin = true;
    } else {
      isHeightMin = false;
    }

    if (isWidthMax && isHeightMax && isWidthMin && isHeightMin) {
      const aspectRatio = maxWidth / maxHeight;
      const newAspectRatio = this.naturalWidth / this.naturalHeight;
      //const checkWidth = this.naturalWidth / minWidth;
      //isWidthRatio = Number.isInteger(checkWidth);
      //const checkHeight = this.naturalHeight / minHeight;
      //isHeightRatio = Number.isInteger(checkHeight);
      if (aspectRatio === newAspectRatio) {
        isWidthRatio = true;
        isHeightRatio = true;
      } else {
        isWidthRatio = false;
        isHeightRatio = false;
      }
    }
  };

  return {
    blobURL,
    isWidthMax,
    isHeightMax,
    isWidthMin,
    isHeightMin,
    isWidthRatio,
    isHeightRatio,
  };
}*/

export function checkImgSize(url, minWidth, minHeight, maxWidth, maxHeight) {
  return new Promise(function (resolve, reject) {
    // let isWidthRatio = false;
    // let isHeightRatio = false;

    //const blob = file;

    // Get an object URL for the local file
    //const url = URL.createObjectURL(blob);

    // Create an image and load the object URL
    const img = new Image();
    img.src = url;

    img.onload = function () {
      URL.revokeObjectURL(url);

      if (this.naturalWidth > maxWidth) {
        reject(false);
      }

      if (this.naturalHeight > maxHeight) {
        reject(false);
      }

      if (this.naturalWidth < minWidth) {
        reject(false);
      }

      if (this.naturalHeight < minHeight) {
        reject(false);
      }

      //const checkWidth = this.naturalWidth / minWidth;
      //isWidthRatio = Number.isInteger(checkWidth);
      const aspectRatio = maxWidth / maxHeight;
      const newAspectRatio = this.naturalWidth / this.naturalHeight;
      if (aspectRatio !== newAspectRatio) {
        reject(false);
      }

      /*const checkHeight = this.naturalHeight / minHeight;
      isHeightRatio = Number.isInteger(checkHeight);
      if (!isHeightRatio) {
        reject(false);
      }*/

      // If we made it here then the file was approved
      resolve();
    };
  });
}

// eslint-disable-next-line
export default {
  createImageRecord,
  checkImgSize,
  //checkImgSize2,
};
