//
// Purpose: SAMB - top material buttons
// Author: Ronny Van Elewyck
// Version: 1.0
// Creation Date: 17.06.2020
// Modification Date: 26.08.2020
//

// installed components
import React from "react";
import Tooltip from "react-bootstrap/Tooltip";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";

// created components
import ExportCSV from "./exportCSV";

// config files
import {
  //refreshClass5,
  classTopButtons5,
  downloadClass5,
  newClass5,
} from "../../config.json";

const TopMaterialButton = ({
  listArray,
  handleRefresh,
  fileName,
  screenFilter,
  handleNew,
  isAdmin,
}) => {
  const [producttext, setValue1] = React.useState(screenFilter);

  function renderTooltip(title) {
    return <Tooltip id="button-tooltip">{title}</Tooltip>;
  }

  function handleKeyDown(event) {
    if (event.key === "Enter") {
      handleRefresh(producttext);
    }
  }

  return (
    <div className={classTopButtons5}>
      {isAdmin && (
        <div>
          <OverlayTrigger
            placement="left"
            delay={{ show: 250, hide: 400 }}
            overlay={renderTooltip("nieuw")}
          >
            <div>
              <button
                type="button"
                className={newClass5}
                onClick={() => handleNew()}
              ></button>
            </div>
          </OverlayTrigger>
        </div>
      )}

      <input
        type="text"
        className="form-control mt-1"
        placeholder="Filter..."
        value={producttext}
        onChange={(e) => setValue1(e.currentTarget.value)}
        size={200}
        onKeyDown={handleKeyDown}
      />
      <ExportCSV
        csvData={listArray}
        fileName={fileName}
        className={downloadClass5}
      />
    </div>
  );
};

export default TopMaterialButton;
