//
// Purpose: SAMB - delete User
// Author: Ronny Van Elewyck
// Version: 1.0
// Creation Date: 12.06.2020
// Modification Date: 28.10.2020
//

// installed components
import React from "react";
import Tooltip from "react-bootstrap/Tooltip";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";

// created components

// config files
import { deleteClass5, cancelClass5 } from "../../config.json";

const DeleteUserForm = ({ item, confirmDeleteItem, cancelItem }) => {
  //
  // format row of table
  //

  const title =
    "de gebruiker " + item.logon + " en zijn taken op alle nodes verwijderen?";
  const message =
    item.firstname +
    " " +
    item.lastname +
    " - " +
    item._id +
    " definitief verwijderen?";

  function renderTooltip(title) {
    return <Tooltip id="button-tooltip">{title}</Tooltip>;
  }

  return (
    <div>
      <div>
        <OverlayTrigger
          placement="left"
          delay={{ show: 250, hide: 400 }}
          overlay={renderTooltip("terug")}
        >
          <button
            className={cancelClass5}
            onClick={() => cancelItem()}
          ></button>
        </OverlayTrigger>
        <OverlayTrigger
          placement="left"
          delay={{ show: 250, hide: 400 }}
          overlay={renderTooltip("verwijder")}
        >
          <button
            className={deleteClass5}
            onClick={() =>
              confirmDeleteItem({
                item,
              })
            }
          ></button>
        </OverlayTrigger>
      </div>

      <div className="table-responsive">
        <table className="table table-bordered">
          <tbody>
            <tr>
              <td>
                <h4>
                  <span className="badge badge-danger d-flex justify-content-center">
                    {title}
                  </span>
                </h4>
              </td>
            </tr>
            <tr>
              <td>
                <h4 className="ml-2 mr-2">{message}</h4>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default DeleteUserForm;
