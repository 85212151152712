//
// Purpose: SAMB - group service
// Author: Ronny Van Elewyck
// Version: 1.1
// Creation Date: 27.02.2020
// Modification Date: 04.12.2020
//

import axios from "axios";
import { basisUrlSam, prd, p1, p2, p3, p4 } from "../config.json";

export async function groupDetails(session) {
  //
  // get all group details for company
  //

  // variables
  let param1 = "";
  const getPHP = "getAllUserGroups.php";

  // sam instance
  if (session.actdb === prd) {
    param1 = session.prd;
  } else {
    param1 = session.dev;
  }
  // url
  const urlGet = basisUrlSam + getPHP + p1 + param1;

  // async call
  const { data: response } = await axios.get(urlGet);
  const jsonReturn = response.DBRETURN;
  const groupList = response.ALLGROUP;

  return {
    jsonReturn,
    groupList,
  };
}

export async function allGroup(session) {
  //
  // get all group details for company
  //

  // variables
  let param1 = "";
  const getPHP = "getGroup1.php";

  // sam instance
  if (session.actdb === prd) {
    param1 = session.prd;
  } else {
    param1 = session.dev;
  }
  // url
  const urlGet = basisUrlSam + getPHP + p1 + param1;

  //console.log("urlGet: ",urlGet);

  // async call
  const { data: response } = await axios.get(urlGet);
  const jsonReturn = response.DBRETURN;
  const groupList = response.ALLGROUPS;

  return {
    jsonReturn,
    groupList,
  };
}

export async function insertGroup(session, grouptext) {
  //
  // insert user course
  //

  // variables
  let param1 = "";
  const getPHP = "insertGroup1.php";

  // sam instance
  if (session.actdb === prd) {
    param1 = session.prd;
  } else {
    param1 = session.dev;
  }
  // url
  const urlPut = basisUrlSam + getPHP + p1 + param1 + p2 + grouptext;

  //console.log(urlPut);

  // async call
  const { data: response } = await axios.get(urlPut);
  const jsonReturn = response.DBRETURN;

  return {
    jsonReturn,
  };
}

export async function updateGroup(session, groupid, active, grouptext) {
  //
  // update user courses
  //

  // variables
  let param1 = "";
  const getPHP = "updateGroup1.php";

  // sam instance
  if (session.actdb === prd) {
    param1 = session.prd;
  } else {
    param1 = session.dev;
  }
  // url
  const urlPut =
    basisUrlSam +
    getPHP +
    p1 +
    param1 +
    p2 +
    groupid +
    p3 +
    active +
    p4 +
    grouptext;

  //console.log(urlPut);

  // async call
  const { data: response } = await axios.get(urlPut);
  const jsonReturn = response.DBRETURN;

  return {
    jsonReturn,
  };
}

export async function deleteGroup(session, groupid) {
  //
  // insert user course
  //

  // variables
  let param1 = "";
  const getPHP = "deleteGroup.php";

  // sam instance
  if (session.actdb === prd) {
    param1 = session.prd;
  } else {
    param1 = session.dev;
  }
  // url
  const urlPut = basisUrlSam + getPHP + p1 + param1 + p2 + groupid;

  //console.log(urlPut);

  // async call
  const { data: response } = await axios.get(urlPut);
  const jsonReturn = response.DBRETURN;

  return {
    jsonReturn,
  };
}

// eslint-disable-next-line
export default {
  groupDetails,
  insertGroup,
  updateGroup,
  allGroup,
  deleteGroup,
};
