//
// Purpose: SAMB - user Row
// Author: Ronny Van Elewyck
// Version: 1.0
// Creation Date: 15.07.2020
// Modification Date: 29.10.2020
//

// installed components
import React from "react";
import { Typeahead } from "react-bootstrap-typeahead";
import "react-bootstrap-typeahead/css/Typeahead.css";
import Tooltip from "react-bootstrap/Tooltip";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";

// created components

// config files
import {
  saveClass5,
  copyClass5,
  deleteClass5,
  imageClass1,
  passwordClass1,
} from "../../config.json";

const UserRow = ({
  item,
  changeItem,
  copyItem,
  deleteItem,
  isUser,
  groupList,
  changePassword,
  changeImage,
  isAdmin,
  isAdminUser,
}) => {
  //
  // format row of table
  //

  const jnList = ["N", "J"];
  const [firstname, setValue1] = React.useState(item.firstname);
  const [lastname, setValue2] = React.useState(item.lastname);
  const [pincode, setValue5] = React.useState(item.pincode);

  const [grouptext1, setValue3] = React.useState(item.grouptext1);

  const [attribute1, setValue11] = React.useState(item.attribute1);
  const [attribute2, setValue12] = React.useState(item.attribute2);
  const [attribute3, setValue13] = React.useState(item.attribute3);
  const [attribute4, setValue14] = React.useState(item.attribute4);
  const [attribute5, setValue15] = React.useState(item.attribute5);
  const [attribute6, setValue16] = React.useState(item.attribute6);
  const [attribute7, setValue17] = React.useState(item.attribute7);
  const [attribute8, setValue18] = React.useState(item.attribute8);
  const [attribute9, setValue19] = React.useState(item.attribute9);
  const [attribute10, setValue20] = React.useState(item.attribute10);

  const [isChecked, setChecked] = React.useState(false);

  let isSamSupport = true;

  const isInUse = false;

  if ((firstname === "SAMSUPPORT") || (firstname === "QR")) {
    isSamSupport = true;
  } else {
    isSamSupport = false;
  }

  function renderTooltip(title) {
    return <Tooltip id="button-tooltip">{title}</Tooltip>;
  }

  return (
    <tr>
      {!isSamSupport && (
        <td>
          <div>
            <OverlayTrigger
              placement="left"
              delay={{ show: 250, hide: 400 }}
              overlay={renderTooltip("detail open/dicht")}
            >
              <div>
                <input
                  type="checkbox"
                  checked={isChecked}
                  onChange={() => setChecked(!isChecked)}
                />
              </div>
            </OverlayTrigger>
          </div>
        </td>
      )}
      {isChecked && !isSamSupport && (
        <td>
          <div>
            <OverlayTrigger
              placement="left"
              delay={{ show: 250, hide: 400 }}
              overlay={renderTooltip("bewaar")}
            >
              <div>
                <button
                  className={saveClass5}
                  onClick={() =>
                    changeItem({
                      ...item,
                      firstname,
                      lastname,
                      pincode,
                      grouptext1,
                      attribute1,
                      attribute2,
                      attribute3,
                      attribute4,
                      attribute5,
                      attribute6,
                      attribute7,
                      attribute8,
                      attribute9,
                      attribute10,
                    })
                  }
                ></button>{" "}
              </div>
            </OverlayTrigger>
          </div>
        </td>
      )}
      {isChecked && !isSamSupport && isAdmin && (
        <td>
          {item.roleid !== "1" && (
            <div>
              <OverlayTrigger
                placement="left"
                delay={{ show: 250, hide: 400 }}
                overlay={renderTooltip("kopieer")}
              >
                <div>
                  <button
                    className={copyClass5}
                    onClick={() => copyItem({ ...item })}
                  ></button>
                </div>
              </OverlayTrigger>
            </div>
          )}
        </td>
      )}
      {isChecked && !isSamSupport && isAdmin && (
        <td>
          {item.roleid !== "1" && (
            <div>
              <OverlayTrigger
                placement="left"
                delay={{ show: 250, hide: 400 }}
                overlay={renderTooltip("verwijder")}
              >
                <div>
                  <button
                    className={deleteClass5}
                    onClick={() => deleteItem({ ...item })}
                  ></button>
                </div>
              </OverlayTrigger>
            </div>
          )}
        </td>
      )}
      {isChecked && !isSamSupport && (
        <td>
          {!isSamSupport && item.roleid !== "2" && (
            <div>
              <b>{item.roletext}</b>
            </div>
          )}
          {!isSamSupport && (
            <div>
              <b>nummer: </b>
              {item._id}
            </div>
          )}
          {!isSamSupport && (
            <div>
              <b>logon: </b>
              {item.logon}
            </div>
          )}
          <div>
            {!isSamSupport && (
              <div>
                <b>naam: </b>
                <input
                  type="text"
                  className="form-control"
                  value={firstname}
                  onChange={(e) => setValue1(e.currentTarget.value)}
                />
              </div>
            )}
            {!isSamSupport && (
              <input
                type="text"
                className="form-control"
                value={lastname}
                onChange={(e) => setValue2(e.currentTarget.value)}
              />
            )}
          </div>
          <div>
            {!isSamSupport && (
              <div>
                <OverlayTrigger
                  placement="left"
                  delay={{ show: 250, hide: 400 }}
                  overlay={renderTooltip("foto opladen")}
                >
                  <div>
                    <button
                      className={imageClass1}
                      onClick={() => changeImage({ ...item })}
                    ></button>{" "}
                  </div>
                </OverlayTrigger>
              </div>
            )}
            {!isSamSupport && (
              <img src={item.imageurl} alt="" width="37" height="50" />
            )}
          </div>
          <div>
            {!isSamSupport && (
              <div>
                <OverlayTrigger
                  placement="left"
                  delay={{ show: 250, hide: 400 }}
                  overlay={renderTooltip("wijzig paswoord")}
                >
                  <div>
                    <button
                      className={passwordClass1}
                      onClick={() => changePassword({ ...item })}
                    ></button>
                  </div>
                </OverlayTrigger>
              </div>
            )}
          </div>
          {isAdminUser && !isSamSupport && (
            <div>
              <b>pincode: </b>
              <input
                type="text"
                className="form-control"
                value={pincode}
                onChange={(e) => setValue5(e.currentTarget.value)}
              />
            </div>
          )}
          {isUser && (
            <div>
              <div>
                <b>klas</b>
                <Typeahead
                  id={"grouptext1"}
                  labelKey={"label"}
                  options={groupList}
                  placeholder={grouptext1}
                  onChange={(selectedOptions) => setValue3(selectedOptions[0])}
                  highlightOnlyResult={true}
                  clearButton={true}
                />
              </div>
              <div>
                <b>pincode controle actief </b>
                <Typeahead
                  id={"attribuut1"}
                  labelKey={"label"}
                  options={jnList}
                  placeholder={attribute1}
                  onChange={(selectedOptions) => setValue11(selectedOptions[0])}
                  highlightOnlyResult={true}
                  clearButton={true}
                />
              </div>
              <div>
                <b>fotohulp actief </b>
                <Typeahead
                  id={"attribuut2"}
                  labelKey={"label"}
                  options={jnList}
                  placeholder={attribute2}
                  onChange={(selectedOptions) => setValue12(selectedOptions[0])}
                  highlightOnlyResult={true}
                  clearButton={true}
                />
              </div>
              <div>
                <b>moet regel van 3 kennen </b>
                <Typeahead
                  id={"attribuut3"}
                  labelKey={"label"}
                  options={jnList}
                  placeholder={attribute3}
                  onChange={(selectedOptions) => setValue13(selectedOptions[0])}
                  highlightOnlyResult={true}
                  clearButton={true}
                />
              </div>
              <div>
                <b>mag rekenmachine gebruiken </b>
                <Typeahead
                  id={"attribuut4"}
                  labelKey={"label"}
                  options={jnList}
                  placeholder={attribute4}
                  onChange={(selectedOptions) => setValue14(selectedOptions[0])}
                  highlightOnlyResult={true}
                  clearButton={true}
                />
              </div>
              <div>
                <b>moet tussen level zien </b>
                <Typeahead
                  id={"attribuut5"}
                  labelKey={"label"}
                  options={jnList}
                  placeholder={attribute5}
                  onChange={(selectedOptions) => setValue15(selectedOptions[0])}
                  highlightOnlyResult={true}
                  clearButton={true}
                />
              </div>
              {isInUse && (
                <div>
                  <div>
                    <b>attribuut6</b>
                    <Typeahead
                      id={"attribuut6"}
                      labelKey={"label"}
                      options={jnList}
                      placeholder={attribute6}
                      onChange={(selectedOptions) =>
                        setValue16(selectedOptions[0])
                      }
                      highlightOnlyResult={true}
                      clearButton={true}
                    />
                  </div>
                  <div>
                    <b>attribuut7</b>
                    <Typeahead
                      id={"attribuut7"}
                      labelKey={"label"}
                      options={jnList}
                      placeholder={attribute7}
                      onChange={(selectedOptions) =>
                        setValue17(selectedOptions[0])
                      }
                      highlightOnlyResult={true}
                      clearButton={true}
                    />
                  </div>
                  <div>
                    <b>attribuut8</b>
                    <Typeahead
                      id={"attribuut8"}
                      labelKey={"label"}
                      options={jnList}
                      placeholder={attribute8}
                      onChange={(selectedOptions) =>
                        setValue18(selectedOptions[0])
                      }
                      highlightOnlyResult={true}
                      clearButton={true}
                    />
                  </div>
                  <div>
                    <b>attribuut9</b>
                    <Typeahead
                      id={"attribuut9"}
                      labelKey={"label"}
                      options={jnList}
                      placeholder={attribute9}
                      onChange={(selectedOptions) =>
                        setValue19(selectedOptions[0])
                      }
                      highlightOnlyResult={true}
                      clearButton={true}
                    />
                  </div>
                  <div>
                    <b>attribuut10</b>
                    <Typeahead
                      id={"attribuut10"}
                      labelKey={"label"}
                      options={jnList}
                      placeholder={attribute10}
                      onChange={(selectedOptions) =>
                        setValue20(selectedOptions[0])
                      }
                      highlightOnlyResult={true}
                      clearButton={true}
                    />
                  </div>
                </div>
              )}
            </div>
          )}
        </td>
      )}
      {!isChecked && !isSamSupport && <td>{item._id}</td>}
      {!isChecked && !isSamSupport && <td>{item.logon}</td>}
      {!isChecked && !isSamSupport && (
        <td>
          {" "}
          <img src={item.imageurl} alt="" width="37" height="50" />
        </td>
      )}
      {!isChecked && !isSamSupport && (
        <td>
          {item.firstname + " " + item.lastname} {"(" + item.roletext + ")"}
        </td>
      )}
    </tr>
  );
};

export default UserRow;
