//
// Purpose: SAMB - change steps
// Author: Ronny Van Elewyck
// Version: 1.0
// Creation Date: 13.05.2020
// Modification Date: 21.07.2020
//

// installed components
import React from "react";
import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";
import Tooltip from "react-bootstrap/Tooltip";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";

// created components

// rest services

// config files
import { downloadClass } from "../../config.json";

// CSV export function
const ExportCSV = ({ csvData, fileName, className }) => {
  let nameFile = "SAMBdownload";
  let renderClass = downloadClass;
  if (typeof className !== "undefined") {
    renderClass = className;
  }

  const fileType =
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
  const fileExtension = ".xlsx";
  //const fileExtension = ".csv";
  const exportToCSV = (csvData, fileName) => {
    //console.log("ExportCSV: ", className);
    if (typeof fileName !== "undefined") {
      nameFile = fileName;
    }

    const ws = XLSX.utils.json_to_sheet(csvData);
    const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    //const excelBuffer = XLSX.write(wb, { bookType: "csv", type: "array" });
    const data = new Blob([excelBuffer], { type: fileType });
    FileSaver.saveAs(data, nameFile + fileExtension);
    //FileSaver.saveAs(data, nameFile);
  };

  function renderTooltip(title) {
    return <Tooltip id="button-tooltip">{title}</Tooltip>;
  }

  return (
    <OverlayTrigger
      placement="right"
      delay={{ show: 250, hide: 400 }}
      overlay={renderTooltip("downloaden")}
    >
      <button
        type="button"
        className={renderClass}
        onClick={(e) => exportToCSV(csvData, fileName)}
      ></button>
    </OverlayTrigger>
  );
};

export default ExportCSV;
