//
// Purpose: SAMB - upload image form
// Author: Ronny Van Elewyck
// Version: 1.0
// Creation Date: 12.06.2020
// Modification Date: 12.06.2020
//

// installed components
import React, { Component } from "react";

// created components
import UploadImage from "./uploadImage";

// utilities
import { checkImgSize } from "../../utils/imageHelpers";

// rest service
//import putImage from "../../services/imageService";

// config files
import {
  uploadClass5,
  classErrorMessage,
  saveClass5,
  cancelClass5,
  //noError,
} from "../../config.json";
import {
  errorImageSize,
  errorImageUpload,
  //prefixImageUser,
  //prefixImageTask,
  //prefixImageStep,
  //prefixImageProduct,
} from "../../imageConfig.json";

class UploadImageForm extends Component {
  //
  // login form
  //

  // local data storage
  state = {
    isLoaded: false,
    uploadedImage: undefined,
    blobURL: undefined,
    isSave: false,
    isShowSave: false,
    isImageSaved: true,
    imageRecordLocal: undefined,
  };

  async componentDidMount() {
    //
    // mount after render
    //
    const { imageRecord } = this.props;
    this.setState({ imageRecordLocal: imageRecord });
  }

  handleFileInputChange = (e) => {
    //
    // put file on state
    //

    let isSave = true;

    const { imageRecordLocal } = this.state;

    const blobURL = URL.createObjectURL(e.target.files[0]);

    const promise1 = checkImgSize(
      blobURL,
      imageRecordLocal.minWidth,
      imageRecordLocal.minHeight,
      imageRecordLocal.maxWidth,
      imageRecordLocal.maxHeight
    );
    promise1.catch((error) => {
      this.setState({ isSave: error, isShowSave: false });
    });

    this.setState({
      uploadedImage: e.target.files[0],
      blobURL,
      isLoaded: true,
      isSave,
      isShowSave: true,
    });
  };

  render() {
    //const { imageRecord } = this.props;
    const {
      blobURL,
      isSave,
      isLoaded,
      isImageSaved,
      isShowSave,
      imageRecordLocal,
      uploadedImage,
    } = this.state;

    let widthImage = 100;
    let heightImage = 100;

    let imageUrl = undefined;

    // don't render if not ready
    if (typeof imageRecordLocal === "undefined") {
      ////console.log("render: ", new Date(), session.companyimageurl);
      return null;
    }

    //console.log("blobURL: ", blobURL);
    const titleScreen =
      "Opladen van foto " +
      imageRecordLocal.description +
      " - grootte min:" +
      imageRecordLocal.minWidth +
      "x" +
      imageRecordLocal.minHeight +
      " max:" +
      imageRecordLocal.maxWidth +
      "x" +
      imageRecordLocal.maxHeight;

    const localFile = "";

    if (imageRecordLocal.minWidth > 1500) {
      widthImage = imageRecordLocal.minWidth / 4;
      heightImage = imageRecordLocal.minHeight / 4;
    } else if (imageRecordLocal.minWidth > 700) {
      widthImage = imageRecordLocal.minWidth / 2;
      heightImage = imageRecordLocal.minHeight / 2;
    } else {
      widthImage = imageRecordLocal.minWidth;
      heightImage = imageRecordLocal.minHeight;
    }

    if (
      typeof imageRecordLocal.oldurl !== "undefined" &&
      typeof blobURL === "undefined"
    ) {
      imageUrl = imageRecordLocal.oldurl;
    } else {
      imageUrl = blobURL;
    }

    return (
      <div className="table-responsive">
        <table className="table table-bordered">
          <tbody>
            <tr>
              <td>
                {/* error message */}
                {!isSave && isLoaded && (
                  <div className={classErrorMessage}>{errorImageSize}</div>
                )}
                {!isImageSaved && isLoaded && (
                  <div className={classErrorMessage}>{errorImageUpload}</div>
                )}
                {/* title */}
                <h4>
                  <span className="badge badge-info d-flex justify-content-center">
                    {titleScreen}
                  </span>
                </h4>
              </td>
            </tr>
            <tr>
              <td>
                <button
                  className={cancelClass5}
                  onClick={() => this.props.cancelImageUpload()}
                ></button>
                <UploadImage
                  file={localFile}
                  className={uploadClass5}
                  onChange={(e) => this.handleFileInputChange(e)}
                />
                <img
                  src={imageUrl}
                  alt="lokale foto"
                  onLoad={this.onImageLoad}
                  width={widthImage}
                  height={heightImage}
                />
                {isShowSave && (
                  <button
                    type="button"
                    className={saveClass5}
                    onClick={() => this.props.handleImageSave(uploadedImage)}
                  ></button>
                )}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    );
  }
}

export default UploadImageForm;
