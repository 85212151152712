//
// Purpose: SAMB - student selection table component
// Author: Ronny Van Elewyck
// Version: 1.0
// Creation Date: 04.06.2020
// Modification Date: 04.06.2020
//

// installed components
import React from "react";

// created components
import StudentSelectionRow from "./studentSelectionRow";

const StudentSelectionTable = ({ userCourseList, updateItem }) => {
  return (
    <div className="table-responsive">
      <table className="table table-bordered">
        <tbody>
          {userCourseList.map((item) => (
            <StudentSelectionRow
              key={item.userid}
              item={item}
              updateItem={updateItem}
            />
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default StudentSelectionTable;
