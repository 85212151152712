//
// Purpose: SAMB - create qrcode
// Author: Ronny Van Elewyck
// Version: 1.0
// Creation Date: 16.04.2021
// Modification Date: 16.04.2021
//

// installed components
import React from "react";
import Tooltip from "react-bootstrap/Tooltip";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import QRCode from "qrcode.react";

// created components

// config files
import {
  cancelClass5,
  keyQRCODE,
} from "../../config.json";

const QrcodeForm = ({ qrcodeRecord, cancelItem, session }) => {
  //
  // format row of table
  //

  let title = qrcodeRecord.tasktext;


 


  //const qrcodeValue = "qrcode" + session.compid + ";" + keyQRCODE + ";" + qrcodeRecord._id + ";" + qrcodeRecord.studentid + ";" + qrcodeRecord.mastertaskid;
  const qrcodeValue = `qrcode${session.compid};${keyQRCODE};${qrcodeRecord._id};${qrcodeRecord.studentid};${qrcodeRecord.mastertaskid}`;


  function renderTooltip(title) {
    return <Tooltip id="button-tooltip">{title}</Tooltip>;
  }

  return (
    <div>
      <div>
        <OverlayTrigger
          placement="left"
          delay={{ show: 250, hide: 400 }}
          overlay={renderTooltip("terug")}
        >
          <button
            className={cancelClass5}
            onClick={() => cancelItem()}
          ></button>
        </OverlayTrigger>

      </div>

      <div className="table-responsive">
        <table className="table table-bordered">
          <tbody>
            <tr>
              <td>
                <h4>
                  <span className="badge badge-info d-flex justify-content-center">
                    {title}
                  </span>
                </h4>
              </td>
            </tr>
            <tr>
              <td>
                <div className="d-flex justify-content-center">  
                  <QRCode  value={qrcodeValue} renderAs="svg" size={256} />
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default QrcodeForm;
