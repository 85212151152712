//
// Purpose: SAMB - top course buttons
// Author: Ronny Van Elewyck
// Version: 1.0
// Creation Date: 09.07.2020
// Modification Date: 17.07.2020
//

// installed components
import React from "react";
import { Typeahead } from "react-bootstrap-typeahead";
import "react-bootstrap-typeahead/css/Typeahead.css";
import DatePicker from "react-date-picker";
import Tooltip from "react-bootstrap/Tooltip";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";

// created components

// config files
import { classTopButtons5, newClass5 } from "../../config.json";

const TopCourseButton = ({
  group,
  mastercourse,
  groupList,
  masterCourseList,
  locale,
  fromDate,
  toDate,
  minDate,
  maxDate,
  handleGroupChange,
  handleCourseChange,
  onFromDateChange,
  onToDateChange,
  handleNew,
  isLoaded,
}) => {
  function renderTooltip(title) {
    return <Tooltip id="button-tooltip">{title}</Tooltip>;
  }

  return (
    <div className={classTopButtons5}>
      {isLoaded && (
        <div>
          <OverlayTrigger
            placement="left"
            delay={{ show: 250, hide: 400 }}
            overlay={renderTooltip("nieuw")}
          >
            <div>
              <button
                type="button"
                className={newClass5}
                onClick={() => handleNew()}
              ></button>
            </div>
          </OverlayTrigger>
        </div>
      )}

      <label className="mt-3 ml-2 mb-2 mr-2">klas</label>
      <Typeahead
        className="mt-2 ml-2 mb-2 mr-2"
        id={"group"}
        labelKey={"label"}
        options={groupList}
        placeholder={group.text}
        onChange={handleGroupChange}
        highlightOnlyResult={true}
        clearButton={true}
      />
      <label className="mt-3 ml-2 mb-2 mr-2">vak</label>
      <Typeahead
        className="mt-2 ml-2 mb-2 mr-2"
        id={"course"}
        labelKey={"label"}
        options={masterCourseList}
        placeholder={mastercourse.text}
        onChange={handleCourseChange}
        highlightOnlyResult={true}
        clearButton={true}
      />
      <label className="mt-3 ml-2 mb-2 mr-2">datum</label>
      <DatePicker
        className="mt-2 ml-2 mb-2 mr-2"
        locale={locale}
        onChange={onFromDateChange}
        value={fromDate}
        maxDate={maxDate}
        minDate={minDate}
        clearIcon={null}
      />
      <label className="mt-3 ml-2 mb-2 mr-2">tot</label>
      <DatePicker
        className="mt-2 ml-2 mb-2 mr-2"
        locale={locale}
        onChange={onToDateChange}
        value={toDate}
        maxDate={maxDate}
        minDate={minDate}
        clearIcon={null}
      />
    </div>
  );
};

export default TopCourseButton;
