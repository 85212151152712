//
// Purpose: SAMB - header Row
// Author: Ronny Van Elewyck
// Version: 1.0
// Creation Date: 27.05.2020
// Modification Date: 27.05.2020
//

// installed components
import React from "react";
import Tooltip from "react-bootstrap/Tooltip";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";

// config files
import { saveClass2, imageClass1 } from "../../config.json";

const HeaderRow = ({ item, saveHeaderItem, changeImageHeader }) => {
  //
  // format row of table
  //

  const [mastertasktext, setValue1] = React.useState(item.mastertasktext);
  const [mastertasktext2, setValue2] = React.useState(item.mastertasktext2);

  function renderTooltip(title) {
    return <Tooltip id="button-tooltip">{title}</Tooltip>;
  }

  return (
    <tr>
      <td>
        {" "}
        <div>
          <OverlayTrigger
            placement="left"
            delay={{ show: 250, hide: 400 }}
            overlay={renderTooltip("bewaar in geheugen")}
          >
            <div>
              <button
                className={saveClass2}
                onClick={() =>
                  saveHeaderItem({ ...item, mastertasktext, mastertasktext2 })
                }
              ></button>{" "}
            </div>
          </OverlayTrigger>
        </div>
      </td>
      <td>{item.typetext}</td>
      <td>
        <div>
          <input
            type="text"
            className="form-control"
            value={mastertasktext}
            onChange={(e) => setValue1(e.currentTarget.value)}
          />
        </div>
      </td>
      <td>
        <div>
          <input
            type="text"
            className="form-control"
            value={mastertasktext2}
            onChange={(e) => setValue2(e.currentTarget.value)}
          />
        </div>
      </td>
      <td>
        <div>
          {" "}
          <div>
            <OverlayTrigger
              placement="left"
              delay={{ show: 250, hide: 400 }}
              overlay={renderTooltip("opladen foto")}
            >
              <div>
                <button
                  className={imageClass1}
                  onClick={() => changeImageHeader({ ...item })}
                ></button>{" "}
              </div>
            </OverlayTrigger>
          </div>
          <img
            src={item.imageurl}
            alt="geen afbeelding"
            width="50"
            height="50"
          />
        </div>
      </td>
    </tr>
  );
};

export default HeaderRow;
