//
// Purpose: SAMB - master course Row
// Author: Ronny Van Elewyck
// Version: 1.0
// Creation Date: 04.06.2020
// Modification Date: 26.08.2020
//

// installed components
import React from "react";
import { Typeahead } from "react-bootstrap-typeahead";
import "react-bootstrap-typeahead/css/Typeahead.css";
import Tooltip from "react-bootstrap/Tooltip";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";

// config files
import { saveClass5, copyClass5, deleteClass5 } from "../../config.json";

const MasterCourseRow = ({
  item,
  changeItem,
  copyItem,
  deleteItem,
  isAdmin,
}) => {
  //
  // format row of table
  //

  const typeList = ["1LES", "2HW", "3VO"];

  const [text, setValue1] = React.useState(item.text);
  const [itemtype, setValue2] = React.useState(item.type);

  let sizeLine = 40;
  function renderTooltip(title) {
    return <Tooltip id="button-tooltip">{title}</Tooltip>;
  }

  return (
    <tr>
      {isAdmin && (
        <td>
          <div>
            <OverlayTrigger
              placement="left"
              delay={{ show: 250, hide: 400 }}
              overlay={renderTooltip("bewaar")}
            >
              <div>
                <button
                  className={saveClass5}
                  onClick={() => changeItem({ ...item, text, itemtype })}
                ></button>
              </div>
            </OverlayTrigger>
          </div>
        </td>
      )}
      {isAdmin && (
        <td>
          <div>
            <OverlayTrigger
              placement="left"
              delay={{ show: 250, hide: 400 }}
              overlay={renderTooltip("kopieer")}
            >
              <div>
                <button
                  className={copyClass5}
                  onClick={() => copyItem({ ...item })}
                ></button>
              </div>
            </OverlayTrigger>
          </div>
        </td>
      )}
      {isAdmin && (
        <td>
          <div>
            <OverlayTrigger
              placement="left"
              delay={{ show: 250, hide: 400 }}
              overlay={renderTooltip("verwijder/activeer/deactiveer")}
            >
              <div>
                <button
                  className={deleteClass5}
                  onClick={() => deleteItem({ ...item })}
                ></button>{" "}
              </div>
            </OverlayTrigger>
          </div>
        </td>
      )}
      <td>
        <div>
          <b>nummer: </b>
          {item.mastercourseid}
        </div>
        <div>
          <b>actief: </b>
          {item.active}
        </div>
        <div>
          <b>naam: </b>
          {isAdmin && (
            <input
              type="text"
              className="form-control"
              value={text}
              onChange={(e) => setValue1(e.currentTarget.value)}
              maxLength={sizeLine}
            />
          )}
          {!isAdmin && <h6>{item.text}</h6>}
        </div>

        <div>
          <b>soort: </b>
          {isAdmin && (
            <Typeahead
              id={"itemtype"}
              labelKey={"label"}
              options={typeList}
              placeholder={itemtype}
              onChange={(selectedOptions) => setValue2(selectedOptions[0])}
              highlightOnlyResult={true}
              clearButton={true}
            />
          )}
          {!isAdmin && <h6>{item.type}</h6>}
        </div>
      </td>
    </tr>
  );
};

export default MasterCourseRow;
