//
// Purpose: SAMB - validate input before save
// Author: Ronny Van Elewyck
// Version: 1.2
// Creation Date: 27.05.2020
// Modification Date: 13.04.2021
//

// installed components
import Joi from "joi-browser";

// config files
import {
  stepMessage23,
  stepMessage24,
  stepMessage25,
  stepMessage26,
  stepMessage29,
  stepMessage32,
  stepMessage33,
  headerMessage1,
  headerMessage2,
  headerMessage3,
  productMessage1,
  productMessage2,
  productMessage3,
  productMessage4,
  productMessage5,
  userMessage1,
  userMessage2,
  userMessage3,
  goalMessage2,
  goalMessage3,
  goalMessage4,
} from "../config.json";

export function validateStepTitle(steptitle, order, screenformatid, errorList) {
  //
  // validate step title and order number
  //


  let errorMessage = errorList;

  // valid order numbers
  let orderArray = [];
  for (let index = 0; index < 50; index++) {
    orderArray[index] = (index + 1).toString();
  }

  // validation schema
  const schemaStep1 = {
    order: Joi.string()
      .required()
      .valid(orderArray)
      .error(() => {
        return {
          message: stepMessage23,
        };
      }),
    steptitle: Joi.string()
      .required()
      .min(2)
      .max(40)
      .regex(/^[A-Z]/)
      .error(() => {
        return {
          message: stepMessage24,
        };
      }),
    screenformatid: Joi.string()
      .valid([
        "C0",
        "C1",
        "C2",
        "C3",
        "C4",
        "C5",
        "C6",
        "C7",
        "C8",
        "C9",
        "C10",
        "C11",
        "C12",
        "C13",
        "C14",
      ])
      .error(() => {
        return {
          message: stepMessage29,
        };
      }),
  };

  // object for validation checking for order and steptitle
  const step1Record = {
    order: order,
    steptitle: steptitle,
    screenformatid: screenformatid,
  };
  // validate input
  const { error } = Joi.validate(step1Record, schemaStep1, {
    abortEarly: false,
  });

  if (error) {
    // log error
    ////console.log(error.details);
    for (let index = 0; index < error.details.length; index++) {
      errorMessage.push({
        errorText: "stap" + order + ": " + error.details[index].message,
      });
    }
  }

  return errorMessage;
}

export function validateStepTextLine(
  textline,
  errorList,
  textlineNb,
  screenformatid,
  stepNb
) {
  //
  // validate stepline text
  //

  let schemaStep1 = {};


  let errorMessage = errorList;

  if (screenformatid === 'C12' || screenformatid === 'C13') {
    schemaStep1 = {
      lineText200: Joi.string()
      .uri()
      .error(() => {
        return {
          message: stepMessage32,
        };
      }),
    };
  } else if (screenformatid === 'C14') {
    // validation schema
    schemaStep1 = {
      lineText200: Joi.string()
        .required()
        .max(200)
        .error(() => {
          return {
            message: stepMessage33,
          };
        }),
    };
  } else {
    // validation schema
    schemaStep1 = {
      lineText200: Joi.string()
        .required()
        .max(200)
        .regex(/^[A-Z]/)
        .error(() => {
          return {
            message: stepMessage25,
          };
        }),
    };
  }

  const schemaStep2 = {
    lineText400: Joi.string()
      .required()
      .max(400)
      .regex(/^[A-Z]/)
      .error(() => {
        return {
          message: stepMessage26,
        };
      }),
  };

  // check valid screen format

  if (textline !== "") {
    if (screenformatid === "C0") {
      // object for validation checking
      const step1Record = {
        lineText400: textline,
      };
      const { error } = Joi.validate(step1Record, schemaStep2, {
        abortEarly: false,
      });
      if (error) {
        // log error
        ////console.log(error.details);
        for (let index = 0; index < error.details.length; index++) {
          errorMessage.push({
            errorText:
              "stap" +
              stepNb +
              ": " +
              textlineNb +
              ": " +
              error.details[index].message,
          });
        }
      }
    } else {
      // object for validation checking
      const step1Record = {
        lineText200: textline,
      };
      const { error } = Joi.validate(step1Record, schemaStep1, {
        abortEarly: false,
      });
      if (error) {
        // log error
        for (let index = 0; index < error.details.length; index++) {
          errorMessage.push({
            errorText:
              "stap" +
              stepNb +
              ": " +
              textlineNb +
              ": " +
              error.details[index].message,
          });
        }
      }
    }
  }

  return errorMessage;
}

export function checkStepInput(inputArray, screenList) {
  //
  // validate text input steps
  //
  // input: flat file array
  // output:
  //        errorList: array of errors in file
  //        tableList: array used to show table on screen
  //        uploadList: array used for up-download
  //

  //console.log("checkStepInput inputArray : ", inputArray);

  // return arrays
  let errorList = [];
  let tableList = [];
  let uploadList = [];

  // save input array in upload list
  uploadList = inputArray;

  // check validity of input
  for (let index = 0; index < uploadList.length; index++) {
    // validate step title, screenformatid and ordernumber check1
    errorList = validateStepTitle(
      uploadList[index].steptitle,
      uploadList[index].order,
      uploadList[index].screenformatid,
      errorList
    );
    // check text validity
    /* USING EVAL IS NOT SECURE for (let i = 1; i < 11; i++) {
      const textName = "tekst" + i;
      const textLine = "uploadList[index].text" + i;
      errorList = validateStepTextLine(
        eval(textLine),
        errorList,
        textName,
        uploadList[index].screenformatid,
        uploadList[index].order
      );
    }*/
    errorList = validateStepTextLine(
      uploadList[index].text1,
      errorList,
      "tekst1",
      uploadList[index].screenformatid,
      uploadList[index].order
    );
    errorList = validateStepTextLine(
      uploadList[index].text2,
      errorList,
      "tekst2",
      uploadList[index].screenformatid,
      uploadList[index].order
    );
    errorList = validateStepTextLine(
      uploadList[index].text3,
      errorList,
      "tekst3",
      uploadList[index].screenformatid,
      uploadList[index].order
    );
    errorList = validateStepTextLine(
      uploadList[index].text4,
      errorList,
      "tekst4",
      uploadList[index].screenformatid,
      uploadList[index].order
    );
    errorList = validateStepTextLine(
      uploadList[index].text5,
      errorList,
      "tekst5",
      uploadList[index].screenformatid,
      uploadList[index].order
    );
    errorList = validateStepTextLine(
      uploadList[index].text6,
      errorList,
      "tekst6",
      uploadList[index].screenformatid,
      uploadList[index].order
    );
    errorList = validateStepTextLine(
      uploadList[index].text7,
      errorList,
      "tekst7",
      uploadList[index].screenformatid,
      uploadList[index].order
    );
    errorList = validateStepTextLine(
      uploadList[index].text8,
      errorList,
      "tekst8",
      uploadList[index].screenformatid,
      uploadList[index].order
    );
    errorList = validateStepTextLine(
      uploadList[index].text9,
      errorList,
      "tekst9",
      uploadList[index].screenformatid,
      uploadList[index].order
    );
    errorList = validateStepTextLine(
      uploadList[index].text10,
      errorList,
      "tekst10",
      uploadList[index].screenformatid,
      uploadList[index].order
    );
  }

  if (errorList.length > 0) {
    uploadList = [];
    tableList = [];
    let errorListTemp = errorList;
    errorList = [];
    for (let index = 0; index < errorListTemp.length; index++) {
      errorList.push({
        key: index,
        errorText: errorListTemp[index].errorText,
      });
    }
    errorListTemp = [];
  } else {
    ////console.log("checkStepInput screenList : ", screenList);
    for (let index = 0; index < uploadList.length; index++) {
      const screenFormat = screenList.filter(
        (m) => m.id === uploadList[index].screenformatid
      );
      ////console.log("checkStepInput screenFormat : ", screenFormat);

      uploadList[index].screenformatlabel = screenFormat[0].label;

      tableList.push({
        subtaskid3: index + 1,
        order: uploadList[index].order,
        screenformatid: uploadList[index].screenformatid,
        screenformatlabel: screenFormat[0].label,
        steptitle: uploadList[index].steptitle,
        text1: uploadList[index].text1,
        text2: uploadList[index].text2,
        text3: uploadList[index].text3,
        text4: uploadList[index].text4,
        text5: uploadList[index].text5,
        text6: uploadList[index].text6,
        text7: uploadList[index].text7,
        text8: uploadList[index].text8,
        text9: uploadList[index].text9,
        text10: uploadList[index].text10,
      });
    }
  }

  ////console.log("checkStepInput errorList : ", errorList);
  ////console.log("checkStepInput tableList : ", tableList);
  ////console.log("checkStepInput uploadList : ", uploadList);

  return {
    errorList,
    tableList,
    uploadList,
  };
}

export function checkHeaderInput(inputArray, originalArray) {
  //
  // validate text header
  //
  // input: flat file array
  // output:
  //        errorList: array of errors in file
  //        tableList: array used to show table on screen
  //        uploadList: array used for up-download
  //

  //console.log("checkHeaderInput: ", inputArray, originalArray);

  let errorList = [];
  let tableList = [];
  let uploadList = [];

  let typeText = "";
  let mastertaskid = 0;
  let imageurl = "";

  uploadList = inputArray;

  // field validation
  const schemaHeader = {
    mastertasktext: Joi.string()
      .required()
      .min(5)
      .max(40)
      .regex(/^[A-Z]/)
      .error(() => {
        return {
          message: headerMessage1,
        };
      }),
   mastertasktext2: Joi.string()
    //  .required()
      .min(0)
      .max(40)
      .error(() => {
        return {
          message: headerMessage2,
        };
      }),
    typeid1: Joi.string()
      .valid(["1", "2", "3"])
      .error(() => {
        return {
          message: headerMessage3,
        };
      }),
  };

  for (let index = 0; index < uploadList.length; index++) {
    if (uploadList[index].mastertasktext2 === "") { uploadList[index].mastertasktext2 = " "}
    const headerRecord = {
      mastertasktext: uploadList[index].mastertasktext,
      mastertasktext2: uploadList[index].mastertasktext2,
      typeid1: uploadList[index].typeid1,
    };
    // validate input
    const { error } = Joi.validate(headerRecord, schemaHeader, {
      abortEarly: false,
    });

    if (error) {
      // log error
      for (let index2 = 0; index2 < error.details.length; index2++) {
        errorList.push({
          errorText: error.details[index2].message,
        });
      }
    }
  }

  if (errorList.length > 0) {
    let errorListTemp = errorList;
    errorList = [];
    for (let index = 0; index < errorListTemp.length; index++) {
      errorList.push({
        key: index,
        errorText: errorListTemp[index].errorText,
      });
    }
    errorListTemp = [];
    uploadList = [];
    tableList = [];
  } else {
    for (let index = 0; index < uploadList.length; index++) {
      switch (uploadList[0].typeid1) {
        case "1":
          typeText = "Recept";
          break;
        case "2":
          typeText = "Standaard1";
          break;
        case "3":
          typeText = "Standaard2";
          break;
        default:
          break;
      }
      if (typeof originalArray[0] !== "undefined") {
        mastertaskid = originalArray[0].mastertaskid1;
        imageurl = originalArray[0].imageurl;
      }
      tableList.push({
        mastertaskid1: mastertaskid,
        mastertasktext: uploadList[0].mastertasktext,
        mastertasktext2: uploadList[0].mastertasktext2,
        typeid1: uploadList[0].typeid1,
        typetext: typeText,
        imageurl: imageurl,
      });
    }
  }

  //console.log("checkHeaderInput errorList : ", errorList);
  //console.log("checkHeaderInput tableList : ", tableList);
  //console.log("checkHeaderInput uploadList : ", uploadList);

  return {
    errorList,
    tableList,
    uploadList,
  };
}

export function validateProduct(
  order,
  productid,
  quantity,
  productList,
  errorList
) {
  //
  // validate product/ingredient common fields
  //

  //console.log("validateProduct: ", productid, productList);

  let errorMessage = errorList;

  // valid order numbers
  let orderArray = [];
  for (let index = 0; index < 300; index++) {
    orderArray[index] = (index + 1).toString();
  }

  // validation schema
  const schemaProduct = {
    order: Joi.string()
      .required()
      .valid(orderArray)
      .error(() => {
        return {
          message: productMessage1,
        };
      }),
    quantity: Joi.string()
      .required()
      .regex(/^[0-9]*$/)
      .error(() => {
        return {
          message: productMessage2,
        };
      }),
    productid: Joi.string()
      .required()
      .valid(productList)
      .error(() => {
        return {
          message: productMessage3,
        };
      }),
  };

  // object for validation checking for order and steptitle
  const productRecord = {
    order: order,
    productid: productid,
    quantity: quantity,
  };
  // validate input
  const { error } = Joi.validate(productRecord, schemaProduct, {
    abortEarly: false,
  });

  if (error) {
    // log error
    ////console.log(error.details);
    for (let index = 0; index < error.details.length; index++) {
      errorMessage.push({
        errorText: order + ": " + error.details[index].message,
      });
    }
  }

  return errorMessage;
}

export function checkProductInput(inputArray, productList, productLookup) {
  //
  // validate product
  //
  // input: flat file array
  // output:
  //        errorList: array of errors in file
  //        tableList: array used to show table on screen
  //        uploadList: array used for up-download
  //

  //console.log("checkProductInput: ", inputArray, productLookup);

  let errorList = [];
  let tableList = [];
  let uploadList = [];

  uploadList = inputArray;

  for (let index = 0; index < uploadList.length; index++) {
    errorList = validateProduct(
      uploadList[index].order,
      uploadList[index].productid1,
      uploadList[index].quantity,
      productList,
      errorList
    );
  }

  if (errorList.length > 0) {
    uploadList = [];
    tableList = [];
    let errorListTemp = errorList;
    errorList = [];
    for (let index = 0; index < errorListTemp.length; index++) {
      errorList.push({
        key: index,
        errorText: errorListTemp[index].errorText,
      });
    }
    errorListTemp = [];
  } else {
    ////console.log("checkStepInput screenList : ", screenList);

    for (let index = 0; index < uploadList.length; index++) {
      const productRecord = productLookup.filter(
        (m) => m.id === uploadList[index].productid1
      );
      tableList.push({
        subtaskid1: index + 1,
        order: uploadList[index].order,
        productid1: uploadList[index].productid1,
        producttext: productRecord[0].label,
        quantity: uploadList[index].quantity,
      });
    }
  }

  return {
    errorList,
    tableList,
    uploadList,
  };
}

export function validateIngredient(
  order,
  productid,
  quantity,
  productList,
  errorList,
  uomid,
  uomList
) {
  //
  // validate ingredient
  //

  //console.log("validateIngredient: ", productid, productList, uomid, uomList);

  let errorMessage = errorList;

  // valid order numbers
  let orderArray = [];
  for (let index = 0; index < 300; index++) {
    orderArray[index] = (index + 1).toString();
  }

  // validation schema
  const schemaProduct = {
    order: Joi.string()
      .required()
      .valid(orderArray)
      .error(() => {
        return {
          message: productMessage1,
        };
      }),
    quantity: Joi.string()
      .required()
      // .regex(/^[0-9](,[0-9]+)?$/)
      .regex(/^\d*,?\d*$/)
      .error(() => {
        return {
          message: productMessage4,
        };
      }),
    productid: Joi.string()
      .required()
      .valid(productList)
      .error(() => {
        return {
          message: productMessage3,
        };
      }),
    uomid: Joi.string()
      .required()
      .valid(uomList)
      .error(() => {
        return {
          message: productMessage5,
        };
      }),
  };

  // object for validation checking for order and steptitle
  const productRecord = {
    order: order,
    productid: productid,
    quantity: quantity,
    uomid: uomid,
  };
  // validate input
  const { error } = Joi.validate(productRecord, schemaProduct, {
    abortEarly: false,
  });

  if (error) {
    // log error
    ////console.log(error.details);
    for (let index = 0; index < error.details.length; index++) {
      errorMessage.push({
        errorText: order + ": " + error.details[index].message,
      });
    }
  }

  return errorMessage;
}

export function checkIngredientInput(
  inputArray,
  productList,
  productLookup,
  uomLookup,
  uomList
) {
  //
  // validate ingredient
  //
  // input: flat file array
  // output:
  //        errorList: array of errors in file
  //        tableList: array used to show table on screen
  //        uploadList: array used for up-download
  //

  //console.log("checkIngredientInput: ", inputArray, productLookup, uomList);

  let errorList = [];
  let tableList = [];
  let uploadList = [];

  uploadList = inputArray;

  for (let index = 0; index < uploadList.length; index++) {
    errorList = validateIngredient(
      uploadList[index].order,
      uploadList[index].productid1,
      uploadList[index].quantity,
      productList,
      errorList,
      uploadList[index].uomid,
      uomLookup
    );
  }

  if (errorList.length > 0) {
    uploadList = [];
    tableList = [];
    let errorListTemp = errorList;
    errorList = [];
    for (let index = 0; index < errorListTemp.length; index++) {
      errorList.push({
        key: index,
        errorText: errorListTemp[index].errorText,
      });
    }
    errorListTemp = [];
  } else {
    for (let index = 0; index < uploadList.length; index++) {
      const productRecord = productLookup.filter(
        (m) => m.id === uploadList[index].productid1
      );
      const uomRecord = uomList.filter((m) => m.id === uploadList[index].uomid);

      tableList.push({
        subtaskid2: index + 1,
        order: uploadList[index].order,
        productid1: uploadList[index].productid1,
        producttext: productRecord[0].label,
        quantity: uploadList[index].quantity,
        uomid: uploadList[index].uomid,
        uomtext: uomRecord[0].label,
      });
    }
  }

  return {
    errorList,
    tableList,
    uploadList,
  };
}

export function validateUserCreate(firstname, lastname, groupid1, errorList) {
  //
  // validate user create
  //

  let errorMessage = errorList;

  // validation schema
  const schemaUser = {
    firstname: Joi.string()
      .required()
      .min(2)
      .max(25)
      .regex(/^[A-Z]/)
      .error(() => {
        return {
          message: userMessage1,
        };
      }),
    lastname: Joi.string()
      .required()
      .min(2)
      .max(25)
      .regex(/^[A-Z]/)
      .error(() => {
        return {
          message: userMessage2,
        };
      }),
    groupid1: Joi.string()
      .required()
      .error(() => {
        return {
          message: userMessage3,
        };
      }),
  };

  // object for validation checking for order and steptitle
  const userRecord = {
    firstname: firstname,
    lastname: lastname,
    groupid1: groupid1,
  };
  // validate input
  const { error } = Joi.validate(userRecord, schemaUser, {
    abortEarly: false,
  });

  if (error) {
    // log error
    ////console.log(error.details);
    for (let index = 0; index < error.details.length; index++) {
      errorMessage.push({
        errorText: error.details[index].message,
      });
    }
  }

  return errorMessage;
}

export function checkUserCreate(inputArray) {
  //
  // validate user create data
  //
  // input: user create data
  // output:
  //        errorList: array of errors in record
  //        tableList: array used to show table on screen
  //        uploadList: corrected result
  //

  // console.log("checkUserCreate: ", inputArray);

  let errorList = [];
  let tableList = [];
  let uploadList = [];

  uploadList = inputArray;

  for (let index = 0; index < uploadList.length; index++) {
    errorList = validateUserCreate(
      uploadList[index].firstname,
      uploadList[index].lastname,
      uploadList[index].groupid1,
      errorList
    );
  }

  if (errorList.length > 0) {
    uploadList = [];
    tableList = [];
    let errorListTemp = errorList;
    errorList = [];
    for (let index = 0; index < errorListTemp.length; index++) {
      errorList.push({
        key: index,
        errorText: errorListTemp[index].errorText,
      });
    }
    errorListTemp = [];
  } else {
    tableList = uploadList;
  }

  return {
    errorList,
    tableList,
    uploadList,
  };
}

export function validateGoal(
  groupid,
  mastercourseid,
  label,
  groupidCheck,
  mastercourseidCheck,
  errorList
) {
  //
  // validate goal
  //

  let errorMessage = errorList;

  // validation schema
  const schemaGoal = {
    groupid: Joi.string()
      .required()
      .valid(groupidCheck)
      .error(() => {
        return {
          message: goalMessage2,
        };
      }),
    mastercourseid: Joi.string()
      .required()
      .valid(mastercourseidCheck)
      .error(() => {
        return {
          message: goalMessage3,
        };
      }),
    label: Joi.string()
      .required()
      .min(1)
      .max(512)
      .regex(/^[A-Z]/)
      .error(() => {
        return {
          message: goalMessage4,
        };
      }),
  };

  // object for validation checking for order and steptitle
  const goalRecord = {
    groupid: groupid,
    mastercourseid: mastercourseid,
    label: label,
  };
  // validate input
  const { error } = Joi.validate(goalRecord, schemaGoal, {
    abortEarly: false,
  });

  if (error) {
    // log error
    ////console.log(error.details);
    for (let index = 0; index < error.details.length; index++) {
      errorMessage.push({
        errorText:
          groupid +
          "-" +
          mastercourseid +
          " " +
          label +
          ": " +
          error.details[index].message,
      });
    }
  }

  return errorMessage;
}

export function checkGoalInput(
  inputArray,
  groupid,
  mastercourseid,
  groupList,
  masterCourseList
) {
  //
  // validate goal
  //
  // input: flat file array
  // output:
  //        errorList: array of errors in file
  //        tableList: array used to show table on screen
  //        uploadList: array used for up-download
  //

  let errorList = [];
  let tableList = [];
  let uploadList = [];

  uploadList = inputArray;

  for (let index = 0; index < uploadList.length; index++) {
    errorList = validateGoal(
      uploadList[index].klasnummer,
      uploadList[index].vaknummer,
      uploadList[index].doelomschrijving,
      groupid,
      mastercourseid,
      errorList
    );
  }

  if (errorList.length > 0) {
    uploadList = [];
    tableList = [];
    let errorListTemp = errorList;
    errorList = [];
    for (let index = 0; index < errorListTemp.length; index++) {
      errorList.push({
        key: index,
        errorText: errorListTemp[index].errorText,
      });
    }
    errorListTemp = [];
  } else {
    for (let index = 0; index < uploadList.length; index++) {
      const groupRecord = groupList.filter(
        (m) => m.id === uploadList[index].klasnummer
      );
      const mastercourseRecord = masterCourseList.filter(
        (m) => m.id === uploadList[index].vaknummer
      );
      tableList.push({
        id: index + 1,
        groupid: uploadList[index].klasnummer,
        grouptext: groupRecord[0].label,
        mastercourseid: uploadList[index].vaknummer,
        mastercoursetext: mastercourseRecord[0].label,
        label: uploadList[index].doelomschrijving,
      });
    }
  }

  return {
    errorList,
    tableList,
    uploadList,
  };
}

// eslint-disable-next-line
export default {
  checkHeaderInput,
  checkStepInput,
  validateStepTextLine,
  validateStepTitle,
  checkProductInput,
  validateProduct,
  checkIngredientInput,
  validateIngredient,
  checkUserCreate,
  validateUserCreate,
  validateGoal,
  checkGoalInput,
};
