//
// Purpose: SAMB - error table component
// Author: Ronny Van Elewyck
// Version: 1.0
// Creation Date: 28.05.2020
// Modification Date: 28.05.2020
//

// installed components
import React from "react";

// created components
import ErrorRow from "./errorRow";

const ErrorTable = ({ inputTextError, title }) => {
  return (
    <div>
      <div className="row ml-2 mr-2 mb-2 mt-2">
        <div className="table-responsive table-striped">
          <table className="table table-bordered">
            <thead className="thead-light">
              <tr>
                <th>{title}</th>
              </tr>
            </thead>
            <tbody>
              {inputTextError.map((item) => (
                <ErrorRow key={item.key} item={item} />
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default ErrorTable;
