//
// Purpose: SAMB - master course screen  (vakken)
// Author: Ronny Van Elewyck
// Version: 1.0
// Creation Date: 06.04.2020
// Modification Date: 02.09.2020
//

// installed components
import React, { Component } from "react";
import _ from "lodash";

// created components
import NavBar from "./navBar";
import SearchBox from "./common/searchBox";
import MasterCourseTable from "./common/masterCourseTable";
import TopButton from "./common/topButton";
import NewMasterCourseForm from "./common/newMasterCourseForm";

// rest services
import getCourse from "../services/courseService";

// utilities
import { checkCharacters } from "../utils/specialCharacters";

// config files
import {
  noError,
  courseMessage1,
  mastercourseTitle,
  classTables,
  classTitle,
} from "../config.json";

class MasterCourse extends Component {
  //
  // master course screen
  //

  // local data
  state = {
    listArray: [],
    allListArray: [],
    downloadListArray: [],
    searchQuery: "",
    isLoaded: false,
    fileName: "SAMDOWNLOADVAKKEN",
    isUpdateError: false,
    isNew: false,
  };

  async componentDidMount() {
    //
    // mount
    //

    let listArray = [];
    let downloadListArray = [];
    let allListArray = [];

    if (typeof this.props.allMasterCourseList !== "undefined") {
      allListArray = this.props.allMasterCourseList;
      listArray = allListArray;
      // local array
      listArray = _.sortBy(listArray, function (item) {
        return item.text;
      });
    }

    // download version for list
    downloadListArray = this.createDownloadList(listArray);

    this.setState({
      isLoaded: true,
      downloadListArray,
      listArray,
      allListArray,
    });
  }

  refreshMasterCourse = async () => {
    //
    // refresh mastercourse data
    //

    this.setState({ listArray: [], allListArray: [] });

    let listArray = [];
    let masterCourseList = [];
    let downloadListArray = [];

    const { session } = this.props;

    // mastercourse get
    const jsonList = await getCourse.allMasterCourses(session);
    const dbreturnList = jsonList.jsonReturn;

    if (dbreturnList[0].errorcode === noError) {
      // update global
      this.props.onSaveAllMasterCourseList(jsonList.masterCourseList);

      // local array
      listArray = _.sortBy(jsonList.masterCourseList, function (item) {
        return item.text;
      });

      // download version for list
      downloadListArray = this.createDownloadList(listArray);

      // master courses get to update dropdown for courses
      const result3 = await getCourse.masterCourse(session);
      if (result3.jsonReturn[0].errorcode === noError) {
        masterCourseList = result3.masterCourseList;
      }
      this.props.onSaveMasterCourseList(masterCourseList);

      this.setState({
        downloadListArray,
        listArray,
        allListArray: listArray,
        isLoaded: true,
      });
    } else {
      this.setState({
        downloadListArray,
        listArray: [],
        allListArray: [],
        isLoaded: false,
      });
    }
  };

  createDownloadList = (listArray) => {
    //
    // create download list
    //

    let downloadList = [];

    for (let index = 0; index < listArray.length; index++) {
      downloadList.push({
        nummer: listArray[index].mastercourseid,
        vak: listArray[index].text,
        actief: listArray[index].active,
        soort: listArray[index].type,
      });
    }

    return downloadList;
  };

  handleRefresh = async () => {
    //
    // Refresh
    //

    // console.log("handleRefresh");
    await this.refreshMasterCourse();
  };

  handleSearch = (query) => {
    //
    // execute search filter
    //
    let listArray = [];
    let downloadListArray = [];

    const { allListArray } = this.state;

    if (query !== "") {
      listArray = allListArray.filter((m) =>
        m.searchfield.toLowerCase().includes(query.toLowerCase())
      );
    } else {
      listArray = allListArray;
    }

    // local array
    listArray = _.sortBy(listArray, function (item) {
      return item.text;
    });

    // download version for list
    downloadListArray = this.createDownloadList(listArray);

    this.setState({
      downloadListArray,
      listArray,
      searchQuery: query,
    });
  };

  scrollTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  render() {
    //
    // render task screen
    //

    //console.log("render(): ");

    // get local state variables
    const {
      listArray,
      allListArray,
      downloadListArray,
      isLoaded,
      fileName,
      searchQuery,
      isUpdateError,
      isNew,
    } = this.state;
    const { isAdmin } = this.props;

    // don't render if not available
    if (typeof allListArray[0] === "undefined") {
      ////console.log("render: ", new Date());
      return null;
    }

    return (
      <React.Fragment>
        {/* error messages */}

        {isUpdateError && (
          <div className="alert alert-danger">{courseMessage1}</div>
        )}

        {/* navigation bar */}
        <NavBar />

        {/* header with school, teacher */}

        {/* title */}
        <h4>
          <span className={classTitle}>{mastercourseTitle}</span>
        </h4>

        {/* button row for global actions */}

        <TopButton
          handleRefresh={this.handleRefresh}
          listArray={downloadListArray}
          fileName={fileName}
          handleNew={this.handleNew}
        />

        {/* new */}
        {isNew && (
          <div className={classTables}>
            <NewMasterCourseForm
              newItem={this.handleNewItem}
              cancelItem={this.handleCancelItem}
            />
          </div>
        )}

        {/* table */}
        {isLoaded && (
          <div className={classTables}>
            <SearchBox value={searchQuery} onChange={this.handleSearch} />
            <MasterCourseTable
              listArray={listArray}
              changeItem={this.changeItem}
              copyItem={this.copyItem}
              deleteItem={this.deleteItem}
              isAdmin={isAdmin}
            />
          </div>
        )}
      </React.Fragment>
    );
  }

  /*****************/
  /* row functions */
  /*****************/
  handleNew = () => {
    //
    // new
    //

    this.scrollTop();

    this.setState({
      isNew: true,
    });
  };

  handleCancelItem = () => {
    //
    // cancel
    //

    this.setState({ isNew: false });
  };

  handleNewItem = async (itemData) => {
    //
    // insert group
    //

    const { session } = this.props;

    this.setState({
      listArray: [],
      allListArray: [],
      isUpdateError: false,
    });

    // insert mastercourse
    const jsonList = await getCourse.insertMasterCourse(
      session,
      itemData.label,
      itemData.coursetype
    );
    const dbreturnList = jsonList.jsonReturn;

    if (dbreturnList[0].errorcode === noError) {
      // refresh data
      await this.refreshMasterCourse();
      this.setState({
        isNew: false,
      });
    } else {
      // error
      this.setState({
        isUpdateError: true,
      });
    }
  };

  changeItem = async (itemData) => {
    //
    // change item
    //
    this.setState({ listArray: [], allListArray: [], isUpdateError: false });

    const { session } = this.props;

    //console.log("changeItem: ", itemData);

    // convert special characters
    const convertedGroupText = checkCharacters(itemData.text);

    // update mastercourse
    const jsonList = await getCourse.updateMasterCourse(
      session,
      itemData.mastercourseid,
      itemData.active,
      convertedGroupText,
      itemData.type
    );
    const dbreturnList = jsonList.jsonReturn;

    if (dbreturnList[0].errorcode === noError) {
      // refresh data
      await this.refreshMasterCourse();
    } else {
      // error
      this.setState({
        isUpdateError: true,
      });
    }
  };

  copyItem = async (itemData) => {
    //
    // copy item
    //
    const { session } = this.props;

    this.setState({
      listArray: [],
      allListArray: [],
      isUpdateError: false,
    });

    //console.log("copyItem: ", itemData);

    itemData.text = "1k - " + itemData.text;
    // insert mastercourse
    const jsonList = await getCourse.insertMasterCourse(
      session,
      itemData.text,
      itemData.type
    );
    const dbreturnList = jsonList.jsonReturn;

    if (dbreturnList[0].errorcode === noError) {
      // refresh data
      await this.refreshMasterCourse();
    } else {
      // error
      this.setState({
        isUpdateError: true,
      });
    }
  };
  deleteItem = async (itemData) => {
    //
    // inactivate item
    //
    this.setState({
      listArray: [],
      allListArray: [],
      isUpdateError: false,
    });

    let checkError = "E99";

    const { session } = this.props;

    //console.log("deleteItem: ", itemData);

    // try to delete mastercourse
    const jsonList1 = await getCourse.deleteMasterCourse(
      session,
      itemData.mastercourseid
    );

    if (jsonList1.jsonReturn[0].errorcode === noError) {
      checkError = noError;
    } else {
      if (itemData.active === "X") {
        itemData.active = "";
      } else {
        itemData.active = "X";
      }

      // update groups
      const jsonList2 = await getCourse.updateMasterCourse(
        session,
        itemData.mastercourseid,
        itemData.active,
        itemData.text,
        itemData.type
      );
      if (jsonList2.jsonReturn[0].errorcode === noError) {
        checkError = noError;
      }
    }

    if (checkError === noError) {
      // refresh data
      await this.refreshMasterCourse();
    } else {
      // error
      this.setState({
        isUpdateError: true,
      });
    }
  };
}

export default MasterCourse;
