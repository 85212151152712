//
// Purpose: SAMB - top waiting message
// Author: Ronny Van Elewyck
// Version: 1.0
// Creation Date: 10.06.2020
// Modification Date: 10.06.2020
//

// installed components
import React from "react";

// created components

// config files

const TopWaitingMessage = ({ messageToShow }) => {
  return (
    <div className="d-flex justify-content-center">
      <div className="spinner-grow text-warning" role="status"></div>
      <div className="alert alert-warning ml-2">{messageToShow}</div>
      <div className="spinner-grow text-warning ml-2" role="status"></div>
    </div>
  );
};

export default TopWaitingMessage;
