//
// Purpose: SAMB - print steps
// Author: Ronny Van Elewyck
// Version: 1.2
// Creation Date: 16.07.2020
// Modification Date: 11.12.2020
//

// installed components
import React, { Component } from "react";

// created components
import ErrorTable from "./common/errorTable";

// utilities

// rest services
import getPrint from "../services/printService";

// config files
import { noError, errorTableTitle1 } from "../config.json";

class PrintWF extends Component {
  //
  // change steps screen
  //

  // local data
  state = {
    headerList: [],
    stepList: [],
    materialList: [],
    ingredientList: [],
    allergenList: [],
    inputTextError: undefined,
    isLoaded: false,
    isShowMaterial: false,
    isShowIngredient: false,
  };

  async componentDidMount() {
    //
    // mount
    //

    if (typeof this.props.location !== "undefined") {
      //console.log(this.props.location.state.item.mastertaskid1);
      await this.refreshWFData(this.props.location.state.item.mastertaskid1);
    }
  }

  refreshWFData = async (mastertaskid) => {
    //
    // show workflow in printable version
    //

    let headerList = [];
    let stepList = [];
    let materialList = [];
    let ingredientList = [];
    let allergenList = [];
    let isShowMaterial = false;
    let isShowIngredient = false;
    let noErrorFromGet = true;
    let inputTextError = [];

    let text1 = null;
    let text2 = null;
    let text3 = null;
    let text4 = null;
    let text5 = null;
    let text6 = null;
    let text7 = null;
    let text8 = null;
    let text9 = null;
    let text10 = null;
    let imageurl1 = null;
    let height1 = 0;
    let width1 = 0;
    let imageurl2 = null;
    let height2 = 0;
    let width2 = 0;
    let movieurl = null;
    let imageurl = null;
    let uom = "";
    let noZeroes = 0;

    this.setState({
      headerList,
      stepList,
      materialList,
      ingredientList,
      allergenList,
      errorTableTitle1,
      isLoaded: false,
    });

    const { session } = this.props;

    const result = await getPrint.getData(session, mastertaskid);

    for (let index = 0; index < result.jsonReturn.length; index++) {
      if (result.jsonReturn[index].errorcode !== noError) {
        noErrorFromGet = false;
      }
    }

    if (noErrorFromGet) {
      headerList = result.headerList;
      //stepList = result.stepList;
      //materialList = result.materialList;
      //ingredientList = result.ingredientList;
      //console.log(result.materialList);
      //console.log(result.stepList);
      for (let index = 0; index < result.materialList.length; index++) {
        if (
          result.materialList[index].imageurl === "" ||
          result.materialList[index].imageurl === null ||
          result.materialList[index].imageurl === "null"
        ) {
          imageurl = null;
        } else {
          imageurl = result.materialList[index].imageurl;
        }
        materialList.push({
          key: index + 1,
          text:
            result.materialList[index].text +
            " " +
            result.materialList[index].quantity +
            " maal",
          imageurl: imageurl,
        });
      }

      for (let index = 0; index < result.ingredientList.length; index++) {
        noZeroes = result.ingredientList[index].quantity * 1;

        if (
          result.ingredientList[index].imageurl === "" ||
          result.ingredientList[index].imageurl === null ||
          result.ingredientList[index].imageurl === "null"
        ) {
          imageurl = null;
        } else {
          imageurl = result.ingredientList[index].imageurl;
        }
        if (typeof result.ingredientList[index].uom === "undefined") {
          uom = "";
        } else {
          uom = result.ingredientList[index].uom;
        }
        if (noZeroes === 0) {
          text1 = result.ingredientList[index].text + " " + uom;
        } else {
          text1 =
            result.ingredientList[index].text +
            " " +
            noZeroes.toString() +
            " " +
            uom;
        }

        ingredientList.push({
          key: index + 1,
          text: text1,
          imageurl: imageurl,
        });
      }

      if (result.allergenList.length > 0) {
        for (let index = 0; index < result.allergenList.length; index++) {
          allergenList.push({
            key: result.allergenList[index].allergenid,
            text: result.allergenList[index].allergentext,
            imageurl: result.allergenList[index].allergenurl,
          });
        }
      } else {
        allergenList.push({
          key: 1,
          text: "geen allergenen",
          imageurl:
            "https://www.qognimacloud.be/node/images/geen_allergenen.png",
        });
      }

      for (let index = 0; index < result.stepList.length; index++) {
        if (
          result.stepList[index].text1 === "" ||
          result.stepList[index].text1 === null ||
          result.stepList[index].text1 === "null"
        ) {
          text1 = null;
        } else {
          text1 = result.stepList[index].text1;
        }
        if (
          result.stepList[index].text2 === "" ||
          result.stepList[index].text2 === null ||
          result.stepList[index].text2 === "null"
        ) {
          text2 = null;
        } else {
          text2 = result.stepList[index].text2;
        }
        if (
          result.stepList[index].text3 === "" ||
          result.stepList[index].text3 === null ||
          result.stepList[index].text3 === "null"
        ) {
          text3 = null;
        } else {
          text3 = result.stepList[index].text3;
        }
        if (
          result.stepList[index].text4 === "" ||
          result.stepList[index].text4 === null ||
          result.stepList[index].text4 === "null"
        ) {
          text4 = null;
        } else {
          text4 = result.stepList[index].text4;
        }
        if (
          result.stepList[index].text5 === "" ||
          result.stepList[index].text5 === null ||
          result.stepList[index].text5 === "null"
        ) {
          text5 = null;
        } else {
          text5 = result.stepList[index].text5;
        }
        if (
          result.stepList[index].text6 === "" ||
          result.stepList[index].text6 === null ||
          result.stepList[index].text6 === "null"
        ) {
          text6 = null;
        } else {
          text6 = result.stepList[index].text6;
        }
        if (
          result.stepList[index].text7 === "" ||
          result.stepList[index].text7 === null ||
          result.stepList[index].text7 === "null"
        ) {
          text7 = null;
        } else {
          text7 = result.stepList[index].text7;
        }
        if (
          result.stepList[index].text8 === "" ||
          result.stepList[index].text8 === null ||
          result.stepList[index].text8 === "null"
        ) {
          text8 = null;
        } else {
          text8 = result.stepList[index].text8;
        }
        if (
          result.stepList[index].text9 === "" ||
          result.stepList[index].text9 === null ||
          result.stepList[index].text9 === "null"
        ) {
          text9 = null;
        } else {
          text9 = result.stepList[index].text9;
        }
        if (
          result.stepList[index].text10 === "" ||
          result.stepList[index].text10 === null ||
          result.stepList[index].text10 === "null"
        ) {
          text10 = null;
        } else {
          text10 = result.stepList[index].text10;
        }
        if (
          result.stepList[index].imageurl1 === "" ||
          result.stepList[index].imageurl1 === null ||
          result.stepList[index].imageurl1 === "null"
        ) {
          imageurl1 = null;
        } else {
          imageurl1 = result.stepList[index].imageurl1;
        }
        if (
          result.stepList[index].imageurl2 === "" ||
          result.stepList[index].imageurl2 === null ||
          result.stepList[index].imageurl2 === "null"
        ) {
          imageurl2 = null;
        } else {
          imageurl2 = result.stepList[index].imageurl2;
        }
        if (
          result.stepList[index].movieurl === "" ||
          result.stepList[index].movieurl === null ||
          result.stepList[index].movieurl === "null"
        ) {
          movieurl = null;
        } else {
          movieurl = result.stepList[index].movieurl;
        }

        // width and height
        if (
          result.stepList[index].screenformatid === "C1" ||
          result.stepList[index].screenformatid === "C4" ||
          result.stepList[index].screenformatid === "C8" ||
          result.stepList[index].screenformatid === "C10" ||
          result.stepList[index].screenformatid === "C11"
        ) {
          height1 = 400;
          width1 = 400;
        }
        if (
          result.stepList[index].screenformatid === "C8" ||
          result.stepList[index].screenformatid === "C11"
        ) {
          height2 = 400;
          width2 = 400;
        }
        if (
          result.stepList[index].screenformatid === "C2" ||
          result.stepList[index].screenformatid === "C5"
        ) {
          height1 = 200;
          width1 = 400;
          height2 = 200;
          width2 = 400;
        }
        if (result.stepList[index].screenformatid === "C7") {
          height1 = 800;
          width1 = 800;
        }
        stepList.push({
          key: index + 1,
          title:
            result.stepList[index].steptext +
            " - " +
            result.stepList[index].steptitle,
          text1: text1,
          text2: text2,
          text3: text3,
          text4: text4,
          text5: text5,
          text6: text6,
          text7: text7,
          text8: text8,
          text9: text9,
          text10: text10,
          imageurl1: imageurl1,
          height1: height1,
          width1: width1,
          imageurl2: imageurl2,
          height2: height2,
          width2: width2,
          movieurl: movieurl,
        });
      }

      if (materialList.length === 0) {
        isShowMaterial = false;
      } else {
        isShowMaterial = true;
      }

      if (ingredientList.length === 0) {
        isShowIngredient = false;
      } else {
        isShowIngredient = true;
      }

      this.setState({
        inputTextError: undefined,
        headerList,
        stepList,
        materialList,
        ingredientList,
        allergenList,
        isLoaded: true,
        isShowMaterial,
        isShowIngredient,
      });
    } else {
      // fill error table
      for (let index = 0; index < result.jsonReturn.length; index++) {
        inputTextError.push({
          key: index + 1,
          errorText:
            result.jsonReturn[index].errorcode +
            " - " +
            result.jsonReturn[index].message,
        });
      }

      this.setState({
        inputTextError,
        isLoaded: false,
        isShowMaterial,
        isShowIngredient,
      });
    }
  };

  handleBack = () => {
    //
    // set history to go back
    //

    // back to parent
    this.props.history.push({
      pathname: "/steps",
      state: {},
    });
  };

  scrollTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  render() {
    //
    // render task screen
    //

    // console.log("render(): ");

    const { session } = this.props;

    const {
      headerList,
      stepList,
      materialList,
      ingredientList,
      allergenList,
      inputTextError,
      isLoaded,
      isShowIngredient,
      isShowMaterial,
    } = this.state;

    // don't render if not ready
    if (typeof session.companyimageurl === "undefined") {
      return null;
    }

    return (
      <React.Fragment>
        {/* error messages */}
        {typeof inputTextError !== "undefined" && (
          <ErrorTable
            inputTextError={inputTextError}
            title={errorTableTitle1}
          />
        )}
        {isLoaded && (
          <div className="table-responsive mt-3 mb-3">
            <table className="table table-bordered">
              <tbody>
                <tr>
                  <td className="ml-3 mr-3 d-flex justify-content-between">
                    <img
                      src={session.companyimageurl}
                      alt={session.company_text1}
                      width="200"
                      height="100"
                    />
                    <h4>{session.company_text1}</h4>
                  </td>
                </tr>
                <tr>
                  <td className="ml-3 mr-3 d-flex justify-content-between">
                    <h4>
                      {headerList[0].mastertasktext +
                        " - " +
                        headerList[0].mastertasktext2}
                    </h4>
                    <img
                      src={headerList[0].imageurl}
                      alt={headerList[0].mastertasktext}
                      width="100"
                      height="100"
                    />
                  </td>
                </tr>
                {isShowMaterial && (
                  <tr>
                    <td className="ml-3 mr-3 d-flex justify-content-left">
                      <h5>Materialen/Producten</h5>
                      <ul>
                        {materialList.map((item) => (
                          <li key={item.key}>
                            <h6 className="mt-5">{item.text}</h6>
                            {item.imageurl !== null && (
                              <div className="mt-3">
                                <img
                                  src={item.imageurl}
                                  alt={"foto1"}
                                  width="140"
                                  height="110"
                                />
                              </div>
                            )}
                          </li>
                        ))}
                      </ul>
                    </td>
                  </tr>
                )}
                {isShowIngredient && (
                  <tr>
                    <td className="ml-3 mr-3 d-flex justify-content-left">
                      <h5>Ingrediënten</h5>
                      <ul>
                        {ingredientList.map((item) => (
                          <li key={item.key}>
                            <h6 className="mt-5">{item.text}</h6>
                            {item.imageurl !== null && (
                              <div className="mt-3">
                                <img
                                  src={item.imageurl}
                                  alt={"foto1"}
                                  width="140"
                                  height="110"
                                />
                              </div>
                            )}
                          </li>
                        ))}
                      </ul>
                    </td>
                  </tr>
                )}
                {isShowIngredient && (
                  <tr>
                    <td className="ml-3 mr-3 d-flex justify-content-left">
                      <h5>Allergenen</h5>
                      <ul>
                        {allergenList.map((item) => (
                          <li key={item.key}>
                            {item.imageurl !== null && (
                              <div className="mt-3">
                                <img
                                  src={item.imageurl}
                                  alt={"foto1"}
                                  width="80"
                                  height="80"
                                />
                              </div>
                            )}
                          </li>
                        ))}
                      </ul>
                    </td>
                  </tr>
                )}
                <tr>
                  <td className="ml-3 mr-3 d-flex justify-content-left">
                    <h5>Stappenplan</h5>

                    <ul>
                      {stepList.map((item) => (
                        <li key={item.key}>
                          <h5 className="mt-5">{item.title}</h5>
                          <ul>
                            {item.text1 !== null && <li>{item.text1}</li>}
                            {item.text2 !== null && <li>{item.text2}</li>}
                            {item.text3 !== null && <li>{item.text3}</li>}
                            {item.text4 !== null && <li>{item.text4}</li>}
                            {item.text5 !== null && <li>{item.text5}</li>}
                            {item.text6 !== null && <li>{item.text6}</li>}
                            {item.text7 !== null && <li>{item.text7}</li>}
                            {item.text8 !== null && <li>{item.text8}</li>}
                            {item.text9 !== null && <li>{item.text9}</li>}
                            {item.text10 !== null && <li>{item.text10}</li>}
                          </ul>
                          {item.imageurl1 !== null && (
                            <div className="mt-3">
                              <img
                                src={item.imageurl1}
                                width={item.width1}
                                height={item.height1}
                                alt=""
                              />
                            </div>
                          )}
                          {item.imageurl2 !== null && (
                            <div className="mt-3">
                              <img
                                src={item.imageurl2}
                                width={item.width2}
                                height={item.height2}
                                alt=""
                              />
                            </div>
                          )}
                          {item.movieurl !== null && (
                            <div className="mt-3">
                              <a href={item.movieurl}>{item.movieurl}</a>
                            </div>
                          )}
                        </li>
                      ))}
                    </ul>
                  </td>
                </tr>
                <tr>
                  <td className="ml-3 mr-3 d-flex justify-content-center">
                    <br></br>
                    <h6>powered by </h6>
                    <img
                      src="https://www.qognimacloud.be/sam3/images/companylogo.png"
                      alt="powered by"
                      width="100"
                      height="50"
                    />
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        )}
      </React.Fragment>
    );
  }

  /*****************/
  /* row functions */
  /*****************/
}

export default PrintWF;
