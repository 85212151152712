//
// Purpose: SAMB - top goal buttons
// Author: Ronny Van Elewyck
// Version: 1.0
// Creation Date: 20.06.2020
// Modification Date: 29.10.2020
//

// installed components
import React from "react";
import { Typeahead } from "react-bootstrap-typeahead";
import "react-bootstrap-typeahead/css/Typeahead.css";
import Tooltip from "react-bootstrap/Tooltip";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";

// created components

// config files
import { classTopButtons, saveClass5, newClass5 } from "../../config.json";

const TopGoalButton = ({
  handleSaveGoal,
  handleNew,
  groupList,
  masterCourseList,
  groupid,
  handleChangeGroupid,
  mastercourseid,
  handleChangeMastercourseid,
}) => {
  function renderTooltip(title) {
    return <Tooltip id="button-tooltip">{title}</Tooltip>;
  }

  return (
    <div className={classTopButtons}>
      <form className="form-inline">
        {groupid !== 0 && mastercourseid !== 0 && (
          <div>
            <OverlayTrigger
              placement="left"
              delay={{ show: 250, hide: 400 }}
              overlay={renderTooltip("bewaar")}
            >
              <div>
                <button
                  type="button"
                  className={saveClass5}
                  onClick={() => handleSaveGoal()}
                ></button>{" "}
              </div>
            </OverlayTrigger>
          </div>
        )}
        {groupid !== 0 && mastercourseid !== 0 && (
          <div>
            <OverlayTrigger
              placement="left"
              delay={{ show: 250, hide: 400 }}
              overlay={renderTooltip("nieuw")}
            >
              <div>
                <button
                  type="button"
                  className={newClass5}
                  onClick={() => handleNew()}
                ></button>{" "}
              </div>
            </OverlayTrigger>
          </div>
        )}

        <label className="mt-3 ml-2 mb-2 mr-2">klas</label>
        <Typeahead
          className="mt-2 ml-2 mb-2 mr-2"
          id={"group"}
          labelKey={"label"}
          options={groupList}
          onChange={handleChangeGroupid}
          highlightOnlyResult={true}
          clearButton={true}
        />
        <label className="mt-3 ml-2 mb-2 mr-2">vak</label>
        <Typeahead
          className="mt-2 ml-2 mb-2 mr-2"
          id={"course"}
          labelKey={"label"}
          options={masterCourseList}
          onChange={handleChangeMastercourseid}
          highlightOnlyResult={true}
          clearButton={true}
        />
      </form>
    </div>
  );
};

export default TopGoalButton;
