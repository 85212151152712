//
// Purpose: SAMB - student selection Row
// Author: Ronny Van Elewyck
// Version: 1.0
// Creation Date: 04.06.2020
// Modification Date: 04.06.2020
//

// installed components
import React from "react";
import Tooltip from "react-bootstrap/Tooltip";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";

// config files

const StudentSelectionRow = ({ item, updateItem }) => {
  //
  // format row of table
  //

  function renderTooltip(title) {
    return <Tooltip id="button-tooltip">{title}</Tooltip>;
  }

  const formatBox1 =
    item.activated === "actief"
      ? "fa fa-power-off btn btn-outline-success  ml-2 mr-2 mb-2 mt-2"
      : "fa fa-power-off btn btn-outline-danger  ml-2 mr-2 mb-2 mt-2";

  return (
    <tr>
      <td>
        <div>
          <OverlayTrigger
            placement="left"
            delay={{ show: 250, hide: 400 }}
            overlay={renderTooltip("zet leerling actief/niet actief")}
          >
            <div>
              <button
                className={`${formatBox1}`}
                onClick={() => updateItem({ ...item })}
              ></button>
            </div>
          </OverlayTrigger>
        </div>
      </td>
      <td>{item.name}</td>
    </tr>
  );
};

export default StudentSelectionRow;
